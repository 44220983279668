  <h3>{{ 'cc.common.view.attributes' | translate }}</h3>
    <div class="row mc-attributes">
      <div class="col-md-12">

        <!--Entity attributes Modal-->
        <mc-edit-entity-attributes-modal [editEntityId]="editEntityId"
                                         [markedInheritedAttributes]="currentEntityInheritedAttributes"
                                         [localAttributesData]="customAttributeList"
                                         [inheritedAttributesData]="inheritedAttributeList"
                                         (entityAttributesEvent)="onSuccessEditAttributes($event)"></mc-edit-entity-attributes-modal>

        <!--Table wrapper-->
        <div class="mc-attributes">

          <div class="row">
            <!--Local attributes-->
            <div class="col-md-6">
              <h5 class="attributes-title-color">{{'cc.entity-profile.view.local-attributes' | translate}}</h5>
              <div class="mc-table-local-attributes-wrapper pr-4">
                <table class="table table-borderless mc-table-local-attributes">
                  <thead class="mc-table-header-local-attributes">
                  <tr>
                    <th class="text-uppercase"></th>
                    <th class="text-uppercase"></th>
                    <th class="text-uppercase"></th>
                    <th class="text-uppercase mc-header-text">NAME</th>
                    <th class="text-uppercase mc-header-text">TYPE</th>
                    <th
                      class="text-uppercase mc-header-text">{{ 'cc.common.view.default-value' | translate }}</th>
                    <th class="text-uppercase"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let attribute of customAttributeList; let i = index" class="border-bottom-row">
                    <td>
                      <img
                        [src]="attribute.enabled === true ? attributesIcons.enable.path : attributesIcons.disable.path"
                        [alt]="attribute.enabled === true ? attributesIcons.enable.alt : attributesIcons.disable.alt">
                    </td>
                    <td>
                      <img
                        [src]="attribute.inheritable === true ? attributesIcons.inheritable.path : attributesIcons.notInheritable.path"
                        [alt]="attribute.inheritable === true ? attributesIcons.inheritable.alt : attributesIcons.notInheritable.alt">
                    </td>
                    <td>
                      <img
                        [src]="attribute.required === true ? attributesIcons.required.path : attributesIcons.notRequired.path"
                        [alt]="attribute.required === true ? attributesIcons.required.alt : attributesIcons.notRequired.alt">
                    </td>
                    <td>{{attribute.name}}</td>
                    <td [ngSwitch]="attribute.type">
                            <span
                              *ngSwitchCase="'INTEGER'">{{attribute.length ? ('Number' + '(' + attribute.length + ')') : 'Number'}}</span>
                      <span
                        *ngSwitchCase="'VARCHAR'">{{attribute.length ? ('String' + '(' + attribute.length + ')') : 'String'}}</span>
                      <span
                        *ngSwitchCase="'DATETIME'">{{attribute.length ? ('Date Time' + '(' + attribute.length + ')') : 'Date Time'}}</span>
                      <span
                        *ngSwitchCase="'DATE'">{{attribute.length ? ('Date ' + '(' + attribute.length + ')') : 'Date '}}</span>
                      <span
                        *ngSwitchCase="'FLOAT'">{{attribute.length ? ('Decimal' + '(' + attribute.length + ')') : 'Decimal'}}</span>
                      <span
                        *ngSwitchCase="'BOOLEAN'">{{attribute.length ? ('Yes/No' + '(' + attribute.length + ')') : 'Yes/No'}}</span>
                    </td>
                    <td *ngIf="attribute.defaultValue" [ngSwitch]="attribute.type">
                            <span
                              *ngSwitchCase="'DATETIME'">{{attribute.defaultValue.day + "/" + attribute.defaultValue.month + "/" + attribute.defaultValue.year}}</span>
                      <span *ngSwitchDefault>{{attribute.defaultValue}}</span>
                    </td>
                    <td *ngIf="!attribute.defaultValue"></td>
                    <td>
                      <img [src]="hoveredIcon === i ? attributesWhiteInfoIcon : attributesInfoIcon"
                           class="mc-attribute-info" (mouseover)="hoveredIcon = i" (mouseout)="hoveredIcon = null"
                           [alt]="attributesIcons.info.alt" placement="left"
                           tooltipClass="description-attribute">
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!--Inherited attributes-->
            <div *ngIf="parentEntityId" class="col-md-6">
              <h5 class="attributes-title-color">Inherited parent attributes</h5>
              <div class="mc-table-inherited-attributes-wrapper">
                <table class="table table-borderless mc-table-inherited-attributes">
                  <thead class="mc-table-header-inherited-attributes">
                  <tr>
                    <th class="text-uppercase"></th>
                    <th class="text-uppercase"></th>
                    <th class="text-uppercase"></th>
                    <th class="text-uppercase mc-header-text">NAME</th>
                    <th class="text-uppercase mc-header-text">TYPE</th>
                    <th
                      class="text-uppercase mc-header-text">{{ 'cc.common.view.default-value' | translate }}</th>
                    <th class="text-uppercase"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let attribute of inheritedAttributeList; let i = index" class="border-bottom-row"
                      [ngClass]="{'mc-select-background' : currentEntityInheritedAttributes?.includes(attribute.name)}">
                    <td>
                      <img
                        [src]="attribute.enabled === true ? attributesIcons.enable.path : attributesIcons.disable.path"
                        [alt]="attribute.enabled === true ? attributesIcons.enable.alt : attributesIcons.disable.alt">
                    </td>
                    <td>
                      <img
                        [src]="attribute.inheritable === true ? attributesIcons.inheritable.path : attributesIcons.notInheritable.path"
                        [alt]="attribute.inheritable === true ? attributesIcons.inheritable.alt : attributesIcons.notInheritable.alt">
                    </td>
                    <td>
                      <img
                        [src]="attribute.required === true ? attributesIcons.required.path : attributesIcons.notRequired.path"
                        [alt]="attribute.required === true ? attributesIcons.required.alt : attributesIcons.notRequired.alt">
                    </td>
                    <td>{{attribute.name}}</td>
                    <td [ngSwitch]="attribute.type">
                            <span
                              *ngSwitchCase="'INTEGER'">{{attribute.length ? ('Number' + '(' + attribute.length + ')') : 'Number'}}</span>
                      <span
                        *ngSwitchCase="'VARCHAR'">{{attribute.length ? ('String' + '(' + attribute.length + ')') : 'String'}}</span>
                      <span
                        *ngSwitchCase="'DATETIME'">{{attribute.length ? ('Date Time' + '(' + attribute.length + ')') : 'Date Time'}}</span>
                      <span
                        *ngSwitchCase="'DATE'">{{attribute.length ? ('Date ' + '(' + attribute.length + ')') : 'Date '}}</span>
                      <span
                        *ngSwitchCase="'FLOAT'">{{attribute.length ? ('Decimal' + '(' + attribute.length + ')') : 'Decimal'}}</span>
                      <span
                        *ngSwitchCase="'BOOLEAN'">{{attribute.length ? ('Yes/No' + '(' + attribute.length + ')') : 'Yes/No'}}</span>
                    </td>
                    <td>{{attribute.defaultValue}}</td>
                    <td>
                      <img [src]="hoveredIcon === 'inherit' + i ? attributesWhiteInfoIcon : attributesInfoIcon"
                           class="mc-attribute-info" (mouseover)="hoveredIcon = 'inherit' + i"
                           (mouseout)="hoveredIcon = null" [alt]="attributesIcons.info.alt" placement="left"
                           tooltipClass="description-attribute">
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div><!--Attributes /end-->

    <!--Else custom attributes-->
    <div class="mc-notify-wrapper">
      <mc-notify-message [message]="customAttributesMessage" [type]="customAttributesType"></mc-notify-message>
    </div>
