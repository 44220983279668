<h3 class="page-title">{{ 'cc.factoring.contracts' | translate}}</h3>

<div>
  <mcc-modal1-wrapper [showMcb]="showRejectMcb" *ngIf="showRejectMcb.value" (eventCanceled)="onRejectModalCanceled()" modalSizeCd="small">
    <app-shared-factoring-reject-contract-part [ebFactoring]="ebFactoring" (eventRejected)="actRejected()"
    (eventCancel)="onRejectModalCanceled()"></app-shared-factoring-reject-contract-part>
  </mcc-modal1-wrapper>
</div>

<mk-mat-table
  [isLoading]="isLoading"
  [items]="items"
  [config]="tableConfig"
  [itemsPerPage]="pageSize"
  [filterConfig]="filterConfig"
  [totalItemsCount]="totalItemsCount"
  [mkMatMenuActionItems]="tableActionItems"
  (actionMkMatMenuItems)="onTableAction($event)"
  (filterChanged)="onFilterChanged($event)"
  [searchTooltip]="searchTooltip"
  (eventColumnButtonAction)="onColumnButtonAction($event)"
></mk-mat-table>
<router-outlet></router-outlet>

