import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogContent } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { McBankAccountsCurrentEntityAdminModule,McComponentsModule,McStatusModule } from '@miticon-ui/mc-components';
import { NgbModule,NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { SafeHtmlModule } from '../../assets/pipes/safe-html/safe-html.module';
import { AppSharedModule } from '../app-shared/app-shared.module';
import { McEntityAttributesModule } from '../mc-management/mc-entity/components/mc-entity-attributes/mc-entity-attributes.module';
import { McEntityBasicInfoModule } from '../mc-management/mc-entity/components/mc-entity-basic-info/mc-entity-basic-info.module';
import { McEntityPasswordPolicyModule } from '../mc-management/mc-entity/components/mc-entity-password-policy/mc-entity-password-policy.module';
import { McInvoiceModule } from '../mc-management/mc-invoice/mc-invoice.module';
import { McEditEntityAttributesModalComponent } from './components/mc-edit-entity-attributes-modal/mc-edit-entity-attributes-modal.component';
import { McEditEntityBasicInfoModalComponent } from './components/mc-edit-entity-basic-info-modal/mc-edit-entity-basic-info-modal.component';
import { McEditEntityPasswordPolicyModalComponent } from './components/mc-edit-entity-password-policy-modal/mc-edit-entity-password-policy-modal.component';
import { McEntityAttributesTabComponent } from './components/mc-entity-attributes-tab/mc-entity-attributes-tab.component';
import { McEntityBankAccountsTabComponent } from './components/mc-entity-bank-accounts-tab/mc-entity-bank-accounts-tab.component';
import { McEntityBasicInfoTabComponent } from './components/mc-entity-basic-info-tab/mc-entity-basic-info-tab.component';
import { McEntityInvitationsTabComponent } from './components/mc-entity-invitations-tab/mc-entity-invitations-tab.component';
import { McEntitySettingsTabComponent } from './components/mc-entity-settings-tab/mc-entity-settings-tab.component';
import { McEntityTransactionDescriptionModalComponent } from './components/mc-entity-transaction-description-modal/mc-entity-transaction-description-modal.component';
import { McProfileActivationDialogComponent } from './components/mc-profile-activation-dialog/mc-profile-activation-dialog.component';
import { McSendInvitationDialogComponent } from './components/mc-send-invitation-dialog/mc-send-invitation-dialog.component';
import { McEntityProfileRoutingModule } from './mc-entity-profile-routing.module';
import { McEntityProfileComponent } from './mc-entity-profile.component';
import { McEntityFactoringWrapperPartChildComponent } from './parts/mc-entity-factoring-wrapper-part/mc-entity-factoring-wrapper-part-child.component';
import { McEntityFactoringWrapperPartParentComponent } from './parts/mc-entity-factoring-wrapper-part/mc-entity-factoring-wrapper-part-parent.component';

@NgModule({
  declarations: [
    McEntityProfileComponent,
    McEditEntityBasicInfoModalComponent,
    McEditEntityAttributesModalComponent,
    McEntityTransactionDescriptionModalComponent,
    McEditEntityPasswordPolicyModalComponent,
    McEntityFactoringWrapperPartParentComponent,
    McEntityFactoringWrapperPartChildComponent,
    McEntityFactoringWrapperPartChildComponent,
    McSendInvitationDialogComponent,
    McEntityBasicInfoTabComponent,
    McEntityBankAccountsTabComponent,
    McEntityAttributesTabComponent,
    McEntitySettingsTabComponent,
    McEntityInvitationsTabComponent,
    McProfileActivationDialogComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    NgbTooltipModule,
    McEntityProfileRoutingModule,
    McStatusModule,
    McComponentsModule,
    ReactiveFormsModule,
    McEntityBasicInfoModule,
    McEntityAttributesModule,
    McEntityPasswordPolicyModule,
    TranslateModule,
    McInvoiceModule,
    McBankAccountsCurrentEntityAdminModule,
    FormsModule,
    AppSharedModule,
    SafeHtmlModule,
    McComponentsModule,
    AppSharedModule,
    SafeHtmlModule,
    MatButton,
    MatDialogContent,
    MatFormField,
    MatInput,
    SafeHtmlModule,
    MatRadioModule,
  ],
  exports: [
    McEntityProfileRoutingModule,
    McEntityProfileComponent,
    McEntityBasicInfoTabComponent,
    McEntityBankAccountsTabComponent,
    McEntityAttributesTabComponent,
    McEntitySettingsTabComponent,
    McEntityInvitationsTabComponent,
    McProfileActivationDialogComponent,
  ],
})
export class McEntityProfileModule {}
