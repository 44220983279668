import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PpOrderServiceGENERATED } from '../_generated/pp-order-generated.service';
import { IPpOrder } from '../_generated/pp-order.interface';


@Injectable({
  providedIn: 'root'
})
export class PpOrderService extends PpOrderServiceGENERATED {

  public generateInvoicesBulk(orderParams: any): Observable<any> {
    return this.httpClient.post<IPpOrder>(
      this.getApiServiceRootUrl() + `/generate-orders-by-type`,
      orderParams
    );
  }

  public generatePreviewByOrderId(orderId: number): Observable<any> {
    return this.httpClient.get(
      this.getApiServiceRootUrl() + `/generate-pdf-preview/${orderId}`,
      { responseType: 'blob' }
    )
  }

  public hasLicensePreview(entityId: number): Observable<any> {
    return this.httpClient.get(
      this.getApiServiceRootUrl() + `/${entityId}/has-license-preview`
    )
  }

  public generateLicensePreviewByOrderId(orderId: number): Observable<any> {
    return this.httpClient.get(
      this.getApiServiceRootUrl() + `/${orderId}/license`,
      { responseType: 'blob' }
    )
  }

  public scheduleSendingInvoice(orderId: number, body: { sendingDate: any }): Observable<any>{
    return this.httpClient.put<any>(this.getApiServiceRootUrl() + `/schedule/${orderId}`, body);
  }
}
