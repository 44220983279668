<ng-template #content let-modal>
  <!--Modal header section-->
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <!--Modal body section-->
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12 px-0">
        <h4 class="modal-title mb-0" id="modal-basic-title">{{'cc.entity-profile.view.edit-entity\'s-attributes' | translate}}:</h4>
      </div>
    </div>
    <mc-entity-attributes [editEntityId]="editEntityId" [markedInheritedAttributes]="markedInheritedAttributes" [localAttributesList]="localAttributesData" [inheritedAttributesList]="inheritedAttributesData" [isEdit]="isFormEdit"></mc-entity-attributes>
  </div>
</ng-template>

<h5 class="mc-entity-profile-title ml-2">Attributes <i *ngIf="localAttributesData || inheritedAttributesData" class="fa fa-pen mc-pen-icon mc-cursor-pointer" (click)="openModal(content)"></i></h5>
