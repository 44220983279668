import { Component, DoCheck, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  CsrContractPreDefined,
  CsrContractPreDefinedService,
  McForm,
  McValueLabelList
} from '@miticon-ui/mc-core';
import {ActivatedRoute} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'mc-consumer-consumer-360-create-contract-products-and-services-part',
  templateUrl: './mc-consumer-consumer-360-create-contract-products-and-services-part.component.html',
  styleUrls: ['./mc-consumer-consumer-360-create-contract-products-and-services-part.component.scss']
})
export class McConsumerConsumer360CreateContractProductsAndServicesPartComponent implements OnInit, DoCheck {

  /*Variables*/
  @Input() selectedProduct: any;
  productsVll = new McValueLabelList();
  allContracts: CsrContractPreDefined[] = [];
  @Input() mcForm!: McForm;
  @Output() emitSelectedContract = new EventEmitter();
  @Output() emitValidRenewal = new EventEmitter();
  @Output() emitErrorMsg = new EventEmitter();
  todayDate = new Date();
  showContractRenewal = false;
  confirmationVll = new McValueLabelList();
  errorMsgRenewal = '';
  errorMsgNoticePeriod = '';


  constructor(private csrContractPredefinedService: CsrContractPreDefinedService,
              private route: ActivatedRoute,
              private tS: TranslateService) {
  }

  ngOnInit() {
    this.getPredefinedContracts();
    this.createConfirmationVll();
  }

  ngDoCheck() {
    const renewalValue = this.mcForm.getControl('ctrlRenewal')?.value.value;
    this.emitValidRenewal.emit(renewalValue);
  }

  validateRenewalPeriodInput(periodInMonths: any) {
    this.errorMsgRenewal = '';

    if (periodInMonths === '') return;
    if (!Number.isInteger(Number(periodInMonths))) this.errorMsgRenewal = this.tS.instant('cc.common.please-enter-whole-number');
    if (periodInMonths <= 0 || periodInMonths > 360) this.errorMsgRenewal = this.tS.instant('cc.common.entry-not-valid');

    switch (this.selectedProduct.typeCd) {
      case 'QUARTERLY':
       if (periodInMonths % 3 !== 0) this.errorMsgRenewal = this.tS.instant('cc.contract.renewal-period-must-be-in-multiples-3') + this.tS.instant('cc.common.please-enter-valid-value');
        break;
      case 'HALF_YEARLY':
        if (periodInMonths % 6 !== 0) this.errorMsgRenewal = this.tS.instant('cc.contract.renewal-period-must-be-in-multiples-6') + this.tS.instant('cc.common.please-enter-valid-value');
        break;
      case 'YEARLY':
        if (periodInMonths % 12 !== 0) this.errorMsgRenewal = this.tS.instant('cc.contract.renewal-period-must-be-in-multiples-12') + this.tS.instant('cc.common.please-enter-valid-value');
        break;
      default:
        break;
    }

    this.emitErrorMsg.emit(this.errorMsgRenewal || this.errorMsgNoticePeriod)
  }

  validateNoticeRenewalPeriod(periodInMonths: any) {
    this.errorMsgNoticePeriod = ''

    if (periodInMonths === '') return;
    if (!Number.isInteger(Number(periodInMonths))) this.errorMsgNoticePeriod = this.tS.instant('cc.common.please-enter-whole-number');
    if (periodInMonths <= 0 || periodInMonths > this.selectedProduct.durationInMonths) this.errorMsgNoticePeriod = this.tS.instant('cc.contract.renewal-number-of-months-can-not-exceed') + '. '+ this.tS.instant('cc.common.please-enter-valid-value');

    this.emitErrorMsg.emit(this.errorMsgRenewal || this.errorMsgNoticePeriod)
  }


  changedRenewal(value: boolean){
    this.showContractRenewal = value;
  }

  getPredefinedContracts() {
    const consumerId = this.route.snapshot.params['id'];
    this.csrContractPredefinedService.getAvailableForPurchase(consumerId).subscribe((contracts) => {
      this.allContracts = contracts;
      if(contracts) {
        contracts.forEach((contract: any) => this.productsVll.add(contract.id, contract.name))
      }
    });
  }

  onProductClick(productId: number) {
    this.selectedProduct = this.allContracts.find((contract) => contract.id === productId);
    this.mcForm.getControl('ctrlContractStartDate')?.get('value')?.setValue(null)
    this.mcForm.getControl('ctrlContractEndDate')?.get('value')?.setValue(null);
    this.clearRenewalData();
    this.emitSelectedContract.emit(this.selectedProduct);
  }

  onStartDateChange(event: string): void {
    if(this.selectedProduct.durationInMonths) {
      const endDate = new Date(this.addXMonthsToDate(this.mcForm.getControl('ctrlContractStartDate')?.value.value, this.selectedProduct.durationInMonths));
      this.mcForm.getControl('ctrlContractEndDate')?.get('value')?.setValue(endDate);
    } else this.mcForm.getControl('ctrlContractEndDate')?.get('value')?.setValue(this.mcForm.getControl('ctrlContractStartDate')?.value.value);
  }

  createConfirmationVll(){
    this.confirmationVll.add(false, this.tS.instant('cc.common.edit.no'));
    this.confirmationVll.add(true, this.tS.instant('cc.common.edit.yes'));
  }

  clearRenewalData(){
    this.showContractRenewal = false;
    this.errorMsgRenewal = '';
    this.errorMsgNoticePeriod = '';
  }

  addXMonthsToDate(date: any, months: number) {
    const dateToBeFormatted = new Date(date);
    return dateToBeFormatted.setMonth(dateToBeFormatted.getMonth() + months)
  }

  getEndDate() {
    return this.mcForm.getControl('ctrlContractEndDate')?.value.value;
  }

  getPriceLabel(typeCd: string) {
    return CsrContractPreDefined.getLabel(typeCd).label;
  }

  getTypeCdLabel(typeCd: string) {
    return this.tS.instant(CsrContractPreDefined.getTypeCdLabel(typeCd));
  }

  isOneTimeType(){
    return this.selectedProduct.typeCd === CsrContractPreDefined.TYPE_CD_ONE_TIME;
  }

  getVat(): string {
    return CsrContractPreDefined.getVat(this.selectedProduct);
  }
}
