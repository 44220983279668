
import {Table3Config} from '../extra/table-3-config';

// import {McMappingGENERATED} from '../_generated/mc-mapping-generated.model';

export class McMappingTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colCreationDate', 'Creation date', 'creationDate', 'creationDate');
    this.addColumn('colDataSet', 'Data set', 'dataSet', 'dataSet');
    this.addColumn('colIdMcEntity', 'Entity ID', 'idMcEntity', 'idMcEntity');
    this.addColumn('colIdMcSystemUser', 'User ID', 'idMcSystemUser', 'idMcSystemUser');
    this.addColumn('colIdWebFile', 'Web file ID', 'idWebFile', 'idWebFile');
    this.addColumn('colMappingName', 'Mapping name', 'mappingName', 'mappingName');
    this.addColumn('colMcSystemUser', 'System user', 'mcSystemUser', 'mcSystemUser');
    this.addColumn('colSharedFlg', 'Shared mapping flag', 'sharedFlg', 'sharedFlg');
    this.addColumn('colSharingDate', 'Sharing date', 'sharingDate', 'sharingDate');

*/
  }
}
