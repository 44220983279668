<ng-container class="dialog">
  <div class="dialog_header">
    <span class="dialog_header_title text-uppercase">
      {{ "cc.invoice.schedule-sending-date-time" | translate }}
    </span>
    <span class="dialog_header_icon"
          [innerHTML]="iconClose | safeHtml"
          (click)="dialogRef.close(false)"></span>
  </div>

  <mat-dialog-content>
    <form [formGroup]="form">
      <div class="form_fields">
        <mat-form-field appearance="outline">
          <mat-label>{{'cc.invoice.choose-date' | translate}}</mat-label>
          <input matInput [matDatepicker]="datePicker" formControlName='date'
                 [min]="today"
                (click)='datePicker.open()' readonly>
          <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
          <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ "cc.jobs.edit.time" | translate }}</mat-label>
          <mat-select panelClass="dialog-select"
                      formControlName="hour">
            <mat-option *ngFor="let hour of hours"
                        [value]="hour.value">
              {{ hour.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </mat-dialog-content>

  <div class='dialog_footer'>
    <button mat-button
            class="secondary-btn"
            (click)="dialogRef.close(false)">
      {{ 'cc.common.edit.cancel' | translate }}
    </button>
    <button mat-button
            class="primary-btn"
            (click)="scheduleSendingInvoice()"
            [disabled]="!form.valid"
            type="submit">
      {{ 'cc.common.create' | translate }}
    </button>
  </div>
</ng-container>
