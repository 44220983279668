import {Component, OnInit} from '@angular/core';
import {
  McForm,
  McValueLabelList,
  McGod,
  CsrConsumerFilter,
  McConsumer,
  CsrConsumer,
  SortCriteriaList,
  CsrConsumerService, McBoolean
} from '@miticon-ui/mc-core';
import {ToastrService} from 'ngx-toastr';
import { Router } from '@angular/router';
import {MkFilterConfig, MkFilterItemType, MkFilterOutput, MkFilterValue, MkMatTableMenuAction, MkTableConfig} from "@miticon-ui/mc-components";
import {HttpParams} from "@angular/common/http";

@Component({
  selector: 'mc-consumer-consumer-360-smart-search-box',
  templateUrl: './mc-consumer-consumer-360-smart-search-box.component.html',
})
export class McConsumerConsumer360SmartSearchBoxComponent implements OnInit {
  mcForm = new McForm();
  mcGod = McGod.getInstance();
  items = [];
  totalItemsCount = 0;
  pageNumber = 0;
  pageSize = 50;
  typeCdsVll: McValueLabelList = McConsumer.getTypeCdVll();
  csrConsumer = new CsrConsumer();
  isLoading = false;
  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();
  searchTooltip = McGod.t('cc.consumer-360.search-tooltip');

  historyConsumerId = 0;
  showHistoryMcb = new McBoolean();
  showAddTransactionsManuallyMcb = new McBoolean();
  showActionHistoryMcb = new McBoolean();

  mkMatMenuActionItems = [
    {
      title: McGod.t('cc.common.profile'),
      matIcon: 'account_box',
      actionCd: CsrConsumer.ACTION_SHOW_PROFILE,
      permission: McGod.PERM_MC_CONSUMER_VIEW
    },
    {
      title: McGod.t('cc.factoring.transactions'),
      matIcon: 'payments',
      actionCd: CsrConsumer.ACTION_SHOW_TRANSACTIONS,
      permission: McGod.PERM_MC_CONSUMER_TRANSACTION_VIEW
    },
    {
      title: McGod.t('cc.common.view.add-transaction'),
      matIcon: 'add_circle_outline',
      actionCd: CsrConsumer.ACTION_SHOW_ADD_TRANSACTION,
      permission: McGod.PERM_EB_FACTORING_MY_TRANSACTION_ADD
    },
    {
      title: McGod.t('cc.common.invoices'),
      matIcon: 'receipt',
      actionCd: CsrConsumer.ACTION_SHOW_INVOICES,
      permission: McGod.PERM_CSR_CONSUMER_INVOICES_VIEW
    },
    {
      title: McGod.t('cc.consumers.view.history'),
      matIcon: 'remove_red_eye',
      actionCd: CsrConsumer.ACTION_SHOW_HISTORY,
      permission: McGod.PERM_MC_CONSUMER_HISTORY_VIEW
    },
    {
      title: 'Action History',
      matIcon: 'remove_red_eye',
      actionCd: CsrConsumer.ACTION_SHOW_ACTION_HISTORY,
      permission: McGod.PERM_MC_CONSUMER_ACTION_HISTORY_VIEW
    },
  ];


  constructor(private toastr: ToastrService,
              private router: Router,
              private csrConsumerService: CsrConsumerService) {
  }

  ngOnInit() {
    this.initTableConfig();
    this.initFilterConfig();

    this.actLoad(this.pageNumber, this.pageSize, new CsrConsumerFilter());
  }

  initTableConfig() {
    this.tableConfig.addColumnStandard(McGod.t('cc.consumer.view.consumer-id'), 'id', 250, 'id');
    this.tableConfig.addColumnSvgIconAction(McGod.t('cc.my-factoring.consumer'), 'getNameAndProfileLink()', 'Profile');
    this.tableConfig.addColumnStandard(McGod.t('cc.common.company-name'), 'getCompanyName()', 250, 'companyName');
    this.tableConfig.addColumnStandard(McGod.t('cc.common.edit.email'), 'email', 250, 'email');
    this.tableConfig.addColumnStandard(McGod.t('cc.jobs.edit.type'), 'getTypeCdLabel()', 250, 'typeCd');
    this.tableConfig.addColumnInnerHtml(McGod.t('cc.users.edit.country'), 'getCountryName()', 'countryName');
    this.tableConfig.addColumnStandard(McGod.t('cc.consumer.number-of-active-contracts'), 'getActiveContracts()', 250, 'contracts');
  }

  initFilterConfig() {
    const typeCds = this.typeCdsVll.items.map((item) => new MkFilterValue(item.label, item.value));
    const countriesCds = CsrConsumer.ISO_COUNTRIES.map((item) => new MkFilterValue(McGod.t(item.label), item.value));

    this.filterConfig.addItem(MkFilterItemType.SELECT, McGod.t(CsrConsumer.FILTER_TYPE), typeCds);
    this.filterConfig.addItem(MkFilterItemType.SELECT, McGod.t(CsrConsumer.FILTER_COUNTRY), countriesCds);
  }

  onFilterChanged(filters: MkFilterOutput) {
    const csrConsumerFilter = new CsrConsumerFilter();
    csrConsumerFilter.searchTerm = filters.search;
    csrConsumerFilter.typeCds = filters.selections[McGod.t(CsrConsumer.FILTER_TYPE)];
    csrConsumerFilter.countryCds = filters.selections[McGod.t(CsrConsumer.FILTER_COUNTRY)];

    this.actLoad(filters.pageEvent.pageIndex, filters.pageEvent.pageSize, csrConsumerFilter);
  }

  actLoad(pageNumber: number, pageSize: number, filters: CsrConsumerFilter) {
    this.isLoading = true;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;

    let params = new HttpParams()
      .set('term', filters.searchTerm ? filters.searchTerm : '')
      .set('page', this.pageNumber)
      .set('size',this.pageSize);

    if (filters.typeCds && filters.typeCds.length > 0) {
      params = params.append('typeCds', filters.typeCds.toString());
    }

    if (filters.countryCds && filters.countryCds.length > 0) {
      params = params.append('countryCds', filters.countryCds.toString());
    }

    const sortCriteria = new SortCriteriaList();
    sortCriteria.addSort('id', 'DESC');

    this.csrConsumerService.get(params, pageNumber, pageSize, sortCriteria)
      .subscribe((res) => {
        this.isLoading = false;
        this.totalItemsCount = res.totalElements;
        this.pageSize = pageSize;
        this.items = res.content.map((object: any) => object = CsrConsumer.createFromJson(object));
      });
  }

  onActionMkMatMenuItems($event: MkMatTableMenuAction) {
    this.csrConsumer = $event.item;
    switch ($event.action.actionCd) {
      case CsrConsumer.ACTION_SHOW_PROFILE: this.router.navigateByUrl(`/entity/${McGod.getLoggedEntityIdFromToken()}/consumers/consumer-360-tabs/${this.csrConsumer.id}/profile/${this.csrConsumer.id}`);
        break;
      case CsrConsumer.ACTION_SHOW_TRANSACTIONS: this.router.navigateByUrl(`/entity/${McGod.getLoggedEntityIdFromToken()}/consumers/transactions/${this.csrConsumer.id}`);
        break;
      case CsrConsumer.ACTION_SHOW_INVOICES: this.router.navigateByUrl(`/entity/${McGod.getLoggedEntityIdFromToken()}/consumers/consumer-360-tabs/${this.csrConsumer.id}/invoices/${this.csrConsumer.id}`);
        break;
      case CsrConsumer.ACTION_SHOW_ADD_TRANSACTION: this.actShowAddTransactionManually(this.csrConsumer);
        break;
      case CsrConsumer.ACTION_SHOW_HISTORY: this.actShowHistory(this.csrConsumer.id);
        break;
      case CsrConsumer.ACTION_SHOW_ACTION_HISTORY: this.actShowActionHistory(this.csrConsumer);
        break;
    }
  }

  onColumnButtonAction(event: any) {
    switch(event.actionCd) {
      case 'Profile':
        const consumerId = event.element.id;
        this.router.navigateByUrl(`/entity/${McGod.getLoggedEntityIdFromToken()}/consumers/consumer-360-tabs/${consumerId}/profile/${consumerId}`);
        break;
    }
  }

  addConsumer() {
    this.router.navigateByUrl(`/entity/${McGod.getLoggedEntityIdFromToken()}/consumers/add-360`);
  }

  private actShowAddTransactionManually(consumer: CsrConsumer) {
    if (consumer.exists()) {
      this.csrConsumer = consumer;
      this.showAddTransactionsManuallyMcb.setTrue();
    }
  }

  actAddTransactionManuallyCanceled() {
    this.showAddTransactionsManuallyMcb.setFalse();
    this.csrConsumer = new CsrConsumer();
  }

  actAddTransactionManuallySaved() {
    this.showAddTransactionsManuallyMcb.setFalse();
    this.csrConsumer = new CsrConsumer();
    this.toastr.success(this.mcGod.t('cc.transactions.transaction-added-successfully'));
    this.actLoad(this.pageNumber, this.pageSize, new CsrConsumerFilter());
  }

  actShowHistory(itemId = 0) {
    this.historyConsumerId = itemId;
    this.showHistoryMcb.setTrue();
  }

  actShowActionHistory(consumer: CsrConsumer) {
    this.csrConsumer = consumer;
    this.showActionHistoryMcb.setTrue();
  }

  actActionHistoryCancel() {
    this.showActionHistoryMcb.setFalse();
    this.csrConsumer = new CsrConsumer();
  }

  actGoToDataImport() {
    this.router.navigateByUrl(`/entity/${McGod.getLoggedEntityIdFromToken()}/data/import/add`);
  }
}
