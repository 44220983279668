import { Component,OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute,Router } from '@angular/router';
import { MkFilterConfig,MkFilterOutput,MkMatTableMenuAction,MkTableConfig } from '@miticon-ui/mc-components';
import {
  CsrContract,
  CsrContractList,
  CsrContractService,
  EbFactoringService,
  McConsumer,
  McCountry,
  McCurrency,
  McEntity2,
  McGod,
  SortCriteriaList,
  WebFile,
  WebFileService
} from '@miticon-ui/mc-core';
import { saveAs } from "file-saver";
import { CancelContractDialogComponent } from './cancel-contract-dialog/cancel-contract-dialog.component';
import { ViewContractDialogComponent } from './view-contract-dialog/view-contract-dialog.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'mc-consumer-consumer-360-contracts-part',
  templateUrl: './mc-consumer-consumer-360-contracts-part.component.html',
  styleUrls: ['./mc-consumer-consumer-360-contracts-part.component.scss']
})
export class McConsumerConsumer360ContractsPartComponent implements OnInit {

  csrContract!: CsrContract;
  webFile!: WebFile;
  csrContractList = new CsrContractList();
  consumerId!: number;
  mcGod = McGod.getInstance();
  mcEntity = new McEntity2();
  mcCountry = new McCountry();
  currency = new McCurrency();
  mcConsumer!: McConsumer;

  // table
  items = [];
  totalItemsCount = 0;
  pageNumber = 0;
  pageSize = 5;
  isLoading = false;
  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();
  mkMatMenuActionItems = [
    {
      title: McGod.t('cc.common.view.view'),
      matIcon: 'remove_red_eye',
      actionCd: 'View',
      permission: McGod.PERM_MC_CONSUMER_VIEW
    },
    {
      title: McGod.t('cc.out-factoring.download'),
      matIcon: 'download',
      actionCd: 'Download',
      permission: McGod.PERM_MC_CONSUMER_VIEW
    },
    {
      title: McGod.t('cc.common.edit.cancel'),
      matIcon: 'cancel',
      actionCd: 'Cancel',
      permission: McGod.PERM_MC_CONSUMER_VIEW
    }
  ];

  constructor(private factoringService: EbFactoringService,
              private router: Router,
              private webFileService: WebFileService,
              private csrContractService: CsrContractService,
              private dialog: MatDialog,
              private route: ActivatedRoute,
              private toastr: ToastrService) {
  }

  ngOnInit() {
    this.consumerId = this.route.snapshot.params['id'];
    this.initTableConfig();

    this.actLoad(this.pageNumber, this.pageSize);
    this.currency = this.factoringService.activeChildFactoring.mcCurrency;
  }

  initTableConfig() {
    this.tableConfig.addColumnStandard(McGod.t('cc.consumer.contract-id'), 'id', 250, 'id');
    this.tableConfig.addColumnStandard(McGod.t('cc.my-factoring.contract-name'), 'contractName', 250, 'contractName');
    this.tableConfig.addColumnStandard(McGod.t('cc.common.contract-start-date'), 'fldStartDate()', 250, 'startDate');
    this.tableConfig.addColumnStandard(McGod.t('cc.common.contract-end-date'), 'fldEndDate()', 250, 'endDate');
    this.tableConfig.addColumnStandard(McGod.t('cc.my-factoring.payment-method'), 'getPaymentMethod()', 250, 'paymentMethod');
    this.tableConfig.addColumnSvgIcon(McGod.t('cc.common.view.status'), 'getStatusCd()', 'statusCd');
  }

  actLoad(pageNumber: number, pageSize: number) {
    this.isLoading = true;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;

    const sortCriteria = new SortCriteriaList();
    sortCriteria.addSort('id', 'DESC');

    this.csrContractService.contractsByConsumer(this.consumerId, this.pageNumber, this.pageSize, sortCriteria)
      .subscribe((res) => {
        this.isLoading = false;
        this.totalItemsCount = res.totalElements;
        this.items = res.content.map((object: any) => CsrContract.createFromJson(object));
      });
  }

  goToCreateContract() {
    this.router.navigateByUrl(`/entity/${McGod.getLoggedEntityIdFromToken()}/consumers/create-contract/${this.consumerId}`);
  }

  onFilterChanged(filters: MkFilterOutput) {
    this.actLoad(filters.pageEvent.pageIndex, filters.pageEvent.pageSize);
  }

  onActionMkMatMenuItems($event: MkMatTableMenuAction) {
    switch ($event.action.actionCd) {
      case 'View':
        this.dialog.open(ViewContractDialogComponent, {
          height: '800px',
          width: '1200px',
          autoFocus: false,
          restoreFocus: false,
          data: $event.item,
        });
        break;
      case 'Download':
        if ($event.item.idWebFile) {
          this.webFileService.getById($event.item.idWebFile).subscribe((webFile: any) => {
            if (webFile) {
              this.webFileService.downloadFile($event.item.idWebFile, webFile.typeCd).subscribe((file: any) => {
                saveAs(file, webFile.originalFilename);
              });
            }
          });
        }
        else this.toastr.error(McGod.t('cc.contract-file-doesnt-exist'))
        break;
      case 'Cancel':
        this.dialog
          .open(CancelContractDialogComponent, {
            width: '750px',
            height: '700px',
            autoFocus: false,
            restoreFocus: false,
            data: $event.item,
          })
          .afterClosed()
          .subscribe((cancelled) => {
            if (cancelled) this.actLoad(this.pageNumber, this.pageSize);
          });
        break;
    }
  }

}
