import {Component, Inject, OnInit} from '@angular/core';
import {ICON_CLOSE} from "../../../../../assets/media/svg_icons/icon-close";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CsrContractService, McGod} from "@miticon-ui/mc-core";
import {ToastrService} from "ngx-toastr";
import { ICON_ATTRIBUTE } from '../../../../../assets/media/svg_icons/icon-attribute';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'lib-cancel-contract-dialog',
  templateUrl: './cancel-contract-dialog.component.html',
  styleUrls: ['./cancel-contract-dialog.component.scss']
})
export class CancelContractDialogComponent implements OnInit {
  iconClose = ICON_CLOSE;
  iconAttribute = ICON_ATTRIBUTE;
  public form!: FormGroup;
  minDate: Date = new Date();
  hasExistingCancellation = false;

  constructor(@Inject(MAT_DIALOG_DATA) public contract: any,
              public dialogRef: MatDialogRef<CancelContractDialogComponent>,
              private csrContractService: CsrContractService,
              private toastr: ToastrService,
              private fb: FormBuilder) {
    this.createForm();
  }

  ngOnInit(): void {
    this.hasExistingCancellation = !!(this.contract.cancellationReason && this.contract.contractCancellationActiveOnDate);
  }

  createForm(){
    this.form = this.fb.group({
      cancellationDate: [this.contract?.contractCancellationActiveOnDate ? new Date(this.contract.contractCancellationActiveOnDate) : '', Validators.required],
      reason: [this.contract?.cancellationReason || '', Validators.required]
    })
  }

  cancelContract(): void {
    this.contract.contractCancellationActiveOnDate = this.formatLocalDateToISO(this.form.get('cancellationDate')?.value);
    this.contract.cancellationReason = this.form.get('reason')?.value;

    this.csrContractService.cancelContract(this.contract)
      .subscribe({
        next: () => {
          const successMsg = this.determineCancellationMessage(this.contract.contractCancellationActiveOnDate);
          this.toastr.success(McGod.t(successMsg));
          this.dialogRef.close(true);
        },
        error: err => this.toastr.error(err.error.message)
      });
  }

  private formatLocalDateToISO(date: Date): string {
    const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    return localDate.toISOString().split('T')[0];
  }

  private determineCancellationMessage(date: string): string {
    const todayISO = new Date().toISOString().split('T')[0];
    return date > todayISO ? 'cc.consumer360.cancel-contract-text' : 'cc.consumer360.successfully-cancelled';
  }

}
