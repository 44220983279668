import {Component, Input, OnInit} from '@angular/core';
import {McBoolean, McDateUtils, McGod, McInvoiceService, PpOrder, PpOrderFilter, PpOrderList, PpOrderService} from '@miticon-ui/mc-core';
import {takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpParams} from '@angular/common/http';
import {MkFilterConfig, MkFilterItemType, MkFilterOutput, MkFilterValue, MkMatTableMenuAction, MkTableConfig} from '@miticon-ui/mc-components';
import {ToastrService} from "ngx-toastr";
import { MatDialog } from '@angular/material/dialog';
import { InvoiceScheduleSendDialogComponent } from '../components/invoice-schedule-send-dialog/invoice-schedule-send-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-mc-invoice-overview',
  templateUrl: './mc-invoice-overview.component.html'
})
export class McInvoiceOverviewComponent implements OnInit {

  @Input() userId!: any;
  @Input() entityId!: any;
  @Input() name!: string;

  area = 'invoiceOverview';
  isContentLoaded = true;
  tableContent: any;
  showLoader!: boolean;
  receiverId!: number;
  receiverType: string;
  pagination = {};
  ppInvoiceList = new PpOrderList();
  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();
  order: any;
  searchTooltip = McGod.t('pp.invoice.search-term-tooltip');
  totalItemsCount = 0;
  pageNumber = 0;
  pageSize = 50;
  showGenerateInvoiceBulkMcb = new McBoolean();

  getAll$!: Observable<any>;
  destroy$ = new Subject<void>();

  mkMatMenuActionItems = [
    {
      title: McGod.t('cc.common.view.invoice-preview'),
      matIcon: 'pageview',
      actionCd: PpOrder.ACTION_SHOW_PREVIEW,
      permission: McGod.PERM_CSR_CONSUMER_INVOICES_VIEW
    },
    {
      title: McGod.t('cc.common.view.edit-invoice'),
      matIcon: 'edit',
      actionCd: PpOrder.ACTION_SHOW_EDIT,
      permission: McGod.PERM_CSR_CONSUMER_INVOICES_VIEW
    },
    {
      title: McGod.t('pp.invoice.cancel-order'),
      matIcon: 'cancel',
      actionCd: PpOrder.ACTION_CANCEL,
      permission: McGod.PERM_CSR_CONSUMER_INVOICES_VIEW
    },
    {
      title: McGod.t('cc.consumer.send-invoice'),
      matIcon: 'send',
      actionCd: PpOrder.ACTION_SEND_INVOICE,
      permission: McGod.PERM_CSR_CONSUMER_INVOICES_VIEW
    }
  ];


  constructor(
    private invoiceService: McInvoiceService,
    private orderService: PpOrderService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private dialog: MatDialog,
    private tS: TranslateService
  ) {
  }

  ngOnInit() {
    this.receiverId = this.activatedRoute.snapshot.params['id'] - 0;
    this.receiverType = this.activatedRoute.snapshot.params['receiverType'];
    this.initTableConfig();
    this.initFilterConfig();
    this.addLicensePreviewAction();
    this.actLoad(this.pageNumber, this.pageSize, new PpOrderFilter());
  }

  addLicensePreviewAction() {
    this.orderService.hasLicensePreview(McGod.getLoggedEntityIdFromToken()).subscribe((hasLicense) => {
     if(hasLicense) {
       this.mkMatMenuActionItems.splice(1, 0, {
         title: McGod.t('cc.common.view.license-preview'),
         matIcon: 'pageview',
         actionCd: PpOrder.ACTION_SHOW_LICENSE_PREVIEW,
         permission: McGod.PERM_CSR_CONSUMER_INVOICES_VIEW
       })
      }
    })
  }

  createParams( pageNumber: number, pageSize: number,filters: PpOrderFilter): HttpParams {
    let params = new HttpParams()
      .set('searchTerm', filters.searchTerm ? encodeURIComponent(filters.searchTerm) : '')
      .set('creationDateFrom', filters.creationDateFrom ? McDateUtils.formatDateToString(filters.creationDateFrom, PpOrder.FILTER_DATE_FORMAT) : '')
      .set('creationDateTo', filters.creationDateTo ? McDateUtils.formatDateToString(filters.creationDateTo, PpOrder.FILTER_DATE_FORMAT) : '')
      .set('page', pageNumber)
      .set('size', pageSize)
      .set('idSender', McGod.getLoggedEntityIdFromToken());

    if (this.receiverId) {
      params = params.set('consumerId', this.receiverId);
    }
    return params;
  };

  appendIfValid(params: HttpParams, key: string, values?: any[]): HttpParams {
    return (values && values.length > 0) ? params.append(key, values.toString()) : params;
  }

  actLoad(pageNumber: number, pageSize: number, filters: PpOrderFilter){
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    let params = this.createParams(this.pageNumber, this.pageSize, filters);

    params = this.appendIfValid(params, 'states', filters.states);
    params = this.appendIfValid(params, 'types', filters.types);

    this.ppInvoiceList.setPager(pageNumber, pageSize);
    this.getAll$ = this.invoiceService.getOrdersByFilter(params).pipe(takeUntil(this.destroy$));
    this.loadInvoices(this.getAll$);
  }

  loadInvoices(getInvoices$ : Observable<any>){
    getInvoices$.subscribe({
      next: data => {
        if(data){
          this.ppInvoiceList.items = data.content.map((object: any) => PpOrder.createFromJson(object));
          this.totalItemsCount = data.totalElements;
          this.showLoader = false;
        }
      },
      error: err => {
        this.showLoader = false;
        this.toastrService.error(err.error.message);
      }
    });
  }

  onInvoiceCreation(){
    this.router.navigateByUrl(`admin/entity/${McGod.getLoggedEntityIdFromToken()}/management/invoices/consumers/create/${this.receiverId}`);
  }

  onInvoiceCreationBulk(){
    this.showGenerateInvoiceBulkMcb.setFalse();
    this.actLoad(this.pageNumber, this.pageSize, new PpOrderFilter());
  }

  initTableConfig(){
    this.tableConfig.addColumnStandard(McGod.t('pp.invoice.order-name'), 'orderNumber');
    this.tableConfig.addColumnStandard(McGod.t('pp.invoice.company-name'), 'entityName');
    this.tableConfig.addColumnStandard(McGod.t('cc.table.recipient'), 'recipient');
    this.tableConfig.addColumnStandard(McGod.t('pp.invoice.consumer-id'), 'idReceiverMcEntity');
    this.tableConfig.addColumnStandard(McGod.t('pp.invoice.total-amount'), 'fldTotalAmount()');
    this.tableConfig.addColumnStandard(McGod.t('pp.invoice.sending-date'), 'fldSendingDate()');
    this.tableConfig.addColumnStandard(McGod.t('pp.invoice.creation-date'), 'fldSysCreatedDate()');
    this.tableConfig.addColumnStandard(McGod.t('pp.invoice.type'), 'fldType()');
    this.tableConfig.addColumnStandard(McGod.t('pp.invoice.status'), 'fldState()');
  }

  initFilterConfig(){
    const stateFilter = PpOrder.getStateVll().items.map((item) => new MkFilterValue(item.label, item.value));
    const typeFilter = PpOrder.getTypeCdVll().items.map((item) => new MkFilterValue(item.label, item.value));

    this.filterConfig.addItem(MkFilterItemType.MULTISELECT, McGod.t(PpOrder.FILTER_TYPE), typeFilter);
    this.filterConfig.addItem(MkFilterItemType.RANGE_DATEPICKER, McGod.t(PpOrder.FILTER_CREATION_DATE));
    this.filterConfig.addItem(MkFilterItemType.MULTISELECT, McGod.t(PpOrder.FILTER_STATE), stateFilter);
  }

  onFilterChanged(filters: MkFilterOutput){
    const ppOrderFilter = new PpOrderFilter();
    this.ppInvoiceList.setSortB(filters.sort.sortProperty, filters.sort.sortType);

    ppOrderFilter.searchTerm = filters.search;
    ppOrderFilter.states = MkFilterOutput.convertFilterSelectionToArray(filters.selections[McGod.t(PpOrder.FILTER_STATE)]);
    ppOrderFilter.types = MkFilterOutput.convertFilterSelectionToArray(filters.selections[McGod.t(PpOrder.FILTER_TYPE)]);
    ppOrderFilter.creationDateFrom = filters.selections[McGod.t(PpOrder.FILTER_CREATION_DATE)]?.startDate;
    ppOrderFilter.creationDateTo = filters.selections[McGod.t(PpOrder.FILTER_CREATION_DATE)]?.endDate;

    this.actLoad(filters.pageEvent.pageIndex, filters.pageEvent.pageSize, ppOrderFilter);
  }

  onActionMkMatMenuItems($event: MkMatTableMenuAction) {
    this.order = $event.item;
    switch ($event.action.actionCd) {
      case PpOrder.ACTION_SHOW_PREVIEW:
        if($event.item.state !== 'FAILED'){
          this.actShowPreview(this.order.id);
        } else {
          this.toastrService.error('Cannot show invoice PDF for the invoice in status ' + $event.item.state + '.');
        }
        break;
      case PpOrder.ACTION_SHOW_LICENSE_PREVIEW: this.actShowLicensePreview(this.order.id);
      break;
      case PpOrder.ACTION_SHOW_EDIT: this.actShowEdit(this.order.id);
        break;
      case PpOrder.ACTION_CANCEL: this.actCancel(this.order.id);
        break;
      case PpOrder.ACTION_SEND_INVOICE: this.actScheduleSendInvoice(this.order.id);
        break;
    }
  }

  actScheduleSendInvoice(orderId: any) {
    this.dialog.open(InvoiceScheduleSendDialogComponent, {
      height: '700px',
      width: '650px',
      panelClass: 'dialog',
      autoFocus: false,
      restoreFocus: false
    }).afterClosed().subscribe((sendingDate) => {
      if (sendingDate) {
          const body = {sendingDate: sendingDate};
          this.scheduleSendingInvoice(orderId, body);
      }
    })
  }

  scheduleSendingInvoice(orderId: number, body: {sendingDate: any}) {
    this.orderService.scheduleSendingInvoice(orderId, body).subscribe({
      next: () => {
        this.toastrService.success(this.tS.instant('cc.common.saved-successfully'));
        this.actLoad(this.pageNumber, this.pageSize, new PpOrderFilter());
      },
      error: (err: any) => {
        this.toastrService.error(err.error.message);
      }
    });
  }

  actShowEdit(orderId: number){
    this.router.navigateByUrl(`admin/entity/${McGod.getLoggedEntityIdFromToken()}/management/invoices/consumers/edit/${orderId}`);
  }

  actShowPreview(orderId: number){
    this.orderService.generatePreviewByOrderId(orderId).subscribe(
      (response) => {
        const blob = new Blob([response], {type: 'application/pdf'});
        const url = URL.createObjectURL(blob);
        const newWindow = window.open(url, '_blank');
        newWindow?.document.write(`<html><head><title>Invoice</title></head><body>
             <embed width="100%" height="100%" name="plugin" src="${url}"
            type="application/pdf" internalinstanceid="21"></body></html>`);
      }
    );
  }

  actShowLicensePreview(orderId: number){
    this.orderService.generateLicensePreviewByOrderId(orderId).subscribe(
      (response) => {
        const blob = new Blob([response], {type: 'application/pdf'});
        const url = URL.createObjectURL(blob);
        const newWindow = window.open(url, '_blank');
        newWindow?.document.write(`<html><head><title>License</title></head><body>
             <embed width="100%" height="100%" name="plugin" src="${url}"
            type="application/pdf" internalinstanceid="21"></body></html>`);
      }
    );
  }

  actCancel(orderId: number){
    this.invoiceService.cancelOrder(orderId).subscribe(
      () => {
        this.actLoad(this.pageNumber, this.pageSize, new PpOrderFilter());
      }
    );
  }
}
