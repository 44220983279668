/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McEntityAdditionalDataTable3ConfigGENERATED} from '../_generated/mc-entity-additional-data-table-3-config-generated.model';

// import {McEntityAdditionalDataGENERATED} from '../_generated/mc-entity-additional-data-generated.model';

export class McEntityAdditionalDataTable3Config extends McEntityAdditionalDataTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colAccountingMattersEmail', 'Accounting Matters Email', 'accountingMattersEmail', 'accountingMattersEmail');
    this.addColumn('colBillingEmail', 'Billing Email', 'billingEmail', 'billingEmail');
    this.addColumn('colManagementEmail', 'Management Email', 'managementEmail', 'managementEmail');
    this.addColumn('colPriceListRef', 'Price List Reference', 'priceListRef', 'priceListRef');
    this.addColumn('colTermsAndConditionsRef', 'TermsAnd Conditions Reference', 'termsAndConditionsRef', 'termsAndConditionsRef');


  }
}
