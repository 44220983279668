<!--Registration form-->
<div class="registration_form">
  <mat-dialog-content>
    <form [formGroup]="form">

      <!---- Basic info ---->
      <div class="registration_form_section">
        <span class="registration_form_title">{{ 'cc.common.basic-information' | translate }}</span>

        <div class="registration_form_fields">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.common.company-name' | translate }}</mat-label>
            <input type="text" formControlName="companyName" matInput />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.common.edit.abbrevation' | translate }}</mat-label>
            <input type="text" formControlName="abbreviation" matInput />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.entity.registration.legal-form' | translate }}</mat-label>
            <input type="text" formControlName="legalForm" matInput />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.common.edit.address' | translate }}</mat-label>
            <input type="text" formControlName="address" matInput />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.users.edit.country' | translate }}</mat-label>
            <mat-select panelClass="dialog-select" formControlName="country">
            <mat-option *ngFor="let country of countries" [value]="country.shortCode">
                {{ country.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <mat-form-field appearance="outline">
          <mat-label>{{ 'cc.common.view.description' | translate }}</mat-label>
          <input type="text" formControlName="description" matInput />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>{{ 'cc.common.edit.impressum' | translate }}</mat-label>
          <input type="text" formControlName="impressum" matInput />
        </mat-form-field>
      </div>


      <div class="line"></div>

      <!---- Business details ---->
      <div class="registration_form_section">
        <span class="registration_form_title">{{ "cc.entity.registration.business-details" | translate }}</span>

        <div class="registration_form_fields">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.common.edit.vat-number' | translate }}</mat-label>
            <input type="text" formControlName="vatNumber" matInput />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'mem.entity-add.tax' | translate }}</mat-label>
            <input type="text" formControlName="taxNumber" matInput />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'mem.entity-add.ust-id' | translate }}</mat-label>
            <input type="text" formControlName="ustId" matInput />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.entity.registration.bank-name-override' | translate }}</mat-label>
            <input type="text" formControlName="bankNameOverride" matInput />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.common.entity.external-creditor-id' | translate }}</mat-label>
            <input type="text" formControlName="externalId" matInput />
          </mat-form-field>
        </div>
      </div>

      <div class="line"></div>

      <!---- Contact ---->
      <div class="registration_form_section">
        <span class="registration_form_title">{{ "cc.contract.contract" | translate }}</span>

        <div class="registration_form_fields">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.entity.registration.general-email' | translate }}</mat-label>
            <input type="text" formControlName="generalEmail" matInput />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.entity.registration.accounting-email' | translate }}</mat-label>
            <input type="text" formControlName="accountingEmail" matInput />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.entity.registration.management-email' | translate }}</mat-label>
            <input type="text" formControlName="managementEmail" matInput />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.entity.registration.billing-email' | translate }}</mat-label>
            <input type="text" formControlName="billingEmail" matInput />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.common.edit.phone-number' | translate }}</mat-label>
            <input type="text" formControlName="phoneNumber" matInput />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.common.edit.fax-number' | translate }}</mat-label>
            <input type="text" formControlName="faxNumber" matInput />
          </mat-form-field>
        </div>
      </div>

      <div class="line"></div>

      <!---- Representatives ---->
      <div class="registration_form_section">
        <span class="registration_form_title">{{ "cc.entity.registration.representatives" | translate }}</span>

        <div class="registration_form_fields">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.entity.registration.studio-owner' | translate }}</mat-label>
            <input type="text" formControlName="studioOwner" matInput />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.entity.registration.studio-owner-address' | translate }}</mat-label>
            <input type="text" formControlName="studioOwnerAddress" matInput />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.entity.registration.studio-owner-postal-code' | translate }}</mat-label>
            <input type="text" formControlName="studioOwnerPostalCode" matInput />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.entity.registration.studio-owner-city' | translate }}</mat-label>
            <input type="text" formControlName="studioOwnerCity" matInput />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.entity.registration.studio-manager' | translate }}</mat-label>
            <input type="text" formControlName="studioManager" matInput />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.entity.registration.responsible-person' | translate }}</mat-label>
            <input type="text" formControlName="responsiblePerson" matInput />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.entity.registration.function-of-responsible-person' | translate }}</mat-label>
            <input type="text" formControlName="responsiblePersonFunction" matInput />
          </mat-form-field>
        </div>
      </div>

      <div class="line"></div>

      <!---- Legal information ---->
      <div class="registration_form_section">
        <span class="registration_form_title">{{ "cc.entity.registration.legal-information" | translate }}</span>

        <div class="registration_form_fields">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.common.edit.legal-representative' | translate }}</mat-label>
            <input type="text" formControlName="legalRepresentative" matInput />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.common.edit.legal-representative-email' | translate }}</mat-label>
            <input type="text" formControlName="legalRepresentativeEmail" matInput />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.entity.registration.court-in-charge' | translate }}</mat-label>
            <input type="text" formControlName="courtInCharge" matInput />
          </mat-form-field>
        </div>
      </div>

      <div class="line"></div>

      <!---- Bank information ---->
      <div class="registration_form_section">
        <span class="registration_form_title">{{ "cc.common.bank-information" | translate }}</span>

        <div class="registration_form_fields">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.consumers.edit.account-owner' | translate }}</mat-label>
            <input type="text" formControlName="accountOwner" matInput />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.ebics.bank-name' | translate }}</mat-label>
            <input type="text" formControlName="bankName" matInput />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.common.bank-city' | translate }}</mat-label>
            <input type="text" formControlName="bankCity" matInput />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.common.bank-country' | translate }}</mat-label>
            <input type="text" formControlName="bankCountry" matInput />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.common.account-number' | translate }}</mat-label>
            <input type="text" formControlName="accountNumber" matInput />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.common.blz' | translate }}</mat-label>
            <input type="text" formControlName="blz" matInput />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.common.swift' | translate }}</mat-label>
            <input type="text" formControlName="swift" matInput />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.common.iban' | translate }}</mat-label>
            <input type="text" formControlName="iban" matInput />
          </mat-form-field>
        </div>
      </div>

      <div class="line"></div>

      <!---- Web domain ---->
      <div class="registration_form_section">
        <span class="registration_form_title">{{ "cc.common.edit.web-domain" | translate }}</span>

        <div class="registration_form_fields">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.entity.registration.subdomain' | translate }}</mat-label>
            <input type="text" formControlName="subdomain" matInput />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.entity.registration.domain' | translate }}</mat-label>
            <mat-select panelClass="dialog-select" formControlName="domain">
              <mat-option *ngFor="let domain of webDomains" [value]="domain">
                {{ domain }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="line"></div>

      <!--Contract-->
      <div class="registration_form_section">
        <span class="registration_form_title">{{ "cc.contract.contract" | translate }}</span>
        <div class="registration_form_subtext">
          <div class="registration_form_subtext_contract">
            <p class="registration_form_subtext_contract_bold">{{ 'cc.contract.factoring' | translate }}&nbsp;</p><p>- {{ 'cc.entity.registration-factoring-text' | translate}}.</p>
          </div>
          <div class="registration_form_subtext_contract">
            <span class="registration_form_subtext_contract_bold">{{ 'cc.entity.registration-service' | translate }}&nbsp;</span><span>- {{ 'cc.entity.registration-service-text' | translate}}.</span>
          </div>
        </div>
        <div class="radio_buttons">
          <div class="radio_buttons_item">
            <mat-radio-group formControlName="contractType">
              <mat-radio-button [value]="'Factoring'">{{'cc.contract.factoring-contract' | translate}}</mat-radio-button>
              <mat-radio-button [value]="'Service'">{{'cc.contracts.service-contract' | translate}}</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

      <div class="line"></div>

      <!--Documents-->
      <div class="registration_form_section">
        <span class="registration_form_title">{{ "cc.consumers.view.documents" | translate }}</span>
        <div class="registration_form_subtext">{{ 'cc.entity.registration.documents-text' | translate}}</div>
      </div>

      <div class="line"></div>

      <!-- Invitation code -->
      <div class="registration_form_section">
        <span class="registration_form_title">{{ "cc.entity.profile.invitation-code" | translate }}</span>
        <div class="registration_form_subtext">{{ "cc.entity.registration.invitation-text" | translate }}</div>

        <div class="registration_form_fields">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.entity.profile.invitation-code' | translate }}</mat-label>
            <input type="text" formControlName="invitationCode" matInput />
          </mat-form-field>
        </div>
      </div>

      <div class="line"></div>

      <!--Terms and conditions-->
      <div class="registration_form_section">
        <span class="registration_form_title">{{ "cc.entity-registration.terms-and-conditions-pricelists" | translate }}</span>
        <div class="registration_form_checkbox">
          <mat-checkbox formControlName="generalTerms">
            <span>
              {{ 'cc.entity-registration.general-terms' | translate }}
              <a href="https://www.my-factura.com/terms-and-conditions/">{{ 'cc.entity-registration.terms-and-conditions' | translate }}</a>
              {{ 'cc.entity-registration.my-factura' | translate }}
            </span>
          </mat-checkbox>
          <mat-checkbox formControlName="pricelistTerms">
            <span>
              {{'cc.entity-registration.associated-price-list' | translate}}
              <a href="https://www.my-factura.com/preisliste/">{{ 'cc.sidemenu.price-lists' | translate }}</a>
            </span>
          </mat-checkbox>

        </div>
      </div>
    </form>
  </mat-dialog-content>
</div>
