/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {McEntityAdditionalData} from '../models/mc-entity-additional-data.model';
import {McEntityAdditionalDataList} from '../models/mc-entity-additional-data-list.model';
import {McEntityAdditionalDataFilter} from '../models/mc-entity-additional-data-filter.model';
import {McEntityAdditionalDataService} from '../services/mc-entity-additional-data.service';
import {IMcEntityAdditionalData} from '../_generated/mc-entity-additional-data.interface';
import {McGod} from "../extra/mc-god.service";

export class McEntityAdditionalDataListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'accountingMattersEmail', 'billingEmail', 'managementEmail', 'priceListRef', 'termsAndConditionsRef'];
  public items: McEntityAdditionalData[] = [];
  public apiService: McEntityAdditionalDataService;
 // protected tempObjList: McEntityAdditionalDataList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McEntityAdditionalDataList {
    console.error('MTCN-ERROR: Not Implemented: McEntityAdditionalDataList::createNewInstance(). Add this method to final class and return new McEntityAdditionalDataList();');
    throw new Error('Cannot McEntityAdditionalDataList::createNewInstance(). Add this method to final class and return new McEntityAdditionalDataList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IMcEntityAdditionalData[]): McEntityAdditionalDataList {
    const  listObj: McEntityAdditionalDataList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: McEntityAdditionalData = McEntityAdditionalData.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): McEntityAdditionalDataList {
    const  listObj: McEntityAdditionalDataList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McEntityAdditionalDataService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IMcEntityAdditionalData[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: McEntityAdditionalData = McEntityAdditionalData.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aMcEntityAdditionalDataFilter: McEntityAdditionalDataFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aMcEntityAdditionalDataFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): McEntityAdditionalData {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new McEntityAdditionalData();
    }
  }

  public replaceItem(newItem: McEntityAdditionalData) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
}

