import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { CsrConsumer, McConsumer, McConsumerAction, WebFile } from '@miticon-ui/mc-core';
import * as fileSaver from 'file-saver';

@Component({
  selector: 'app-shared-processes-action-detail-part',
  templateUrl: './app-shared-processes-action-detail-part.component.html',
})
export class AppSharedProcessesActionDetailPartComponent implements OnInit {

  /*Variables*/
  @Input() mcConsumerAction!: McConsumerAction;
  @Input() mcActionCd!: string;
  @Output() eventSaved = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();
  attachmentFile = new WebFile();
  actionShowEmail = McConsumerAction.ACTION_SHOW_EMAIL;
  actionShowPost = McConsumerAction.ACTION_SHOW_POST;
  actionShowSms = McConsumerAction.ACTION_SHOW_SMS;

  public csrConsumer = new CsrConsumer();
  public mcConsumer = new McConsumer();

  ngOnInit() {
    // this.mcConsumer.loadById(this.mcConsumerAction.idCsrConsumer);
    this.csrConsumer.loadById(this.mcConsumerAction.idCsrConsumer);
    if (this.mcActionCd === this.actionShowPost) {
      this.attachmentFile.loadById(this.mcConsumerAction.postWebfileId);
    }
    if (this.mcActionCd === this.actionShowEmail) {
      this.attachmentFile.loadById(this.mcConsumerAction.emailBodyAttachmentWebfileId);
    }
  }

  actCancel() {
    this.eventCanceled.emit();
  }

  actDownload() {
    this.attachmentFile.downloadFile().subscribe(next => {
      fileSaver.saveAs(next, `${this.attachmentFile.filename}`);
    });
  }
}
