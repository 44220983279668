import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  CsrBankAccount,
  CsrConsumer,
  CsrContract,
  CsrContractPreDefined,
  CsrContractService,
  CsrNotificationDetails,
  CsrPaymentDetails,
  CsrRecurringDetails,
  CurrencyUtil,
  EbFactoringService,
  IEbFactoring,
  McBankAccount,
  McBankAccountService,
  McConsumer,
  McCountry,
  McCurrency,
  McCurrencyService,
  McDateUtils,
  McEntity2,
  McForm,
  McGod,
  McProduct,
  McProductList,
  WebFile
} from '@miticon-ui/mc-core';
import {CdkStepper} from '@angular/cdk/stepper';
import {MccStepperComponent} from '@miticon-ui/mc-components';
import {ContractType} from '../mc-consumer-consumer-360-create-contract-details-part/mc-consumer-consumer-360-create-contract-details-part.component';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {ICON_BACK} from "../../../../assets/media/svg_icons/icon-back";
import { DatePipe, Location } from '@angular/common';

@Component({
  selector: 'mc-consumer-consumer-360-create-contract-part',
  templateUrl: './mc-consumer-consumer-360-create-contract-part.component.html',
  styleUrls: ['./mc-consumer-consumer-360-create-contract-part.component.scss'],
  providers: [{provide: CdkStepper, useExisting: MccStepperComponent}]
})
export class McConsumerConsumer360CreateContractPartComponent implements OnInit {

  /*Variables*/
  selectedTab = 0;
  consumerContractMcForm = new McForm();
  mcGod = McGod.getInstance();
  validFileUpload = true;
  message = '';
  disableAutomaticRenewal = false;
  totalNetto = 0;
  totalVat = 0;
  selectedProductList = new McProductList();
  errorMessage = '';
  csrContract = new CsrContract();
  webFile = new WebFile();
  file: File;
  mcCountry = new McCountry();
  mcEntity = new McEntity2();
  paymentMethod = '';
  paymentPurpose = '';
  loadingFlg = false;
  mcBankAccount: McBankAccount | null;
  primaryBankAccount: CsrBankAccount |  null;
  csrConsumer = new CsrConsumer();
  csrConsumerId = 0;
  sendInvitationEmail = false;
  iconBack = ICON_BACK;
  validPaymentTab: boolean = false;
  isLoading: boolean = false;
  contractFormValid = false;
  contactFormErrorMsg: any;

  @Input() entityCurrency!: string;
  @Output() eventCsrContractSaved = new EventEmitter();
  selectedContract: CsrContractPreDefined;

  constructor(private mcBankAccountService: McBankAccountService,
              private currencyService: McCurrencyService,
              private csrContractService: CsrContractService,
              private factoringService: EbFactoringService,
              private route: ActivatedRoute,
              private router: Router,
              private toastr: ToastrService,
              private location: Location,
              private datePipe: DatePipe) {}

  ngOnInit() {
    this.csrConsumerId = this.route.snapshot.params['id'];
    this.csrConsumer.loadById(this.csrConsumerId);

    this.selectedTab = 0;
  }


  isContractFormValid(renewal: boolean) {
    let startDateControl: any
    let renewalPeriodControl: any
    let renewalNoticePeriodControl: any

    if (renewal){
       startDateControl = this.consumerContractMcForm.getControl('ctrlContractStartDate')?.value.value;
       renewalPeriodControl = this.consumerContractMcForm.getControl('ctrlRenewalPeriodInMonths')?.value.value;
       renewalNoticePeriodControl = this.consumerContractMcForm.getControl('ctrlRenewalNoticePeriod')?.value.value;
       this.contractFormValid = !!(startDateControl && renewalNoticePeriodControl && renewalPeriodControl && !this.contactFormErrorMsg);
    }
    else {
      startDateControl = this.consumerContractMcForm.getControl('ctrlContractStartDate')?.value.value;
      this.contractFormValid = !!startDateControl;
    }
  }

  setContractFormErrorMsg(error: any){
    this.contactFormErrorMsg = error;
  }

  onEmitSelectedContract(contract: CsrContractPreDefined) {
    this.selectedContract = contract;
  }

  onPrev() {
    this.selectedTab -= 1;
    const bankFormControls = [];
    this.message = '';
  }

  onNext() {
    if (this.selectedTab === 2 && !this.consumerContractMcForm.isValid()) {
      return;
    }
    if (this.selectedTab === 2) {
      this.paymentMethod = this.consumerContractMcForm.getValue('ctrlPaymentMethod');
      if (this.paymentMethod === CsrPaymentDetails.TYPE_CD_DIRECT_DEBIT) {
        this.paymentMethod = CsrContract.PAYMENT_METHOD_DIRECT_DEBIT;
      }
      if (this.paymentMethod === CsrPaymentDetails.TYPE_CD_CASH) {
        this.paymentMethod = CsrContract.PAYMENT_METHOD_CASH_ONSITE;
      }
      if (this.paymentMethod === CsrPaymentDetails.TYPE_CD_TRANSFER) {
        this.paymentMethod = CsrContract.PAYMENT_METHOD_MONEY_TRANSFER;
      }
      const paymentDetails = new CsrPaymentDetails();
      paymentDetails.typeCd = this.consumerContractMcForm.getValue('ctrlPaymentMethod');
      this.csrContract.paymentDetails = paymentDetails;
    }
    if (this.selectedTab === 2 && this.consumerContractMcForm.getValue('ctrlPaymentMethod') === CsrPaymentDetails.TYPE_CD_DIRECT_DEBIT && this.consumerContractMcForm.getValue('ctrlUseDiffBankAccount')) {
      this.validateIban();
      return;
    }
    if (this.selectedTab === 1) {
      this.mapCsrContractProperties();
    }
    if (this.consumerContractMcForm.isValid()) {
      this.selectedTab += 1;
      this.message = '';
    }
  }

  onFileUploaded(value: File) {
    this.validFileUpload = !!value;
    this.file = value;
  }

  saveContract() {
    this.isLoading = true;
    this.csrContract.idCsrContractPreDefined = this.selectedContract.id;
    this.csrContract.contractName = this.selectedContract.name;
    if(this.mcBankAccount) {
      this.csrContract.idBeneficiary = this.mcBankAccount.id;
    }
    this.csrContract.consumer360Flg = true;
    this.csrContractService.createFromPredefined(this.csrContract).subscribe((data) => {
      this.isLoading = false;
      if(data) {
        this.toastr.success(`${McGod.t('cc.common.contract-successfully-created')}.`, '', {timeOut: 4000, extendedTimeOut: 4000});
        this.eventCsrContractSaved.emit();
        this.router.navigate([`/entity/${McGod.getLoggedEntityIdFromToken()}/consumers/consumer-360-tabs/${this.csrConsumerId}/contracts/${this.csrConsumerId}`]);
      }
    }, (error) => {
      this.isLoading = false;
      this.toastr.error(`${this.csrContract.apiErrorMessage}`, '', {timeOut: 4000, extendedTimeOut: 4000});
    })
  }

  formatDate(dateString: string):any {
    const date = new Date(dateString);
    return this.datePipe.transform(date, "yyyy-MM-dd");
  }

  mapCsrContractProperties() {
    const recurringDetails = new CsrRecurringDetails();
    recurringDetails.typeCd = this.consumerContractMcForm.getValue('ctrlPayment');
    recurringDetails.day = this.consumerContractMcForm.getValue('ctrlDays');
    if (recurringDetails.typeCd === CsrRecurringDetails.TYPE_CD_QUARTERLY ||
      recurringDetails.typeCd === CsrRecurringDetails.TYPE_CD_HALF_YEARLY ||
      recurringDetails.typeCd === CsrRecurringDetails.TYPE_CD_YEARLY) {
      recurringDetails.month = this.consumerContractMcForm.getValue('ctrlMonthYear');
    }
    this.csrContract.recurringDetails = recurringDetails;

    this.csrContract.contractName = this.selectedContract.name;
    this.csrContract.endDate = this.consumerContractMcForm.getValue('ctrlContractEndDate') ? this.formatDate(this.consumerContractMcForm.getValue('ctrlContractEndDate')) : null;
    this.csrContract.startDate = this.consumerContractMcForm.getValue('ctrlContractStartDate') ? this.formatDate(this.consumerContractMcForm.getValue('ctrlContractStartDate')) : null;
    this.csrContract.autoRenewalLength = this.consumerContractMcForm.getValue('ctrlRenewalPeriodInMonths') ? this.consumerContractMcForm.getValue('ctrlRenewalPeriodInMonths') : null;
    this.csrContract.renewalNoticePeriod = this.consumerContractMcForm.getValue('ctrlRenewalNoticePeriod') ? this.consumerContractMcForm.getValue('ctrlRenewalNoticePeriod') : null;
    const notificationsDetails = new CsrNotificationDetails();
    notificationsDetails.sendInvoice = this.consumerContractMcForm.getValue('ctrlSendInvoice');
    if (notificationsDetails.sendInvoice) {
      if (this.consumerContractMcForm.getValue('ctrlEmailAddress') === 1) {
        notificationsDetails.email = this.csrConsumer.email;
      } else {
        notificationsDetails.email = this.consumerContractMcForm.getValue('ctrlEmailAddressTextBox');
      }
    }
    this.csrContract.notificationDetails = notificationsDetails;
    this.csrContract.notes = this.consumerContractMcForm.getValue('ctrlFreeText');

    this.csrContract.priceUnit = this.totalNetto;
    this.csrContract.vatUnit = this.totalVat;

    /*this.csrContract.autoRenewal = false;
    if (this.csrContract.autoRenewal && this.csrContract.durationInMonths) {
      this.csrContract.autoRenewalLength = this.consumerContractMcForm.getValue('ctrlAutomaticRenewalYears');
      const dateToRemoveDaysFrom = this.csrContract.endDate;
      const daysToSubtract = this.consumerContractMcForm.getValue('ctrlAutomaticRenewalDays');
      const newDate = McDateUtils.subtractDateToNumber(dateToRemoveDaysFrom, daysToSubtract, 'days');
      // const newDateObject = McDateUtils.formatTimestampDateObject(newDate);
      const formatIso = McDateUtils.formatDateToIsoString(newDate);
    }*/

    this.csrContract.idCsrConsumer = this.csrConsumer.id;
    this.csrContract.idMcEntity = McGod.getLoggedEntityIdFromToken();
  }

  onPaymentMethodChanged(value: any) {
    this.paymentMethod = value;
  }

  onPaymentPurpose(value: any) {
    this.paymentPurpose = value;
  }

  onChangeMcBankAccount(value: any) {
    this.mcBankAccount = value;
  }

  onChangeCsrBankAccount(value: any) {
    this.primaryBankAccount = value;
  }

  validateIban() {
    this.loadingFlg = true;
    if(this.mcBankAccount) {
      this.mcBankAccount.iban = this.consumerContractMcForm.getValue('ctrlIban');
      this.mcBankAccountService.validateIban(this.mcBankAccount).subscribe(data => {
        if (!data) {
          this.message = this.mcGod.t('cc.consumer-invalid-iban-please-enter-the-iban-in-the-valid-format') + '.';
        } else {
          this.selectedTab++;
          this.message = '';
        }
        this.loadingFlg = false;
      }, error => {
        this.message = error.error.message;
        this.loadingFlg = false;
      });
    }
  }

  goBack() {
    this.location.back();
  }

  getPriceLabel(typeCd: string) {
    return CsrContractPreDefined.getLabel(typeCd).label;
  }

  isOneTimeType(){
    return this.selectedContract && this.selectedContract.typeCd === CsrContractPreDefined.TYPE_CD_ONE_TIME;
  }

  getVat(): string {
    return CsrContractPreDefined.getVat(this.selectedContract);
  }
}
