import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/autocomplete';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogContent } from '@angular/material/dialog';
import { MatFormField,MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MccFiFileUploadModule,McComponentsModule,McFilterModule,McTableModule,UploadFileModule } from '@miticon-ui/mc-components';
import { SafeHtmlModule } from '../../assets/pipes/safe-html/safe-html.module';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from '@ngx-translate/core';
import { AppSharedModule } from '../app-shared/app-shared.module';
import { McConsumerModule } from '../mc-consumer/mc-consumer.module';
import { McManagementModule } from '../mc-management/mc-management.module';
import { McOutFactoringBlacklistAddEditPartComponent } from './components/mc-out-factoring-blacklist-add-edit-part/mc-out-factoring-blacklist-add-edit-part.component';
import { McOutFactoringSepaExportDialogComponent } from './components/mc-out-factoring-sepa-export-dialog/mc-out-factoring-sepa-export-dialog.component';
import { McOutFactoringSepaExportPartComponent } from './components/mc-out-factoring-sepa-export-part/mc-out-factoring-sepa-export-part.component';
import { McOutFactoringRoutingModule } from './mc-out-factoring-routing.module';
import { McOutFactoringBlacklistListPageComponent } from './pages/mc-out-factoring-blacklist-list-page/mc-out-factoring-blacklist-list-page.component';
import { McOutFactoringContractListPageComponent } from './pages/mc-out-factoring-contract-list-page/mc-out-factoring-contract-list-page.component';
import { McOutFactoringInkassoExportListPageComponent } from './pages/mc-out-factoring-inkasso-export-list-page/mc-out-factoring-inkasso-export-list-page.component';
import { McOutFactoringSepaInListPageComponent } from './pages/mc-out-factoring-sepa-in-list-page/mc-out-factoring-sepa-in-list-page.component';
import { McOutFactoringSepaOutListPageComponent } from './pages/mc-out-factoring-sepa-out-list-page/mc-out-factoring-sepa-out-list-page.component';
import { McOutFactoringTransactionListPageComponent } from './pages/mc-out-factoring-transaction-list-page/mc-out-factoring-transaction-list-page.component';

@NgModule({
  declarations: [
    McOutFactoringInkassoExportListPageComponent,
    McOutFactoringSepaExportPartComponent,
    McOutFactoringBlacklistAddEditPartComponent,
    McOutFactoringBlacklistListPageComponent,
    McOutFactoringTransactionListPageComponent,
    McOutFactoringSepaOutListPageComponent,
    McOutFactoringSepaInListPageComponent,
    McOutFactoringContractListPageComponent,
    McOutFactoringSepaExportDialogComponent,
  ],
  imports: [
    CommonModule,
    McOutFactoringRoutingModule,
    NgbModule,
    McComponentsModule,
    McFilterModule,
    McTableModule,
    McManagementModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    AppSharedModule,
    McComponentsModule,
    McConsumerModule,
    MccFiFileUploadModule,
    UploadFileModule,
    SafeHtmlModule,
    MatButton,
    MatDialogContent,
    MatFormField,
    MatInput,
    MatLabel,
    MatOption,
    MatSelectModule,
    MatDatepickerModule,
    MatCheckbox,
  ],
  exports: [
    McOutFactoringInkassoExportListPageComponent,
    McOutFactoringSepaExportPartComponent,
    McOutFactoringBlacklistAddEditPartComponent,
    McOutFactoringBlacklistListPageComponent,
    McOutFactoringTransactionListPageComponent,
    McOutFactoringSepaOutListPageComponent,
    McOutFactoringSepaInListPageComponent,
    McOutFactoringContractListPageComponent,
    McOutFactoringRoutingModule,
    FormsModule,
    McOutFactoringSepaExportDialogComponent,
  ],
})
export class McOutFactoringModule {}
