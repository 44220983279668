import {Injectable} from '@angular/core';
import {EmailConfigKeyServiceGENERATED} from '../_generated/email-config-key-generated.service';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class EmailConfigKeyService extends EmailConfigKeyServiceGENERATED {

  public getKeysForEmail(idEmailConfig: number): Observable<any> {
    return this.httpClient.get(this.getApiServiceRootUrl() + `/email-config/${idEmailConfig}`);
  }
}
