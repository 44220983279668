import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ICON_CLOSE} from "../../../../assets/media/svg_icons/icon-close";

@Component({
  selector: 'lib-email-template-info-dialog',
  templateUrl: './email-template-info-dialog.component.html',
  styleUrl: './email-template-info-dialog.component.scss'
})
export class EmailTemplateInfoDialogComponent {

  iconClose = ICON_CLOSE;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      optionalElements: any,
      requiredElements: any},
    public dialogRef: MatDialogRef<EmailTemplateInfoDialogComponent>
  ) {}

  ngOnInit() {}
}
