/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McBillingStatement} from '../models/mc-billing-statement.model';
import {McBillingStatementService} from '../services/mc-billing-statement.service';
import {IMcBillingStatement} from '../_generated/mc-billing-statement.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';

import {McBillingPayoutTableItemList} from '../models/mc-billing-payout-table-item-list.model';


export abstract class McBillingStatementGENERATED extends BaseObject {


    public static readonly STATUS_CD_NEW: string = 'NEW';
    public static readonly STATUS_CD_PROCESSING: string = 'PROCESSING';
    public static readonly STATUS_CD_ACTIVE: string = 'ACTIVE';
    public static readonly STATUS_CD_SUBMITTED: string = 'SUBMITTED';
    public static readonly STATUS_CD_COMPLETED: string = 'COMPLETED';
    public static readonly STATUS_CD_CANCELED: string = 'CANCELED';
    public static readonly STATUS_CD_IN_PROGRESS_FINALIZATION: string = 'IN_PROGRESS_FINALIZATION';
    public static readonly STATUS_CD_PENDING_FINALIZATION: string = 'PENDING_FINALIZATION';
    public static readonly STATUS_CD_FAILED_FINALIZATION: string = 'FAILED_FINALIZATION';
    public static readonly STATUS_CD_IN_PROGRESS_AUTO_IMPORT: string = 'IN_PROGRESS_AUTO_IMPORT';
    public static readonly STATUS_CD_PENDING_AUTO_IMPORT: string = 'PENDING_AUTO_IMPORT';
    public static readonly STATUS_CD_FAILED_AUTO_IMPORT: string = 'FAILED_AUTO_IMPORT';
    public static readonly STATUS_CD_FINALIZED: string = 'FINALIZED';
  public static readonly STATUS_CD_LIST__ALL = [
    McBillingStatementGENERATED.STATUS_CD_NEW,
    McBillingStatementGENERATED.STATUS_CD_PROCESSING,
    McBillingStatementGENERATED.STATUS_CD_ACTIVE,
    McBillingStatementGENERATED.STATUS_CD_SUBMITTED,
    McBillingStatementGENERATED.STATUS_CD_COMPLETED,
    McBillingStatementGENERATED.STATUS_CD_CANCELED,
    McBillingStatementGENERATED.STATUS_CD_IN_PROGRESS_FINALIZATION,
    McBillingStatementGENERATED.STATUS_CD_PENDING_FINALIZATION,
    McBillingStatementGENERATED.STATUS_CD_FAILED_FINALIZATION,
    McBillingStatementGENERATED.STATUS_CD_IN_PROGRESS_AUTO_IMPORT,
    McBillingStatementGENERATED.STATUS_CD_PENDING_AUTO_IMPORT,
    McBillingStatementGENERATED.STATUS_CD_FAILED_AUTO_IMPORT,
    McBillingStatementGENERATED.STATUS_CD_FINALIZED
];

  public apiService: McBillingStatementService;
  public _rawJson: IMcBillingStatement;
    id: number = 0;
  childEntityId: number;
  completedDatetime: number;
  description = '';
  feesAmount: number;
  flgHidden: boolean;
  idPayoutFile: number;
  parentEntityId: number;
  pdfWebfileId: number;
  statementDate: number;
  statementNumber = '';
  statusCd: string;
  statusDetails = '';
  sysCreatedDatetime: number;
  sysCreatedUserId: number;
  sysUpdatedDatetime: number;
  title = '';
  totalPayoutAmount: number;
  totalVat: number;
  xlsWebfileId: number;
  accountOwner: string;  bic: string;  childEntityName: string;  currencyCode: string;  exportedDate: any;  fileName: string;  flgFactoring: boolean;  iban: string;  mcBillingStatementItemsCount: number;  payoutDate: any;  payoutFileDescription: string;  payoutTableItems: McBillingPayoutTableItemList;  studioOwner: string;  totalAmount: any;  totalPayout: any;

  public properties: string[] = ['id', 'childEntityId', 'completedDatetime', 'description', 'feesAmount', 'flgHidden', 'idPayoutFile', 'parentEntityId', 'pdfWebfileId', 'statementDate', 'statementNumber', 'statusCd', 'statusDetails', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'title', 'totalPayoutAmount', 'totalVat', 'xlsWebfileId', 'accountOwner', 'bic', 'childEntityName', 'currencyCode', 'exportedDate', 'fileName', 'flgFactoring', 'iban', 'mcBillingStatementItemsCount', 'payoutDate', 'payoutFileDescription', 'payoutTableItems', 'studioOwner', 'totalAmount', 'totalPayout'];
  public propertiesRegular: string[] = ['id', 'childEntityId', 'completedDatetime', 'description', 'feesAmount', 'flgHidden', 'idPayoutFile', 'parentEntityId', 'pdfWebfileId', 'statementDate', 'statementNumber', 'statusCd', 'statusDetails', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'title', 'totalPayoutAmount', 'totalVat', 'xlsWebfileId', 'accountOwner', 'bic', 'childEntityName', 'currencyCode', 'exportedDate', 'fileName', 'flgFactoring', 'iban', 'mcBillingStatementItemsCount', 'payoutDate', 'payoutFileDescription', 'studioOwner', 'totalAmount', 'totalPayout'];
  public propertiesSpecial: string[] = ['payoutTableItems'];



  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McBillingStatementGENERATED.STATUS_CD_NEW, McGod.t('New'));
    list.add(McBillingStatementGENERATED.STATUS_CD_PROCESSING, McGod.t('Processing'));
    list.add(McBillingStatementGENERATED.STATUS_CD_ACTIVE, McGod.t('Active'));
    list.add(McBillingStatementGENERATED.STATUS_CD_SUBMITTED, McGod.t('Submitted'));
    list.add(McBillingStatementGENERATED.STATUS_CD_COMPLETED, McGod.t('Completed'));
    list.add(McBillingStatementGENERATED.STATUS_CD_CANCELED, McGod.t('Canceled'));
    list.add(McBillingStatementGENERATED.STATUS_CD_IN_PROGRESS_FINALIZATION, McGod.t('In progress finalization'));
    list.add(McBillingStatementGENERATED.STATUS_CD_PENDING_FINALIZATION, McGod.t('Pending finalization'));
    list.add(McBillingStatementGENERATED.STATUS_CD_FAILED_FINALIZATION, McGod.t('Failed finalization'));
    list.add(McBillingStatementGENERATED.STATUS_CD_IN_PROGRESS_AUTO_IMPORT, McGod.t('In progress auto import'));
    list.add(McBillingStatementGENERATED.STATUS_CD_PENDING_AUTO_IMPORT, McGod.t('Pending auto import'));
    list.add(McBillingStatementGENERATED.STATUS_CD_FAILED_AUTO_IMPORT, McGod.t('Failed auto import'));
    list.add(McBillingStatementGENERATED.STATUS_CD_FINALIZED, McGod.t('Finalized'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = McBillingStatementGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): McBillingStatement {
    console.error('MTCN-ERROR: Not Implemented: McBillingStatement::createNewInstance(). Add this method to final class and return new McBillingStatement();');
    throw new Error('Cannot McBillingStatement::createNewInstance(). Add this method to final class and return new McBillingStatement();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcBillingStatement): McBillingStatement {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McBillingStatementService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McBillingStatement AABB()');
          if (this._rawJson['payoutTableItems']) {
        this.payoutTableItems = McBillingPayoutTableItemList.createFromJson(this._rawJson['payoutTableItems']);
      }

  }

  // ---------------------------------------------------------------------
  
    public getCompletedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.completedDatetime);
    }

    public getCompletedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.completedDatetime);
    }

    public getFeesAmountStr(): string {
       return McGod.formatNumberToMoney(this.feesAmount);
    }

    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }

    public getSysUpdatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysUpdatedDatetime);
    }

    public getSysUpdatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysUpdatedDatetime);
    }

    public getTotalPayoutAmountStr(): string {
       return McGod.formatNumberToMoney(this.totalPayoutAmount);
    }

    public getTotalVatStr(): string {
       return McGod.formatNumberToMoney(this.totalVat);
    }

    public getTotalAmountStr(): string {
       return McGod.formatNumberToMoney(this.totalAmount);
    }

    public getTotalPayoutStr(): string {
       return McGod.formatNumberToMoney(this.totalPayout);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();
    if (this.hasOwnProperty('payoutTableItems')) {
        if (this.payoutTableItems != null) {
            dto['payoutTableItems'] = this.payoutTableItems.toDto();
        } else {
            dto['payoutTableItems'] = [];
        }
    }

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getStatusCdLabel(): string {
    return McBillingStatementGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdNEW(): boolean {
    const result = (this.statusCd === McBillingStatementGENERATED.STATUS_CD_NEW);

    return result;
  }

 public  isStatusCdPROCESSING(): boolean {
    const result = (this.statusCd === McBillingStatementGENERATED.STATUS_CD_PROCESSING);

    return result;
  }

 public  isStatusCdACTIVE(): boolean {
    const result = (this.statusCd === McBillingStatementGENERATED.STATUS_CD_ACTIVE);

    return result;
  }

 public  isStatusCdSUBMITTED(): boolean {
    const result = (this.statusCd === McBillingStatementGENERATED.STATUS_CD_SUBMITTED);

    return result;
  }

 public  isStatusCdCOMPLETED(): boolean {
    const result = (this.statusCd === McBillingStatementGENERATED.STATUS_CD_COMPLETED);

    return result;
  }

 public  isStatusCdCANCELED(): boolean {
    const result = (this.statusCd === McBillingStatementGENERATED.STATUS_CD_CANCELED);

    return result;
  }

 public  isStatusCdIN_PROGRESS_FINALIZATION(): boolean {
    const result = (this.statusCd === McBillingStatementGENERATED.STATUS_CD_IN_PROGRESS_FINALIZATION);

    return result;
  }

 public  isStatusCdPENDING_FINALIZATION(): boolean {
    const result = (this.statusCd === McBillingStatementGENERATED.STATUS_CD_PENDING_FINALIZATION);

    return result;
  }

 public  isStatusCdFAILED_FINALIZATION(): boolean {
    const result = (this.statusCd === McBillingStatementGENERATED.STATUS_CD_FAILED_FINALIZATION);

    return result;
  }

 public  isStatusCdIN_PROGRESS_AUTO_IMPORT(): boolean {
    const result = (this.statusCd === McBillingStatementGENERATED.STATUS_CD_IN_PROGRESS_AUTO_IMPORT);

    return result;
  }

 public  isStatusCdPENDING_AUTO_IMPORT(): boolean {
    const result = (this.statusCd === McBillingStatementGENERATED.STATUS_CD_PENDING_AUTO_IMPORT);

    return result;
  }

 public  isStatusCdFAILED_AUTO_IMPORT(): boolean {
    const result = (this.statusCd === McBillingStatementGENERATED.STATUS_CD_FAILED_AUTO_IMPORT);

    return result;
  }

 public  isStatusCdFINALIZED(): boolean {
    const result = (this.statusCd === McBillingStatementGENERATED.STATUS_CD_FINALIZED);

    return result;
  }


  
// ---------------------------------------------------------------------
public callRegenerateXLSandPDF( statementId: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.regenerateXLSandPDF(statementId)
    .subscribe(responseJson => {
        this.populateFromJson(responseJson);
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.processApiError(errorResponse, onErrorCallback);
      });
}

// ---------------------------------------------------------------------
public callCollectBillingItems( id: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.collectBillingItems(id).subscribe(responseJson => {
    this.setApiSuccess();
    this.processApiSuccess(responseJson, onSuccessCallback);
    },
  errorResponse => {
    this.setApiError();
    this.processApiError(errorResponse, onErrorCallback);
  });
}

  // ---------------------------------------------------------------------
  public recalculate( id: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.recalculate(id)
      .subscribe(responseJson => {
        this.populateFromJson(responseJson);
         this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.processApiError(errorResponse, onErrorCallback);
      });
  }

// ---------------------------------------------------------------------
public callChangeStatusHidden( ids: any, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.changeStatusHidden(ids).subscribe(responseJson => {
    this.setApiSuccess();
    this.processApiSuccess(responseJson, onSuccessCallback);
    },
  errorResponse => {
    this.setApiError();
    this.processApiError(errorResponse, onErrorCallback);
  });
}

// ---------------------------------------------------------------------
public callRemoveStatementItemsFromStatement( statementId: number, statementItemIds: any, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.removeStatementItemsFromStatement(statementId,statementItemIds).subscribe(responseJson => {
    this.setApiSuccess();
    this.processApiSuccess(responseJson, onSuccessCallback);
    },
  errorResponse => {
    this.setApiError();
    this.processApiError(errorResponse, onErrorCallback);
  });
}

}
