<mat-expansion-panel class="mb-3" (opened)="panelOpenState = true"(closed)="panelOpenState = false">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <div>
        <span [innerHTML]='iconFilter | safeHtml'></span>
        <span>{{'cc.common.view.filter' | translate}}s</span>
      </div>
      <span [innerHTML]='(panelOpenState ? dropDownIcon : dropUpIcon) | safeHtml'></span>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="mk-mat-filter">
    <div class="mk-mat-filter-container">
      <div class="filter-inputs">

        <!--  SEARCH  -->
        <div  *ngIf="inputFields">
          <mc-input-field *ngFor="let item of inputFields"
                          [setup]="item"
                          (newSetup)="getInputValue($event)"></mc-input-field>
        </div>

        <!-- STATUS -->
        <form [formGroup]="form">
          <mat-form-field appearance="outline" *ngIf="getAvailableOptions()?.length">
            <mat-label>{{ "cc.common.status" | translate }}</mat-label>
            <mat-select formControlName="status" multiple disableOptionCentering #multiselect>
              <mat-option *ngFor="let item of getAvailableOptions()" [value]="item" #matOption (click)="onOptionClick(matOption)">
                {{ getOptionLabel(item) }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
    </div>
    <div class="filter-actions">
      <button mat-flat-button class="mc-button clear-all" (click)="removeFilters()">{{'cc.common.clear-all-filters' | translate}}</button>
      <button mat-flat-button class="mc-button filter-button" (click)="onPressFilterButton()">{{'cc.common.view.filter' | translate}}</button>
    </div>
  </div>
</mat-expansion-panel>
