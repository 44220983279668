import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {IEmailTemplate} from '../_generated/email-template.interface';
import {EmailTemplate} from '../models/email-template.model';
import {EmailTemplateList} from '../models/email-template-list.model';
import {EmailTemplateServiceGENERATED} from '../_generated/email-template-generated.service';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class EmailTemplateService extends EmailTemplateServiceGENERATED {

  public saveTemplate(templateDetails: any){
    return this.httpClient.post(this.getApiServiceRootUrl() + `/template`, templateDetails);
  }

  public getTemplate(idEmailConfig: number) {
    return this.httpClient.get(this.getApiServiceRootUrl() + `/template/${idEmailConfig}`, { responseType: 'text' });
  }

  public deleteTemplate(idEmailConfig: number) {
    return this.httpClient.delete(this.getApiServiceRootUrl() + `/reset-template/${idEmailConfig}`);
  }
}
