/*BbmTestObj*/
import {EmailTemplateGENERATED} from '../_generated/email-template-generated.model';

export class EmailTemplate extends EmailTemplateGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new EmailTemplate();
  }

  // ---------------------------------------------------------------------
}
