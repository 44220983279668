import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class McSidebarMenuConfigService {

  private storageNewMenuKey = 'newMenuFlag';
  private newMenuFlagSubject = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem(this.storageNewMenuKey) || 'false')
  );
  newMenuFlag$ = this.newMenuFlagSubject.asObservable();

  setMenuFlag(flag: boolean): void {
    this.newMenuFlagSubject.next(flag);
    localStorage.setItem(this.storageNewMenuKey, JSON.stringify(flag));
  }

  getMenuFlg(){
    return this.newMenuFlagSubject.getValue();
  }

  constructor() { }
}
