import {EmailConfigFilterGENERATED} from '../_generated/email-config-filter-generated.model';

export class EmailConfigFilter extends EmailConfigFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new EmailConfigFilter();
  }

  // ---------------------------------------------------------------------
}
