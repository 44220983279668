<section class="mc-invoice-step justify-content-start">
  <div class="row mc-header-wrapper">
    <div class="col-md-12 mc-stepper-header-title">
      <span class="pl-4 pb-1 mr-2 mc-semi-active">
        <span class="mc-notification-badge-gray mc-semi-active-background">1</span>
        {{ 'cc.common.articles' | translate }}
      </span>
      <span class="pl-4 pb-1 ml-4 mr-2 mc-active">
        <span class="mc-notification-badge-gray mc-active-background">2</span>
        {{'cc.my-factoring.payment' | translate}}
      </span>
      <span class="pl-4 pb-1 ml-4 mr-2">
        <span class="mc-notification-badge-gray">3</span>
        {{'cc.invoice.view.schedule-and-summary' | translate}}
      </span>
    </div>
  </div>

  <form class="form-row mc-add-consumers-form" [formGroup]="stepForm">
    <div class="col-md-12">
      <h5 class="mt-large mc-schedule-title">{{'cc.roles.edit.billing' | translate}}</h5>
      <div class="row mx-0 mx-btn w-100">
        <mat-radio-group formControlName="billingOption">
          <mat-radio-button [value]="true">{{'cc.common.edit.yes' | translate}}</mat-radio-button>
          <mat-radio-button [value]="false">{{'cc.common.edit.no' | translate}}</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="payment-step-info">
        <span [innerHtml]="iconGenerate | safeHtml" class="icon-info-small"></span>
        <p>{{'cc.invoice.payment-step-text' | translate}}</p>
      </div>


      <ng-container *ngIf="stepForm.get('billingOption')?.value">
        <h5 class="mt-large mc-schedule-title">{{'cc.my-factoring.payment-method' | translate}}</h5>
        <div class="row mx-0 mt-3 w-100"
             [ngClass]="{ 'flex-column': stepForm.controls['paymentMethod']?.value == 'DIRECT_DEBIT' }">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'cc.invoice.choose-payment-method' | translate }}</mat-label>
            <mat-select panelClass="dialog-select"
                        [placeholder]="'cc.invoice.choose-payment-method' | translate"
                        formControlName="paymentMethod"
                        >
              <mat-option *ngFor="let paymentMethod of paymentMethods" [value]="paymentMethod.value">
                {{ paymentMethod.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
<!-- --------------------------------------Money transfer---------------------------------------->
          <ng-container *ngIf="stepForm.controls['paymentMethod']?.value == 'TRANSFER'">
            <mat-form-field appearance="outline"  >
              <mat-select panelClass="dialog-select"
                          [placeholder]="'cc.consumer.transfer-money-to' | translate"
                          formControlName="transferMoneyTo"
                          >
                <mat-option *ngFor="let account of transferMoneyOptionsVll.items" [value]="account.value"  (click)="onTransferMoneyValueChanged(account.value)">
                  {{ account.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <ng-container *ngIf="mcBankAccountsVll.items.length>0">
              <mat-form-field appearance="outline"  >
                <mat-select panelClass="dialog-select"
                            [placeholder]="'cc.common.edit.bank-account' | translate"
                            formControlName="moneyTransferBankAccs"
                            >
                  <mat-option *ngFor="let account of mcBankAccountsVll.items" [value]="account.value"  (click)="onBankAccountValueChanged(account.value)">
                    {{ account.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>

                <div class="card dialog-header-card" *ngIf="showBankInfoForTransferMoney && mcBankAccount">
                  <div class='dialog-row-wrapper dialog-header'>
                    <div class='dialog-col'>
                      <span class='dialog-col-label'>{{ 'cc.common.bank-name' | translate}}:</span>
                      <span class='dialog-col-value'>{{ mcBankAccount.bankName ? mcBankAccount.bankName : '-' }}</span>
                    </div>
                    <div class='dialog-col'>
                      <span class='dialog-col-label'>{{ 'cc.common.account-owner' | translate}}:</span>
                      <span class='dialog-col-value'>{{ mcBankAccount.accountOwner ? mcBankAccount.accountOwner : '-' }}</span>
                    </div>
                    <div class='dialog-col'>
                      <span class='dialog-col-label'>{{ 'cc.common.iban' | translate}}:</span>
                      <span class='dialog-col-value'>{{ mcBankAccount.iban ? mcBankAccount.iban : '-' }}</span>
                    </div>
                    <div class='dialog-col'>
                      <span class='dialog-col-label'>{{ 'cc.consumers.edit.bic' | translate}}:</span>
                      <span class='dialog-col-value'>{{ mcBankAccount.bic ? mcBankAccount.bic : '-' }}</span>
                    </div>
                  </div>
                </div>

          </ng-container>
<!----------------------------------Direct debit----------------------------------------------->
          <ng-container *ngIf="stepForm.controls['paymentMethod']?.value == 'DIRECT_DEBIT'">
            <div class="card hasBank">
              <span [innerHtml]="(primaryBankAccount ? iconSuccess : iconFailed) | safeHtml"></span>
              <span class="hasBank_text">{{ (primaryBankAccount ? "cc.consumer360.has-bank-account" : "cc.consumer360.has-not-bank-account") | translate }}</span>
            </div>
              <mat-checkbox
                (change)="onDiffBankAccountChanged($event)"
                *ngIf="primaryBankAccount"
                formControlName="differentBankAccount">{{ "cc.invoice.use-different-bank-acc" | translate }}</mat-checkbox>
            <div *ngIf="!primaryBankAccount || stepForm.controls['differentBankAccount']?.value">
              <h2 class="title-medium-dark mb-large mt-large">{{'cc.common.bank-information' | translate}}</h2>
              <div class='input-wrapper'>
                <mat-form-field appearance="outline">
                  <input  formControlName="bankName" type="text" matInput placeholder="{{'cc.common.account-owner' | translate}}*" value="">
                </mat-form-field>
                <mat-form-field appearance="outline" >
                  <input formControlName="accountOwner" type="text" matInput placeholder="{{'cc.common.bank-name' | translate}}*" value="">
                </mat-form-field>
                <mat-form-field appearance="outline" >
                  <input formControlName="iban" type="text" matInput placeholder="{{'cc.common.iban' | translate}}*" value="">
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <input formControlName="bic" type="text" matInput placeholder="{{'cc.consumers.edit.bic' | translate}}*" value="">
                </mat-form-field>
              </div>
              <h2 class="title-medium-dark mb-large">{{'cc.consumer360.sepa-mandate' | translate}}</h2>
              <div class="card hasBank">
                <span [innerHtml]="iconGenerate | safeHtml" class="mb-smallest"></span>
                <span class="hasBank_text">{{ "cc.consumer360.sepa-mandate-info" | translate }}</span>
              </div>
              <div class="pt-5 px-0 d-flex align-items-center justify-content-start">
                <a class="mc-invoice-preview-btn" href="javascript:void(0)" (click)="generateSepaMandate()">
                  {{ 'cc.consumer360.generate-sepa-mandate' | translate }}
                </a>
              </div>
            </div>
          </ng-container>

        </div>
      </ng-container>

    </div>
  </form>
</section>

