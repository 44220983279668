import { Component, OnInit } from '@angular/core';
import { ICON_BACK } from "../../../../assets/media/svg_icons/icon-back";
import {
  CsrBankAccount,
  CsrBankAccountList,
  CsrConsumer,
  CsrConsumerService,
  McCurrency,
  McCurrencyService,
  McDateUtils,
  McEntityConsumerAttribute,
  McEntityConsumerAttributeList, McForm,
  McGod
} from "@miticon-ui/mc-core";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { isEqual } from 'lodash';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lib-csr-consumer-add-edit',
  templateUrl: './csr-consumer-add-edit.component.html',
  styleUrls: ['./csr-consumer-add-edit.component.scss']
})
export class CsrConsumerAddEditComponent implements OnInit {
  mcForm = new McForm();
  iconBack = ICON_BACK;
  form!: FormGroup;
  consumerId!: number;
  maxDate = new Date(Date.now() - 86400000);
  currencies: McCurrency[] = [];
  entityConsumerAttributes: McEntityConsumerAttributeList;
  csrConsumer = new CsrConsumer();
  primaryBankAccount = new CsrBankAccount();
  csrContract: any;
  isConsumer360: boolean;

  genders = [
    { label: 'cc.common.edit.male', value: 'MALE' },
    { label: 'cc.common.edit.female', value: 'FEMALE' },
    { label: 'cc.common.edit.prefer-not-to-say', value: 'OTHER' }
  ];

  isoCountries = CsrConsumer.ISO_COUNTRIES;

  isoLanguages = CsrConsumer.ISO_LANGUAGES;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              private mcGod: McGod,
              private location: Location,
              private mcCurrencyService: McCurrencyService,
              private csrConsumerService: CsrConsumerService,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.createForm();

    this.route.params.pipe().subscribe((params) => {
      this.consumerId = params['id'];
      if (this.consumerId) return this.loadConsumer();
    });

    this.route.url.subscribe(urlSegment => {
      this.isConsumer360 = this.router.url.includes('360');
    });

    this.currencies = this.mcCurrencyService.currencies;

    if(this.csrConsumer.entityConsumerAttributes == null) {
      this.csrConsumer.entityConsumerAttributes = new McEntityConsumerAttributeList();
      this.loadEntityCustomAttributes();
    }
  }

  createForm() {
    this.form = this.fb.group({
      firstName: [''],
      lastName: [''],
      statusCd: [''],
      birthday: [''],
      gender: [''],
      email: ['', [Validators.required, Validators.pattern(/\S/), Validators.pattern(/^[\w+-.]+@([\w-]+\.)+[\w-]{2,64}$/)]],
      telephone: [''],
      street: [''],
      postCode: [''],
      city: [''],
      typeCd: ['', Validators.required],
      extConsumerId: [''],
      title: [''],
      addressSupplement: [''],
      isoCountry: [''],
      isoLanguage: [''],
      cellphone: [''],
      fax: [''],
      flgDunningEnabled: [true],
      flgInkassoEnabled: [true],
      countryName: [''],
      companyName: [''],
      euVat: [''],

      currency: ['EUR'],

      iban: ['', [Validators.pattern("^[A-Z]{2}\\d{2}[A-Z0-9]+")]],
      bic: ['', [Validators.minLength(8), Validators.maxLength(11)]],
      owner: [''],
      bankName: [''],
      sepaMandateId: [''],
      sepaMandateDate: [''],

      contractNumber: [''],
      contractStatus: [''],
      contractEndDate: [''],
      contractStartDate: [''],
      contractBaseAmount: [''],
      contractSigningDate: [''],
      contractCancellationDate: [''],
      contractCancellationActiveOnDate: [''],
      contractCancellationReason: [''],
      contractModel: [''],
      contractType: ['']
    });

    this.form.controls['typeCd'].valueChanges.subscribe((typeCd) => {
      if(typeCd === CsrConsumer.TYPE_CD_PERSON) {
        this.setValidators('firstName', true);
        this.setValidators('lastName', true);
        this.setValidators('companyName', false);
      } else {
        if(typeCd === CsrConsumer.TYPE_CD_COMPANY) {
          this.setValidators('firstName', false);
          this.setValidators('lastName', false);
          this.setValidators('companyName', true);
        }
      }
    })
  }

  setValidators(controlName: string, isRequired: boolean) {
    isRequired ? this.form.get(controlName)?.setValidators([Validators.required]) : this.form.get(controlName)?.clearValidators();
    this.form.get(controlName)?.updateValueAndValidity({onlySelf: true});
  }

  loadEntityCustomAttributes() {
    this.csrConsumerService.getAttributes()
      .subscribe(
        (response: McEntityConsumerAttribute[]) => {
          response.forEach(item => {
            if (!item.name.startsWith('contract')) {
              const existingAttribute = this.csrConsumer.entityConsumerAttributes.items.find(attr => attr.name === item.name);

              if (!existingAttribute) {
                let entityAttribute = new McEntityConsumerAttribute();
                entityAttribute.name = item.name;
                entityAttribute.value = item.value;
                this.csrConsumer.entityConsumerAttributes.items.push(entityAttribute);
              }
            }
          });
        }
      );
  }

  loadConsumer() {
    this.csrConsumerService.getById(this.consumerId).subscribe((consumer: any) => {
      this.csrConsumer = consumer;
      this.csrContract = consumer.activeContracts[0];

      if(consumer.bankAccounts.find((bankAccount: CsrBankAccount) => bankAccount.flgPrimary) != null) {
        this.primaryBankAccount = consumer.bankAccounts.find((bankAccount: CsrBankAccount) => bankAccount.flgPrimary);
      }
      if(this.csrConsumer.entityConsumerAttributes == null) {
        this.csrConsumer.entityConsumerAttributes = new McEntityConsumerAttributeList();
        this.loadEntityCustomAttributes();
      }
      this.form.setValue({
        firstName: consumer.firstName ? consumer.firstName: null,
        lastName: consumer.lastName ? consumer.lastName: null,
        statusCd: consumer.statusCd ? consumer.statusCd: null,
        birthday: consumer.birthday ? consumer.birthday: null,
        gender: consumer.gender ? consumer.gender: null,
        email: consumer.email ? consumer.email: null,
        telephone: consumer.telephone ? consumer.telephone: null,
        street: consumer.street ? consumer.street: null,
        postCode: consumer.postCode ? consumer.postCode: null,
        city: consumer.city ? consumer.city: null,
        typeCd: consumer.typeCd ? consumer.typeCd: null,
        extConsumerId: consumer.extConsumerId ? consumer.extConsumerId: null,
        title: consumer.title ? consumer.title: null,
        addressSupplement: consumer.addressSupplement ? consumer.addressSupplement: null,
        isoCountry: consumer.isoCountry ? consumer.isoCountry: null,
        isoLanguage: consumer.isoLanguage ? consumer.isoLanguage: null,
        cellphone: consumer.cellphone ? consumer.cellphone: null,
        fax: consumer.fax ? consumer.fax: null,
        flgDunningEnabled: consumer.flgDunningEnabled,
        flgInkassoEnabled: consumer.flgInkassoEnabled,
        countryName: consumer.countryName ? consumer.countryName : null,
        companyName: consumer.companyName ? consumer.companyName : null,
        euVat: consumer.euVat ? consumer.euVat : null,
        currency: 'EUR',

        iban: this.primaryBankAccount && this.primaryBankAccount.iban ? this.primaryBankAccount.iban : null,
        bic: this.primaryBankAccount && this.primaryBankAccount.bic ? this.primaryBankAccount.bic : null,
        owner: this.primaryBankAccount && this.primaryBankAccount.owner ? this.primaryBankAccount.owner : null,
        bankName: this.primaryBankAccount && this.primaryBankAccount.bankName ? this.primaryBankAccount.bankName : null,
        sepaMandateId: this.primaryBankAccount && this.primaryBankAccount.sepaMandateId ? this.primaryBankAccount.sepaMandateId : null,
        sepaMandateDate: this.primaryBankAccount && this.primaryBankAccount.sepaMandateDate ? this.primaryBankAccount.sepaMandateDate : null,

        contractNumber: this.csrContract && this.csrContract.contractNumber ? this.csrContract.contractNumber: null,
        contractStatus: this.csrContract && this.csrContract.statusCd ? this.csrContract.statusCd: null,
        contractEndDate: this.csrContract && this.csrContract.endDate ? this.csrContract.endDate: null,
        contractStartDate: this.csrContract && this.csrContract.startDate ? this.csrContract.startDate: null,
        contractBaseAmount: this.csrContract && this.csrContract.priceUnit ? this.csrContract.priceUnit: null,
        contractSigningDate: this.csrContract && this.csrContract.contractSigningDate? this.csrContract.contractSigningDate: null,
        contractCancellationDate: this.csrContract && this.csrContract.contractCancellationDate ? this.csrContract.contractCancellationDate : null,
        contractCancellationActiveOnDate: this.csrContract && this.csrContract.contractCancellationActiveOnDate ? this.csrContract.contractCancellationActiveOnDate: null,
        contractCancellationReason: this.csrContract && this.csrContract.cancellationReason ? this.csrContract.cancellationReason: null,
        contractModel: this.csrContract && this.csrContract.contractModel ? this.csrContract.contractModel: null,
        contractType: this.csrContract && this.csrContract.contractType ? this.csrContract.contractType: null,
      })
    })
  }

  goBack() {
    this.location.back();
  }

  save() {
    const csrConsumer = new CsrConsumer();

    Object.keys(this.form.controls).forEach(controlName => {
      const controlValue = this.form.controls[controlName].value;
      if (csrConsumer.hasOwnProperty(controlName)) {
        // @ts-ignore
        csrConsumer[controlName] = controlValue;
      }
    });

    csrConsumer.typeCd = this.form.controls['typeCd'].value;
    csrConsumer.gender = this.form.controls['gender'].value;
    csrConsumer.isoCountry = this.form.controls['isoCountry'].value;
    csrConsumer.isoLanguage = this.form.controls['isoLanguage'].value;
    csrConsumer.birthday = this.form.controls['birthday'].value;
    csrConsumer.flgDunningEnabled = this.form.controls['flgDunningEnabled'].value;
    csrConsumer.flgInkassoEnabled = this.form.controls['flgInkassoEnabled'].value;

    csrConsumer.additionalAttributes = this.mapAdditionalAttributes();

    let csrBankAccountList = new CsrBankAccountList();
    csrBankAccountList.addItem(this.mapBankAccounts());

    if (this.consumerId) {
      csrConsumer.id = this.consumerId;
      if(this.areBankInfoDifferent() && this.bankHasValues()) {
        csrConsumer.bankAccounts = csrBankAccountList;
      }
      this.csrConsumerService.update(csrConsumer).subscribe({
        next: (data) => {
          if (data) {
            this.location.back();
          }
        },
        error: (error) => {
          this.toastr.error(error.error.message);
        }
      });
    } else {
      if(this.form.controls['iban']?.value) {
        csrConsumer.bankAccounts = csrBankAccountList;
      }
      this.csrConsumerService.insert(csrConsumer).subscribe((data) => {
        if (data) {
          this.location.back();
        }
      })
    }
  }

  mapBankAccounts() {
    const bankAccount = new CsrBankAccount();
    bankAccount.bankName = this.form.controls['bankName'].value;
    bankAccount.owner = this.form.controls['owner'].value;
    bankAccount.iban = this.form.controls['iban'].value;
    bankAccount.bic = this.form.controls['bic'].value;
    bankAccount.sepaMandateId = this.form.controls['sepaMandateId'].value;
    bankAccount.sepaMandateDate = this.form.controls['sepaMandateDate'].value;
    bankAccount.flgPrimary = true;
    return bankAccount;
  }

  mapAdditionalAttributes() {
    let additionalAttributes = new Map<string, any>();
    const contractFieldsString = ['contractNumber', 'contractStatus', 'contractBaseAmount', 'contractCancellationReason', 'contractModel', 'contractType'];
    const contractFieldsDate = ['contractEndDate', 'contractStartDate', 'contractSigningDate', 'contractCancellationDate', 'contractCancellationActiveOnDate'];
    contractFieldsString.forEach((contractFieldsString) => {
      if(this.form.controls[contractFieldsString].value) {
        additionalAttributes.set(contractFieldsString, this.form.controls[contractFieldsString].value)
      }
    });
    contractFieldsDate.forEach((contractFieldsDate) => {
      if(this.form.controls[contractFieldsDate].value) {
        additionalAttributes.set(contractFieldsDate, McDateUtils.formatDateToString(this.form.controls[contractFieldsDate].value, 'YYYY-MM-DD'));
      }
    });

    if (this.csrConsumer.entityConsumerAttributes) {
      this.csrConsumer.entityConsumerAttributes.items.forEach(item => {
        if (this.mcForm.getValue(item.name) != null) {
          item.value = this.mcForm.getValue(item.name).toString();
          additionalAttributes.set(item.name, item.value);
        }
      });
    }

    additionalAttributes.forEach((value, key) => {
      if (value === '' || value === 0) {
        additionalAttributes.delete(key);
      }
    });

    let additionalAttributesObject: { [key: string]: any } = {};
    additionalAttributes.forEach((value, key) => {
      additionalAttributesObject[key] = value;
    });

    return additionalAttributesObject;
  }

  isCompany() {
    return this.form.controls['typeCd'].value === CsrConsumer.TYPE_CD_COMPANY;
  }

  isRequired(formControlName: string) {
    return this.form.controls[formControlName].hasError('required');
  }

  onlyNumbersCheck(event: KeyboardEvent) {
    const onlyNumbersRegex = /[0-9\b]/;
    const backspace = 'Backspace'
    if (!event.key.match(onlyNumbersRegex) && event.key != backspace) {
      event.preventDefault();
    }
  }

  bankHasValues(): boolean {
    const bankInfoControls = ['iban', 'bic', 'owner', 'bankName', 'sepaMandateId', 'sepaMandateDate']
    for (let control of bankInfoControls) {
      if(this.form.get(control)?.value != null && this.form.get(control)?.value != "") {
        return true;
      }
    }
    return false;
  }
  areBankInfoDifferent(): boolean {
    const bankInfoControls = ['iban', 'bic', 'owner', 'bankName', 'sepaMandateId', 'sepaMandateDate']
    const primaryBankAccount = this.primaryBankAccount;

    for (let control of bankInfoControls) {
      const formValue = this.form.get(control)?.value;
      const primaryValue = primaryBankAccount[control as keyof CsrBankAccount];

      if (!isEqual(formValue, primaryValue)) {
        return true;
      }
    }
    return false;
  }
}
