
import {Table3Config} from '../extra/table-3-config';

// import {CsrConsumerGENERATED} from '../_generated/csr-consumer-generated.model';

export class CsrConsumerTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colAccountOwner', 'Account owner', 'accountOwner', 'accountOwner');
    this.addColumn('colAddressSupplement', 'Address supplement', 'addressSupplement', 'addressSupplement');
    this.addColumn('colArchived', 'Archived flag', 'archived', 'archived');
    this.addColumn('colBankName', 'Bank name', 'bankName', 'bankName');
    this.addColumn('colBic', 'BIC', 'bic', 'bic');
    this.addColumn('colBirthday', 'Birthday', 'birthday', 'birthday');
    this.addColumn('colBlockReason', 'Blocking reason', 'blockReason', 'blockReason');
    this.addColumn('colCellphone', 'Cellphone', 'cellphone', 'cellphone');
    this.addColumn('colCity', 'City', 'city', 'city');
    this.addColumn('colCompanyName', 'Company name', 'companyName', 'companyName');
    this.addColumn('colContractNumber', 'Contract number', 'contractNumber', 'contractNumber');
    this.addColumn('colCountryName', 'Country name', 'countryName', 'countryName');
    this.addColumn('colCountryPhoneCode', 'Country Phone Code', 'countryPhoneCode', 'countryPhoneCode');
    this.addColumn('colCustomAttributes', 'Custom attributes', 'customAttributes', 'customAttributes');
    this.addColumn('colCustomAttributesJsonb', 'Custom attributes', 'customAttributesJsonb', 'customAttributesJsonb');
    this.addColumn('colEmail', 'Email', 'email', 'email');
    this.addColumn('colEmgCity', 'Emergency city', 'emgCity', 'emgCity');
    this.addColumn('colEmgCountry', 'Emergency country', 'emgCountry', 'emgCountry');
    this.addColumn('colEmgCountryPhoneCode', 'Emergency Country Phone Code', 'emgCountryPhoneCode', 'emgCountryPhoneCode');
    this.addColumn('colEmgEmail', 'Emergency email', 'emgEmail', 'emgEmail');
    this.addColumn('colEmgLastName', 'Emergency last name', 'emgLastName', 'emgLastName');
    this.addColumn('colEmgName', 'Emergency name', 'emgName', 'emgName');
    this.addColumn('colEmgPhoneNumber', 'Emergency phone number', 'emgPhoneNumber', 'emgPhoneNumber');
    this.addColumn('colEmgPostCode', 'Emergency post code', 'emgPostCode', 'emgPostCode');
    this.addColumn('colEmgRelationCd', 'Emergency relation', 'emgRelationCd', 'emgRelationCd');
    this.addColumn('colEmgStreet', 'Emergency street', 'emgStreet', 'emgStreet');
    this.addColumn('colEuVat', 'Eu vat', 'euVat', 'euVat');
    this.addColumn('colExtConsumerId', 'External Consumer ID', 'extConsumerId', 'extConsumerId');
    this.addColumn('colFax', 'Fax', 'fax', 'fax');
    this.addColumn('colFirstName', 'First name', 'firstName', 'firstName');
    this.addColumn('colFlgDunningEnabled', 'Dunning enabled flag', 'flgDunningEnabled', 'flgDunningEnabled');
    this.addColumn('colFlgInkassoEnabled', 'Inkasso enabled flag', 'flgInkassoEnabled', 'flgInkassoEnabled');
    this.addColumn('colGender', 'Gender', 'gender', 'gender');
    this.addColumn('colIban', 'IBAN', 'iban', 'iban');
    this.addColumn('colIdMcEntity', 'Entity ID', 'idMcEntity', 'idMcEntity');
    this.addColumn('colIsoCountry', 'Country ISO code', 'isoCountry', 'isoCountry');
    this.addColumn('colIsoLanguage', 'Language ISO code', 'isoLanguage', 'isoLanguage');
    this.addColumn('colLastName', 'Last name', 'lastName', 'lastName');
    this.addColumn('colPostCode', 'Post code', 'postCode', 'postCode');
    this.addColumn('colSepaMandanteDate', 'Sepa mandate date of signature', 'sepaMandanteDate', 'sepaMandanteDate');
    this.addColumn('colSepaMandanteId', 'Sepa mandante id', 'sepaMandanteId', 'sepaMandanteId');
    this.addColumn('colStatusCd', 'Status code', 'statusCd', 'statusCd');
    this.addColumn('colStreet', 'Street', 'street', 'street');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');
    this.addColumn('colSysUpdatedDatetime', 'System Updated Datetime', 'sysUpdatedDatetime', 'sysUpdatedDatetime');
    this.addColumn('colSysUpdatedUserId', 'Sys Updated User Id', 'sysUpdatedUserId', 'sysUpdatedUserId');
    this.addColumn('colTelephone', 'Telephone', 'telephone', 'telephone');
    this.addColumn('colTitle', 'Title', 'title', 'title');
    this.addColumn('colTypeCd', 'Type code', 'typeCd', 'typeCd');
    this.addColumn('colVersion', 'Version', 'version', 'version');

*/
  }
}
