export const ICON_INVITATIONS = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3700_47414)">
<path d="M5.93999 18.99H15C15.65 18.99 16.26 18.68 16.63 18.15L20.58 12.58C20.83 12.23 20.83 11.77 20.58 11.42L16.62 5.84C16.26 5.31 15.65 5 15 5H5.93999C5.12999 5 4.65999 5.93 5.12999 6.59L8.99999 12L5.12999 17.41C4.65999 18.07 5.12999 18.99 5.93999 18.99Z" fill="currentColor"/>
</g>
<defs>
<clipPath id="clip0_3700_47414">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>`
