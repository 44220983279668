<lib-mc-loader [showLoader]="invoicesList.apiLoadingFlg"></lib-mc-loader>
<mcc-message [errorMsg]="errorMessage"></mcc-message>

<div class="mc-consumer-360-contracts">
  <div class="actions-container">
    <span>{{'cc.consumer-360.invoices' | translate}}</span>
    <div class="actions-wrapper">
      <button class="system-action-btn" id="createInvoiceModalButton" (click)="onCreateInvoice()">
        <i class="fas fa-plus-circle mc-add-new-user-icon"></i>{{'cc.common.view.create-invoice' | translate}}
      </button>
    </div>
  </div>

  <div *ngIf="invoicesList.apiSuccessFlg">
    <mk-mat-table
      [isLoading]="isLoading"
      [items]="ppInvoiceList.items"
      [config]="tableConfig"
      [showFilters]="false"
      [showTotalItems]="false"
      [totalItemsCount]="totalItemsCount"
      [itemsPerPage]="pageSize"
      [mkMatMenuActionItems]="mkMatMenuActionItems"
      (actionMkMatMenuItems)="onActionMkMatMenuItems($event)"
    >
    </mk-mat-table>
  </div>
</div>
