import { Component } from '@angular/core';
import { ICON_INFO } from '../../../../assets/media/svg_icons/icon-info';
import { ICON_CLOSE } from '../../../../assets/media/svg_icons/icon-close';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'lib-new-menu-dialog',
  standalone: false,
  templateUrl: './new-menu-dialog.component.html',
  styleUrl: './new-menu-dialog.component.scss'
})
export class NewMenuDialogComponent {
  iconInfo = ICON_INFO;
  iconClose = ICON_CLOSE;
  constructor(public dialogRef: MatDialogRef<NewMenuDialogComponent>) {}

}
