/*BbmTestObj*/
import {McEntityAdditionalDataGENERATED} from '../_generated/mc-entity-additional-data-generated.model';

export class McEntityAdditionalData extends McEntityAdditionalDataGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new McEntityAdditionalData();
  }

  // ---------------------------------------------------------------------
}
