import {Component, OnInit} from '@angular/core';
import { McEntityService, } from '@miticon-ui/mc-core';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import { ICON_STATUS_WAITING_FOR_APPROVAL } from '../../assets/media/svg_icons/icon-status-waiting-for-approval';
import { ICON_STATUS_INACTIVE } from '../../assets/media/svg_icons/icon-status-inactive';
import { ICON_STATUS_ACTIVE } from '../../assets/media/svg_icons/icon-status-active';
import { ICON_STATUS_GRAY } from '../../assets/media/svg_icons/icon-status-gray';

import { MatDialog } from '@angular/material/dialog';
import { McProfileActivationDialogComponent } from './components/mc-profile-activation-dialog/mc-profile-activation-dialog.component';

@Component({
  selector: 'mc-entity-profile',
  templateUrl: './mc-entity-profile.component.html',
  styleUrl: './mc-entity-profile.component.scss',
})
export class  McEntityProfileComponent implements OnInit {

  showLoader: boolean;
  entityId: any;
  mcEntity!: any;

  iconStatus: string;
  isExperimentalEnv = false;

  constructor(private activatedRoute: ActivatedRoute,
              private entityService: McEntityService,
              private toastr: ToastrService,
              private dialog: MatDialog,) {
    this.isExperimentalEnv = localStorage.getItem('isExperimentalEnv') === 'true';
  }

  ngOnInit() {
    this.entityId = this.activatedRoute.snapshot.params['id'];
    this.getEntityFromDb();
 }

  private getEntityFromDb() {
    this.showLoader = true;
      if(this.entityId) {
        this.entityService.getEntityBasicInfoById(this.entityId).subscribe({
        next: (response) => {
          this.mcEntity = response;
          this.entityService.currentMcEntity.set(response);
          this.getStatusIcon(response.statusCd);
          this.showLoader = false;
        },
        error: (error) => {
          this.showLoader = false;
          this.toastr.error(error.error.message);
        }
      });
      }
  }

  private getStatusIcon(status: string){
    switch (status) {
      case 'ACTIVE': this.iconStatus = ICON_STATUS_ACTIVE;
      break;
      case 'INACTIVE': this.iconStatus = ICON_STATUS_INACTIVE;
      break;
      case 'PENDING_ACTIVATION': this.iconStatus = ICON_STATUS_GRAY;
      break;
      case 'PENDING_CONFIRMATION': this.iconStatus = ICON_STATUS_WAITING_FOR_APPROVAL;
      break;
    }
  }

  isStatusPending(status: string): boolean {
    const nonPendingStatuses = ['ACTIVE', 'INACTIVE'];
    return !nonPendingStatuses.includes(status);
  }

  onSuccessEditBasicInfo(event: boolean) {
    if (event) { this.getEntityFromDb(); }
  }

  openProfileActivationDialog(): void {
    this.dialog.open(McProfileActivationDialogComponent, {
      data: { entityId: this.entityId },
      height: '400px',
      width: '700px',
      panelClass: 'dialog',
      autoFocus: false,
      restoreFocus: false
    }).afterClosed().subscribe((isCreated) => {
      if(isCreated) { this.getEntityFromDb(); }
    })
  }

  getFormattedStatus(status: string): string {
    if (!status) return '-';
    return status.replace(/_/g, ' ').split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }
}
