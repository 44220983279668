<h3 class="page-title">{{"cc.common.consumer-360" | translate}}</h3>
<div class="actions-container">
  <span>{{"cc.sidemenu.consumer-cockpit" | translate}}</span>
  <div class="actions-wrapper">
    <button  *ngIf="mcGod.userHasMcConsumerAddPermission()" class="system-action-btn mr-2" (click)="addConsumer()">
      <i class="fas fa-plus-circle mc-add-new-user-icon"></i> {{'cc.consumers.create.create-consumers' | translate}}
    </button>
    <button *ngIf="mcGod.userHasMcConsumerImportPerformPermission()" class="system-action-btn" (click)="actGoToDataImport()">
      <i class="fas fa-plus-circle mc-add-new-user-icon"></i> {{ 'cc.consumers.consumers-overview.import-consumers' | translate }}
    </button>
  </div>
</div>
<mk-mat-table
  [isLoading]="isLoading"
  [items]="items"
  [config]="tableConfig"
  [filterConfig]="filterConfig"
  [totalItemsCount]="totalItemsCount"
  [itemsPerPage]="pageSize"
  (filterChanged)="onFilterChanged($event)"
  [mkMatMenuActionItems]="mkMatMenuActionItems"
  (actionMkMatMenuItems)="onActionMkMatMenuItems($event)"
  (eventColumnButtonAction)="onColumnButtonAction($event)"
  [searchTooltip]="searchTooltip"
>
</mk-mat-table>
<mcc-modal1-wrapper *ngIf="showHistoryMcb.value" [showMcb]="showHistoryMcb">
  <lib-mc-consumers-details-history [csrConsumer]="csrConsumer"></lib-mc-consumers-details-history>
</mcc-modal1-wrapper>

<mcc-modal1-wrapper modalSizeCd="small" *ngIf="showAddTransactionsManuallyMcb.value" [showMcb]="showAddTransactionsManuallyMcb">
  <app-shared-transaction-add-manually-part
    [csrConsumer]="csrConsumer"
    (eventCanceled)="actAddTransactionManuallyCanceled()"
    (eventSaved)="actAddTransactionManuallySaved()"
  ></app-shared-transaction-add-manually-part>
</mcc-modal1-wrapper>

<mcc-modal1-wrapper *ngIf="showActionHistoryMcb.value" [showMcb]="showActionHistoryMcb" (eventCanceled)="actActionHistoryCancel()">
  <mc-consumer-action-history-part [csrConsumer]="csrConsumer" (eventCanceled)="actActionHistoryCancel()"></mc-consumer-action-history-part>
</mcc-modal1-wrapper>

<router-outlet></router-outlet>



