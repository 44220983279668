import {EmailTemplateFilterGENERATED} from '../_generated/email-template-filter-generated.model';

export class EmailTemplateFilter extends EmailTemplateFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new EmailTemplateFilter();
  }

  // ---------------------------------------------------------------------
}
