<!--Price adjustment wrapper-->
<div class="mc-price-adjustment-wrapper" [ngStyle]="{'height': additional ? 'auto': '38px'}">

  <!--Clear button-->
  <p class="mb-1 mc-clear-amount-button mc-cursor-pointer"
     [ngStyle]="clearButtonCssStyle()"
     *ngIf="discount.value"
     (click)="onClearAmountPrice()">
    {{"cc.common.clear" | translate}}
  </p>

  <!--Input group-->
  <div class="input-group mc-amount-wrapper">

    <!--Input field - amount value-->
    <input type="number"
           class="form-control text-right mc-no-focus"
           (input)="onAmountPriceChange($event)"
           [ngClass]="{'mc-input-field-invalid': discountValidation()}"
           [(ngModel)]="discount.value"
           [readonly]="additional && isEnabled"
           [min]="0"
           [max]="discount.valueTypeCd === 'PERCENTAGE' ? 100 : totalAmountValue"
           >

    <!--Select option-->
    <!--TODO: get currency list from BE and render in select options-->
    <div class="input-group-append">
      <select class="custom-select border-left-0 mc-no-focus"
              [ngClass]="discount.value ? 'mc-select-blue' : 'mc-select-gray'"
              [(ngModel)]="discount.valueTypeCd"
              [disabled]="additional && isEnabled"
              (ngModelChange)="onAmountPriceChange(discount.value)">
        <option selected value="PERCENTAGE" class="bg-light c">%</option>
        <option value="ABSOLUTE_VALUE" class="bg-light">€</option>
      </select>
    </div><!--Select option /end-->
  </div><!--Input group /end-->

  <div *ngIf="discountValidation()" class="text-danger {{!additional ? 'text-left' : 'mc-invalid-additional-discount'}}">
    {{discountValidation()}}
  </div>

  <!--Exclude discounted items-->
  <div *ngIf="additional" class="form-check clear">
    <input class="form-check-input"
           id="defaultCheck1"
           type="checkbox"
           [(ngModel)]="discount.excludeDiscountedItems"
           [disabled]="discount.valueTypeCd !== 'PERCENTAGE' || isEnabled"
           [checked]="discount.excludeDiscountedItems">
    <label class="form-check-label text-capitalize" for="defaultCheck1">
      {{ "cc.common.exclude-already-discounted-items" | translate }}
    </label>
  </div>
</div><!--Price adjustment /end-->
