
import {Table3Config} from '../extra/table-3-config';

// import {ImDataSetGENERATED} from '../_generated/im-data-set-generated.model';

export class ImDataSetTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colAllowCustomAtt', 'Allow Custom Attribute', 'allowCustomAtt', 'allowCustomAtt');
    this.addColumn('colAllowedForEntityId', 'Allowed For Entity Id', 'allowedForEntityId', 'allowedForEntityId');
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colName', 'Name', 'name', 'name');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');

*/
  }
}
