<lib-mc-loader [showLoader]="csrContractList.apiLoadingFlg"></lib-mc-loader>

<div class="mc-consumer-360-contracts">
  <div class="actions-container">
    <span>{{'cc.consumer.consumer-contracts' | translate}}</span>
    <div class="actions-wrapper">
      <button class="system-action-btn" (click)="goToCreateContract()" *ngIf="mcGod.userHasMcConsumerContractCreatePermission()">
        <i class="fas fa-plus-circle mc-add-new-user-icon"></i>{{'cc.out-factoring.create-new-contract' | translate}}
      </button>
    </div>
  </div>

  <div *ngIf="csrContractList.apiSuccessFlg">
    <mk-mat-table
      [isLoading]="isLoading"
      [items]="items"
      [config]="tableConfig"
      [showFilters]="false"
      [showTotalItems]="false"
      [filterConfig]="filterConfig"
      [totalItemsCount]="totalItemsCount"
      [itemsPerPage]="pageSize"
      (filterChanged)="onFilterChanged($event)"
      [mkMatMenuActionItems]="mkMatMenuActionItems"
      (actionMkMatMenuItems)="onActionMkMatMenuItems($event)"
    >
    </mk-mat-table>
  </div>
</div>
