<ng-container class="dialog">
    <div class="dialog_header">
      <div class='dialog-icon mr-medium'>
        <span [innerHTML]="iconInfo | safeHtml"></span>
      </div>
      <span class="title-medium-dark">
        {{ "cc.new-menu-header" | translate }}
      </span>
      <span class="dialog_header_icon"
            [innerHTML]="iconClose | safeHtml"
            (click)="dialogRef.close(false)">
      </span>
    </div>

  <mat-dialog-content>
    <div class="config mb-large">{{ "cc.new-menu-subheader" | translate }}</div>
    <div class="dialog_content mb-medium">{{ "cc.new-menu-body" | translate }}</div>
    <div class="dialog_content">{{ "cc.new-menu-info" | translate}}</div>
  </mat-dialog-content>

  <div class="line mt-medium"></div>
  <div class='dialog-footer'>
      <button mat-button class="secondary-btn"
              (click)="dialogRef.close(false)">
        {{ "cc.common.edit.no" | translate }}
      </button>
      <button mat-button class="primary-btn"
              (click)="dialogRef.close(true)">
        {{ "cc.common.edit.yes" | translate }}
      </button>
    </div>
</ng-container>

