import { NgModule } from '@angular/core';
import { RouterModule,Routes } from '@angular/router';
import { McMyFactoringViewContactComponent } from './pages/mc-my-factoring-view-contact/mc-my-factoring-view-contact.component';
import { McMyFactoringContractListPageComponent } from './pages/mc-my-factoring-contract-list-page/mc-my-factoring-contract-list-page.component';
import { McMyFactoringSepaInListPageComponent } from './pages/mc-my-factoring-sepa-in-list-page/mc-my-factoring-sepa-in-list-page.component';
import { McMyFactoringTransactionListPageComponent } from './pages/mc-my-factoring-transaction-list-page/mc-my-factoring-transaction-list-page.component';

const routes: Routes = [
  {
    path: 'transactions',
    component: McMyFactoringTransactionListPageComponent,
  },
  {
    path: 'sepa-in',
    component: McMyFactoringSepaInListPageComponent,
  },
  {
    path: 'contracts',
    component: McMyFactoringContractListPageComponent,
  },
  {
    path: 'view-contact',
    component: McMyFactoringViewContactComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class McMyFactoringRoutingModule {
}
