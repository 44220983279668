import {Injectable} from '@angular/core';
import {EmailConfigServiceGENERATED} from '../_generated/email-config-generated.service';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class EmailConfigService extends EmailConfigServiceGENERATED {
  public getAllEmailConfigs(){
    return this.httpClient.get(this.getApiServiceRootUrl() + `/template/all`);
  }

  public getDefaultEmailTemplate(idEmailConfig: number): Observable<any> {
    return this.httpClient.get(this.getApiServiceRootUrl() + `/template/${idEmailConfig}`, { responseType: 'text' });
  }
}
