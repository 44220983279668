<lib-mc-loader [showLoader]="csrConsumer.apiLoadingFlg"></lib-mc-loader>

<div class="mc-consumer-360-create-contract">
  <span class="back-option" (click)="goBack()">
    <span [innerHTML]="iconBack | safeHtml"></span>
    {{ "cc.consumer360.back-option" | translate }}
  </span>
  <div class="card mt-medium">
    <h2 class="title-medium-dark">{{"cc.consumers.create-contract" | translate}}</h2>
    <div class="line"></div>
    <div class='dialog-col'>
      <div class='dialog-col-label'>{{ 'cc.my-factoring.consumer' | translate}}:</div>
      <div class='dialog-col-value'>{{ csrConsumer.isTypeCdPERSON() ? (csrConsumer.firstName + " " + csrConsumer.lastName) : csrConsumer.companyName }}</div>
    </div>
    <div class='dialog-col'>
      <div class='dialog-col-label'>{{ 'cc.consumer.view.consumer-id' | translate}}:</div>
      <div class='dialog-col-value'>{{ csrConsumer.id }}</div>
    </div>
  </div>

  <div *ngIf="!isOneTimeType()" class="header-info mb-small">
    <p class="header-info_label">
      {{ 'cc.common.original-amount' | translate }}:
      <span class="header-info_value">
        {{ selectedContract ? selectedContract.originalAmount + '€' : '-' }}
      </span>
    </p>
    <p class="header-info_label">
      {{ 'cc.common.discount' | translate }}:
      <span class="header-info_value">
        {{ selectedContract && selectedContract.discount ? selectedContract.discount.value + '%' : '-' }}
      </span>
    </p>
    <p class="header-info_label">
      {{ 'cc.product.view.vat' | translate }}:
      <span class="header-info_value">
        {{ csrConsumer && csrConsumer.euVat ? csrConsumer.euVat + '%' : '-' }}
      </span>
    </p>
    <p class="header-info_label">
      {{ 'cc.billing.total-amount' | translate }}:
      <span class="header-info_value">
        {{ selectedContract ? selectedContract.totalAmount + '€' : '-' }}
      </span>
    </p>
    <p class="header-info_label">
      {{ (selectedContract ? getPriceLabel(selectedContract.typeCd) : 'cc.billing.price') | translate }}:
      <span class="header-info_value">
        {{ selectedContract && selectedContract.price ? selectedContract.price + '€' : '-' }}
      </span>
    </p>
    <p class="header-info_label">
      {{ 'mem.articles.one-time' | translate }}:
      <span class="header-info_value">
        {{ selectedContract && selectedContract.priceOneTime ? selectedContract.priceOneTime + '€' : '-' }}
      </span>
    </p>
  </div>
  <div *ngIf="isOneTimeType()" class="header-info mb-small">
    <p class="header-info_label">
      {{ 'cc.consumer.total-netto' | translate }}:
      <span class="header-info_value">
        {{ selectedContract ? selectedContract.totalNetto + '€' : '-' }}
      </span>
    </p>
    <p class="header-info_label">
      {{ 'mem.common.total-brutto' | translate }}:
      <span class="header-info_value">
        {{ selectedContract ? selectedContract.totalAmount + '€' : '-' }}
      </span>
    </p>
    <p class="header-info_label">
      {{ 'cc.product.view.vat' | translate }}:
      <span class="header-info_value">
        {{ selectedContract ? getVat() + ' %' : '-' }}
      </span>
    </p>
  </div>

  <mat-stepper [linear]="false">
    <mat-step [completed]="false">
      <ng-template matStepLabel>
        {{ "cc.contract.contract" | translate }}
      </ng-template>
      <mc-consumer-consumer-360-create-contract-products-and-services-part [mcForm]="consumerContractMcForm"
                                                                           [selectedProduct]="selectedContract"
                                                                           (emitValidRenewal)="isContractFormValid($event)"
                                                                           (emitErrorMsg)="setContractFormErrorMsg($event)"
                                                                           (emitSelectedContract)="onEmitSelectedContract($event)"
      ></mc-consumer-consumer-360-create-contract-products-and-services-part>
      <div class="card card-flex-end">
        <button class="primary-btn ml-small"
                type="button"
                (click)="onNext()"
                [disabled]="!selectedContract || !contractFormValid"
                *ngIf="selectedTab < 3"
                matStepperNext>
          {{ "cc.common.view.next" | translate }}
        </button>
      </div>
    </mat-step>

    <mat-step [completed]="false">
      <ng-template matStepLabel>
        {{ "cc.my-factoring.payment"| translate }}
      </ng-template>
      <mc-consumer-consumer-360-create-contract-payments-part
        *ngIf="selectedTab === 1"
        [mcForm]="consumerContractMcForm"
        [isOneTimeService]="selectedContract.typeCd === 'ONE_TIME'"
        (emitValidPaymentTab)="validPaymentTab = $event">
      </mc-consumer-consumer-360-create-contract-payments-part>
      <div class="card card-flex-end">
        <button class="primary-btn"
                (click)="onPrev()" [ngClass]="selectedTab === 0 ? 'button-hidden' : ''"
                matStepperPrevious>
          {{ 'cc.common.view.prev' | translate }}
        </button>
        <button class="primary-btn ml-small"
                type="button"
                (click)="onNext()"
                [disabled]="!validPaymentTab"
                *ngIf="selectedTab < 3"
                matStepperNext>
          {{ "cc.common.view.next" | translate }}
        </button>
      </div>
    </mat-step>

    <mat-step [completed]="false">
      <ng-template matStepLabel>
        {{ "cc.my-factoring.payment-method" | translate }}
      </ng-template>
      <mc-consumer-consumer-360-create-contract-payment-method-part *ngIf="selectedTab === 2"
                                                                    [selectedTab]="selectedTab"
                                                                    [csrConsumer]="csrConsumer"
                                                                    (eventFileUploaded)="onFileUploaded($event)"
                                                                    [errorMessage]="message"
                                                                    [mcBankAccount]="mcBankAccount"
                                                                    (eventPaymentMethod)="onPaymentMethodChanged($event)"
                                                                    (eventPaymentPurpose)="onPaymentPurpose($event)"
                                                                    (eventMcBankAccount)="onChangeMcBankAccount($event)"
                                                                    (eventPrimaryCsrBankAccount)="onChangeCsrBankAccount($event)"
                                                                    [mcForm]="consumerContractMcForm"></mc-consumer-consumer-360-create-contract-payment-method-part>
      <div class="card card-flex-end">
        <button class="primary-btn"
                (click)="onPrev()" [ngClass]="selectedTab === 0 ? 'button-hidden' : ''"
                matStepperPrevious>
          {{ 'cc.common.view.prev' | translate }}
        </button>
        <button class="primary-btn ml-small"
                type="button"
                (click)="onNext()"
                [disabled]="!paymentMethod || (paymentMethod === 'Money transfer'  ? !mcBankAccount : !primaryBankAccount)"
                *ngIf="selectedTab < 3"
                matStepperNext>
          {{ "cc.common.view.next" | translate }}
        </button>
      </div>
    </mat-step>

    <mat-step [completed]="false">
      <ng-template matStepLabel>
        {{ "cc.consumer.overview-and-confirmation" | translate }}
      </ng-template>
      <mc-consumer-consumer-create-contract-overview-and-confirmation-part *ngIf="selectedTab === 3"
                                                                           [csrConsumer]="csrConsumer"
                                                                           [csrContract]="csrContract"
                                                                           [mcForm]="consumerContractMcForm"
                                                                           [selectedProductList]="selectedProductList"
                                                                           [consumerContractMcForm]="consumerContractMcForm"
                                                                           [mcBankAccount]="mcBankAccount"
                                                                           [selectedContract]="selectedContract"
                                                                           [primaryBankAccount]="primaryBankAccount"
                                                                           [totalVat]="totalVat" [totalNetto]="totalNetto"
                                                                           [message]="message"> </mc-consumer-consumer-create-contract-overview-and-confirmation-part>
      <div class="card card-flex-end">
        <button class="primary-btn"
                (click)="onPrev()" [ngClass]="selectedTab === 0 ? 'button-hidden' : ''"
                matStepperPrevious>
          {{ 'cc.common.view.prev' | translate }}
        </button>
        <button class="primary-btn ml-small"
                (click)="saveContract()" *ngIf="selectedTab === 3">
          {{ "cc.common.create" | translate }}
        </button>
      </div>
    </mat-step>
  </mat-stepper>
</div>
<lib-mc-loader [showLoader]="mcCountry.apiLoadingFlg || mcEntity.apiLoadingFlg || isLoading"></lib-mc-loader>
