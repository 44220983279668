import { Component, effect } from '@angular/core';
import { McEntityService } from '@miticon-ui/mc-core';

@Component({
  selector: 'lib-mc-entity-basic-info-tab',
  templateUrl: './mc-entity-basic-info-tab.component.html',
  styleUrl: './mc-entity-basic-info-tab.component.scss',
})
export class McEntityBasicInfoTabComponent {

  entityObject!: any;

  constructor(private entityService: McEntityService) {
    effect(() => {
      this.entityObject = this.entityService.currentMcEntity();
    });
  }
}
