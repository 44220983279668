import {EmailConfigKey} from './email-config-key.model';
import {EmailConfigKeyListGENERATED} from '../_generated/email-config-key-list-generated.model';

export class EmailConfigKeyList extends EmailConfigKeyListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new EmailConfigKeyList();
  }

  // ---------------------------------------------------------------------
}
