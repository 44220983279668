<ng-container class="dialog">
  <div class="dialog_header">
    <span class="dialog_header_title text-uppercase">
      {{ ("cc.contract-360" | translate) + ": " + csrContract.contractName}}
    </span>
    <span class="dialog_header_icon"
          [innerHTML]="iconClose | safeHtml"
          (click)="dialogRef.close()"></span>
  </div>

  <mat-dialog-content>
    <div class='card'>
      <div class="items info">
        <div class="items_info">
          <div class='card_title'>{{ "cc.billing.details" | translate }}</div>
        </div>
      </div>
      <div class="card">
        <div class="dialog-row-wrapper">
          <div class="dialog-col">
            <span class="dialog_content_row dialog-col-label">{{ "cc.contract-360" | translate }}:</span>
            <span class="dialog_content_row dialog-col-value">{{ csrContract.contractName ? csrContract.contractName : '-' }}</span>
          </div>
          <div class="dialog-col">
            <span class="dialog_content_row dialog-col-label">{{ "cc.contract-360.type" | translate }}:</span>
            <span class="dialog_content_row dialog-col-value">{{ csrContractPreDefined ? getTypeCdLabel(csrContractPreDefined.typeCd) : '-' }}</span>
          </div>
          <div class="dialog-col">
            <span class="dialog_content_row dialog-col-label">{{ "cc.contract-360.duration" | translate }}:</span>
            <span class="dialog_content_row dialog-col-value">{{ formatDuration() }}</span>
          </div>
          <div class="dialog-col">
            <span class="dialog_content_row dialog-col-label">{{ "cc.common.original-amount" | translate }}:</span>
            <span class="dialog_content_row dialog-col-value">{{ getOriginalAmount() }}</span>
          </div>
          <div class="dialog-col">
            <span class="dialog_content_row dialog-col-label">{{ "cc.billing.total-amount" | translate }}:</span>
            <span class="dialog_content_row dialog-col-value">{{ getTotalAmount() }}</span>
          </div>
          <div class="dialog-col">
            <span class="dialog_content_row dialog-col-label">{{ csrContractPreDefined ? (getPriceLabel(csrContractPreDefined.typeCd) | translate) : '-' }}:</span>
            <span class="dialog_content_row dialog-col-value">{{ getPrice() }}</span>
          </div>
          <div class="dialog-col">
            <span class="dialog_content_row dialog-col-label">{{ 'cc.common.discount' | translate }}:</span>
            <span class="dialog_content_row dialog-col-value">{{ getDiscount() }}</span>
          </div>
        </div>
      </div>

      <div class="dialog-row-wrapper">
        <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ "cc.common.contract-start-date" | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">{{ (csrContract.startDate | date : 'dd.MM.YYYY') }}</span>
        </div>
        <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ "cc.common.contract-end-date" | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">{{ csrContract.endDate ? (csrContract.endDate | date : 'dd.MM.YYYY') : '-' }}</span>
        </div>
        <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ "cc.common.contract-cancellation-date" | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">{{ csrContract.contractCancellationDate ? (csrContract.contractCancellationDate | date : 'dd.MM.YYYY') : '-' }}</span>
        </div>
        <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ "cc.common.cancellation-active-on-date" | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">{{ csrContract.contractCancellationActiveOnDate ? (csrContract.contractCancellationActiveOnDate | date : 'dd.MM.YYYY') : '-' }}</span>
        </div>
      </div>

    </div>

    <div class='card' *ngIf="csrContract.jsonbRecurringDetails">
      <div class="items info">
        <div class="items_info">
          <div class='card_title'>{{ "cc.my-factoring.payment" | translate }}</div>
        </div>
      </div>
      <div class="dialog-row-wrapper">
        <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ "cc.my-factoring.payment" | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">{{ getMethod() }}</span>
        </div>
      </div>
    </div>

    <div class='card' *ngIf="paymentDetails">
      <div class="items info">
        <div class="items_info">
          <div class='card_title'>{{ "cc.my-factoring.payment-method" | translate }}</div>
        </div>
      </div>
      <div class="dialog-row-wrapper payment-method-card">
        <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ "cc.my-factoring.payment-method" | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">{{ getPaymentMethod() }}</span>
        </div>
        <ng-container *ngIf="getPaymentMethod() === 'Direct debit' && csrBankAccount">
          <div class='dialog-col'>
            <span class='dialog_content_row dialog-col-label'>{{ 'cc.common.account-owner' | translate}}:</span>
            <span class='dialog_content_row dialog-col-value'>
              {{ csrBankAccount.owner ? csrBankAccount.owner : '-' }}
            </span>
          </div>
          <div class='dialog-col'>
            <span class='dialog_content_row dialog-col-label'>{{ 'cc.common.bank-name' | translate}}:</span>
            <span class='dialog_content_row dialog-col-value'>
              {{ csrBankAccount.bankName ? csrBankAccount.bankName : '-' }}
            </span>
          </div>
          <div class='dialog-col'>
            <span class='dialog_content_row dialog-col-label'>{{ 'cc.common.iban' | translate}}:</span>
            <span class='dialog_content_row dialog-col-value'>
              {{ csrBankAccount.iban ? csrBankAccount.iban : '-' }}
            </span>
          </div>
          <div class='dialog-col'>
            <span class='dialog_content_row dialog-col-label'>{{ 'cc.consumers.edit.bic' | translate}}:</span>
            <span class='dialog_content_row dialog-col-value'>
              {{ csrBankAccount.bic ? csrBankAccount.bic : '-' }}
            </span>
          </div>
          <div class='dialog-col'>
            <span class='dialog_content_row dialog-col-label'>{{ 'cc.consumer.overview.sepa-mandate-id' | translate}}:</span>
            <span class='dialog_content_row dialog-col-value'>
              {{ csrBankAccount.sepaMandateId ? csrBankAccount.sepaMandateId : '-' }}
            </span>
          </div>
          <div class='dialog-col'>
            <span class='dialog_content_row dialog-col-label'>{{ 'cc.consumer.overview.sepa-mandate-date-of-signature' | translate}}:</span>
            <span class='dialog_content_row dialog-col-value'>
              {{ csrBankAccount.sepaMandateDate ? (csrBankAccount.sepaMandateDate | date : "dd.MM.YYYY") : '-' }}
            </span>
          </div>
        </ng-container>
        <ng-container *ngIf="getPaymentMethod() === 'Money transfer' && mcBankAccount">
          <div class='dialog-col'>
            <span class='dialog_content_row dialog-col-label'>{{ 'cc.common.account-owner' | translate}}:</span>
            <span class='dialog_content_row dialog-col-value'>
              {{ mcBankAccount.accountOwner ? mcBankAccount.accountOwner : '-' }}
            </span>
          </div>
          <div class='dialog-col'>
            <span class='dialog_content_row dialog-col-label'>{{ 'cc.common.bank-name' | translate}}:</span>
            <span class='dialog_content_row dialog-col-value'>
              {{ mcBankAccount.bankName ? mcBankAccount.bankName : '-' }}
            </span>
          </div>
          <div class='dialog-col'>
            <span class='dialog_content_row dialog-col-label'>{{ 'cc.common.iban' | translate}}:</span>
            <span class='dialog_content_row dialog-col-value'>
              {{ mcBankAccount.iban ? mcBankAccount.iban : '-' }}
            </span>
          </div>
          <div class='dialog-col'>
            <span class='dialog_content_row dialog-col-label'>{{ 'cc.consumers.edit.bic' | translate}}:</span>
            <span class='dialog_content_row dialog-col-value'>
              {{ mcBankAccount.bic ? mcBankAccount.bic : '-' }}
            </span>
          </div>
        </ng-container>
      </div>
    </div>
  </mat-dialog-content>

  <div class='dialog_footer'>
    <button mat-button
            class="secondary-btn"
            (click)="dialogRef.close()">
      {{ "cc.common.close" | translate }}
    </button>
  </div>
</ng-container>
