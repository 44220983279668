/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {EmailConfig} from '../models/email-config.model';
import {EmailConfigList} from '../models/email-config-list.model';
import {EmailConfigFilter} from '../models/email-config-filter.model';
import {EmailConfigService} from '../services/email-config.service';
import {IEmailConfig} from '../_generated/email-config.interface';
import {McGod} from "../extra/mc-god.service";

export class EmailConfigListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'defaultEmailPath', 'emailName', 'emailType', 'languageCode'];
  public items: EmailConfig[] = [];
  public apiService: EmailConfigService;
 // protected tempObjList: EmailConfigList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): EmailConfigList {
    console.error('MTCN-ERROR: Not Implemented: EmailConfigList::createNewInstance(). Add this method to final class and return new EmailConfigList();');
    throw new Error('Cannot EmailConfigList::createNewInstance(). Add this method to final class and return new EmailConfigList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IEmailConfig[]): EmailConfigList {
    const  listObj: EmailConfigList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: EmailConfig = EmailConfig.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): EmailConfigList {
    const  listObj: EmailConfigList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EmailConfigService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IEmailConfig[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: EmailConfig = EmailConfig.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aEmailConfigFilter: EmailConfigFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aEmailConfigFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): EmailConfig {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new EmailConfig();
    }
  }

  public replaceItem(newItem: EmailConfig) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
}

