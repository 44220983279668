import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { NgbDateAdapter, NgbDateNativeAdapter} from "@ng-bootstrap/ng-bootstrap";
import {FormGroup, FormBuilder} from "@angular/forms";
import {McUtilityService, McInvoiceService, CsrConsumerService, CsrPaymentDetails } from '@miticon-ui/mc-core';
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {Subscription} from "rxjs";
import { StepperSelectionEvent } from '@angular/cdk/stepper';

@Component({
  selector: "lib-mc-invoice-creation",
  templateUrl: "./mc-invoice-creation.component.html",
  providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}]
})
export class McInvoiceCreationComponent implements OnInit {

  @Input() isDataFormInvalid!: boolean;
  @Input() consumerName!: string;

  selectedTableProductsProceed: any = {};
  userId!: number;
  entityId!: any;
  senderEntityId: any;
  invoiceReceiver!: string;
  activeRoute;
  isInvoices;
  showLoader!: boolean;
  responseMessageDisplay!: boolean;
  notifyMsgMessage!: string;
  notifyMsgType!: string;
  dataFromStep1: any;
  dataFromStep2 = new CsrPaymentDetails();
  mainForm!: FormGroup;
  private invoiceProductList = [];
  private invoiceProductListSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private utilityService: McUtilityService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private consumerService: CsrConsumerService,
    private _location: Location,
    private invoiceService: McInvoiceService,
    private cd: ChangeDetectorRef,
  ) {
    this.createForm();

    // check if invoice is for consumers or entities
    this.isInvoices = this.router.url.includes("invoices");
    this.activeRoute = this.router.url.includes("consumers");
    if (this.activeRoute) {
      // set id for current consumer
      this.userId = this.activatedRoute.snapshot.params["id"] - 0;
      this.senderEntityId = this.utilityService.getPropertyFromToken("entity_id");
    } else {
      /*Set unique Id for current entity*/
      this.activatedRoute.paramMap.subscribe(data => {
        if (data.get("id")) {
          this.entityId = data.get("id");
        } else {
          this.entityId = this.utilityService.getPropertyFromToken("entity_id");
        }
      });
    }
    this.utilityService.setStepNumber(1);
    this.getInvoiceReceiverData();

    this.invoiceProductListSubscription = this.invoiceService.invoiceList.subscribe(list => this.invoiceProductList = list);
  }

  ngOnInit() {
  }

  setContentOnSummaryStep(event: StepperSelectionEvent): void {
    if (event.selectedIndex === 2) this.mainForm.get("dataFromStep1")?.setValue(this.selectedTableProductsProceed);
  }

  getInvoiceReceiverData() {
    if (this.activeRoute) {
      if (this.userId) {
        this.consumerService.getById(this.userId).subscribe(
          response => {
            this.invoiceReceiver = response.displayName ? response.displayName : response.companyName;
          },
          error => {
            this.apiErrorHandler(error);
          }
        );
      }
    }
  }

  goToPrevious() {
    this._location.back();
  }

  createForm() {
    this.mainForm = this.fb.group({
      step1: "",
      step2: "",
      step3: "",
      dataFromStep1: "",
      resetServicePeriodDates: ""
    });
  }

  userDataFormInvalid(data: boolean) {
    this.isDataFormInvalid = data;
    this.cd.detectChanges();
  }

  selectedTableProducts(data: any) {
    this.selectedTableProductsProceed = {...this.selectedTableProductsProceed, ...data};

    // this.selectedTableProductsProceed.area = "create";
    this.activeRoute ? this.selectedTableProductsProceed.idRefReceiver = this.userId : this.selectedTableProductsProceed.receiverEntityId = this.entityId;
    this.selectedTableProductsProceed.idSenderMcEntity = this.senderEntityId
    this.mainForm.get("dataFromStep1")?.setValue(this.selectedTableProductsProceed);
  }

  // Api ErrorHandler
  private apiErrorHandler(error: any) {
    let message = "";
    let msgType = "";
    if (error.status >= 500) {
      message = "Server error";
      msgType = "error";
    } else if (error.error) {
      if (error.error.errors.length > 1) {
        message = error.error.errors.join();
        msgType = "error";
      } else {
        message = error.error.errors;
        msgType = "error";
      }
    } else {
      message = "Invalid request";
      msgType = "error";
    }
    this.responseMessageDisplay = true;
    this.setNotificationMessage(message, msgType);
  }

  // Display notification message
  setNotificationMessage(message: string, type: string): void {
    this.notifyMsgMessage = message;
    this.notifyMsgType = type;
  }

  public handleCssForWrapper() {
    return {
      width: this.invoiceProductList.length  && window.innerWidth < 1600 ? 'fit-content' : '100%'
    };
  }

  onNgDestroy() {
    this.invoiceProductListSubscription.unsubscribe();
  }

  setPaymentDetails(paymentDetails: CsrPaymentDetails){
    this.dataFromStep2 = paymentDetails;
  }
}
