import { Component } from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {EmailConfigKeyService, EmailConfigService, EmailTemplateService, McGod} from "@miticon-ui/mc-core";
import {catchError, concatMap, mapTo, tap} from "rxjs/operators";
import {EmailTemplateInfoDialogComponent} from "../../components/email-template-info-dialog/email-template-info-dialog.component";
import {Observable, of} from "rxjs";
import {HTMLHint} from 'htmlhint';
import {ICON_EMAIL_ERROR_CIRCLE} from "../../../../assets/media/svg_icons/icon-email-error-circle";
import {ConfirmationDialogComponent} from "@miticon-ui/mc-components";

@Component({
  selector: 'lib-email-templates',
  templateUrl: './email-templates.component.html',
  styleUrl: './email-templates.component.scss'
})
export class EmailTemplatesComponent {
  iconError = ICON_EMAIL_ERROR_CIRCLE;

  emailTemplates: { type: string; name: string; languageCode: string; id: number }[] = [];
  currentEmail: { type: string; name: string; languageCode: string; id: number } = {
    type: '',
    name: '',
    languageCode: '',
    id: 0
  };

  editorOptions = {
    theme: 'hc-light', language: 'html',
    automaticLayout: true, minimap: {enabled: false}
  };
  htmlCode: string = '';
  errorMessages: { line: number; column: number; message: string }[] = [];
  isResetEnabled = false;
  isCodeModified = false;
  successInfoMsg!: string;
  errorInfoMsg!: string;
  requiredElements: string[] = [];
  optionalElements: string[] = [];
  editorInstance: any;

  constructor(private dialog: MatDialog,
              private tS: TranslateService,
              private emailConfigService: EmailConfigService,
              private emailConfigKeyService: EmailConfigKeyService,
              private emailTemplateService: EmailTemplateService,
  ) {
  }

  ngOnInit(): void {
    this.getAllEmailTemplates().pipe(
      concatMap(() => this.getEmailContent()),
      concatMap(() => this.getKeysForEmail())
    ).subscribe();
  }

  validateHtml() {
    const rules = {
      'tag-pair': true,
      'doctype-first': true,
      'tagname-lowercase': true,
    };
    const messages = HTMLHint.verify(this.htmlCode, rules);

    this.errorMessages = messages.map((msg) => ({
      line: msg.line,
      column: msg.col,
      message: msg.message,
    }));

    this.requiredElements.forEach((requiredElement) => {
      if (!this.htmlCode.includes(requiredElement)) {
        this.errorMessages.push({
          line: 0,
          column: 0,
          message: this.tS.instant('cc.email-missing-required-element') + `: ${requiredElement}`,
        });
      }
    });
  }

  onChangeEmail(name: string) {
    this.currentEmail = this.emailTemplates.find(email => email.name == name) || this.currentEmail;
    this.clearElements();
    this.getEmailContent().subscribe();
    this.getKeysForEmail().subscribe();
  }

  openEmailInfoDialog(): void {
    this.dialog.open(EmailTemplateInfoDialogComponent, {
      autoFocus: false,
      width: '700px',
      data: {
        requiredElements: this.requiredElements,
        optionalElements: this.optionalElements,
      }
    });
  }

  onEditorInit(editor: any) {
    this.editorInstance = editor;
    this.editorInstance.setScrollTop(0);
  }

  openResetTemplateDialog(): void {
    this.dialog.open(ConfirmationDialogComponent, {
      autoFocus: false,
      width: '600px',
      data: {
        header: 'cc.email.reset-template',
        description: 'cc.reset-template-text',
        cancelBtnLabel: 'cc.common.edit.no',
        confirmBtnLabel: 'cc.common.edit.yes'
      }
    }).afterClosed()
      .subscribe((res) => {
        if (res) this.resetTemplate();
      });
  }

  getAllEmailTemplates(): Observable<void> {
    return this.emailConfigService.getAllEmailConfigs().pipe(
      tap((data: any) => {
        if (data) {
          this.emailTemplates = data.map((item: any) => ({
            type: item.emailType || '',
            name: item.emailName || '',
            languageCode: item.languageCode || 'DE',
            id: item.id
          }));
          this.currentEmail = this.emailTemplates[0];
        }
      }),
    );
  }

  getEmailContent(): Observable<void> {
    return this.emailTemplateService.getTemplate(this.currentEmail.id).pipe(
      tap((response: any) => {
        if (response) {
          let decodedString = atob(response);
          this.htmlCode = decodeURIComponent(escape(decodedString));
          this.validateHtml();
          this.isResetEnabled = true;
        } else {
          this.getDefaultEmailContent();
        }
      }),
      catchError((error) => {
        this.setInfoMessage(error.error.message, true);
        return of(void 0);
      }),
    );
  }

  getDefaultEmailContent() {
    this.emailConfigService.getDefaultEmailTemplate(this.currentEmail.id).subscribe((response: string) => {
    let decodedString = atob(response);
    this.htmlCode = decodeURIComponent(escape(decodedString));
      this.validateHtml();
      this.isResetEnabled = false;
    });
  }

  getKeysForEmail(): Observable<void> {
    return this.emailConfigKeyService.getKeysForEmail(this.currentEmail.id).pipe(
      tap((keys: { id: number; key: string; name: string; requiredFlg: boolean }[]) => {
        keys.forEach(key => {
          (key.requiredFlg ? this.requiredElements : this.optionalElements).push(key.key);
        });
      }),
      mapTo(void 0),
      catchError((error) => {
        this.setInfoMessage(error.error.message, true);
        return of(void 0);
      })
    );
  }

  saveEmailTemplate() {
    const utf8Encoded = unescape(encodeURIComponent(this.htmlCode));
    const base64Content = btoa(utf8Encoded);
    const body = {
      idEmailConfig: this.currentEmail.id,
      idMcEntity: McGod.getLoggedEntityIdFromToken(),
      languageCode: this.currentEmail.languageCode,
      base64Content: base64Content,
    }
    this.emailTemplateService.saveTemplate(body).subscribe({
      next: data => {
        if (data) {
          this.setInfoMessage('cc.common.saved-successfully', false);
          this.isResetEnabled = true;
          this.isCodeModified = false;
        }
      },
      error: error => {
        this.setInfoMessage(error.error.message, true);
      }
    });
  }

  resetTemplate() {
    if (this.isResetEnabled) {
      this.emailTemplateService.deleteTemplate(this.currentEmail.id).subscribe({
        next: () => {
          this.getDefaultEmailContent();
          this.setInfoMessage('cc-email-successfully-reset', false);
        },
        error: () =>
          this.setInfoMessage('cc-email-failed-to-reset', true)
      });
    }
  }

  get formattedTooltip(): string {
    return this.errorMessages
      .map(error => {
        return error.line === 0 ? error.message : (this.tS.instant('cc.email-error-line') + ` ${error.line}, ` + this.tS.instant('cc.email-error-column') + ` ${error.column}: ${error.message}`);
      })
      .join('\n');
  }

  clearElements(): void {
    this.editorInstance.setScrollTop(0);
    this.optionalElements = [];
    this.requiredElements = [];
    this.errorMessages = [];
  }

  setInfoMessage(message: string, error: boolean): void {
    const messageType = error ? 'errorInfoMsg' : 'successInfoMsg';
    this[messageType] = this.tS.instant(message);
    setTimeout(() => {
      this[messageType] = '';
    }, 4000);
  }
}
