/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {McBillingStatement} from '../models/mc-billing-statement.model';
import {McBillingStatementList} from '../models/mc-billing-statement-list.model';
import {McBillingStatementFilter} from '../models/mc-billing-statement-filter.model';
import {McBillingStatementService} from '../services/mc-billing-statement.service';
import {IMcBillingStatement} from '../_generated/mc-billing-statement.interface';
import {McGod} from "../extra/mc-god.service";

export class McBillingStatementListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'childEntityId', 'completedDatetime', 'description', 'feesAmount', 'flgHidden', 'idPayoutFile', 'parentEntityId', 'pdfWebfileId', 'statementDate', 'statementNumber', 'statusCd', 'statusDetails', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'title', 'totalPayoutAmount', 'totalVat', 'xlsWebfileId', 'accountOwner', 'bic', 'childEntityName', 'currencyCode', 'exportedDate', 'fileName', 'flgFactoring', 'iban', 'mcBillingStatementItemsCount', 'payoutDate', 'payoutFileDescription', 'payoutTableItems', 'studioOwner', 'totalAmount', 'totalPayout'];
  public items: McBillingStatement[] = [];
  public apiService: McBillingStatementService;
 // protected tempObjList: McBillingStatementList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McBillingStatementList {
    console.error('MTCN-ERROR: Not Implemented: McBillingStatementList::createNewInstance(). Add this method to final class and return new McBillingStatementList();');
    throw new Error('Cannot McBillingStatementList::createNewInstance(). Add this method to final class and return new McBillingStatementList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IMcBillingStatement[]): McBillingStatementList {
    const  listObj: McBillingStatementList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: McBillingStatement = McBillingStatement.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): McBillingStatementList {
    const  listObj: McBillingStatementList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McBillingStatementService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IMcBillingStatement[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: McBillingStatement = McBillingStatement.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aMcBillingStatementFilter: McBillingStatementFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aMcBillingStatementFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): McBillingStatement {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new McBillingStatement();
    }
  }

  public replaceItem(newItem: McBillingStatement) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
// ---------------------------------------------------------------------
  public callRegenerateXLSandPDF( statementId: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.regenerateXLSandPDF(statementId)
      .subscribe(responseJson => {
        this.setApiSuccess();
        this.processApiSuccess(responseJson, onSuccessCallback);
      },
      errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });
  }

  // ---------------------------------------------------------------------
  public loadByIdPayoutFile( idPayoutFile: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getByIdPayoutFile(idPayoutFile, this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

  // ---------------------------------------------------------------------
  public loadAllActiveOrNewByParentEntityId( parentEntityId: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getAllActiveOrNewByParentEntityId(parentEntityId, this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

// ---------------------------------------------------------------------
public callCollectBillingItems( id: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.collectBillingItems(id).subscribe(
    responseJson => {
      this.setApiSuccess();
      this.processApiSuccess(responseJson, onSuccessCallback);
    },
    errorResponse => {
      this.setApiError();
      this.processApiError(errorResponse, onErrorCallback);
    });
}

// ---------------------------------------------------------------------
public callChangeStatusHidden( ids: any, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.changeStatusHidden(ids).subscribe(
    responseJson => {
      this.setApiSuccess();
      this.processApiSuccess(responseJson, onSuccessCallback);
    },
    errorResponse => {
      this.setApiError();
      this.processApiError(errorResponse, onErrorCallback);
    });
}

// ---------------------------------------------------------------------
public callRemoveStatementItemsFromStatement( statementId: number, statementItemIds: any, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.removeStatementItemsFromStatement(statementId,statementItemIds).subscribe(
    responseJson => {
      this.setApiSuccess();
      this.processApiSuccess(responseJson, onSuccessCallback);
    },
    errorResponse => {
      this.setApiError();
      this.processApiError(errorResponse, onErrorCallback);
    });
}

}

