/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {EbFactoring} from '../models/eb-factoring.model';
import {EbFactoringService} from '../services/eb-factoring.service';
import {IEbFactoring} from '../_generated/eb-factoring.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';

import {McCurrency} from '../models/mc-currency.model';
import {McEntity} from '../models/mc-entity.model';


export abstract class EbFactoringGENERATED extends BaseObject {


    public static readonly FEE_PER_TRANSACTION_TYPE_CD_AMOUNT: string = 'AMOUNT';
    public static readonly FEE_PER_TRANSACTION_TYPE_CD_PERCENTAGE: string = 'PERCENTAGE';
  public static readonly FEE_PER_TRANSACTION_TYPE_CD_LIST__ALL = [
    EbFactoringGENERATED.FEE_PER_TRANSACTION_TYPE_CD_AMOUNT,
    EbFactoringGENERATED.FEE_PER_TRANSACTION_TYPE_CD_PERCENTAGE
];

    public static readonly STATUS_CD_ACCEPTED: string = 'ACCEPTED';
    public static readonly STATUS_CD_REJECTED: string = 'REJECTED';
    public static readonly STATUS_CD_PENDING_ACCEPTANCE: string = 'PENDING_ACCEPTANCE';
    public static readonly STATUS_CD_REVOKED: string = 'REVOKED';
    public static readonly STATUS_CD_REMOVED: string = 'REMOVED';
    public static readonly STATUS_CD_ACTIVE: string = 'ACTIVE';
  public static readonly STATUS_CD_LIST__ALL = [
    EbFactoringGENERATED.STATUS_CD_ACCEPTED,
    EbFactoringGENERATED.STATUS_CD_REJECTED,
    EbFactoringGENERATED.STATUS_CD_PENDING_ACCEPTANCE,
    EbFactoringGENERATED.STATUS_CD_REVOKED,
    EbFactoringGENERATED.STATUS_CD_REMOVED,
    EbFactoringGENERATED.STATUS_CD_ACTIVE
];

    public static readonly TYPE_CD_FACTORING: string = 'FACTORING';
    public static readonly TYPE_CD_SERVICE: string = 'SERVICE';
  public static readonly TYPE_CD_LIST__ALL = [
    EbFactoringGENERATED.TYPE_CD_FACTORING,
    EbFactoringGENERATED.TYPE_CD_SERVICE
];

  public apiService: EbFactoringService;
  public _rawJson: IEbFactoring;
    id: number = 0;
  amountPaidAfterWithdrawalProcessingDays: number;
  blacklistTriggerHardBounceCount: number;
  blacklistTriggerSoftBounceCount: number;
  contractCancellationPeriod: number;
  contractEndDate: number;
  contractRejectionReason = '';
  contractStartDate: number;
  createdBy: number;
  daysForInitialPayoutForFactoredTransactions: number;
  daysForInitialPayoutForNonFactoredTransactions: number;
  delayDaysForOverLimit: number;
  factoredClaimFeePerTransaction: number;
  factoringContractCd = '';
  factoringContractName = '';
  feePerBatch: number;
  feePerTransaction: number;
  feePerTransactionTypeCd: string;
  hardCumulativeLimit: number;
  hardCumulativeLimitDays: number;
  hardLimitForCurrentEntity: number;
  hardLimitForCurrentEntityDays: number;
  idChildMcEntity: number;
  idMcCurrency: number;
  idParentMcEntity: number;
  idWebFile: number;
  limitPerConsumer: number;
  limitPerEntityTree: number;
  maximalNumberOfFailedWithdrawals: number;
  monthsAllowedFutureCount: number;
  monthsAllowedPastCount: number;
  noDaysForInitialPayoutNonFactoring: number;
  noDaysForTheRestToBePaidNonFactoring: number;
  nonFactoredClaimFeePerTransaction: number;
  percentageForInitialPayoutNonFactoring: number;
  removeMemberWithoutOpenDebtFromBlFlg: boolean;
  returnedTransactionFee: number;
  sendClaimsToBlacklistFlg: boolean;
  sendDirectToInkassoFlg: boolean;
  softLimitPerSepaRun: number;
  softLimitPerTransaction: number;
  statusCd: string;
  sysCreatedDatetime: number;
  sysCreatedUserId: number;
  sysUpdatedDatetime: number;
  sysUpdatedUserId: number;
  totalMonthlyLimitPerEntity: number;
  typeCd: string;
  updatedBy: number;
  version: number;
  childEntity: McEntity;  mcCurrency: McCurrency;

  public properties: string[] = ['id', 'amountPaidAfterWithdrawalProcessingDays', 'blacklistTriggerHardBounceCount', 'blacklistTriggerSoftBounceCount', 'contractCancellationPeriod', 'contractEndDate', 'contractRejectionReason', 'contractStartDate', 'createdBy', 'daysForInitialPayoutForFactoredTransactions', 'daysForInitialPayoutForNonFactoredTransactions', 'delayDaysForOverLimit', 'factoredClaimFeePerTransaction', 'factoringContractCd', 'factoringContractName', 'feePerBatch', 'feePerTransaction', 'feePerTransactionTypeCd', 'hardCumulativeLimit', 'hardCumulativeLimitDays', 'hardLimitForCurrentEntity', 'hardLimitForCurrentEntityDays', 'idChildMcEntity', 'idMcCurrency', 'idParentMcEntity', 'idWebFile', 'limitPerConsumer', 'limitPerEntityTree', 'maximalNumberOfFailedWithdrawals', 'monthsAllowedFutureCount', 'monthsAllowedPastCount', 'noDaysForInitialPayoutNonFactoring', 'noDaysForTheRestToBePaidNonFactoring', 'nonFactoredClaimFeePerTransaction', 'percentageForInitialPayoutNonFactoring', 'removeMemberWithoutOpenDebtFromBlFlg', 'returnedTransactionFee', 'sendClaimsToBlacklistFlg', 'sendDirectToInkassoFlg', 'softLimitPerSepaRun', 'softLimitPerTransaction', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'totalMonthlyLimitPerEntity', 'typeCd', 'updatedBy', 'version', 'childEntity', 'mcCurrency'];
  public propertiesRegular: string[] = ['id', 'amountPaidAfterWithdrawalProcessingDays', 'blacklistTriggerHardBounceCount', 'blacklistTriggerSoftBounceCount', 'childEntity', 'contractCancellationPeriod', 'contractEndDate', 'contractRejectionReason', 'contractStartDate', 'createdBy', 'daysForInitialPayoutForFactoredTransactions', 'daysForInitialPayoutForNonFactoredTransactions', 'delayDaysForOverLimit', 'factoredClaimFeePerTransaction', 'factoringContractCd', 'factoringContractName', 'feePerBatch', 'feePerTransaction', 'feePerTransactionTypeCd', 'hardCumulativeLimit', 'hardCumulativeLimitDays', 'hardLimitForCurrentEntity', 'hardLimitForCurrentEntityDays', 'idChildMcEntity', 'idMcCurrency', 'idParentMcEntity', 'idWebFile', 'limitPerConsumer', 'limitPerEntityTree', 'maximalNumberOfFailedWithdrawals', 'mcCurrency', 'monthsAllowedFutureCount', 'monthsAllowedPastCount', 'noDaysForInitialPayoutNonFactoring', 'noDaysForTheRestToBePaidNonFactoring', 'nonFactoredClaimFeePerTransaction', 'parentEntity', 'percentageForInitialPayoutNonFactoring', 'removeMemberWithoutOpenDebtFromBlFlg', 'returnedTransactionFee', 'sendClaimsToBlacklistFlg', 'sendDirectToInkassoFlg', 'softLimitPerSepaRun', 'softLimitPerTransaction', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'totalMonthlyLimitPerEntity', 'typeCd', 'updatedBy', 'version'];
  public propertiesSpecial: string[] = ['childEntity', 'mcCurrency'];



  public static getFeePerTransactionTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(EbFactoringGENERATED.FEE_PER_TRANSACTION_TYPE_CD_AMOUNT, McGod.t('Amount'));
    list.add(EbFactoringGENERATED.FEE_PER_TRANSACTION_TYPE_CD_PERCENTAGE, McGod.t('Percentage'));


    return list;
  }

  public static getFeePerTransactionTypeCdLabel(aCd: string): string {
    const list = EbFactoringGENERATED.getFeePerTransactionTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(EbFactoringGENERATED.STATUS_CD_ACCEPTED, McGod.t('cc.eb-factoring.accepted'));
    list.add(EbFactoringGENERATED.STATUS_CD_REJECTED, McGod.t('cc.eb-factoring.rejected'));
    list.add(EbFactoringGENERATED.STATUS_CD_PENDING_ACCEPTANCE, McGod.t('cc.eb-factoring.pending-acceptance'));
    list.add(EbFactoringGENERATED.STATUS_CD_REVOKED, McGod.t('cc.eb-factoring.revoked'));
    list.add(EbFactoringGENERATED.STATUS_CD_REMOVED, McGod.t('cc.eb-factoring.removed'));
    list.add(EbFactoringGENERATED.STATUS_CD_ACTIVE, McGod.t('cc.common.view.active'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = EbFactoringGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(EbFactoringGENERATED.TYPE_CD_FACTORING, McGod.t('Factoring'));
    list.add(EbFactoringGENERATED.TYPE_CD_SERVICE, McGod.t('Service'));


    return list;
  }

  public static getTypeCdLabel(aCd: string): string {
    const list = EbFactoringGENERATED.getTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): EbFactoring {
    console.error('MTCN-ERROR: Not Implemented: EbFactoring::createNewInstance(). Add this method to final class and return new EbFactoring();');
    throw new Error('Cannot EbFactoring::createNewInstance(). Add this method to final class and return new EbFactoring();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IEbFactoring): EbFactoring {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EbFactoringService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE EbFactoring AABB()');
          if (this._rawJson['childEntity']) {
        this.childEntity = McEntity.createFromJson(this._rawJson['childEntity']);
      }
      if (this._rawJson['mcCurrency']) {
        this.mcCurrency = McCurrency.createFromJson(this._rawJson['mcCurrency']);
      }

  }

  // ---------------------------------------------------------------------
  
    public getAmountPaidAfterWithdrawalProcessingDaysStr(): string {
       return McGod.formatNumberToMoney(this.amountPaidAfterWithdrawalProcessingDays);
    }

    public getFactoredClaimFeePerTransactionStr(): string {
       return McGod.formatNumberToMoney(this.factoredClaimFeePerTransaction);
    }

    public getFeePerBatchStr(): string {
       return McGod.formatNumberToMoney(this.feePerBatch);
    }

    public getFeePerTransactionStr(): string {
       return McGod.formatNumberToMoney(this.feePerTransaction);
    }

    public getHardCumulativeLimitStr(): string {
       return McGod.formatNumberToMoney(this.hardCumulativeLimit);
    }

    public getHardLimitForCurrentEntityStr(): string {
       return McGod.formatNumberToMoney(this.hardLimitForCurrentEntity);
    }

    public getLimitPerConsumerStr(): string {
       return McGod.formatNumberToMoney(this.limitPerConsumer);
    }

    public getLimitPerEntityTreeStr(): string {
       return McGod.formatNumberToMoney(this.limitPerEntityTree);
    }

    public getNonFactoredClaimFeePerTransactionStr(): string {
       return McGod.formatNumberToMoney(this.nonFactoredClaimFeePerTransaction);
    }

    public getPercentageForInitialPayoutNonFactoringStr(): string {
       return McGod.formatNumberToMoney(this.percentageForInitialPayoutNonFactoring);
    }

    public getReturnedTransactionFeeStr(): string {
       return McGod.formatNumberToMoney(this.returnedTransactionFee);
    }

    public getSoftLimitPerSepaRunStr(): string {
       return McGod.formatNumberToMoney(this.softLimitPerSepaRun);
    }

    public getSoftLimitPerTransactionStr(): string {
       return McGod.formatNumberToMoney(this.softLimitPerTransaction);
    }

    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }

    public getSysUpdatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysUpdatedDatetime);
    }

    public getSysUpdatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysUpdatedDatetime);
    }

    public getTotalMonthlyLimitPerEntityStr(): string {
       return McGod.formatNumberToMoney(this.totalMonthlyLimitPerEntity);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();
    if (this.hasOwnProperty('childEntity')) {
        if (this.childEntity != null) {
            dto['childEntity'] = this.childEntity.toDto();
        } else {
            dto['childEntity'] = null;
        }
    }
    if (this.hasOwnProperty('mcCurrency')) {
        if (this.mcCurrency != null) {
            dto['mcCurrency'] = this.mcCurrency.toDto();
        } else {
            dto['mcCurrency'] = null;
        }
    }

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getFeePerTransactionTypeCdLabel(): string {
    return EbFactoringGENERATED.getFeePerTransactionTypeCdLabel(this.feePerTransactionTypeCd);
  }


 public  isFeePerTransactionTypeCdAMOUNT(): boolean {
    const result = (this.feePerTransactionTypeCd === EbFactoringGENERATED.FEE_PER_TRANSACTION_TYPE_CD_AMOUNT);

    return result;
  }

 public  isFeePerTransactionTypeCdPERCENTAGE(): boolean {
    const result = (this.feePerTransactionTypeCd === EbFactoringGENERATED.FEE_PER_TRANSACTION_TYPE_CD_PERCENTAGE);

    return result;
  }


  public getStatusCdLabel(): string {
    return EbFactoringGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdACCEPTED(): boolean {
    const result = (this.statusCd === EbFactoringGENERATED.STATUS_CD_ACCEPTED);

    return result;
  }

 public  isStatusCdREJECTED(): boolean {
    const result = (this.statusCd === EbFactoringGENERATED.STATUS_CD_REJECTED);

    return result;
  }

 public  isStatusCdPENDING_ACCEPTANCE(): boolean {
    const result = (this.statusCd === EbFactoringGENERATED.STATUS_CD_PENDING_ACCEPTANCE);

    return result;
  }

 public  isStatusCdREVOKED(): boolean {
    const result = (this.statusCd === EbFactoringGENERATED.STATUS_CD_REVOKED);

    return result;
  }

 public  isStatusCdREMOVED(): boolean {
    const result = (this.statusCd === EbFactoringGENERATED.STATUS_CD_REMOVED);

    return result;
  }

 public  isStatusCdACTIVE(): boolean {
    const result = (this.statusCd === EbFactoringGENERATED.STATUS_CD_ACTIVE);

    return result;
  }


  public getTypeCdLabel(): string {
    return EbFactoringGENERATED.getTypeCdLabel(this.typeCd);
  }


 public  isTypeCdFACTORING(): boolean {
    const result = (this.typeCd === EbFactoringGENERATED.TYPE_CD_FACTORING);

    return result;
  }

 public  isTypeCdSERVICE(): boolean {
    const result = (this.typeCd === EbFactoringGENERATED.TYPE_CD_SERVICE);

    return result;
  }


  
  // ---------------------------------------------------------------------
  public loadActiveForChildEntity( idChildMcEntity: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getActiveForChildEntity(idChildMcEntity)
      .subscribe(responseJson => {
        this.populateFromJson(responseJson);
         this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.processApiError(errorResponse, onErrorCallback);
      });
  }

}
