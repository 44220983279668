import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {EbConsumerBlacklist, McDateUtils} from "@miticon-ui/mc-core";
import {
  McForm,
  McGod,
} from '@miticon-ui/mc-core';

@Component({
  selector: 'mc-out-factoring-blacklist-add-edit-part',
  templateUrl: './mc-out-factoring-blacklist-add-edit-part.component.html',
})
export class McOutFactoringBlacklistAddEditPartComponent implements OnChanges {

  mcForm: McForm = new McForm();

  @Input() ebConsumerBlacklistId!: number;
  @Input() ebConsumerBlacklist!: EbConsumerBlacklist;
  @Input() showPageTitleFlg = false;
  @Input() showCancelButtonFlg = false;
  @Input() showSaveButtonFlg = false;
  @Input() showDeleteButtonFlg = false;

  @Output() eventSaved = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventDeleted = new EventEmitter();
  public today =  McDateUtils.getNowTimestamp();

  // ---------------------------------------------------------------------
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['ebConsumerBlacklistId']) {
      if ((changes['ebConsumerBlacklistId'].previousValue !== changes['ebConsumerBlacklistId'].currentValue) && (changes['ebConsumerBlacklistId'].currentValue > 0)) {
        this.actLoad();
      } else {
        this.actLoad(); // TODO in hurry need to do it right
      }
    }
  }

  // ---------------------------------------------------------------------
  actLoad(): void {
    this.ebConsumerBlacklist = new EbConsumerBlacklist();
    if (this.ebConsumerBlacklistId > 0) {
      this.ebConsumerBlacklist.loadById(this.ebConsumerBlacklistId, () => {
      });
    } else {
      this.ebConsumerBlacklist = new EbConsumerBlacklist();
    }

  }

  // ---------------------------------------------------------------------
  actSaveItem() {

    if (this.mcForm.isValid()) {
      this.ebConsumerBlacklist.idCsrConsumer = 0;
      this.ebConsumerBlacklist.idMcEntity = McGod.getLoggedEntityIdFromToken();
      this.ebConsumerBlacklist.idTransaction = 0;
      this.ebConsumerBlacklist.addedUserId = McGod.getLoggedUserIdFromToken();
      this.ebConsumerBlacklist.firstName = this.mcForm.getValue('ctrlFirstName');
      this.ebConsumerBlacklist.lastName = this.mcForm.getValue('ctrlLastName');
      this.ebConsumerBlacklist.birthday = this.mcForm.getValue('ctrlBirthday');
      this.ebConsumerBlacklist.iban = this.mcForm.getValue('ctrlIban');
      this.ebConsumerBlacklist.city = this.mcForm.getValue('ctrlCity');
      this.ebConsumerBlacklist.postCode = this.mcForm.getValue('ctrlPostCode');
      this.ebConsumerBlacklist.street = this.mcForm.getValue('ctrlStreet');
      this.ebConsumerBlacklist.email = this.mcForm.getValue('ctrlEmail');
      this.ebConsumerBlacklist.addedReason = this.mcForm.getValue('ctrlAddedReason');

      this.ebConsumerBlacklist.save(() => {
        this.eventSaved.emit();
        this.mcForm.getControl('ctrlAddedReason')?.get('value')?.setValue('');
      });
    }

  }

  // ---------------------------------------------------------------------
  actCancel() {
    this.eventCanceled.emit();
  }

  // ---------------------------------------------------------------------
  actDeleteItem() {
    if (this.ebConsumerBlacklist.exists()) {

      this.ebConsumerBlacklist.delete(() => {
        this.eventDeleted.emit();
      });
    }
  }

  isVisible(): boolean {
    return (this.ebConsumerBlacklist != null);
  }

}
