import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder,  FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {McAuthService, CreateAccount, LoginEnums} from "@miticon-ui/mc-core";
import {Location} from "@angular/common";

export interface GeneratingSettingsTypes {
  name: string;
  setOfCharacters: string;
}

@Component({
  selector: "lib-setup",
  templateUrl: "./setup.component.html",
  styleUrls: ["../../style/mc-login.scss"]
})
export class SetupComponent implements OnInit {

  // Variables
  welcomeImg: string;
  logo: string;
  syncIcon: any;
  copyIcon: any;
  responseMessage!: boolean;
  message!: string;
  type!: string;
  counter!: number;
  showLoader!: boolean;
  isReset: boolean;
  messError!: string | undefined;
  generateError!: string | undefined;

  ifMinChars!: boolean;
  checkDiffChars!: boolean;
  checkDigits!: boolean;
  checkSpecialChars!: boolean;
  checkLowercaseLetters!: boolean;
  checkUppercaseLetters!: boolean;

  minChars!: number;
  noOfDiffChar!: number;
  noOfDigits!: number;
  noOfSpecialChars!: number;
  noOfLowercaseChars!: number;
  noOfUppercaseChars!: number;
  disableInputs = false;

  seePassword = false;
  seeRepeatedPassword = false;
  ifPasswordValid!: boolean;
  ifGeneratePasswordValid!: boolean;
  // responseMessage = false;
  inputFieldsError = false;
  lengthNumber!: number;
  active = 1;

  enterPassForm: FormGroup;
  generatePasswordForm: FormGroup;
  charTypes = [
    {name: "A-Z", setOfCharacters: "ABCDEFGHIJKLMNOPQRSTUVWXYZ"} as GeneratingSettingsTypes,
    {name: "a-z", setOfCharacters: "abcdefghijklmnopqrstuvwxyz"} as GeneratingSettingsTypes,
    {name: "0-9", setOfCharacters: "0123456789"} as GeneratingSettingsTypes,
    {name: "/*...", setOfCharacters: "!@#$%^&*()_+?><,./\\|';][{}~`"} as GeneratingSettingsTypes,
  ];

  constructor(
    private fb: FormBuilder,
    private authService: McAuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public location: Location,
    @Inject("designConfig") design: any,
    // config: NgbTabsetConfig
  ) {
    this.enterPassForm = new FormGroup({});
    this.generatePasswordForm = new FormGroup({});
    this.welcomeImg = design.welcomeImg;
    this.logo = design.logo;
    this.syncIcon = design.syncIcon;
    this.copyIcon = design.copyIcon;
    // config.justify = "center";
    // config.type = "pills";
    this.isReset = this.location.path().includes("reset-password");
  }


  ngOnInit() {
    this.enterPassForm = this.fb.group({
      setPassword: [""],
      checkPassword: [""]
    });
    this.generatePasswordForm = this.fb.group({
      length: [this.lengthNumber],
      excludeLookLikeCharacters: [true],
      ensurePasswordContainsCharFromAllGroups: [false],
      includeCharTypes: this.fb.array([
        this.fb.control(false),
        this.fb.control(false),
        this.fb.control(false),
        this.fb.control(false),
      ]),
      password: [""]
    });
    localStorage.removeItem("mc-access-token");
    this.passwordPolicyData();

    this.enterPassForm.valueChanges.subscribe(() => {
      this.validatePassword();
    });

    this.generatePasswordForm.get("ensurePasswordContainsCharFromAllGroups")?.valueChanges.subscribe(data => {
      this.checkAllTypes(data);
      this.disableInputs = !this.disableInputs;
    });
  }
  checkL() {
    if (this.generatePasswordForm.get('length')?.value < this.minChars || this.generatePasswordForm.get('length')?.value > 255) {
      this.generateError = `Minimum length is ${this.minChars}, maximum length is 255`;
    } else {
      this.generateError = undefined;
    }
  }

  seePasswordClick() {
    this.seePassword = !this.seePassword;
  }

  checkAllTypes(input: any) {
    const char = (this.generatePasswordForm.get("includeCharTypes") as FormArray).controls;
    char.forEach((item) => {
      item.setValue(input);
    });
  }

  // Dynamic password policy
  passwordPolicyData() {
    const uuid = this.activatedRoute.snapshot.params['uuid'];
    this.authService.getPasswordPolicyData(uuid, this.isReset).subscribe(
      (response) => {
        const policyData = response;
        this.minChars = policyData.minPassLength;
        this.noOfDiffChar = policyData.minDiffChars;
        this.noOfDigits = policyData.minDigits;
        this.noOfSpecialChars = policyData.minSpecialChars;
        this.noOfLowercaseChars = policyData.minLowercaseLetters;
        this.noOfUppercaseChars = policyData.minUppercaseLetter;
        this.lengthNumber = this.minChars;
      },
      (error) => {

        /*Logout user if user is not found in DB.*/
        this.authService.logout();
        this.responseMessage = true;
      }
    );
  }

  // See password on eye-button click
  onChangeSeePassword(seePassData: boolean, type: number) {
    (type === 1) ? this.seePassword = seePassData : this.seeRepeatedPassword = seePassData;
  }

  // Check password fields
  validatePassword() {
    this.responseMessage = true;
    this.inputFieldsError = false;
    const formData = this.enterPassForm.getRawValue();
    const passwordEntered = formData.setPassword;
    const formatSpecialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

    // Check if there is a minimum of characters
    this.ifMinChars = passwordEntered.length > this.minChars - 1;

    // Check for minimum different characters
    let enterPassString = formData.setPassword;
    enterPassString = enterPassString.split('').filter(function(item: any, i: any, ar: any){ return ar.indexOf(item) === i; }).join('');
    this.checkDiffChars = enterPassString.length > 2;

    // Check the number of digits
    this.checkDigits = /\D/.test(passwordEntered) &&
      passwordEntered.length - passwordEntered.replace(/\d/g, "").length > this.noOfDigits - 1;

    // Check the number of special characters
    this.checkSpecialChars = formatSpecialChars.test(passwordEntered) &&
      passwordEntered.length - passwordEntered.replace(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g, "").length > this.noOfSpecialChars - 1;

    // Check for lowercase letters
    this.checkLowercaseLetters = /[a-z]/.test(passwordEntered) &&
      passwordEntered.length - passwordEntered.replace(/[a-z]/g, "").length > this.noOfLowercaseChars - 1;


    // Check for uppercase letters
    this.checkUppercaseLetters = /[A-Z]/.test(passwordEntered) &&
      passwordEntered.length - passwordEntered.replace(/[A-Z]/g, "").length > this.noOfUppercaseChars - 1;

    // Check if password is valid and enable form submit
    this.ifPasswordValid = this.ifMinChars
      && this.checkDiffChars
      && this.checkDigits
      && this.checkSpecialChars
      && this.checkLowercaseLetters
      && this.checkUppercaseLetters
      && formData.setPassword === formData.checkPassword;

    const isSetPassValid = this.ifMinChars && this.checkDiffChars && this.checkDigits && this.checkSpecialChars
      && this.checkLowercaseLetters && this.checkUppercaseLetters;

    if (isSetPassValid && this.messError === "Your password does not satisfy the current policy requirements") {
      this.setNotificationMessage('', '');
      this.messError = undefined;
    } else if (formData.setPassword === formData.checkPassword && this.messError === "Passwords don't match") {
      this.setNotificationMessage('', '');
      this.messError = undefined;
    }

  }

  // Display notification message
  private setNotificationMessage(message: string, type: string): void {
    this.message = message;
    this.type = type;
  }

  isIncludedCharacterTypesSet() {
    return this.generatePasswordForm.value.includeCharTypes.reduce((prev: any, next: any) => prev || next);
  }


  // Save account and go to login page - for enter and generate password
  saveAccount(type: number): any {
    this.showLoader = true;
    let msgText: string;
    let msgType: string;

    if (type === 1) {
      const formData = this.enterPassForm.getRawValue();
      const body: CreateAccount = {
        password: formData.setPassword,
        confirmPassword: formData.checkPassword,
        uuid: this.activatedRoute.snapshot.params['uuid'],
      };
      if (this.ifPasswordValid) {
        if (!this.isReset) {
          this.authService.createAccount(body).subscribe(
            () => {
              msgText = LoginEnums.successMsg;
              msgType = LoginEnums.successMsg;
              this.setNotificationMessage(msgText, msgType);
              this.router.navigate(["/auth/login"]).catch(error => console.log(error));
              this.showLoader = false;
            },
            error => {
              msgText = error.error.message;
              msgType = error.ok === false ? LoginEnums.errorMsg : LoginEnums.successMsg;
              this.setNotificationMessage(msgText, msgType);
              this.responseMessage = true;
              this.inputFieldsError = true;
              this.showLoader = false;
            }
          );
        } else {
          this.authService.resetPassword(body).subscribe(
            () => {
              msgText = LoginEnums.successMsg;
              msgType = LoginEnums.successMsg;
              this.setNotificationMessage(msgText, msgType);
              this.router.navigate(["/auth/login"]).catch(error => console.log(error));
              this.showLoader = false;
            },
            error => {
              msgText = error.error.message;
              msgType = error.ok === false ? LoginEnums.errorMsg : LoginEnums.successMsg;
              this.setNotificationMessage(msgText, msgType);
              this.responseMessage = true;
              this.inputFieldsError = true;
              this.showLoader = false;
            }
          );
        }
      } else if (formData.setPassword !== formData.checkPassword) {
        this.responseMessage = true;
        msgText = "Passwords don't match";
        msgType = "error";
        this.messError = "Passwords don't match";
        this.setNotificationMessage(msgText, msgType);
      } else {
        this.responseMessage = true;
        msgText = "Your password does not satisfy the current policy requirements";
        msgType = "error";
        this.messError = "Your password does not satisfy the current policy requirements";
        this.setNotificationMessage(msgText, msgType);
      }
    } else {
      const formData = this.generatePasswordForm.getRawValue();
      const body: CreateAccount = {
        confirmPassword: formData.password,
        password: formData.password,
        uuid: this.activatedRoute.snapshot.params['uuid']
      };
      if (!this.isReset) {
        return this.authService.createAccount(body)
          .subscribe(() => {
            msgText = LoginEnums.successMsg;
            msgType = LoginEnums.successMsg;
            this.setNotificationMessage(msgText, msgType);
            this.router.navigate(["/auth/login"]).catch(error => console.log(error));
            this.showLoader = false;
          });
      } else {
        return this.authService.resetPassword(body).subscribe(
          () => {
            msgText = LoginEnums.successMsg;
            msgType = LoginEnums.successMsg;
            this.setNotificationMessage(msgText, msgType);
            this.router.navigate(["/auth/login"]).catch(error => console.log(error));
            this.showLoader = false;
          }
        );
      }
    }
  }

  copyPassword() {
    const formData = this.generatePasswordForm.getRawValue();
    const copyText = formData.password;
    const input = document.createElement("input");
    input.setAttribute("type", "text");
    input.value = copyText;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
  }

  /********************************************* Generate Password *******************************************************/

  generateRandomPassword() {
    if (this.generatePasswordForm.get('length')?.value >= this.minChars && this.generatePasswordForm.get('length')?.value < 256) {
      let setOfCharForRandomGeneratingPassword = "";
      this.generatePasswordForm.value.includeCharTypes.forEach((item: any, index: number) => {
        setOfCharForRandomGeneratingPassword += (item ? this.charTypes[index].setOfCharacters : "");
      });
      // safe guard - checking is everything ok
      if (!this.isIncludedCharacterTypesSet() ||
        (this.generatePasswordForm.value.excludeLookLikeCharacters ?
          !(this.generatePasswordForm.value.length <= setOfCharForRandomGeneratingPassword.length)
          : false)

      ) {
        return;
      }
      setOfCharForRandomGeneratingPassword.split("").sort(() => {
        return 0.5 - Math.random();
      });
      /***************   Policy Reqs      ***************/
      let passDigits = "";
      let passSpecialChars = "";
      let passUpper = "";
      let passLower = "";
      if (this.generatePasswordForm.get('includeCharTypes')?.value[2] === true) {
        for (let i = 0; i < this.noOfDigits; i++) {
          passDigits += this.charTypes[2].setOfCharacters[Math.floor(this.charTypes[2].setOfCharacters.length * Math.random())];
        }
      }
      if (this.generatePasswordForm.get('includeCharTypes')?.value[3] === true) {
        for (let i = 0; i < this.noOfSpecialChars; i++) {
          passSpecialChars += this.charTypes[3].setOfCharacters[Math.floor(this.charTypes[3].setOfCharacters.length * Math.random())];
        }
      }
      if (this.generatePasswordForm.get('includeCharTypes')?.value[0] === true) {
        for (let i = 0; i < this.noOfUppercaseChars; i++) {
          passUpper += this.charTypes[0].setOfCharacters[Math.floor(this.charTypes[0].setOfCharacters.length * Math.random())];
        }
      }
      if (this.generatePasswordForm.get('includeCharTypes')?.value[1] === true) {
        for (let i = 0; i < this.noOfLowercaseChars; i++) {
          passLower += this.charTypes[1].setOfCharacters[Math.floor(this.charTypes[1].setOfCharacters.length * Math.random())];
        }
      }

      let getpass = "";
      getpass = passDigits + passSpecialChars + passUpper + passLower;
      const limit = this.generatePasswordForm.value.length - getpass.length;

      for (let i = 0; i < limit; i++) {
        const choosenChar = setOfCharForRandomGeneratingPassword[Math.floor(setOfCharForRandomGeneratingPassword.length * Math.random())];
        getpass += choosenChar;
        if (this.generatePasswordForm.value.excludeLookLikeCharacters) {
          setOfCharForRandomGeneratingPassword = setOfCharForRandomGeneratingPassword.split(choosenChar)[0] + setOfCharForRandomGeneratingPassword.split(choosenChar)[1];
        }
      }
      getpass = getpass.split('').sort(function() {
        return 0.5 - Math.random();
      }).join('');
      this.generatePasswordForm.patchValue({password: getpass});
      this.ifGeneratePasswordValid = true;
    }
  }
}

