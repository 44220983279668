<!--MC Two FA section-->
<div class="mc-forgot-password valign-container full-height" [ngStyle]="{'background-image': 'url(' + welcomeImg + ')'}">
  <div class="container">
    <div class="row">
        <!--Card section-->
        <div class="mc-card card">
          <!--Card header section-->
          <div class="mc-card-header text-center">
<!--            <app-mc-back-to-login></app-mc-back-to-login>-->
            <span class="back-option" (click)="goToLogin()">
              <span [innerHTML]="iconBack | safeHtml"></span>Back</span>
            <!--Logo-->
            <img src="{{logo}}" class="mc-logo" alt="Cass Control Logo">
            <hr class="mt-4">

            <div class="mc-position-relative">
              <mc-notify-message [message]="errorObject.message" [type]="errorObject.type"></mc-notify-message>
            </div>
          </div><!--Card header /end-->

          <!--Card body section-->
          <div *ngIf="!resetPasswordSubmitted" class="card-body mc-card-body text-center">

            <div class="forgot-password-label">
              <h3>Forgot your password? No problem.</h3>
            </div>

            <p class="mb-3">Enter the email address of your account and we'll send you the instruction for password resetting.</p>

            <div class="forgot-password-container">
              <!--Email input field-->
              <div class="form-label-group mt-large">
                <input type="email"
                       [(ngModel)]="email"
                       (ngModelChange)="errorObject.type = ''"
                       [ngClass]="cssClassInputField()"
                       name="email"
                       class="form-control"
                       placeholder="Email*"
                       id="email"
                       required>
              </div>

              <div class="col-md-12 mt-large text-center mc-login-btn-area">
                <button mat-button
                        class="primary-btn"
                        id="loginBtn"
                        type="submit"
                        [disabled]="!email"
                        (click)="onResetPassword()">
                  {{'cc.common.view.send' | translate}}
                </button>
              </div>
            </div>
          </div><!--Card body /end-->

          <!--Card body section-->
          <div *ngIf="resetPasswordSubmitted" class="card-body mc-card-body text-center">
            <h4>Thank you!</h4>
            <p class="mb-5">You will receive the instruction for resetting your password on the entered email if there is an account connected with this email.</p>
          </div><!--Card body /end-->

        </div><!--Card section /end-->
    </div><!--Row /end-->
  </div><!--Container /end-->
</div><!--Wrapper /end-->
