<lib-mc-loader [showLoader]="csrConsumer.apiLoadingFlg"></lib-mc-loader>

<section >
  <div class="cardbox">
    <h2>{{'cc.consumers.edit.contract-information' | translate}}</h2>
    <div class='dialog-row-wrapper'>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.contract-number' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrContract && csrContract.contractNumber ? csrContract.contractNumber : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.contract-model' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrContract && csrContract.contractModel ?  csrContract.contractModel : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.contract-type' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrContract && csrContract.contractType ?  csrContract.contractType : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.contract-status' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrContract && csrContract.statusCd ? csrContract.statusCd : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.contract-signing-date' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrContract && csrContract.contractSigningDate ? getFormattedDate(csrContract.contractSigningDate) : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.contract-start-date' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrContract && csrContract.startDate ? getFormattedDate(csrContract.startDate) : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.contract-end-date' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrContract && csrContract.endDate ? getFormattedDate(csrContract.endDate) : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.contract-base-amount' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrContract && csrContract.priceUnit ? csrContract.priceUnit : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.contract-cancellation-date' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrContract && csrContract.contractCancellationDate ? getFormattedDate(csrContract.contractCancellationDate) : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.cancellation-active-on-date' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrContract && csrContract.contractCancellationActiveOnDate ? getFormattedDate(csrContract.contractCancellationActiveOnDate) : '-'}}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.contract-cancellation-reason' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrContract && csrContract.cancellationReason ? csrContract.cancellationReason : '-'}}</span>
      </div>
    </div>
  </div>
  <div class="cardbox" *ngIf="hasCustomInfo()">
    <h2>{{'cc.consumer.custom-info' | translate}}</h2>
    <div *ngIf="csrConsumer.additionalAttributes" class='dialog-row-wrapper'>
      <ng-container *ngFor="let item of csrConsumer.additionalAttributes | keyvalue">
        <div *ngIf="item.key.indexOf('contract') === -1" class='dialog-col'>
          <span class='dialog-col-label'>{{ item.key }}:</span>
          <span class='dialog-col-value'>{{ item.value }}</span>
        </div>
      </ng-container>
    </div>
  </div>
</section>

