
<div class="cardbox">
  <h2>{{'cc.common.view.basic-info' | translate}}</h2>
  <div *ngIf="entityObject" class='dialog-row-wrapper'>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.common.edit.vat-number' | translate}}:</span>
      <span class='dialog-col-value'>{{  entityObject.euVat ? entityObject.euVat : '-' }}</span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.entity.registration.bank-name-override' | translate}}:</span>
      <span class='dialog-col-value'>{{ entityObject.bankTransactionEntityNameOverride ? entityObject.bankTransactionEntityNameOverride : '-' | translate }}</span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.common.entity.external-creditor-id' | translate}}:</span>
      <span class='dialog-col-value'>{{ entityObject.extCreditorId ?  entityObject.extCreditorId :'-'| translate }}</span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.common.edit.impressum' | translate}}:</span>
      <span class='dialog-col-value'>{{ entityObject.impressum ? entityObject.impressum : '-' }}</span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.common.view.description' | translate}}:</span>
      <span class='dialog-col-value'>{{ entityObject.description ? entityObject.description : '-' }}</span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.common.edit.mail-domain' | translate}}:</span>
      <span class='dialog-col-value'>{{ entityObject.mailDomain ? entityObject.mailDomain : '-' }}</span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.common.edit.web-domain' | translate}}:</span>
      <span class='dialog-col-value'>{{ entityObject.webDomain ? entityObject.webDomain : '-' }}</span>
    </div>
  </div>

  <div class="divider"></div>

  <h2>{{'cc.consumer.overview.contact' | translate}}</h2>
  <div *ngIf="entityObject" class='dialog-row-wrapper'>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.common.edit.phone-number' | translate}}:</span>
      <span class='dialog-col-value'>{{  entityObject.phone ? entityObject.phone : '-' }}</span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.common.edit.fax-number' | translate}}:</span>
      <span class='dialog-col-value'>{{ entityObject.fax ? entityObject.fax : '-' | translate }}</span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.common.edit.address' | translate}}:</span>
      <span class='dialog-col-value'>{{ entityObject.address ? entityObject.address : '-' }}</span>
    </div>
  </div>

  <div class="divider"></div>

  <h2>{{'cc.entity.registration.representatives' | translate}}</h2>
  <div *ngIf="entityObject" class='dialog-row-wrapper'>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.entity.registration.studio-owner' | translate}}:</span>
      <span class='dialog-col-value'>{{  entityObject.studioOwner ? entityObject.studioOwner : '-' }}</span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.entity.registration.studio-owner-address' | translate}}:</span>
      <span class='dialog-col-value'>{{ entityObject.studioOwnerAddress ? entityObject.studioOwnerAddress : '-' | translate }}</span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.entity.registration.studio-owner-postal-code' | translate}}:</span>
      <span class='dialog-col-value'>{{ entityObject.studioOwnerPostalCode ? entityObject.studioOwnerPostalCode : '-' }}</span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.entity.registration.studio-owner-city' | translate}}:</span>
      <span class='dialog-col-value'>{{ entityObject.studioOwnerCity ? entityObject.studioOwnerCity : '-' }}</span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.entity.registration.studio-manager' | translate}}:</span>
      <span class='dialog-col-value'>{{ entityObject.studioManager ? entityObject.studioManager : '-' }}</span>
    </div>
  </div>

  <div class="divider"></div>

  <h2>{{'cc.common.edit.legal-representative' | translate}}</h2>
  <div *ngIf="entityObject" class='dialog-row-wrapper'>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.common.edit.legal-representative' | translate}}:</span>
      <span class='dialog-col-value'>{{  entityObject.legalRepresentative ? entityObject.legalRepresentative : '-' }}</span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.common.edit.legal-representative-email' | translate}}:</span>
      <span class='dialog-col-value'>{{ entityObject.legalRepresentativeContact ? entityObject.legalRepresentativeContact : '-' | translate }}</span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.entity.registration.court-in-charge' | translate}}:</span>
      <span class='dialog-col-value'>{{ entityObject.courtInCharge ? entityObject.courtInCharge : '-' }}</span>
    </div>
  </div>
</div>

