import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogContent } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { NgbDropdown,NgbDropdownItem,NgbDropdownMenu,NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { SafeHtmlModule } from '../../assets/pipes/safe-html/safe-html.module';
import { AppSharedModule } from '../app-shared/app-shared.module';
import { McEntityCompanyInfoComponent } from './components/mc-entity-company-info/mc-entity-company-info.component';
import { McEntityRegistrationSuccessComponent } from './pages/mc-entity-registration-success/mc-entity-registration-success.component';
import { McEntityRegistrationComponent } from './pages/mc-entity-registration/mc-entity-registration.component';
import { PbEntityRegistrationRoutingModule } from './pb-entity-registration-routing.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { McEntityActivationFeedbackComponent } from './pages/mc-entity-activation-feedback/mc-entity-activation-feedback.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [McEntityRegistrationComponent, McEntityCompanyInfoComponent, McEntityRegistrationSuccessComponent, McEntityActivationFeedbackComponent],
  imports: [
    CommonModule,
    PbEntityRegistrationRoutingModule,
    TranslatePipe,
    SafeHtmlModule,
    AppSharedModule,
    MatDialogContent,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatButtonModule,
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownItem,
    NgbDropdownToggle,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      isolate: true,
    }),
  ],
  exports: [McEntityRegistrationComponent, PbEntityRegistrationRoutingModule, McEntityCompanyInfoComponent, McEntityRegistrationSuccessComponent, TranslateModule, McEntityActivationFeedbackComponent],
})
export class PbEntityRegistrationModule {}