/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {PmTransaction} from '../models/pm-transaction.model';
import {PmTransactionList} from '../models/pm-transaction-list.model';
import {PmTransactionFilter} from '../models/pm-transaction-filter.model';
import {PmTransactionService} from '../services/pm-transaction.service';
import {IPmTransaction} from '../_generated/pm-transaction.interface';
import {McGod} from "../extra/mc-god.service";

export class PmTransactionListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'amount', 'collectionTypeCd', 'description', 'details', 'dueDate', 'dunningStatusCd', 'flgDelay', 'flgPaused', 'idCsrConsumer', 'idCsrContract', 'idExtTransaction', 'idMcEntity', 'idMcFactoring', 'idRef', 'lastPauseUnpauseByUserId', 'maxPauseDays', 'pauseDaysCountWithoutCurrentPauseDays', 'pauseEndDate', 'pauseStartDate', 'pauseUnpauseReason', 'reason', 'refTypeCd', 'reminderLastInterestCalculatedDatetime', 'sentToInkassoDatetime', 'sourceTypeCd', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'version', 'waitingPeriod', 'amountDue', 'balance', 'consumerName', 'createdByName', 'csrConsumer', 'matchingId', 'mcEntity', 'mcFactoring', 'pausedDaysCount', 'paymentAccountEntities', 'pmAdjustments', 'type'];
  public items: PmTransaction[] = [];
  public apiService: PmTransactionService;
 // protected tempObjList: PmTransactionList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): PmTransactionList {
    console.error('MTCN-ERROR: Not Implemented: PmTransactionList::createNewInstance(). Add this method to final class and return new PmTransactionList();');
    throw new Error('Cannot PmTransactionList::createNewInstance(). Add this method to final class and return new PmTransactionList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IPmTransaction[]): PmTransactionList {
    const  listObj: PmTransactionList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: PmTransaction = PmTransaction.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): PmTransactionList {
    const  listObj: PmTransactionList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(PmTransactionService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IPmTransaction[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: PmTransaction = PmTransaction.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aPmTransactionFilter: PmTransactionFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aPmTransactionFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): PmTransaction {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new PmTransaction();
    }
  }

  public replaceItem(newItem: PmTransaction) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
  // ---------------------------------------------------------------------
  public loadAllByConsumerId( idCsrConsumer: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getAllByConsumerId(idCsrConsumer)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

// ---------------------------------------------------------------------
public callPauseTransaction( transactionIds: any, pauseEndDate: any, pauseUnpauseReason: string, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.pauseTransaction(transactionIds,pauseEndDate,pauseUnpauseReason).subscribe(
    responseJson => {
      this.setApiSuccess();
      this.processApiSuccess(responseJson, onSuccessCallback);
    },
    errorResponse => {
      this.setApiError();
      this.processApiError(errorResponse, onErrorCallback);
    });
}

// ---------------------------------------------------------------------
public callUnpauseTransaction( transactionIds: any, pauseUnpauseReason: string, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.unpauseTransaction(transactionIds,pauseUnpauseReason).subscribe(
    responseJson => {
      this.setApiSuccess();
      this.processApiSuccess(responseJson, onSuccessCallback);
    },
    errorResponse => {
      this.setApiError();
      this.processApiError(errorResponse, onErrorCallback);
    });
}

  // ---------------------------------------------------------------------
  public loadAllByFactoringId( idMcFactoring: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getAllByFactoringId(idMcFactoring)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

}

