
import {Table3Config} from '../extra/table-3-config';

// import {CsrContractGENERATED} from '../_generated/csr-contract-generated.model';

export class CsrContractTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colApprovalKey', 'Approval key', 'approvalKey', 'approvalKey');
    this.addColumn('colAutoRenewalLength', 'Auto renewal length in years', 'autoRenewalLength', 'autoRenewalLength');
    this.addColumn('colCancellationReason', 'Cancellation reason', 'cancellationReason', 'cancellationReason');
    this.addColumn('colConsumer360Flg', 'Consumer 360', 'consumer360Flg', 'consumer360Flg');
    this.addColumn('colConsumerName', 'Consumer name', 'consumerName', 'consumerName');
    this.addColumn('colConsumerStatusCd', 'Consumer status', 'consumerStatusCd', 'consumerStatusCd');
    this.addColumn('colContractCancellationActiveOnDate', 'Contract cancellation active on date', 'contractCancellationActiveOnDate', 'contractCancellationActiveOnDate');
    this.addColumn('colContractCancellationDate', 'Contract cancellation date', 'contractCancellationDate', 'contractCancellationDate');
    this.addColumn('colContractModel', 'Contract model', 'contractModel', 'contractModel');
    this.addColumn('colContractName', 'Contract name', 'contractName', 'contractName');
    this.addColumn('colContractNumber', 'Contract number', 'contractNumber', 'contractNumber');
    this.addColumn('colContractSigningDate', 'Contract signing date', 'contractSigningDate', 'contractSigningDate');
    this.addColumn('colContractType', 'Contract type', 'contractType', 'contractType');
    this.addColumn('colCsrConsumer', 'Consumer', 'csrConsumer', 'csrConsumer');
    this.addColumn('colCsrContractPreDefined', 'Pre defined Contract', 'csrContractPreDefined', 'csrContractPreDefined');
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colDurationInDays', 'Duration of contract in days', 'durationInDays', 'durationInDays');
    this.addColumn('colDurationInMonths', 'Duration of contract in months', 'durationInMonths', 'durationInMonths');
    this.addColumn('colEndDate', 'End date of contract', 'endDate', 'endDate');
    this.addColumn('colIdBeneficiary', 'McEntity for transaction payment', 'idBeneficiary', 'idBeneficiary');
    this.addColumn('colIdCsrConsumer', 'Consumer', 'idCsrConsumer', 'idCsrConsumer');
    this.addColumn('colIdCsrContractPreDefined', 'Pre defined cotract ID', 'idCsrContractPreDefined', 'idCsrContractPreDefined');
    this.addColumn('colIdCsrDiscount', 'Discount ID', 'idCsrDiscount', 'idCsrDiscount');
    this.addColumn('colIdExternal', 'External ID', 'idExternal', 'idExternal');
    this.addColumn('colIdMcEntity', 'McEntity that created contract', 'idMcEntity', 'idMcEntity');
    this.addColumn('colIdWebFile', 'Webfile ID', 'idWebFile', 'idWebFile');
    this.addColumn('colIdWebfileDdApproval', 'Signed debit approval webfile id', 'idWebfileDdApproval', 'idWebfileDdApproval');
    this.addColumn('colIdWebfileOther', 'Other webfile ids', 'idWebfileOther', 'idWebfileOther');
    this.addColumn('colIdWebfileSignature', 'Signature webfile id', 'idWebfileSignature', 'idWebfileSignature');
    this.addColumn('colJsonbNotificationsDetails', 'Notification details', 'jsonbNotificationsDetails', 'jsonbNotificationsDetails');
    this.addColumn('colJsonbPaymentDetails', 'Payment details', 'jsonbPaymentDetails', 'jsonbPaymentDetails');
    this.addColumn('colJsonbRecurringDetails', 'Recurring details', 'jsonbRecurringDetails', 'jsonbRecurringDetails');
    this.addColumn('colNextRecurring', 'Date of next recurring', 'nextRecurring', 'nextRecurring');
    this.addColumn('colNotes', 'Consumer notes', 'notes', 'notes');
    this.addColumn('colPriceUnit', 'Price unit', 'priceUnit', 'priceUnit');
    this.addColumn('colRenewalNoticePeriod', 'How many months before end_date check should happen', 'renewalNoticePeriod', 'renewalNoticePeriod');
    this.addColumn('colStartDate', 'Start date of contract', 'startDate', 'startDate');
    this.addColumn('colStatusCd', 'Statuses', 'statusCd', 'statusCd');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');
    this.addColumn('colSysUpdatedDatetime', 'System Updated Datetime', 'sysUpdatedDatetime', 'sysUpdatedDatetime');
    this.addColumn('colVatUnit', 'Vat amount unit', 'vatUnit', 'vatUnit');
    this.addColumn('colVersion', 'Version', 'version', 'version');

*/
  }
}
