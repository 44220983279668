/*BbmTestObj*/
import {EntityInvitationGENERATED} from '../_generated/entity-invitation-generated.model';

export class EntityInvitation extends EntityInvitationGENERATED {

  public static ACTION_CANCEL = 'Cancel';
  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new EntityInvitation();
  }

  invitedEntityName = '';
  public propertiesRegular: string[] = ['id', 'createdAt', 'email', 'idMcEntity', 'invitationCode', 'invitedEntityId', 'status', 'usedAt', 'invitedEntityName'];
  // ---------------------------------------------------------------------
}
