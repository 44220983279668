<h3 class="page-title">{{'cc.data-export' | translate}}</h3>
<div class="actions-container">
  <span>{{ 'cc.sidemenu.sepa-direct-debit' | translate }}</span>
  <div class="actions-wrapper" *ngIf="parentEntityId === 'undefined'">
    <button  class="system-action-btn mr-2" style="gap: 10px" (click)="openSepaExportDialog()">
      <span [innerHTML]="iconDownload | safeHtml"></span>
      {{ 'cc.common.sepa-export' | translate}}
    </button>
  </div>
</div>
<div>
    <lib-mc-loader [showLoader]="ebSepaExportList.apiLoadingFlg"></lib-mc-loader>

    <div *ngIf="ebSepaExportList.apiErrorFlg">
        Error: {{ebSepaExportList.apiErrorMessage}}
    </div>

  <div class="col-md-12 position-relative mt-1 mb-5">
    <div class="mc-create-user-notify-wrapper">
      <mc-notify-message [message]=notifyMessage.message [type]="notifyMessage.type"></mc-notify-message>
    </div>
  </div>
</div>

<mcc-modal1-wrapper modalSizeCd="small" [showMcb]="showUploadFileMcb" *ngIf="showUploadFileMcb.value">
  <lib-upload-file (eventUploaded)="onUploadFile($event)" (eventCancel)="onUploadCancel()"></lib-upload-file>
</mcc-modal1-wrapper>

<mk-mat-table
  [isLoading]="isLoading"
  [items]="items"
  [config]="tableConfig"
  [itemsPerPage]="pageSize"
  [filterConfig]="filterConfig"
  [totalItemsCount]="totalItemsCount"
  (filterChanged)="onFilterChanged($event)"
  [mkMatMenuActionItems]="tableActionItems"
  (actionMkMatMenuItems)="onTableAction($event)"
></mk-mat-table>
