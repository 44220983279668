import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-mc-entity-company-info',
  templateUrl: './mc-entity-company-info.component.html',
  styleUrl: './mc-entity-company-info.component.scss'
})
export class McEntityCompanyInfoComponent implements OnInit{
  form!: FormGroup;
  countries: any[] = [];
  webDomains: string[] = ['az-p.de', 'my-factura.com'];
  invitationCode = '';

  @Output() companyInfoForm = new EventEmitter<FormGroup>();

  constructor(private fb: FormBuilder,
              private http: HttpClient,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.invitationCode = params['invitationCode'];
    });
    this.getCounties();
    this.createForm();

    this.form.valueChanges.subscribe(() => {
      if(this.form.valid) this.companyInfoForm.emit(this.form);
    })
  }

  getCounties(){
    this.http.get<any[]>('assets/data/country.json').subscribe( data =>{ this.countries = data; });
  }

  createForm(){
    this.form = this.fb.group({
      companyName: ['', Validators.required],
      abbreviation: ['', Validators.required],
      legalForm: ['', Validators.required],
      address: ['', Validators.required],
      country: ['', Validators.required],
      description: [''],
      impressum: [''],

      vatNumber: ['', Validators.required],
      taxNumber: ['', Validators.required],
      ustId: ['', Validators.required],
      bankNameOverride: [''],
      externalId: [''],

      generalEmail: ['', [Validators.required, Validators.email]],
      accountingEmail: ['', [Validators.required, Validators.email]],
      managementEmail: ['', [Validators.required, Validators.email]],
      billingEmail: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required, Validators.pattern('^\\+?[1-9][0-9]{7,14}$')]],
      faxNumber: [''],

      studioOwner: ['', Validators.required],
      studioOwnerAddress: [''],
      studioOwnerPostalCode: [''],
      studioOwnerCity: [''],
      studioManager: [''],
      responsiblePerson: ['', Validators.required],
      responsiblePersonFunction: ['', Validators.required],

      legalRepresentative: [''],
      legalRepresentativeEmail: [''],
      courtInCharge: [''],

      accountOwner: ['', Validators.required],
      bankName: ['', Validators.required],
      bankCity: ['', Validators.required],
      bankCountry: ['', Validators.required],
      accountNumber: ['', Validators.required],
      blz: ['', Validators.required],
      swift: ['', Validators.required],
      iban: ['', [Validators.pattern("^[A-Z]{2}\\d{2}[A-Z0-9]+"), Validators.required]],

      subdomain: ['', Validators.required],
      domain: ['', Validators.required],

      contractType: ['', Validators.required],

      invitationCode: [this.invitationCode],

      generalTerms:['', Validators.required],
      pricelistTerms:['', Validators.required],

    });
  }
}
