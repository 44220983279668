<lib-app-shared-action-row [leadingIcon]="iconInvitations" [trailingIcon]="iconInvitations" leadingTitle="cc.entity.profile.invitations" trailingTitle="cc.entity.profile.send-invitations" (onTrailingAction)="openSendInvitationDialog()"></lib-app-shared-action-row>

<mk-mat-table
  [items]="items"
  [itemsPerPage]="items.length"
  [config]="tableConfig"
  [isLoading]="isLoading"
  [pageNumber]="pageNumber"
  [pageSize]="pageSize"
  [mkMatMenuActionItems]="tableActionItems"
  (actionMkMatMenuItems)="onTableAction($event)"
  (filterChanged)="onFilterChanged($event)"
  [totalItemsCount]="totalItemsCount"
>
</mk-mat-table>
