import {
  Component,
  EventEmitter, HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {FormGroup, FormBuilder} from "@angular/forms";
import {Observable, Subject, Subscription} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {
  McInvoiceService,
  invoiceEnums,
  Link,
  McBankAccountList,
  McUtilityService, McStateManagementService, McProductService, McProductFilter, McGod, SortCriteriaList, McProduct
} from '@miticon-ui/mc-core';
import {NgbDateAdapter, NgbDateNativeAdapter, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import _moment from "moment";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: "lib-invoice-step1",
  templateUrl: "./invoice-step1.component.html",
  providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}]
})
export class InvoiceStep1Component implements OnInit, OnDestroy {

  responseMessageDisplay!: boolean;
  invalidProducts: boolean;
  notifyMsgMessage!: string;
  notifyMsgType!: string;
  tableContent: any;
  filtersName = "invoicesProducts";
  invoiceArea!: string;
  consumerId: number;
  invoiceTableContent: any;
  productList: any[] = [];
  productListFromService: any[] = [];
  previouslySelectedProducts: any[] = [];
  showLoader!: boolean;
  minServicePeriodSendDate = {year: 0, month: 0, day: 0};
  minServicePeriodDate = {year: 1970, month: 1, day: 1};
  maxServicePeriodDate = {year: 2100, month: 12, day: 31};
  isServicePeriodFromSelected!: boolean;
  invalidDiscountForm!: boolean;
  link: Link;
  ID;
  bankAccounts: McBankAccountList;
  ispageLoaded = false;

  // Edit
  receivedInvoiceData: any;

  servicePeriodDates = {
    servicePeriodFrom: '',
    servicePeriodTo: ''
  };

  getAllProducts$!: Observable<any>;
  destroy$ = new Subject<void>();

  stepForm!: FormGroup;
  private invoiceProductListSubscription!: Subscription;

  @Input() parentForm!: FormGroup;
  @Output() isDataFormInvalid = new EventEmitter<boolean>();
  @Output() selectedTableProducts = new EventEmitter<any>();

  @HostListener("window:resize")
  onResize() {
    this.stateManagementService.setCollapsedSidebar(window.innerWidth < 1600);
  }

  constructor(
    private fb: FormBuilder,
    private productService: McProductService,
    private invoiceService: McInvoiceService,
    private utilityService: McUtilityService,
    private stateManagementService: McStateManagementService,
    private modal: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.link = {
      path: '',
      params: ''
    };
    this.bankAccounts = new McBankAccountList();
    this.ID = this.utilityService.getPropertyFromToken("entity_id");
    this.onResize();
    this.createForm();
  }

  ngOnInit() {
    this.parentForm.setControl("step1", this.stepForm);
    this.consumerId = this.activatedRoute.snapshot.params["id"] - 0;

    this.getAllProducts$ = this.productService.getByFilter(this.getProductFilter(), 0 , 100, new SortCriteriaList()).pipe(
      takeUntil(this.destroy$)
    );

    this.invoiceProductListSubscription = this.invoiceService.invoiceList.subscribe(list => {
      this.productListFromService = list;
      this.invoiceValidation();
    });

    this.stepForm.get("servicePeriodFrom")?.valueChanges.subscribe(() => {

      this.invoiceValidation();
      const servicePeriodFrom = this.stepForm.get("servicePeriodFrom")?.value === null ? this.stepForm.get("servicePeriodFrom")?.value : _moment(this.stepForm.get("servicePeriodFrom")?.value).format();
      this.stepForm.get("servicePeriodTo")?.setValue(null);
      this.servicePeriodDates = {
        servicePeriodFrom,
        servicePeriodTo: ''
      };

      this.minServicePeriodSendDate = {
        year: new Date(servicePeriodFrom).getFullYear(),
        month:  new Date(servicePeriodFrom).getMonth() + 1,
        day: new Date(servicePeriodFrom).getDate()
      };

      this.sendSelectedProducts(this.servicePeriodDates);
      this.isServicePeriodFromSelected = true;

      if (servicePeriodFrom) {
        this.stepForm.get("servicePeriodTo")?.enable();
        this.isServicePeriodFromSelected = true;
      } else {
        this.stepForm.get("servicePeriodTo")?.disable();
        this.isServicePeriodFromSelected = false;
      }
      this.userDataFormInvalid(!(this.productListFromService.length > 0 && servicePeriodFrom !== null && !this.invalidDiscountForm));
    });
    this.stepForm.get("servicePeriodTo")?.valueChanges.subscribe(() => {
      const servicePeriodFrom = _moment(this.stepForm.get("servicePeriodFrom")?.value).format();
      const servicePeriodTo = this.stepForm.get("servicePeriodTo")?.value === null ? this.stepForm.get("servicePeriodTo")?.value : _moment(this.stepForm.get("servicePeriodTo")?.value).format();
      this.servicePeriodDates = {
        servicePeriodFrom,
        servicePeriodTo
      };
      this.sendSelectedProducts(this.servicePeriodDates);
    });

    if (this.parentForm.get("resetServicePeriodDates")) {
      this.parentForm.get("resetServicePeriodDates")?.valueChanges.subscribe(() => {
        const resetServicePeriod = this.parentForm.get("resetServicePeriodDates")?.value;
        if (resetServicePeriod) {
          this.stepForm.get("servicePeriodFrom")?.setValue(null);
          this.stepForm.get("servicePeriodTo")?.setValue(null);
        }
      });
    }

    // From Edit
    if (this.parentForm.get("receivedData")) {
      if (this.productListFromService.length === 0) {
        this.setNotificationMessage(invoiceEnums.selectProductForInvoices, invoiceEnums.successMsg, "", "");
        this.responseMessageDisplay = true;
      }
      this.parentForm.get("receivedData")?.valueChanges.subscribe(() => {
        this.receivedInvoiceData = this.parentForm.get("receivedData")?.value;

        if (this.receivedInvoiceData.servicePeriodTo === null) {
          this.receivedInvoiceData.servicePeriodTo = this.receivedInvoiceData.servicePeriodFrom;
        }

        this.setReceivedServicePeriod(this.receivedInvoiceData.servicePeriodFrom, this.receivedInvoiceData.servicePeriodTo);

        this.receivedInvoiceData.orderItems.forEach((item: any) => {
          item.name = item.productName;
          item.vatRate = Number(item.vat)
          this.invoiceService.addProductToInvoice(item);
        });

        if (this.receivedInvoiceData.orderItems) {
          this.invoiceTableContent = Object.assign({}, {
            content: [],
            discount: this.receivedInvoiceData.discount,
            _name: "invoiceForSelectedProducts",
            isLoaded: true
          });
          this.showLoader = false;
        }
      });
    } else {
      this.bankAccounts.loadByEntityId(this.ID, () => {
        this.checkIfBankAccountExist();
      });
    }

    this.isServicePeriodFromSelected = false;
  }

  /*Invoice validation - product list and service period*/
  private invoiceValidation() {
    if (!this.productListFromService.length || !this.stepForm.get("servicePeriodFrom")?.value) {
      this.userDataFormInvalid(true);
      this.setNotificationMessage(invoiceEnums.selectProductForInvoices, invoiceEnums.successMsg, "", "");
      this.responseMessageDisplay = true;
    } else {
      this.responseMessageDisplay = false;
    }
  }

  checkIfBankAccountExist() {
    if (this.bankAccounts && this.bankAccounts.items.length > 0 && this.productListFromService.length === 0) {
      this.setNotificationMessage(invoiceEnums.selectProductForInvoices, invoiceEnums.successMsg, "", "");
      this.responseMessageDisplay = true;
    } else {
      const msg = "Your profile is missing bank account information. To insert bank account information, please follow this";
      const type = "error";
      const path = `/entity/${this.ID}/entity-profile`;
      const params = "bankAccountsTab";
      this.setNotificationMessage(msg, type, path, params);
      this.responseMessageDisplay = true;
    }
  }

  createForm() {
    this.stepForm = this.fb.group({
      servicePeriodFrom: [{value: "", disabled: false}],
      servicePeriodTo: [{value: "", disabled: true}],
    });
  }

  openProductsModal(addProducts: any) {
    this.modal.open(addProducts, {
      backdrop: "static",
      centered: true,
      windowClass: "mc-create-invoice-modal",
      beforeDismiss: () => {
        this.previouslySelectedProducts.push(...this.productList)
        this.productList = [];
        this.invoiceValidation();
        return true;
      }
    });
    this.responseMessageDisplay = false;
    this.getAllProducts();
  }

  getAllProducts() {
    this.showLoader = true;
    this.getAllProducts$.subscribe(
      response => {
        this.showLoader = false;
        const modifiedResponse = {
          ...response,
          content: response.content.map((item: any) => ({
            ...item,
            currency: item.mcCurrency?.code,
            productCategoryName: item.ppCategory?.name,
          }))
        };
        this.tableContent = Object.assign({}, {
            ...modifiedResponse,
          _name: "addProduct",
          isLoaded: true
        });
      },
      error => {
        this.showLoader = false;
        this.apiErrorHandler(error);
      }
    );
  }

  receiveSelectedTableData(data: any) {
    this.invoiceValidation();
    this.invoiceTableContent = Object.assign({}, {
      content: [],
      _name: "invoiceForSelectedProducts",
      isLoaded: true
    });
    this.responseMessageDisplay = false;

    if (this.stepForm.get("servicePeriodFrom")?.value) {
      this.userDataFormInvalid(false);
    }
  }

  /*Get selected product from table*/
  public receiveSelectedProduct(productEvent: any) {
    this.responseMessageDisplay = false;
    this.invoiceValidation();
    if (productEvent) {
      const productIndex = this.productList.findIndex(item => item.id === productEvent.product.id);
      if (productIndex === -1)
        this.productList.push(productEvent.product);
      else
        this.productList[productIndex] = productEvent.product;

      if (this.productList.length > 0)
        this.productList = this.productList.filter(item => item.numberOfProducts > 0);

      this.checkIfProductsAreValid()
    }
  }

  setReceivedServicePeriod(receivedServicePeriodFrom: any, receivedServicePeriodTo: any) {
    const receivedServicePeriodFromFullDate = _moment(new Date(receivedServicePeriodFrom)).format("ll");
    const receivedServicePeriodToFullDate = _moment(new Date(receivedServicePeriodTo)).format("ll");
    const servicePeriodFrom = this.stepForm.get("servicePeriodFrom");
    const servicePeriodTo = this.stepForm.get("servicePeriodTo");

    if (receivedServicePeriodFromFullDate === receivedServicePeriodToFullDate) {
      servicePeriodFrom?.setValue(new Date(receivedServicePeriodFrom));
      servicePeriodTo?.setValue(null);
    } else {
      servicePeriodFrom?.setValue(new Date(receivedServicePeriodFrom));
      servicePeriodTo?.setValue(new Date(receivedServicePeriodTo));
    }
  }

  // Unlock Next btn
  userDataFormInvalid(data: boolean) {
    this.isDataFormInvalid.emit(data);
  }

  isDiscountInvalid(event: boolean) {
    this.invalidDiscountForm = event;
    const servicePeriodFrom =  this.stepForm.get("servicePeriodFrom")?.value;
    this.userDataFormInvalid(!(this.productListFromService.length > 0 && !this.invalidDiscountForm && servicePeriodFrom !== ''))
  }

  sendSelectedProducts(data: any) {
    this.stateManagementService.invoiceData = data;
    this.selectedTableProducts.emit(data);

    if(data && data.orderItems && data.orderItems.length === 0){
      this.previouslySelectedProducts = []; //if all order items are removed, reset the previously selected product list
    }
  }

  getFilter(event: any) {
    this.showLoader = true;

    this.productService.getByFilter(this.getProductFilter(), 0 , 100, new SortCriteriaList()).subscribe(
      response => {
        this.showLoader = false;
        this.tableContent = Object.assign({}, {
          ...response,
          _name: "addProduct",
          isLoaded: true
        });
      },
      error => {
        this.showLoader = false;
        this.apiErrorHandler(error);
      }
    );
  }

  // Api ErrorHandler
  private apiErrorHandler(error: any) {
    let message = "";
    let msgType = "";
    if (error.status >= 500) {
      message = "Server error";
      msgType = "error";
    } else if (error.error) {
      if (error.error.errors.length > 1) {
        message = error.error.errors.join();
        msgType = "error";
      } else {
        message = error.error.errors;
        msgType = "error";
      }
    } else {
      message = "Invalid request";
      msgType = "error";
    }
    this.responseMessageDisplay = true;
    this.setNotificationMessage(message, msgType, "", "");
  }

  // Display notification message
  private setNotificationMessage(message: string, type: string, path: string, params: string): void {
    this.notifyMsgMessage = message;
    this.notifyMsgType = type;
    this.link.path = path;
    this.link.params = params;
  }

  onFinishAddingProducts() {
    this.productList.forEach(product => {
      for (let i = 1; i <= product.numberOfProducts; i++) {
        this.invoiceService.addProductToInvoice(product);
      }
    });
    this.receiveSelectedTableData(null);
  }

  ngOnDestroy() {
    this.invoiceProductListSubscription.unsubscribe();
  }

  getProductFilter(){
    let productFilter = new McProductFilter()
    productFilter.idMcEntity = McGod.getLoggedEntityIdFromToken();
    productFilter.pricingType = McProduct.PRICING_TYPE_CD_VALUE

    return productFilter;
  }

  checkIfProductsAreValid() {
    //Current product selection
    const vatSet = new Set(this.productList.map(product => product.idVat));
    const currencySet = new Set(this.productList.map(product => product.idCurrency));

    //Previously selected products
    const previouslySelectedVatSet = new Set(this.previouslySelectedProducts.map(product => product.idVat));
    const previouslySelectedCurrencySet = new Set(this.previouslySelectedProducts.map(product => product.idCurrency));
    const vatNotInPrevious = Array.from(vatSet).some(vat => !previouslySelectedVatSet.has(vat));
    const currencyNotInPrevious = Array.from(currencySet).some(currency => !previouslySelectedCurrencySet.has(currency));

    const isCurrentSelectionInvalid = this.productList.length > 0 && (vatSet.size !== 1 || currencySet.size !== 1);
    const isNotContainedInPreviousSelection = this.previouslySelectedProducts.length > 0 && (vatNotInPrevious || currencyNotInPrevious);

    this.invalidProducts = isCurrentSelectionInvalid || isNotContainedInPreviousSelection;
  }

}
