import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {BehaviorSubject} from 'rxjs';
import {Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import { McUserLogin, McInitLogin } from '../models/auth.model';

@Injectable({
  providedIn: 'root'
})
export class McUtilityService {

  /*Public variables*/
  public _goToStep = new BehaviorSubject(null);

  /*Private variables*/
  private jwtHelper = new JwtHelperService();

  /*Constructor*/
  constructor(private location: Location, private route: ActivatedRoute) {
  }

  /*Check if value is JSON*/
  public isJson(value: string): boolean {
    if (typeof value !== 'string') {
      return false;
    }
    try {
      const result = JSON.parse(value);
      return Object.prototype.toString.call(result) === '[object Object]' || Array.isArray(result);
    } catch (err) {
      return false;
    }
  }

  /*Store logged user data in localStorage*/
  public storeAuthData(data: McUserLogin | McInitLogin) {
    localStorage.setItem('mc-user', JSON.stringify(data));
    localStorage.setItem('mc-access-token', JSON.stringify(data.access_token));
    localStorage.setItem('mc-refresh-token', JSON.stringify(data.refresh_token));
    localStorage.setItem('authorities', JSON.stringify(data.authorities));
  }

  /*Get property from token
  * properties: aud, user_id, user_name, scope, exp, entity_id, authorities, jti, client_id*/
  public getPropertyFromToken(property: string) {
    const token = localStorage.getItem('mc-access-token');
    /*Check if token and property exists
    * than check if token has property*/
    if (token && property) {
      const decodedToken = this.jwtHelper.decodeToken(token);
     // console.log("DECODED TOKEN88:", decodedToken);
      return decodedToken.hasOwnProperty(property) && decodedToken[property];
    }
    return false;
  }

  public getAuthoritiesFromStorage() {
    const authoritiesStringified = JSON.stringify(localStorage.getItem('authorities'));
    const authoritiesParsed = JSON.parse(authoritiesStringified)

    return JSON.parse(authoritiesParsed)
  }

  public getPropertyFromRefreshToken(property: string) {
    const token = localStorage.getItem('mc-refresh-token');
    /*Check if token and property exists
    * than check if token has property*/
    if (token && property) {
      const decodedToken = this.jwtHelper.decodeToken(token);
      return decodedToken.hasOwnProperty(property) && decodedToken[property];
    }
    return false;
  }

  /*Set go to step number*/
  public setStepNumber(step: number | null) {
    // @ts-ignore
    this._goToStep.next(step);
  }

  /*Go back*/
  public goBack() {
    this.location.back();
  }

  /*Set language*/
  public setLanguage(language: string): void {
    localStorage.setItem("mc-language", language);
  }

  /*Get language*/
  public getLanguage(): string {
    // @ts-ignore
    return localStorage.getItem("mc-language");
  }

  /*Round half up*/
  public roundHalfUp(aNumber: any, aDecimals: string | number | undefined): any {
    // @ts-ignore
    return Number(Math.round(Number(String(aNumber) + "e" + aDecimals)) + "e-" + aDecimals).toFixed(aDecimals);
  }


  /*Only for entity profile page - edit entity*/
  public isLoggedEntity(): any {
    return window.location.href.indexOf("edit/") >= 0 && (window.location.href.split('edit/')[1].split('/')[0] === this.getPropertyFromToken("entity_id"));
  }

  public isActivRoute(aRouteName: string): boolean {
    return window.location.href.includes(aRouteName);
  }
}
