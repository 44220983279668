import {BkFinanceReport} from './bk-finance-report.model';
import {BkFinanceReportListGENERATED} from '../_generated/bk-finance-report-list-generated.model';

export class BkFinanceReportList extends BkFinanceReportListGENERATED {

  public propertyNames: string[] = ['id', 'bookingDateFrom', 'bookingDateTo', 'idWebFile', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'exportedBy', 'financeReportFilename'];

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new BkFinanceReportList();
  }

  // ---------------------------------------------------------------------
}
