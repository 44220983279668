import {Component, Inject, OnInit} from '@angular/core';
import {
  McProcessItemList,
  McProcessItem,
  McProcessItemTable3Config,
  McProcess,
  McForm,
  McMenu, McGod, McHtmlButton, McBoolean, McSlideActionSet, McSlideActionItem
} from '@miticon-ui/mc-core';
import {ActivatedRoute, Router} from "@angular/router";
import {ICON_BACK} from "../../../../assets/media/svg_icons/icon-back";

@Component({
  selector: 'mc-process-process-item-list-page',
  templateUrl: './mc-process-process-item-list-page.component.html',
})
export class McProcessProcessItemListPageComponent implements OnInit {

  mcProcessId!: number;
  mcProcess = new McProcess();
  mcProcessItemList!: McProcessItemList;
  mcProcessItemTable3Config = new  McProcessItemTable3Config();
  mcForm = new McForm();
  mcMenu = new McMenu();
  showChangeNextActionDateMcb = new McBoolean();
  showTransactionProfileMcb = new McBoolean();
  showConsumerActionHistoryMcb = new McBoolean();
  mcProcessItemIds: any[] = [];
  actionSet = new McSlideActionSet();
  selectedListNumber = 0;
  selectedList = new McProcessItemList();
  successMsg = '';
  mcGod = McGod.getInstance();
  ebSepaFileTransactionId!: number;
  mcConsumerId!: number;
  iconBack = ICON_BACK;

  // ---------------------------------------------------------------------
  constructor(private route: ActivatedRoute, private router: Router,  @Inject('designConfig') design: any) {
  }

  // ---------------------------------------------------------------------
  ngOnInit() {

    this.mcProcessItemList = new McProcessItemList();
    this.mcProcessItemList.setPager(0, 50);

    this.route.params.subscribe(params => {
      this.mcProcessId = +params['id'];
      this.actLoad();
    })

    this.mcMenu.addMenuItem('configuration', this.mcGod.t('cc.common.configuration'), '', [McGod.PERM_MC_PROCESS_VIEW], `/entity/${McGod.getLoggedEntityIdFromToken()}/processes/process-profile/${this.mcProcessId}`);
    this.mcMenu.addMenuItem('items', this.mcGod.t('cc.common.items'), '', [McGod.PERM_MC_PROCESS_ITEM_VIEW], `/entity/${McGod.getLoggedEntityIdFromToken()}/processes/process-items/${this.mcProcessId}`, true);
    // this.mcMenu.addMenuItem('statistics', 'Statistics', '', [], ``);
    this.mcMenu.addMenuItem('runs', this.mcGod.t('cc.processes.runs'), '', [McGod.PERM_MC_PROCESS_RUN_VIEW], `/entity/${McGod.getLoggedEntityIdFromToken()}/processes/process-runs/${this.mcProcessId}`);

    this.actionSet.addItem(this.mcGod.t('cc.processes.change-next-action-datetime'),
      McProcessItem.ACTION_CHANGE_NEXT_ACTION_DATE, [McGod.PERM_EB_FACTORING_MY_TRANSACTION_RECORD_PAYMENT]);

  }

  public actLoad(): void {
    this.mcProcessItemList.loadByProcessId(this.mcProcessId);
    this.mcProcess.loadById(this.mcProcessId);
  }

  actGoToProcesses() {
    this.router.navigate([`/entity/${McGod.getLoggedEntityIdFromToken()}/processes/overview`]);
  }

  onTableItemAction(mcButton: McHtmlButton) {
    if (mcButton.actionCdForEvent === McProcessItem.ACTION_CHANGE_NEXT_ACTION_DATE) {
      this.selectedList = new McProcessItemList();
      this.selectedList.addItem(mcButton.item);
      this.showChangeNextActionDateMcb.setTrue();
    } else if (mcButton.actionCdForEvent === McProcessItem.ACTION_VIEW_TRANSACTION_DETAILS) {
      this.ebSepaFileTransactionId = mcButton.item.pmTransaction.id;
      this.router.navigateByUrl(`/entity/${McGod.getLoggedEntityIdFromToken()}/transactions/${this.ebSepaFileTransactionId}`);
    } else if (mcButton.actionCdForEvent === McProcessItem.ACTION_VIEW_ACTION_HISTORY) {
      this.mcConsumerId = mcButton.item.idMcConsumer;
      this.showConsumerActionHistoryMcb.setTrue();
    }
  }

  actCancelChangeNextActionDateTime() {
    this.showChangeNextActionDateMcb.setFalse();
  }

  actSavedChangeNextActionDateTime() {
    this.successMsg = this.mcGod.t('cc.processes.next-action-date-changed-successfully');
    this.showChangeNextActionDateMcb.setFalse();
    this.selectedList = new McProcessItemList();
    this.selectedListNumber = 0;
    this.actLoad();
  }

  actTableSelectionChanged(selectedList: McProcessItemList) {
    this.selectedListNumber = selectedList.getCount();
    this.selectedList = selectedList;
    this.mcProcessItemIds = this.selectedList.getIds();
  }

  slideActionItemClicked(actionItem: McSlideActionItem) {
    if (actionItem.actionCdForEvent === McProcessItem.ACTION_CHANGE_NEXT_ACTION_DATE) {
      this.showChangeNextActionDateMcb.setTrue();
    }
  }

  actOnTransactionProfileClose() {
    this.showTransactionProfileMcb.setFalse();
    this.actLoad();
  }

  actActionHistoryCancel() {
    this.showConsumerActionHistoryMcb.setFalse();
    this.mcConsumerId = 0;
  }
}
