/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {McEntityConsumerAttribute} from '../models/mc-entity-consumer-attribute.model';
import {McEntityConsumerAttributeList} from '../models/mc-entity-consumer-attribute-list.model';
import {McEntityConsumerAttributeFilter} from '../models/mc-entity-consumer-attribute-filter.model';
import {McEntityConsumerAttributeService} from '../services/mc-entity-consumer-attribute.service';
import {IMcEntityConsumerAttribute} from '../_generated/mc-entity-consumer-attribute.interface';
import {McGod} from "../extra/mc-god.service";

export class McEntityConsumerAttributeListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'archivedFlg', 'creationDate', 'dataSet', 'defaultValue', 'description', 'enabledFlg', 'groupName', 'idMcEntity', 'inheritableFlg', 'inheritedFlg', 'label', 'name', 'requiredFlg', 'typeCd', 'validationRegex', 'value'];
  public items: McEntityConsumerAttribute[] = [];
  public apiService: McEntityConsumerAttributeService;
 // protected tempObjList: McEntityConsumerAttributeList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McEntityConsumerAttributeList {
    console.error('MTCN-ERROR: Not Implemented: McEntityConsumerAttributeList::createNewInstance(). Add this method to final class and return new McEntityConsumerAttributeList();');
    throw new Error('Cannot McEntityConsumerAttributeList::createNewInstance(). Add this method to final class and return new McEntityConsumerAttributeList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IMcEntityConsumerAttribute[]): McEntityConsumerAttributeList {
    const  listObj: McEntityConsumerAttributeList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: McEntityConsumerAttribute = McEntityConsumerAttribute.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): McEntityConsumerAttributeList {
    const  listObj: McEntityConsumerAttributeList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McEntityConsumerAttributeService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IMcEntityConsumerAttribute[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: McEntityConsumerAttribute = McEntityConsumerAttribute.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aMcEntityConsumerAttributeFilter: McEntityConsumerAttributeFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aMcEntityConsumerAttributeFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): McEntityConsumerAttribute {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new McEntityConsumerAttribute();
    }
  }

  public replaceItem(newItem: McEntityConsumerAttribute) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
  // ---------------------------------------------------------------------
  public loadByEntityId( onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getByEntityId( this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }


// ---------------------------------------------------------------------
public callDevFixConsumerAttributes( onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.devFixConsumerAttributes().subscribe(
    responseJson => {
      this.setApiSuccess();
      this.processApiSuccess(responseJson, onSuccessCallback);
    },
    errorResponse => {
      this.setApiError();
      this.processApiError(errorResponse, onErrorCallback);
    });
}

}

