<div class="background" [style.background-image]="landingBg">
  <div class="registration_container">

    <!--Header-->
    <div class="registration_container_header">
      <span class="registration_container_header_logo" [style.background-image]="logo"></span>
      <div class="registration_container_header_text">
        <span class="registration_container_header_text_title">{{ "cc.entity.registration-welcome-title" | translate }}</span>
        <p>{{"cc.entity.registration-welcome-text" | translate}}</p>
      </div>
      <li class="mc-header-right-action mc-header-lang">
        <div class="lg-dropdown" ngbDropdown>
          <div class="mc-dropdown-toggle text-uppercase" id="languageDropdown" ngbDropdownToggle>
            {{selectedLanguage}}
          </div>
          <div ngbDropdownMenu class="mc-dropdown-menu mc-dropdown-language" aria-labelledby="languageDropdown">
            <a ngbDropdownItem
               class="language-link"
               *ngFor="let language of languagesList"
               (click)="onChooseLanguage(language.abbreviation)"
            >{{language.name}}</a>
          </div>
        </div>
      </li>
    </div>

    <div class="line"></div>

    <div class="form">

      <lib-mc-entity-company-info (companyInfoForm)="companyInfoForm = $event"></lib-mc-entity-company-info>

      <div class='dialog_footer'>
        <button mat-button
                class="primary-btn"
                [disabled]="!companyInfoForm"
                (click)="onRegister()"
                type="submit">
          {{ 'cc.entity.registration-register' | translate }}
        </button>
      </div>
    </div>

  </div>

</div>

