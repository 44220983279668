<ng-container *ngIf="ebFactoring.exists()">
  <div class="content-wrapper">

    <div *ngIf="ebFactoring.apiLoadingFlg">
      <img src="/assets/images/loading-whitebg.gif" />
    </div>

    <div class="content-header">
      <h1>{{'cc.contract.contract' | translate}} "{{ebFactoring.factoringContractName}}"</h1>
      <mcc-message [errorMsg]="ebFactoring.apiErrorMessage"></mcc-message>
    </div>

    <div class="content-body">
      <div class="info-data">
        <div class="row">
          <div class="col-md-12">
            <div class="section-header">
              <h2>
                {{'cc.contract.contract-details' | translate}}
              </h2>
            </div>
          </div>
          <div class="col-md-6">
            <mcc-row-title-description  [widerTitleFlg]="true"       title="{{'cc.common.view.entity' | translate}}" [description]="ebFactoring && ebFactoring.childEntity ? ebFactoring.childEntity.name : ''"></mcc-row-title-description>
            <mcc-row-title-description     [widerTitleFlg]="true"    title="{{'cc.common.view.status' | translate}}" [description]="ebFactoring.getStatusCdLabel()"></mcc-row-title-description>

            <mcc-row-title-description      [widerTitleFlg]="true"  title="{{'cc.my-factoring.contract-name' | translate}}" [description]="ebFactoring.factoringContractName"></mcc-row-title-description>
            <mcc-row-title-description      [widerTitleFlg]="true"  title="{{'cc.contract.contract-type' | translate}}" [description]="ebFactoring.factoringContractCd === 'FACTORING' ? this.mcGod.t('cc.contract.factoring') : this.mcGod.t('cc.contracts.service-contract')"></mcc-row-title-description>
          </div>

        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="section-header">
              <h2>{{'cc.contract.general-conditions' | translate}}</h2>
            </div>
          </div>
          <div class="col-md-6">
            <div class="section-sub-header">
            <h3>{{'cc.contract.contract-validity' | translate}}</h3>
            </div>
            <mcc-row-title-description      [widerTitleFlg]="true"   title="{{'cc.contract.contract-start' | translate}}" [description]="ebFactoring.contractStartDate | date"></mcc-row-title-description>

            <mcc-row-title-description        [widerTitleFlg]="true"  title="{{'cc.contract.contract-end' | translate}}" [description]="ebFactoring.contractEndDate | date"></mcc-row-title-description>
          </div>
          <div class="col-md-6">
            <div class="section-sub-header">
            <h3>{{'cc.contract.contract-cancellation' | translate}}</h3>
            </div>
            <mcc-row-title-description        [widerTitleFlg]="true"  title="{{'cc.my-factoring.contract-cancellation-period' | translate}}" [description]="getContractCancellationPeriod()"></mcc-row-title-description>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="section-header">
              <h2>{{'cc.contract.fees' | translate}}</h2>
            </div>
          </div>
          <ng-container *ngIf="ebFactoring.factoringContractCd === 'FACTORING'">
            <div class="col-md-6">
              <mcc-row-title-description [widerTitleFlg]="true" title="{{'cc.contract.factored-transaction-fee' | translate}}" [description]="ebFactoring.factoredClaimFeePerTransaction + ' ' + '%'"></mcc-row-title-description>
            </div>
            <div class="col-md-6">
              <mcc-row-title-description [widerTitleFlg]="true" title="{{'cc.contract.non-factored-transaction-fee' | translate}}" [description]="ebFactoring.nonFactoredClaimFeePerTransaction + ' ' + '%'"></mcc-row-title-description>
            </div>
          </ng-container>

          <ng-container *ngIf="ebFactoring.factoringContractCd === 'SERVICE'">
            <div class="col-md-6">
                <mcc-row-title-description [widerTitleFlg]="true" title="{{'cc.contract.per-transaction' | translate}}" [description]="getPerTransactionFeeString()"></mcc-row-title-description>
              </div>
            <div class="col-md-6">
              <mcc-row-title-description [widerTitleFlg]="true" title="{{'cc.contract.per-batch-%-of-the-total-amount' | translate}}"
                                         [description]="(ebFactoring.feePerBatch ? ebFactoring.feePerBatch : 0) + ' ' + '%'"></mcc-row-title-description>
            </div>
          </ng-container>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="section-header">
              <h2>{{'cc.contract.limits' | translate}}</h2>
            </div>
          </div>
          <div class="col-md-6">
            <div class="section-sub-header">
              <h3>{{'cc.contract.failed-withdrawals' | translate}}</h3>
            </div>
              <mcc-row-title-description      [widerTitleFlg]="true"   [extraCssClasses]="'pb-3'"  title="{{'cc.contract.maximal-number-of-failed-withdrawals' | translate}}" [description]="ebFactoring.maximalNumberOfFailedWithdrawals"></mcc-row-title-description>
            <div class="section-sub-header">
              <h3>{{'cc.contract.import-time-limits' | translate}}</h3>
            </div>
              <mcc-row-title-description      [widerTitleFlg]="true"   title="{{'cc.contract.allowed-to-import-transactions-in-the-past' | translate}}" [description]="getMonthsAllowedPastCount()"></mcc-row-title-description>
              <mcc-row-title-description       [widerTitleFlg]="true"  title="{{'cc.contract.allowed-to-import-transactions-in-the-future' | translate}}" [description]="getMonthsAllowedFutureCount()"></mcc-row-title-description>
            </div>

          <div class="col-md-6">
            <div class="section-sub-header">
              <h3>{{'cc.contract.soft-limits' | translate}}</h3>
            </div>
              <mcc-row-title-description      [widerTitleFlg]="true"   title="{{'cc.contract.per-transaction-limit' | translate}}" [description]="format(ebFactoring.softLimitPerTransaction)"></mcc-row-title-description>
              <mcc-row-title-description       [widerTitleFlg]="true" [extraCssClasses]="'pb-3'"  title="{{'cc.contract.per-sepa-run-limit' | translate}}" [description]="format(ebFactoring.softLimitPerSepaRun)"></mcc-row-title-description>
            <div class="section-sub-header">
              <h3>{{'cc.contract.hard-limits' | translate}}</h3>
            </div>
              <mcc-row-title-description      [widerTitleFlg]="true"   title="{{'cc.contract.limit-for-current-entity' | translate}}" [description]="getHardLimitForCurrentEntityInLastDays()"></mcc-row-title-description>
              <mcc-row-title-description       [widerTitleFlg]="true"  title="{{'cc.contract.cumulative-limit-for-current-entity-and-all-sub-entities' | translate}}" [description]="getHardCumulativeLimitInLastDays()"></mcc-row-title-description>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="section-header">
              <h2>{{'cc.contracts.payout-management' | translate}}</h2>
            </div>
          </div>
          <div class="row col-md-12">
            <div class="col-md-6">
              <div class="section-sub-header">
                <h3>{{'cc.contracts.initial-payout' | translate}}</h3>
              </div>
              <ng-container *ngIf="ebFactoring.factoringContractCd === 'FACTORING'">
                <mcc-row-title-description  title="{{'cc.contracts.factored-transactions' | translate}}" [description]="ebFactoring.daysForInitialPayoutForFactoredTransactions + ' ' + mcGod.t('cc.common.day(s)')"></mcc-row-title-description>
                <mcc-row-title-description  title="{{'cc.contracts.non-factored-transactions' | translate}}" [description]="ebFactoring.daysForInitialPayoutForNonFactoredTransactions + ' ' + mcGod.t('cc.common.day(s)')"></mcc-row-title-description>
              </ng-container>
              <ng-container *ngIf="ebFactoring.factoringContractCd === 'SERVICE'">
                <mcc-row-title-description  title="{{'cc.contracts.split' | translate}}" [description]="ebFactoring.percentageForInitialPayoutNonFactoring + ' ' + '% ' + mcGod.t('cc.contracts.to-be-paid-out') + ' ' + ebFactoring.noDaysForInitialPayoutNonFactoring + ' day(s) after withdrawal'"></mcc-row-title-description>
                <mcc-row-title-description  title="{{'cc.contracts.the-rest-to-be-paid' | translate}}" [description]="ebFactoring.noDaysForTheRestToBePaidNonFactoring + ' ' + mcGod.t('cc.contracts.day-s-after-withdrawal')"></mcc-row-title-description>
              </ng-container>
            </div>
        </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="section-header">
              <h2>{{'cc.contract.blacklist-management' | translate}}</h2>
            </div>
          </div>
          <div class="row col-md-12">
            <div class="col-md-12">
              <mcc-row-title-description  title="{{'cc.contracts.send-transactions-to-blacklist' | translate}}" [description]="ebFactoring.sendClaimsToBlacklistFlg ? mcGod.t('cc.common.edit.yes') : mcGod.t('cc.common.edit.no')"></mcc-row-title-description>
            </div>
            <div class="col-md-12">
              <mcc-row-title-description  title="{{'cc.contract.remove-member-without-open-debt-from-bl' | translate}}" [description]="ebFactoring.removeMemberWithoutOpenDebtFromBlFlg ? mcGod.t('cc.common.edit.yes') : mcGod.t('cc.common.edit.no')"></mcc-row-title-description>
            </div>
            <div class="col-md-6" *ngIf="ebFactoring.sendClaimsToBlacklistFlg">
              <div class="section-sub-header">
                <h3>{{'cc.contract.soft-bounce' | translate}}</h3>
              </div>
              <mcc-row-title-description  title="{{'cc.contract.send-to-blacklist-after' | translate}}" [description]="getBlacklistTriggerSoftBounceCount()"></mcc-row-title-description>
            </div>
            <div class="col-md-6" *ngIf="ebFactoring.sendClaimsToBlacklistFlg">
              <div class="section-sub-header">
                <h3>{{'cc.contract.hard-bounce' | translate}}</h3>
              </div>
              <mcc-row-title-description title="{{'cc.contract.send-to-blacklist-after' | translate}}" [description]="getBlacklistTriggerHardBounceCount()"></mcc-row-title-description>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="section-header">
              <h2>{{'cc.common.currency' | translate}}</h2>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <mcc-row-title-description  title="{{'cc.common.currency' | translate}}" [description]="currencyName"></mcc-row-title-description>

        </div>

      </div>
    </div>

    <div class="content-footer" *ngIf="statusOutPendingMcb.value">
      <button class="btn btn-secondary move-right" (click)="actCancelContract()">{{'cc.common.close' | translate}}</button>
      <button class="btn btn-primary" (click)="showRevokeContractDialogMcb.setTrue()">{{'cc.factoring.revoke' | translate}}</button>
    </div>

    <div class="content-footer" *ngIf="statusNotMyPendingMcb.value || statusNotOutPendingMcb.value">
      <button class="btn btn-secondary move-right" (click)="actCancelContract()">{{'cc.common.close' | translate}}</button>
    </div>

    <div class="content-footer" *ngIf="statusMyPendingMcb.value">
      <button class="btn btn-secondary move-left" (click)="actCancelContract()">{{'cc.common.close' | translate}}</button>
      <button class="btn btn-secondary move-right" (click)="actRejectContract()">{{'cc.factoring.reject' | translate}}</button>
      <button class="btn btn-primary" (click)="openAcceptContractDialog()">{{'cc.factoring.accept' | translate}}</button>
    </div>

    <mcc-modal1-wrapper [showMcb]="showRejectMcb" (eventCanceled)="onRejectModalCanceled()" modalSizeCd="small">
      <app-shared-factoring-reject-contract-part [ebFactoring]="ebFactoring" (eventRejected)="onContractRejected()"
                                                 (eventCancel)="onRejectModalCanceled()"></app-shared-factoring-reject-contract-part>
    </mcc-modal1-wrapper>

    <mcc-dialog-box *ngIf="showRevokeContractDialogMcb.value" [enableCloseButtonFlg]="false"
                    title="{{'cc.out-factoring.revoke-contract' | translate}}" name="revoke-contract"
                    description="{{'cc.out-factoring.are-you-sure-you-want-to-revoke-this-contract' | translate}}?"
                    [buttonsKvm]="revokeDialogButtonsKvm"
                    popupType="question"
                    (eventActionButtonClicked)="onMcDialogBoxRevokeContractActionButtonClicked($event)"></mcc-dialog-box>

  </div>
</ng-container>
<router-outlet></router-outlet>
