import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {IMcEntityAdditionalData} from '../_generated/mc-entity-additional-data.interface';
import {McEntityAdditionalData} from '../models/mc-entity-additional-data.model';
import {McEntityAdditionalDataList} from '../models/mc-entity-additional-data-list.model';
import {McEntityAdditionalDataServiceGENERATED} from '../_generated/mc-entity-additional-data-generated.service';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class McEntityAdditionalDataService extends McEntityAdditionalDataServiceGENERATED {

}
