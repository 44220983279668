import {EmailConfigKeyFilterGENERATED} from '../_generated/email-config-key-filter-generated.model';

export class EmailConfigKeyFilter extends EmailConfigKeyFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new EmailConfigKeyFilter();
  }

  // ---------------------------------------------------------------------
}
