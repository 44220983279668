/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {EbFactoring} from '../models/eb-factoring.model';
import {EbFactoringList} from '../models/eb-factoring-list.model';
import {EbFactoringFilter} from '../models/eb-factoring-filter.model';
import {EbFactoringService} from '../services/eb-factoring.service';
import {IEbFactoring} from '../_generated/eb-factoring.interface';
import {McGod} from "../extra/mc-god.service";

export class EbFactoringListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'amountPaidAfterWithdrawalProcessingDays', 'blacklistTriggerHardBounceCount', 'blacklistTriggerSoftBounceCount', 'contractCancellationPeriod', 'contractEndDate', 'contractRejectionReason', 'contractStartDate', 'createdBy', 'daysForInitialPayoutForFactoredTransactions', 'daysForInitialPayoutForNonFactoredTransactions', 'delayDaysForOverLimit', 'factoredClaimFeePerTransaction', 'factoringContractCd', 'factoringContractName', 'feePerBatch', 'feePerTransaction', 'feePerTransactionTypeCd', 'hardCumulativeLimit', 'hardCumulativeLimitDays', 'hardLimitForCurrentEntity', 'hardLimitForCurrentEntityDays', 'idChildMcEntity', 'idMcCurrency', 'idParentMcEntity', 'idWebFile', 'limitPerConsumer', 'limitPerEntityTree', 'maximalNumberOfFailedWithdrawals', 'monthsAllowedFutureCount', 'monthsAllowedPastCount', 'noDaysForInitialPayoutNonFactoring', 'noDaysForTheRestToBePaidNonFactoring', 'nonFactoredClaimFeePerTransaction', 'percentageForInitialPayoutNonFactoring', 'removeMemberWithoutOpenDebtFromBlFlg', 'returnedTransactionFee', 'sendClaimsToBlacklistFlg', 'sendDirectToInkassoFlg', 'softLimitPerSepaRun', 'softLimitPerTransaction', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'totalMonthlyLimitPerEntity', 'typeCd', 'updatedBy', 'version', 'childEntity', 'mcCurrency'];
  public items: EbFactoring[] = [];
  public apiService: EbFactoringService;
 // protected tempObjList: EbFactoringList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): EbFactoringList {
    console.error('MTCN-ERROR: Not Implemented: EbFactoringList::createNewInstance(). Add this method to final class and return new EbFactoringList();');
    throw new Error('Cannot EbFactoringList::createNewInstance(). Add this method to final class and return new EbFactoringList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IEbFactoring[]): EbFactoringList {
    const  listObj: EbFactoringList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: EbFactoring = EbFactoring.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): EbFactoringList {
    const  listObj: EbFactoringList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EbFactoringService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IEbFactoring[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: EbFactoring = EbFactoring.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aEbFactoringFilter: EbFactoringFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aEbFactoringFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): EbFactoring {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new EbFactoring();
    }
  }

  public replaceItem(newItem: EbFactoring) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
  // ---------------------------------------------------------------------
  public loadByChildEntityId( idChildMcEntity: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getByChildEntityId(idChildMcEntity, this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

  // ---------------------------------------------------------------------
  public loadByParentEntityId( idParentMcEntity: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    this.setApiLoading();
    this.apiService.getByParentEntityId(idParentMcEntity, this.pageNum, this.pageItemsPerPageCount, this.sortCriteria)
    .subscribe(responseJson => {
      this.setApiSuccess();
      this.populateFromJsonPageable(responseJson);
      this.processPostLoad();
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.setApiError();
        this.processApiError(errorResponse, onErrorCallback);
      });

  }

}

