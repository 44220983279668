import { ImDataImportModule } from './modules/im-data-import/im-data-import.module';
import { McEbicsModule } from './modules/mc-ebics/mc-ebics.module';
import { McMyBillingModule } from './modules/mc-my-billing/mc-my-billing.module';
import { McPricelistModule } from './modules/mc-pricelist/mc-pricelist.module';
import { McCountryModule } from './modules/mc-country/mc-country.module';
import { McOutBillingModule } from './modules/mc-out-billing/mc-out-billing.module';
import { McUserModule } from './modules/mc-user/mc-user.module';
import { McProcessModule } from './modules/mc-process/mc-process.module';
import { McDevModule } from './modules/mc-dev/mc-dev.module';
import { AppSharedModule } from './modules/app-shared/app-shared.module';
import { McBankModuleModule } from './modules/mc-bank-module/mc-bank-module.module';
import { McMyFactoringModule } from './modules/mc-my-factoring/mc-my-factoring.module';
import { McOutFactoringModule } from './modules/mc-out-factoring/mc-out-factoring.module';
import { McProductModule } from './modules/mc-product/mc-product.module';
import { McConsumerModule } from './modules/mc-consumer/mc-consumer.module';
import { NgModule } from '@angular/core';
import {McCoreUiRouterModule} from './mc-core-ui-router.module';
import {McComponentsModule} from '@miticon-ui/mc-components';
import {McLoginModuleModule} from './modules/mc-login/mc-login-module.module';
import {McAdminModule} from './modules/mc-admin/mc-admin.module';
import {McDashboardModule} from './modules/mc-dashboard/mc-dashboard.module';
import {McCoreUiComponent} from './mc-core-ui.component';
import {McManagementModule} from './modules/mc-management/mc-management.module';
import {McProfileUserModule} from './modules/mc-profile-user/mc-profile-user.module';
import {AuthGuard} from '@miticon-ui/mc-core';
import { McSchedulerModule } from './modules/mc-scheduler/mc-scheduler.module';
import {TranslateModule} from '@ngx-translate/core';
import {McBankingModuleModule} from './modules/mc-banking-module/mc-banking-module.module';
import {PmTransactionsModule} from './modules/pm-transactions/pm-transactions.module';
import {
  ConsumerConfirmationContractComponent
} from './modules/mc-consumer/pages/consumer-confirmation-contract/consumer-confirmation-contract.component';
import { ConsumerMollieSuccessfulMessageComponent } from './modules/mc-consumer/pages/consumer-mollie-successful-message/consumer-mollie-successful-message.component';
import {CommonModule} from '@angular/common';
import { PbEntityRegistrationModule } from './modules/pb-entity-registration/pb-entity-registration.module';

@NgModule({
  declarations: [
    McCoreUiComponent,
    ConsumerConfirmationContractComponent,
    ConsumerMollieSuccessfulMessageComponent
  ],
  imports: [
ImDataImportModule,
    McEbicsModule,
    McMyBillingModule,
    McPricelistModule,
    McCountryModule,
    McOutBillingModule,
    McUserModule,
    McProcessModule,
    McDevModule,
    AppSharedModule,
    McBankModuleModule,
    McMyFactoringModule,
    McOutFactoringModule,
    McProductModule,
    McConsumerModule,
    McCoreUiRouterModule,
    McComponentsModule,
    McDashboardModule,
    McAdminModule,
    McManagementModule,
    McProfileUserModule,
    McLoginModuleModule,
    McSchedulerModule,
    TranslateModule,
    PbEntityRegistrationModule,
    McBankingModuleModule,
    PmTransactionsModule,
    CommonModule,
  ],
  exports: [
ImDataImportModule,
McEbicsModule,
McMyBillingModule,
McPricelistModule,
McCountryModule,
    McOutBillingModule,
McUserModule,
McProcessModule,
McDevModule,
AppSharedModule,
McBankModuleModule,
McMyFactoringModule,
McOutFactoringModule,
McProductModule,
McConsumerModule,
    McCoreUiRouterModule,
    McLoginModuleModule,
    McAdminModule,
    McCoreUiComponent,
    McManagementModule,
    McProfileUserModule,
    McSchedulerModule,
    McBankingModuleModule,
    PbEntityRegistrationModule,
    PmTransactionsModule,
    ConsumerConfirmationContractComponent,
    ConsumerMollieSuccessfulMessageComponent,
    // OverlayModule
  ],
  providers: [AuthGuard]

})
export class McCoreUiModule { }
