
import {Table3Config} from '../extra/table-3-config';

// import {McEntityAdditionalDataGENERATED} from '../_generated/mc-entity-additional-data-generated.model';

export class McEntityAdditionalDataTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colAccountingMattersEmail', 'Accounting Matters Email', 'accountingMattersEmail', 'accountingMattersEmail');
    this.addColumn('colBillingEmail', 'Billing Email', 'billingEmail', 'billingEmail');
    this.addColumn('colManagementEmail', 'Management Email', 'managementEmail', 'managementEmail');
    this.addColumn('colPriceListRef', 'Price List Reference', 'priceListRef', 'priceListRef');
    this.addColumn('colTermsAndConditionsRef', 'TermsAnd Conditions Reference', 'termsAndConditionsRef', 'termsAndConditionsRef');

*/
  }
}
