<div class="valign-container full-height mc-setup-area" [ngStyle]="{'background-image': 'url(' + welcomeImg + ')', 'background-repeat': 'no-repeat', 'min-height': '100%',
'min-width': '1024px', 'width': '100%', 'height': 'auto'}">
  <div class="container">
    <div class="row">
        <div class="card mc-login-card">
          <div class="card-header mc-login-header forgot-password-label">
            <img [src]="logo" alt="cc_logo">
            <h3 *ngIf="!isReset">Welcome to portal. Please enter initial password in order to
              proceed.</h3>
            <h3 *ngIf="isReset">Reset your password</h3>
          </div>
          <div class="card-body mc-login-card-body">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="mc-tabset">
              <li [ngbNavItem]="1" id="enterPassword" class="tab-title">
                <ng-template ngbNavContent>
                  <mc-notify-message *ngIf="responseMessage" [message]="message" [type]="type"></mc-notify-message>
                  <div class="row">
                    <form [formGroup]="enterPassForm">
                      <div class="row">
                        <div class="col-md-8 mc-divider mc-login-password-fields">
                          <div class="form-group mc-input-password mr-large">
                            <label for="password"></label>
                            <input [type]="seePassword ? 'text' : 'password'"
                                   name="password"
                                   class="form-control"
                                   [ngClass]="inputFieldsError ? 'mc-error-input-wrapper' : ''"
                                   id="password"
                                   formControlName="setPassword"
                                   placeholder="Enter password"
                                   required/>
                            <mc-see-password (seePasswordOnEyeBtn)="onChangeSeePassword($event, 1)"></mc-see-password>
                          </div>
                          <div class="form-group mc-input-password mr-large">
                            <label for="checkPassword"></label>
                            <input [type]="seeRepeatedPassword ? 'text' : 'password'"
                                   class="form-control"
                                   [ngClass]="inputFieldsError ? 'mc-error-input-wrapper' : ''"
                                   id="checkPassword"
                                   name="password"
                                   placeholder="Repeat password"
                                   formControlName="checkPassword"
                                   required/>
                            <mc-see-password (seePasswordOnEyeBtn)="onChangeSeePassword($event, 2)"></mc-see-password>
                          </div>
                        </div>
                        <div class="col-md-4 mc-password-policy">
                          <p>Password policy</p>
                          <div class="mc-password-policy-requirements">
                            <div class="form-group">
                              <label class="custom-control fill-checkbox">
                                <input type="checkbox" class="fill-control-input" disabled [checked]="ifMinChars"/>
                                <span class="fill-control-indicator"></span>
                                <span class="fill-control-description">Min. characters: <strong>{{minChars}}</strong></span>
                              </label>
                            </div>
                            <div class="form-group">
                              <label class="custom-control fill-checkbox">
                                <input type="checkbox" class="fill-control-input" disabled [checked]="checkDiffChars"/>
                                <span class="fill-control-indicator"></span>
                                <span
                                  class="fill-control-description">Min. different characters: <strong>{{noOfDiffChar}}</strong></span>
                              </label>
                            </div>
                            <div class="form-group">
                              <label class="custom-control fill-checkbox">
                                <input type="checkbox" class="fill-control-input" disabled [checked]="checkDigits"/>
                                <span class="fill-control-indicator"></span>
                                <span
                                  class="fill-control-description">min. digits: <strong>{{noOfDigits}}</strong></span>
                              </label>
                            </div>
                            <div class="form-group">
                              <label class="custom-control fill-checkbox">
                                <input type="checkbox" class="fill-control-input" disabled
                                       [checked]="checkSpecialChars"/>
                                <span class="fill-control-indicator"></span>
                                <span
                                  class="fill-control-description">Min. special characters: <strong>{{noOfSpecialChars}}</strong></span>
                              </label>
                            </div>
                            <div class="form-group">
                              <label class="custom-control fill-checkbox">
                                <!--                                  <input type="checkbox" disabled [checked]="checkLowercaseLetters"/>-->
                                <input type="checkbox" class="fill-control-input" disabled
                                       [checked]="checkLowercaseLetters"/>
                                <span class="fill-control-indicator"></span>
                                <span
                                  class="fill-control-description">Min. lowercase letters: <strong>{{noOfLowercaseChars}}</strong></span>
                              </label>
                            </div>
                            <div class="form-group">
                              <label class="custom-control fill-checkbox">
                                <!--                                  <input type="checkbox" disabled [checked]="checkUppercaseLetters"/>-->
                                <input type="checkbox" class="fill-control-input" disabled
                                       [checked]="checkUppercaseLetters"/>
                                <span class="fill-control-indicator"></span>
                                <span
                                  class="fill-control-description">Min. uppercase letters: <strong>{{noOfUppercaseChars}}</strong></span>
                              </label>
                            </div>

                          </div>

                        </div>
                        <div class="col-md-12 text-center mc-login-btn-area setup-login-btn">
                          <button mat-button
                                  class="primary-btn"
                                  type="submit"
                                  [disabled]="!ifPasswordValid"
                                  (click)="saveAccount(1)">
                            {{ isReset ? "Confirm & log in" : ('cc.common.view.log-in' | translate)}}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </ng-template>
              </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
          </div>
        </div>
    </div>
  </div>
</div>
<!--Loader-->
<lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>
