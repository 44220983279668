import {Component, EventEmitter, Inject, Input, OnInit, Output} from "@angular/core";
import {
  McAuthService,
  McForm, McProcessFlow, McProcessFlowCriteria,
  McProcessFlowCriteriaAttribute,
  McProcessFlowCriteriaAttributeList,
  McProcessFlowCriteriaList,
  McValueLabelList
} from "@miticon-ui/mc-core";

@Component({
  selector: "mc-process-process-flow-criteria-edit",
  templateUrl: "./mc-process-process-flow-criteria-edit.component.html",
})
export class McProcessProcessFlowCriteriaEditComponent implements OnInit {

  mcForm = new McForm();
  mcProcessFlowCriteriaList!: McProcessFlowCriteriaList;
  @Input() mcProcessFlow!: McProcessFlow;
  attributesVll = new McValueLabelList();
  mcProcessFlowCriteriaAttributeList = new McProcessFlowCriteriaAttributeList();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventSaved = new EventEmitter();
  criteriaId = -1;
  errorMsg = '';

  /*Constructor*/
  /*  constructor(private authService: McAuthService,
                @Inject('designConfig') design) {

       this.welcomeImg = design.welcomeImg;
      this.logo = design.logo;
    }*/

  /*On init*/
  ngOnInit() {
    this.mcProcessFlowCriteriaList = this.mcProcessFlow.flowCriteriaList;
    this.mcProcessFlowCriteriaAttributeList.loadAll(() => {
      this.attributesVll = this.mcProcessFlowCriteriaAttributeList.toVll("attributeName", "attributeName");
      this.attributesVll.add('currency', 'currency');
      this.attributesVll.removeItemByValue('[Transaction] status_cd');
    });
  }

  actSave() {
    if (this.mcForm.isValid()) {

      this.mcProcessFlowCriteriaList.items.forEach(item => {
        item.idMcProcessFlow = this.mcProcessFlow.id;
      });
      this.mcProcessFlowCriteriaList.items.forEach(item => {
        item.save();
      });
      this.mcProcessFlow.flowCriteriaList = this.mcProcessFlowCriteriaList;
      this.eventSaved.emit();
    }
  }

  actCancel() {
    this.eventCanceled.emit();
  }
  // ---------------------------------------------------------------------
  addCriteria() {
    const criteria = new McProcessFlowCriteria();
    this.mcProcessFlowCriteriaList.items.push(criteria);

  }

  removeCriteria(index: number, id: number) {
    this.mcProcessFlowCriteriaList.items.forEach(item => {
      if (item.id === id) {
        item.delete();
      }
    });
    this.mcProcessFlowCriteriaList.items.splice(index, 1);
  }
}
