/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McEntityAdditionalDataFilter} from '../models/mc-entity-additional-data-filter.model';

export class McEntityAdditionalDataFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McEntityAdditionalDataFilter {
    console.error('MTCN-ERROR: Not Implemented: McEntityAdditionalDataFilter::createNewInstance(). Add this method to final class and return new McEntityAdditionalDataFilter();');
      throw new Error('Cannot McEntityAdditionalDataFilter::createNewInstance(). Add this method to final class and return new McEntityAdditionalDataFilter();');
  }

}
