/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {EntityInvitation} from '../models/entity-invitation.model';
import {EntityInvitationService} from '../services/entity-invitation.service';
import {IEntityInvitation} from '../_generated/entity-invitation.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class EntityInvitationGENERATED extends BaseObject {


    public static readonly STATUS_PENDING: string = 'PENDING';
    public static readonly STATUS_USED: string = 'USED';
    public static readonly STATUS_EXPIRED: string = 'EXPIRED';
    public static readonly STATUS_CANCELED: string = 'CANCELED';
  public static readonly STATUS_LIST__ALL = [
    EntityInvitationGENERATED.STATUS_PENDING,
    EntityInvitationGENERATED.STATUS_USED,
    EntityInvitationGENERATED.STATUS_EXPIRED,
    EntityInvitationGENERATED.STATUS_CANCELED
];

  public apiService: EntityInvitationService;
  public _rawJson: IEntityInvitation;
    id: number = 0;
  createdAt: number;
  email = '';
  idMcEntity: number;
  invitationCode = '';
  invitedEntityId: number;
  status: string;
  usedAt: number;


  public properties: string[] = ['id', 'createdAt', 'email', 'idMcEntity', 'invitationCode', 'invitedEntityId', 'status', 'usedAt'];
  public propertiesRegular: string[] = ['id', 'createdAt', 'email', 'idMcEntity', 'invitationCode', 'invitedEntityId', 'status', 'usedAt'];
  public propertiesSpecial: string[] = [];



  public static getStatusVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(EntityInvitationGENERATED.STATUS_PENDING, McGod.t('Pending'));
    list.add(EntityInvitationGENERATED.STATUS_USED, McGod.t('Used'));
    list.add(EntityInvitationGENERATED.STATUS_EXPIRED, McGod.t('Expired'));
    list.add(EntityInvitationGENERATED.STATUS_CANCELED, McGod.t('Canceled'));


    return list;
  }

  public static getStatusLabel(aCd: string): string {
    const list = EntityInvitationGENERATED.getStatusVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): EntityInvitation {
    console.error('MTCN-ERROR: Not Implemented: EntityInvitation::createNewInstance(). Add this method to final class and return new EntityInvitation();');
    throw new Error('Cannot EntityInvitation::createNewInstance(). Add this method to final class and return new EntityInvitation();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IEntityInvitation): EntityInvitation {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EntityInvitationService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE EntityInvitation AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getCreatedAtStrD(): string {
       return McDateUtils.formatDateToString(this.createdAt);
    }

    public getCreatedAtStr(): string {
       return McDateUtils.formatDateTimeToString(this.createdAt);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getStatusLabel(): string {
    return EntityInvitationGENERATED.getStatusLabel(this.status);
  }


 public  isStatusPENDING(): boolean {
    const result = (this.status === EntityInvitationGENERATED.STATUS_PENDING);

    return result;
  }

 public  isStatusUSED(): boolean {
    const result = (this.status === EntityInvitationGENERATED.STATUS_USED);

    return result;
  }

 public  isStatusEXPIRED(): boolean {
    const result = (this.status === EntityInvitationGENERATED.STATUS_EXPIRED);

    return result;
  }

 public  isStatusCANCELED(): boolean {
    const result = (this.status === EntityInvitationGENERATED.STATUS_CANCELED);

    return result;
  }


  
}
