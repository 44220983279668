<ng-container class="dialog">
  <div class="dialog_header">
    <span class="dialog_header_title text-uppercase">
      {{ "cc.entity.invitation" | translate }}
    </span>
    <span class="dialog_header_icon"
          [innerHTML]="iconClose | safeHtml"
          (click)="dialogRef.close(false)"></span>
  </div>

  <mat-dialog-content>
    <form [formGroup]="form">

      <div class="config mt-medium">{{ "cc.entity.invitation-email" | translate }}</div>
      <div class="form_description">
        <mat-form-field appearance="outline">
          <input type="email"
                 [placeholder]="'cc.common.edit.email' | translate"
                 formControlName="email"
                 matInput/>
        </mat-form-field>
        <button mat-button
                class="primary-btn"
                type="submit"
                (click)="sendInvitation()">{{ "cc.common.view.send" | translate }}</button>
      </div>
      <div class="config_item">{{ "cc.entity.invitation-email-text" | translate }}</div>

      <div class="card info_message" *ngIf="infoMessage && infoIcon">
        <span [innerHTML]="infoIcon | safeHtml"></span>
        <p class="info_message_text">{{ infoMessage | translate }}</p>
      </div>

      <div class="line mt-large"></div>

      <div class="config mt-medium">{{ "cc.entity.invitation-code" | translate }}</div>
      <div class="form_description">
        <mat-form-field appearance="outline">
          <input type="text"
                 [placeholder]="'cc.entity.invitation-code' | translate"
                 formControlName="code"
                 matInput/>
        </mat-form-field>
        <button mat-button
                class="primary-btn"
                type="submit"
                (click)="generateInvitationCode()"
        >{{ "cc.entity.generate" | translate }}</button>

      </div>

      <div class="copy_content" (click)="copyToClipboard()">
        <span [innerHTML]="iconCopy | safeHtml"></span>
        <div class="copy_content_text">{{ copyToClipboardMsg | translate }}</div>
      </div>

      <div class="config_item">{{ "cc.entity.invitation-code-text" | translate }}</div>

    </form>
  </mat-dialog-content>

  <div class='dialog_footer'>
    <button mat-button
            class="secondary-btn"
            (click)="dialogRef.close(false)">
      {{ 'cc.common.close' | translate }}
    </button>
  </div>
</ng-container>
