/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {EmailTemplateTable3ConfigGENERATED} from '../_generated/email-template-table-3-config-generated.model';

// import {EmailTemplateGENERATED} from '../_generated/email-template-generated.model';

export class EmailTemplateTable3Config extends EmailTemplateTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colIdEmailConfig', 'Email config ID', 'idEmailConfig', 'idEmailConfig');
    this.addColumn('colIdMcEntity', 'Entity ID', 'idMcEntity', 'idMcEntity');
    this.addColumn('colLanguageCode', 'Language code', 'languageCode', 'languageCode');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');
    this.addColumn('colSysUpdatedDatetime', 'System Updated Datetime', 'sysUpdatedDatetime', 'sysUpdatedDatetime');
    this.addColumn('colWebFile', 'Web file email', 'webFile', 'webFile');
    this.addColumn('colWebFileId', 'Email file ID', 'webFileId', 'webFileId');


  }
}
