import {EntityInvitationListGENERATED} from '../_generated/entity-invitation-list-generated.model';

export class EntityInvitationList extends EntityInvitationListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new EntityInvitationList();
  }

  // ---------------------------------------------------------------------
}
