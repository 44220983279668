import { Component } from '@angular/core';

@Component({
  selector: 'lib-mc-entity-bank-accounts-tab',
  templateUrl: './mc-entity-bank-accounts-tab.component.html',
  styleUrl: './mc-entity-bank-accounts-tab.component.scss'
})
export class McEntityBankAccountsTabComponent {

}
