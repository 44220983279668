import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MccFiFileUploadModule,McComponentsModule,McFilterModule,McTableModule } from '@miticon-ui/mc-components';
import { SafeHtmlModule } from '../../assets/pipes/safe-html/safe-html.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { AppSharedModule } from '../app-shared/app-shared.module';
import { McConsumerModule } from '../mc-consumer/mc-consumer.module';
import { McManagementModule } from '../mc-management/mc-management.module';
import { McMyFactoringImportSepaFileComponent } from './components/mc-my-factoring-import-sepa-file/mc-my-factoring-import-sepa-file.component';
import { McMyFactoringReportingComponent } from './components/mc-my-factoring-reporting/mc-my-factoring-reporting.component';
import { McMyFactoringRoutingModule } from './mc-my-factoring-routing.module';
import { McMyFactoringContractListPageComponent } from './pages/mc-my-factoring-contract-list-page/mc-my-factoring-contract-list-page.component';
import { McMyFactoringSepaInListPageComponent } from './pages/mc-my-factoring-sepa-in-list-page/mc-my-factoring-sepa-in-list-page.component';
import { McMyFactoringTransactionListPageComponent } from './pages/mc-my-factoring-transaction-list-page/mc-my-factoring-transaction-list-page.component';
import { McMyFactoringViewContactComponent } from './pages/mc-my-factoring-view-contact/mc-my-factoring-view-contact.component';

@NgModule({
  declarations: [
    McMyFactoringImportSepaFileComponent,
    McMyFactoringReportingComponent,
    McMyFactoringTransactionListPageComponent,
    McMyFactoringSepaInListPageComponent,
    McMyFactoringContractListPageComponent,
    McMyFactoringViewContactComponent,
  ],
  imports: [
    CommonModule,
    McMyFactoringRoutingModule,
    NgbModule,
    McComponentsModule,
    McFilterModule,
    McTableModule,
    FormsModule,
    ReactiveFormsModule,
    McManagementModule,
    AppSharedModule,
    MccFiFileUploadModule,
    TranslateModule,
    McConsumerModule,
    McConsumerModule,
    SafeHtmlModule,
  ],
  exports: [
    McMyFactoringImportSepaFileComponent,
    McMyFactoringReportingComponent,
    McMyFactoringTransactionListPageComponent,
    McMyFactoringSepaInListPageComponent,
    McMyFactoringContractListPageComponent,
    McMyFactoringRoutingModule,
    FormsModule,
    McMyFactoringViewContactComponent,
  ],
})
export class McMyFactoringModule {}
