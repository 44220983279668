<ng-container class="dialog">
  <div class="dialog_header">
      <span class="dialog_header_title text-uppercase">
        {{ 'cc.consumer360.cancel-contract' | translate }}
      </span>
      <span class="dialog_header_icon"
            [innerHTML]="iconClose | safeHtml"
            (click)="dialogRef.close(false)">
      </span>
  </div>

  <div class="card cancel-contract-info">
    <span [innerHTML]="iconAttribute | safeHtml" class="dialog-icon"></span>
    <ng-container *ngIf="!hasExistingCancellation; else editTemplate">
    <span class="dialog_content">
      {{ "cc.consumer360.cancel-contract-text" | translate }}
    </span>
    </ng-container>
    <ng-template #editTemplate>
    <span class="dialog_content">
      {{ 'cc.contract.cancelled-on' | translate }} {{ contract.contractCancellationActiveOnDate }}.
      <span>{{ 'cc.contract.edit-cancellation-text' | translate }}</span>
    </span>
    </ng-template>
  </div>

  <mat-dialog-content>
    <form [formGroup]="form">
      <div class="form_fields">
        <mat-form-field appearance="outline">
          <mat-label>{{'cc.common.cancellation-active-on-date' | translate}}</mat-label>
          <input matInput [matDatepicker]="cancellationDate" formControlName='cancellationDate'
                (click)='cancellationDate.open()' readonly
                 [min]="minDate">
          <mat-datepicker-toggle matSuffix [for]="cancellationDate"></mat-datepicker-toggle>
          <mat-datepicker #cancellationDate></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="form_description">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'cc.common.cancellation-reason' | translate }}</mat-label>
          <input type="text"
                 formControlName="reason"
                 matInput/>
        </mat-form-field>
      </div>
      <div *ngIf="hasExistingCancellation" class="config_item mt-large">{{ "cc.contract.save-cancellation-text" | translate }}</div>
    </form>
  </mat-dialog-content>

  <div class='dialog_footer'>
    <button mat-button class="secondary-btn"
              (click)="dialogRef.close(false)">
        {{ 'cc.common.edit.cancel' | translate }}
    </button>
    <button mat-button class="primary-btn"
              [disabled]="!form.dirty || form.invalid"
              (click)="cancelContract()">
        {{ 'cc.common.edit.confirm' | translate }}
    </button>
  </div>
</ng-container>
