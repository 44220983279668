import {McEntityAdditionalDataFilterGENERATED} from '../_generated/mc-entity-additional-data-filter-generated.model';

export class McEntityAdditionalDataFilter extends McEntityAdditionalDataFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new McEntityAdditionalDataFilter();
  }

  // ---------------------------------------------------------------------
}
