/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {EmailConfigKey} from '../models/email-config-key.model';
import {EmailConfigKeyService} from '../services/email-config-key.service';
import {IEmailConfigKey} from '../_generated/email-config-key.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class EmailConfigKeyGENERATED extends BaseObject {


  public apiService: EmailConfigKeyService;
  public _rawJson: IEmailConfigKey;
    id: number = 0;
  key = '';
  name = '';
  requiredFlg: boolean;


  public properties: string[] = ['id', 'key', 'name', 'requiredFlg'];
  public propertiesRegular: string[] = ['id', 'key', 'name', 'requiredFlg'];
  public propertiesSpecial: string[] = [];


  // ---------------------------------------------------------------------
public static createNewInstance(): EmailConfigKey {
    console.error('MTCN-ERROR: Not Implemented: EmailConfigKey::createNewInstance(). Add this method to final class and return new EmailConfigKey();');
    throw new Error('Cannot EmailConfigKey::createNewInstance(). Add this method to final class and return new EmailConfigKey();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IEmailConfigKey): EmailConfigKey {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EmailConfigKeyService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE EmailConfigKey AABB()');
    
  }

  // ---------------------------------------------------------------------
  

  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  
}
