import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { CsrContract, EbFactoring, EbSepaExport, McBillingPayoutFileFeCtd, EbPaymentReport, McGod, McProcess, PpOrder, EntityInvitation } from '@miticon-ui/mc-core';
import {MatMenuTrigger} from "@angular/material/menu";

export interface MkMatMenuItem {
  disabled?: boolean;
  matIcon: string;
  title: string;
  actionCd: string;
  permission: string,
}

@Component({
  selector: 'mk-mat-table-actions',
  templateUrl: './mk-mat-table-actions.component.html',
  styleUrls: ['./mk-mat-table-actions.component.scss']
})
export class MkMatTableActionsComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  @Input() matMenuItems: MkMatMenuItem[];
  @Input() element: any;
  @Output() menuItemAction = new EventEmitter<MkMatMenuItem>();
  mcGod = McGod.getInstance();


  constructor() {
  }


  ngOnInit() {
  }

  isHidden(item: MkMatMenuItem) {
    return this.isProcessMenuItemHidden(item) ||
      this.isSepaExportMenuItemHidden(item) ||
      this.isPayoutMenuItemHidden(item) ||
      this.isEbPaymentReportMenuItemHidden(item) ||
      this.areInvoiceOptionsHidden(item);
  }

  isProcessMenuItemHidden(item: MkMatMenuItem) {
    const actionMap = new Map([
      [McProcess.STATUS_CD_ACTIVE, McProcess.ACTION_ACTIVATE],
      [McProcess.STATUS_CD_INACTIVE, McProcess.ACTION_DEACTIVATE],
      [McProcess.TYPE_CD_NOTIFICATIONS, McProcess.ACTION_SHOW_ITEM_LIST],
      [McProcess.TYPE_CD_DUNNING || McProcess.TYPE_CD_MARKETING, McProcess.ACTION_SHOW_EMAIL]
    ]);

    const isMatch = (key: string, action: string) => actionMap.get(key) === action;

    if (this.element instanceof McProcess) {
      const isProcessActive = isMatch(this.element.statusCd, item.actionCd);
      const isProcessInactive = isMatch(this.element.statusCd, item.actionCd);
      const isNotification = isMatch(this.element.typeCd, item.actionCd);
      const isDunningOrMarketing = isMatch(this.element.typeCd, item.actionCd);

      return isProcessActive || isProcessInactive || isNotification || isDunningOrMarketing;
    }

    return false;
  }

  isSepaExportMenuItemHidden(item: MkMatMenuItem) {
    return this.element instanceof EbSepaExport &&
      (this.element.statusCd === EbSepaExport.STATUS_CD_SENT_TO_BANK ||
      this.element.statusCd === EbSepaExport.STATUS_CD_FAILED) &&
      item.actionCd === EbSepaExport.ACTION_SEND_VIA_EBICS;
  }

  isPayoutMenuItemHidden(item: MkMatMenuItem): boolean {
    const isSentToBank =
      (this.element.statusCd === McBillingPayoutFileFeCtd.STATUS_CD_SENT_TO_BANK ||
        this.element.statusCd === McBillingPayoutFileFeCtd.STATUS_CD_FAILED) &&
      item.actionCd === McBillingPayoutFileFeCtd.ACTION_SEND_VIA_EBICS;

    const isRefundAction =
      this.element.payoutType === McBillingPayoutFileFeCtd.PAYOUT_TYPE_REFUND_PAYMENT  &&
      (item.actionCd === McBillingPayoutFileFeCtd.ACTION_VIEW ||
        item.actionCd === McBillingPayoutFileFeCtd.ACTION_UPLOAD_FILE);

    const isRevertAction =
      (this.element.payoutType === McBillingPayoutFileFeCtd.PAYOUT_TYPE_BUSINESS_PAYOUT ||
      (this.element.payoutType === McBillingPayoutFileFeCtd.PAYOUT_TYPE_REFUND_PAYMENT && this.element.statusCd !== McBillingPayoutFileFeCtd.STATUS_CD_CREATED)) &&
      item.actionCd === McBillingPayoutFileFeCtd.ACTION_REVERT;

    return this.element instanceof McBillingPayoutFileFeCtd && (isSentToBank || isRefundAction || isRevertAction);
  }


  isEbPaymentReportMenuItemHidden(item: MkMatMenuItem): boolean {
    const isRefundAction = item.actionCd === EbPaymentReport.ACTION_REFUND;
    const canShowRefund =
      this.element instanceof EbPaymentReport &&
      this.element.status === EbPaymentReport.STATUS_UNMATCHED &&
      this.element.type === EbPaymentReport.TYPE_PAYMENT;

    return isRefundAction && !canShowRefund;
  }

  isDisabled(item: MkMatMenuItem) {
    if (this.isCsrContractCanceled(item) || this.isEbFactoringNotPending(item)
      || this.isInvoiceStateDraft(item) || this.isInvoiceStatusCompleted(item)
      || this.isEntityInvitationCancelAction(item) || this.isInvoiceStatusCanceled(item)
      || this.isInvoiceStatusFailed(item)) {
      return true;
    }
    return item.disabled;
  }

  private isCsrContractCanceled(item: MkMatMenuItem): boolean {
    return this.element instanceof CsrContract &&
      this.element.statusCd === CsrContract.STATUS_CD_CANCELED &&
      item.actionCd === 'Cancel';
  }

  private isEbFactoringNotPending(item: MkMatMenuItem): boolean {
    return this.element instanceof EbFactoring &&
      this.element.statusCd !== EbFactoring.STATUS_CD_PENDING_ACCEPTANCE &&
      (item.actionCd === EbFactoring.ACTION_SHOW_REVOKE ||
        item.actionCd === EbFactoring.ACTION_SHOW_REJECT ||
        item.actionCd === EbFactoring.ACTION_SHOW_ACCEPT);
  }

  private isInvoiceStateDraft(item: MkMatMenuItem): boolean {
    return this.element instanceof PpOrder &&
      this.element.state !== PpOrder.STATE_DRAFT &&
      item.actionCd === PpOrder.ACTION_SEND_INVOICE;
  }

  private isEntityInvitationCancelAction(item: MkMatMenuItem): boolean {
    return this.element instanceof EntityInvitation &&
      item.actionCd === EntityInvitation.ACTION_CANCEL &&
      this.element.status !== EntityInvitation.STATUS_PENDING
  }


  mouseEnter() {
    this.trigger.openMenu();
  }

  mouseLeave() {
    this.trigger.closeMenu();
  }

  matMenuItemClicked(item: MkMatMenuItem): void {
    this.menuItemAction.emit(item);
  }

  addMenuItem(item: MkMatMenuItem) {
    this.matMenuItems.push(item);
  }

  private isInvoiceStatusCompleted(item: MkMatMenuItem) {
    return this.element instanceof PpOrder &&
      this.element.state === PpOrder.STATE_COMPLETED &&
      (item.actionCd === PpOrder.ACTION_SEND_INVOICE
        || item.actionCd === PpOrder.ACTION_CANCEL
        || item.actionCd === PpOrder.ACTION_SHOW_EDIT
      )
  }

  private isInvoiceStatusCanceled(item: MkMatMenuItem) {
    return this.element instanceof PpOrder &&
      this.element.state === PpOrder.STATE_CANCELED;
  }

  private isInvoiceStatusFailed(item: MkMatMenuItem) {
    return this.element instanceof PpOrder &&
      this.element.state === PpOrder.STATE_FAILED &&
      (item.actionCd === PpOrder.ACTION_SHOW_PREVIEW ||
      item.actionCd === PpOrder.ACTION_SHOW_LICENSE_PREVIEW ||
      item.actionCd === PpOrder.ACTION_SHOW_EDIT);
  }

  private areInvoiceOptionsHidden(item: MkMatMenuItem) {
    return this.element instanceof PpOrder &&
      this.element.typeCd !== PpOrder.TYPE_CD_STANDARD &&
      (item.actionCd === PpOrder.ACTION_SEND_INVOICE ||
        item.actionCd === PpOrder.ACTION_SHOW_EDIT);
  }
}
