
import {Table3Config} from '../extra/table-3-config';

// import {McEntityConsumerAttributeGENERATED} from '../_generated/mc-entity-consumer-attribute-generated.model';

export class McEntityConsumerAttributeTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colArchivedFlg', 'Archived', 'archivedFlg', 'archivedFlg');
    this.addColumn('colCreationDate', 'Creation date', 'creationDate', 'creationDate');
    this.addColumn('colDataSet', 'Data set', 'dataSet', 'dataSet');
    this.addColumn('colDefaultValue', 'Default value', 'defaultValue', 'defaultValue');
    this.addColumn('colDescription', 'Description', 'description', 'description');
    this.addColumn('colEnabledFlg', 'Enabled', 'enabledFlg', 'enabledFlg');
    this.addColumn('colGroupName', 'Group name', 'groupName', 'groupName');
    this.addColumn('colIdMcEntity', 'Entity ID', 'idMcEntity', 'idMcEntity');
    this.addColumn('colInheritableFlg', 'Inheritable', 'inheritableFlg', 'inheritableFlg');
    this.addColumn('colInheritedFlg', 'Inherited', 'inheritedFlg', 'inheritedFlg');
    this.addColumn('colLabel', 'Label', 'label', 'label');
    this.addColumn('colName', 'Name', 'name', 'name');
    this.addColumn('colRequiredFlg', 'Required', 'requiredFlg', 'requiredFlg');
    this.addColumn('colTypeCd', 'Type', 'typeCd', 'typeCd');
    this.addColumn('colValidationRegex', 'Validation regex', 'validationRegex', 'validationRegex');

*/
  }
}
