import { Component, Inject, OnInit } from '@angular/core';
import { McEntityService, McForm, McUtilityService } from '@miticon-ui/mc-core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lib-mc-entity-attributes-tab',
  templateUrl: './mc-entity-attributes-tab.component.html',
  styleUrl: './mc-entity-attributes-tab.component.scss'
})
export class McEntityAttributesTabComponent implements OnInit {
  customAttributeList!: any;
  inheritedAttributeList: any[] = [];
  customAttributesMessage!: string;
  customAttributesType!: string;
  parentEntityId!: any;
  currentEntityInheritedAttributes!: any;
  showLoader!: boolean;
  attributesIcons;
  attributesInfoIcon;
  attributesWhiteInfoIcon;
  hoveredIcon!: any;
  bankAccount!: any;
  impressum!: any;
  entityId: any;
  editEntityId: any;
  entityName: any;
  userId: any;
  mcForm = new McForm();
  active = 1;
  message!: string;
  type!: string;

  /*Constructor*/
  constructor(private activatedRoute: ActivatedRoute,
    private utilityService: McUtilityService,
    private entityService: McEntityService,
    private toastr: ToastrService,
  @Inject('designConfig') design: any) {
    this.attributesIcons = design.attributesIcons;
    this.attributesInfoIcon = this.attributesIcons.info.path;
    this.attributesWhiteInfoIcon = this.attributesIcons.whiteInfo.path;
    this.userId = this.utilityService.getPropertyFromToken('user_id');

    const currentEntity = this.entityService.currentMcEntity();
    if (currentEntity) {
      this.parentEntityId = currentEntity.parentEntityId;
    }
  }

  /*On init class*/
  ngOnInit() {
    this.entityId = this.activatedRoute.snapshot.queryParamMap.get('id');
    this.parentEntityId = localStorage.getItem('parentEntityId');
    this.loadCustomAttributes();
  }

  public loadCustomAttributes(): void {
    if (!this.entityId) return;

    this.showLoader = true;
    this.entityService.getEntityAttributesById(this.entityId).subscribe({
      next: (attributes) => {
        this.customAttributeList = attributes.filter((attr: any) => !attr.inherited);
        const inheritedAttributes = attributes.filter((attr: any) => attr.inherited);

        this.currentEntityInheritedAttributes = inheritedAttributes.map((attr: any) => attr.name);

        this.inheritedAttributeList = this.parentEntityId ? inheritedAttributes.map((attr: any) => ({ ...attr, select: true })) : [];

        this.showLoader = false;
      },
      error: (error) => {
        this.showLoader = false;
        this.toastr.error(error?.error?.message || 'Failed to load attributes');
      }
    });
  }

  onSuccessEditAttributes(event: boolean) {
    if (event) {
      this.inheritedAttributeList = [];
      this.loadCustomAttributes();
    }
  }

}

