<div class="content-wrapper">

  <div class="content-header">
    <h1>{{mcActionCd === actionShowEmail ? 'Email' : mcActionCd === actionShowPost ? 'Post' : mcActionCd === actionShowSms ? 'SMS' : ''}}
      Details</h1>
  </div>

  <div class="content-body">
    <div class="info-summary-top-lite" *ngIf="mcConsumerAction.id > 0 && mcConsumer.apiSuccessFlg">
      <div class="inner-content">
        <mcc-row-title-description title="SENT TO CONSUMER:"
                                   description="{{csrConsumer.getDisplayName()}}"></mcc-row-title-description>

        <ng-container *ngIf="mcActionCd === actionShowEmail">
          <mcc-row-title-description title="EMAIL ADDRESS:"
                                     description="{{csrConsumer.email}}"></mcc-row-title-description>
        </ng-container>

        <ng-container *ngIf="mcActionCd === actionShowSms">
          <mcc-row-title-description title="PHONE NUMBER:"
                                     description="{{mcConsumer.cellphone}}"></mcc-row-title-description>
        </ng-container>

        <ng-container *ngIf="mcActionCd === actionShowPost">
          <mcc-row-title-description title="ADDRESS:"
                                     description="{{mcConsumer.addressSupplement}}"></mcc-row-title-description>
        </ng-container>

        <mcc-row-title-description title="SENT ON:"
                                   description="{{mcConsumerAction.getSentDatetimeString()}}"></mcc-row-title-description>

        <ng-container *ngIf="mcActionCd === actionShowEmail">
          <mcc-row-title-description title="SUBJECT:"
                                     description="{{mcConsumerAction.emailSubject}}"></mcc-row-title-description>
        </ng-container>

        <ng-container *ngIf="mcActionCd === actionShowEmail || mcActionCd === actionShowPost">
          <span>ATTACHMENT(S): </span>
          <button *ngIf="attachmentFile.exists()" class="mc-button-link attachment-link" (click)="actDownload()"><i class="fas fa-paperclip"></i> {{attachmentFile.originalFilename}}</button>
        </ng-container>

        <ng-container *ngIf="mcActionCd === actionShowEmail">
          <div class="email-body-emf">
            <mcc-row-title-description title="EMAIL BODY:"
                                       description="{{mcConsumerAction.emailBodyHtml}}"></mcc-row-title-description>
          </div>
        </ng-container>

        <ng-container *ngIf="mcActionCd === actionShowSms">
          <div class="email-body-emf">
            <mcc-row-title-description title="SMS BODY:"
                                       description="{{mcConsumerAction.smsBody}}"></mcc-row-title-description>
          </div>
        </ng-container>
      </div>
    </div>

    <mcc-message *ngIf="mcConsumerAction.id <= 0" infoMsg="No consumer action for this items"></mcc-message>
  </div>

  <div class="content-footer">
    <button class="btn btn-secondary move-right" (click)="actCancel()">Close</button>
  </div>

</div>
