/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {EntityInvitationTable3ConfigGENERATED} from '../_generated/entity-invitation-table-3-config-generated.model';

// import {EntityInvitationGENERATED} from '../_generated/entity-invitation-generated.model';

export class EntityInvitationTable3Config extends EntityInvitationTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colCreatedAt', 'Created at', 'createdAt', 'createdAt');
    this.addColumn('colEmail', 'Email', 'email', 'email');
    this.addColumn('colIdMcEntity', 'Entity ID', 'idMcEntity', 'idMcEntity');
    this.addColumn('colInvitationCode', 'Invitation code', 'invitationCode', 'invitationCode');
    this.addColumn('colInvitedEntityId', 'Invited entity id', 'invitedEntityId', 'invitedEntityId');
    this.addColumn('colStatus', 'Status', 'status', 'status');
    this.addColumn('colUsedAt', 'Used at', 'usedAt', 'usedAt');


  }
}
