
import {Table3Config} from '../extra/table-3-config';

// import {McCountryGENERATED} from '../_generated/mc-country-generated.model';

export class McCountryTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colCode', 'Country Code', 'code', 'code');
    this.addColumn('colEbReturnPeriod', 'Direct debit return period', 'ebReturnPeriod', 'ebReturnPeriod');
    this.addColumn('colEnabled', 'Country enabled', 'enabled', 'enabled');
    this.addColumn('colIdMcCurrency', 'Currency Id', 'idMcCurrency', 'idMcCurrency');
    this.addColumn('colLocale', 'Country Locale', 'locale', 'locale');
    this.addColumn('colMcCurrency', 'Currency', 'mcCurrency', 'mcCurrency');
    this.addColumn('colName', 'Country Name', 'name', 'name');
    this.addColumn('colPhoneCode', 'Country Phone Code', 'phoneCode', 'phoneCode');

*/
  }
}
