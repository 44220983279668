  import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA,NgModule } from '@angular/core';

import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { McComponentsModule } from '@miticon-ui/mc-components';
import { SafeHtmlModule } from '../../assets/pipes/safe-html/safe-html.module';
import { NgbDropdownModule,NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { QRCodeModule } from "angularx-qrcode";
import { McLoginRoutingModule } from './mc-login-routing.module';
import { AuthLockedComponent } from './pages/auth-locked/auth-locked.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { IncompleteProfileComponent } from './pages/incomplete-profile/incomplete-profile.component';
import { LoginComponent } from './pages/login/login.component';
import { SetupComponent } from './pages/setup/setup.component';
import { TwoFaLoginComponent } from './pages/two-fa-login/two-fa-login.component';

@NgModule({
  declarations: [LoginComponent, SetupComponent, TwoFaLoginComponent, ForgotPasswordComponent, AuthLockedComponent, IncompleteProfileComponent],
  exports: [LoginComponent, SetupComponent, McLoginRoutingModule, IncompleteProfileComponent],
  imports: [
    CommonModule,
    McLoginRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgbDropdownModule,
    McComponentsModule,
    TranslateModule,
    NgbNavModule,
    QRCodeModule,
    MatButton,
    MatCheckbox,
    MatFormField,
    MatInput,
    SafeHtmlModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class McLoginModuleModule {}
