import {allPermissions} from './all-permissions.config';
import {McGod} from "@miticon-ui/mc-core";

/*Table configuration
* property name: table component will find configuration by name (page)
* property hasCheckbox: table will have checkbox column
* property tableContent: will be rendered in table head
* property tableRowList: is mapping for table content input
* property tableRowList - label: name from table content witch need to display
* property tableRowList - leftIcon/rightIcon: left or right icons
* property tableRowList - routeName: will give click event to TD and return route string
* property tableRowList - paramAttribute: if routeName need special attributes from URL, setup it here
* property tableRowList - routePermission: if route need to have permission, setup it here
* property tableRowList - formatDate: flag for formatting dates
* property tableRowList - component: it will be added in future, we will use this flag for dynamically rendering components*/

export const tableConfig = [
  {
    id: 1,
    name: 'user',
    isConfigurable: true,
    type: 'regular',
    hasCheckbox: true,
    tableContent: [
      {
        id: 1,
        label: 'image',
        translateKey: 'cc.common.edit.image'
      },
      {
        id: 2,
        label: 'user',
        translateKey: 'cc.users.view.user',
      isSortable: true
      },
      {
        id: 3,
        label: 'email',
        translateKey: 'cc.common.edit.email',
        isSortable: true
      },
      {
        id: 4,
        label: 'roles',
        translateKey: 'cc.common.admin.roles',
        isSortable: true
      },
      {
        id: 5,
        label: 'status',
        translateKey: 'cc.common.view.status',
        isSortable: true
      }
    ],
    tableRowList: [
      {
        id: 1,
        label: 'image',
      },
      {
        id: 2,
        label: 'firstname',
        leftIcon: [
          {
            tableServiceMethod: 'goToUserEdit',
            icon: 'fa fa-pen',
            toolTip: 'cc.common.view.edit',
            permission: [allPermissions.MC_SYSTEM_USER_CAN_UPDATE]
          }
        ],
        rightIcon: [
          {
            tableServiceMethod: 'resendUserMail',
            icon: 'fas fa-paper-plane',
            toolTip: '',
            permission: [allPermissions.MC_SYSTEM_USER_CAN_UPDATE]
          }
        ],
        routePermission: allPermissions.MC_SYSTEM_USER_CAN_UPDATE.name,
        routeName: (entityId: any, itemId: any) => `/admin/entity/${entityId}/management/user/edit/${itemId}`
      },
      {
        id: 3,
        label: 'email',
      },
      {
        id: 4,
        label: 'roles',
        leftIcon: 'fas fa-wrench',
      },
      {
        id: 5,
        label: 'statusCode',
      }
    ]
  },
  {
    id: 2,
    name: 'entity',
    isConfigurable: true,
    type: 'regular',
    hasCheckbox: true,
    tableContent: [
      {
        id: 1,
        label: 'entity',
        translateKey: 'cc.common.view.entity',
        isSortable: true
      },
      {
        id: 2,
        label: 'abbreviation',
        translateKey: 'cc.common.abbreviation',
        isSortable: true
      },
      {
        id: 3,
        label: 'nameParentMcEntity',
        translateKey: 'cc.common.view.parent',
        isSortable: true
      },
      {
        id: 4,
        label: 'roles',
        translateKey: 'cc.common.admin.roles',
        isSortable: true
      },
      {
        id: 5,
        label: 'users',
        translateKey: 'cc.common.admin.users',
        isSortable: true
      },
      {
        id: 6,
        label: 'status',
        translateKey: 'cc.common.view.status',
        isSortable: true
      }
    ],
    tableRowList: [
      {
        id: 1,
        label: 'name',
        leftIcon: [
          {
            tableServiceMethod: 'goToEntityDetails',
            icon: 'fa fa-pen',
            toolTip: 'cc.entity.view.edit-entity',
            permission: [allPermissions.MC_ENTITY_CAN_UPDATE]
          },
        ],
        rightIcon: [
          {
            tableServiceMethod: 'goToEntityProfileInvoice',
            icon: 'fas fa-file-invoice-dollar',
            toolTip: 'cc.common.view.create-invoice'
          }
        ],
        routePermission: allPermissions.MC_ENTITY_CAN_UPDATE.name
      },
      {
        id: 2,
        label: 'abbreviation',
      },
      {
        id: 3,
        label: 'nameParentMcEntity',
      },
      {
        id: 4,
        label: 'numberOfRoles',
        routePermission: allPermissions.MC_LOGIN_CAN_READ.name,
        routeName: (entityId: any) => `/admin/entity/${entityId}/management/role`,
        paramAttribute: 'entityId',
      },
      {
        id: 5,
        label: 'numberOfUsers',
        routePermission: allPermissions.MC_SYSTEM_USER_CAN_READ.name,
        routeName: (entityId: any) => `/admin/entity/${entityId}/management/user`,
        paramAttribute: 'entityId',
      },
      {
        id: 6,
        label: 'statusCode',
      }
    ]
  },
  {
    id: 3,
    name: 'role',
    isConfigurable: true,
    type: 'regular',
    hasCheckbox: true,
    tableContent: [
      {
        id: 1,
        label: 'role',
        translateKey: 'cc.common.admin.roles',
        isSortable: true
      },
      {
        id: 2,
        label: 'permissions',
        translateKey: 'cc.roles.view.permissions',
        isSortable: true
      },
      {
        id: 3,
        label: 'users',
        translateKey: 'cc.common.admin.users',
        isSortable: true
      },
      {
        id: 4,
        label: 'status',
        translateKey: 'cc.common.view.status',
        isSortable: true
      }
    ],
    tableRowList: [
      {
        id: 1,
        label: 'caption',
        leftIcon: [
          {
            tableServiceMethod: 'goToRoleDetails',
            icon: 'fa fa-pen',
            toolTip: 'cc.role.view.edit-role',
            permission: [allPermissions.MC_LOGIN_CAN_UPDATE]
          }
        ],
        routePermission: allPermissions.MC_LOGIN_CAN_UPDATE.name,
        routeName: (entityId: any, itemId: any) => `/admin/entity/${entityId}/management/role/edit/${itemId}`,
      },
      {
        id: 2,
        label: 'numberOfPermissions',
        modal: 'permissionsListModal',
        leftIcon: [
          {
            icon: 'fas fa-wrench',
          }
        ],
      },
      {
        id: 3,
        label: 'numberOfUsers',
        routePermission: allPermissions.MC_SYSTEM_USER_CAN_READ.name,
        routeName: (roleId: any) => `/admin/entity/${roleId}/management/user`,
        paramAttribute: 'roleId',
      },
      {
        id: 4,
        label: 'statusCode',
      }
    ]
  },
  {
    id: 4,
    name: 'product',
    isConfigurable: true,
    type: 'regular',
    hasCheckbox: true,
    tableContent: [
      {
        id: 1,
        label: 'external Id',
        translateKey: 'cc.common.edit.external-id',
        isSortable: true
      },
      {
        id: 2,
        label: 'name',
        translateKey: 'cc.common.view.name',
        isSortable: true
      },
      {
        id: 3,
        label: 'unit cost(€)',
        translateKey: 'cc.product.view.unit-cost-euro',
        isSortable: true
      },
      {
        id: 4,
        label: 'units in stock',
        translateKey: 'cc.product.view.units-in-stock',
        isSortable: true
      },
      {
        id: 5,
        label: 'VAT(%)',
        translateKey: 'cc.table.vat-percent',
        isSortable: true
      },
      {
        id: 6,
        label: 'available from',
        translateKey: 'cc.common.view.available-from',
        isSortable: true
      },
      {
        id: 7,
        label: 'available to',
        translateKey: 'cc.common.view.available-to',
        isSortable: true
      },
      {
        id: 8,
        label: 'category',
        translateKey: 'cc.jobs.view.category',
        isSortable: true
      },
      {
        id: 9,
        label: 'status',
        translateKey: 'cc.common.view.status',
        isSortable: true
      }
    ],
    tableRowList: [
      {
        id: 1,
        label: 'externalId',
        leftIcon: [
          {
            tableServiceMethod: 'goToProductDetails',
            icon: 'fa fa-pen',
            toolTip: 'cc.product.view.edit-product',
            permission: [allPermissions.PP_PRODUCT_CAN_UPDATE]
          }
        ]
      },
      {
        id: 2,
        label: 'name',
      },
      {
        id: 3,
        label: 'unitPrice',
        css: {
          _style: {'text-align': 'center'},
        }
      },
      {
        id: 4,
        label: 'unitsInStock',
        css: {
          _style: {'text-align': 'center'},
        }
      },
      {
        id: 5,
        label: 'vatRate',
        css: {
          _style: {'text-align': 'center'},
        }
      },
      {
        id: 6,
        label: 'availableFrom',
        formatDate: 'll'
      },
      {
        id: 7,
        label: 'availableTo',
        formatDate: 'll'
      },
      {
        id: 8,
        label: 'categoryName',
      },
      {
        id: 9,
        label: 'statusCode'
      }
      /*todo: BE need to return statusCode instead of status property.*/
    ]
  },
  {
    id: 5,
    name: 'consumers',
    isConfigurable: true,
    type: 'regular',
    hasCheckbox: true,
    tableContent: [
      {
        id: 1,
        label: 'company name',
        translateKey: 'cc.common.company-name',
        isSortable: true
      },
      {
        id: 2,
        label: 'name',
        translateKey: 'cc.common.view.name',
        isSortable: true
      },
      {
        id: 3,
        label: 'email',
        translateKey: 'cc.common.edit.email',
        isSortable: true
      }, {
        id: 4,
        label: 'address',
        translateKey: 'cc.common.edit.address',
        isSortable: true
      },
      {
        id: 5,
        label: 'city',
        translateKey: 'cc.common.edit.city',
        isSortable: true
      },
      {
        id: 6,
        label: 'type',
        translateKey: 'cc.jobs.edit.type',
        isSortable: true
      },
      {
        id: 7,
        label: 'status',
        translateKey: 'cc.common.view.status',
        isSortable: true
      }
    ],
    tableRowList: [{
      id: 1,
      label: 'companyName',
      leftIcon: [
        {
          tableServiceMethod: 'goToConsumerDetails',
          icon: 'fa fa-pen',
          toolTip: 'cc.common.view.edit',
          permission: [allPermissions.CSR_CONSUMER_CAN_UPDATE]
        },
        {
          tableServiceMethod: 'goToEntityInvoice',
          icon: 'fas fa-file-invoice-dollar',
          toolTip: 'cc.common.view.payment-info'
        }
      ],
      // routeName: (entityId, itemId) => `/admin/entity/${entityId}/management/consumers/edit/${itemId}`,
    },
    {
      id: 2,
      label: 'displayName',
    },
    {
      id: 3,
      label: 'email',
    },
    {
      id: 4,
      label: 'street',
    },
    {
      id: 5,
      label: 'city',
    },
    {
      id: 6,
      label: 'individual',
    },
    {
      id: 7,
      label: 'statusCode',
    }]
  },
  {
    id: 6,
    name: 'jobs',
    isConfigurable: true,
    type: 'regular',
    hasCheckbox: false,
    tableContent: [
      {
        id: 1,
        label: 'name',
        translateKey: 'cc.common.view.name',
        isSortable: true
      },
      {
        id: 2,
        label: 'schedule time',
        translateKey: 'cc.jobs.view.schedule-time',
        isSortable: true
      },
      {
        id: 3,
        label: 'last fired time',
        translateKey: 'cc.jobs.view.last-fired-time',
        isSortable: true
      },
      {
        id: 4,
        label: 'next fire time',
        translateKey: 'cc.jobs.view.next-fire-time',
        isSortable: true
      },
      {
        id: 5,
        label: 'action',
        translateKey: 'cc.jobs.view.actions'
      },
      {
        id: 6,
        label: 'status',
        translateKey: 'cc.common.view.status',
        isSortable: true
      }
    ],
    tableRowList: [
      {
        id: 1,
        label: 'name',
        leftIcon: [
          {
            tableServiceMethod: 'goToJobDetail',
            icon: 'fa fa-pen',
            permission: [allPermissions.QRTZ_JOB_CAN_UPDATE],
            toolTip: 'cc.job.view.edit-job'
          }
        ],
        routeName: (entityId: any, itemId: any) => `/admin/entity/${entityId}/scheduler/jobs/edit/${itemId}`,
      },
      {
        id: 2,
        label: 'scheduleTime',
        formatDate: 'll'
      },
      {
        id: 3,
        label: 'lastFiredTime',
        formatDate: 'll'
      },
      {
        id: 4,
        label: 'nextFireTime',
        formatDate: 'll'
      },
      {
        id: 5,
        label: 'actions',
      },
      {
        id: 6,
        label: 'statusCode',
        component: 'mc-status'
      }
    ]
  },
  {
    id: 7,
    name: 'consumers_history',
    isConfigurable: true,
    type: 'regular',
    tableContent: [
      {
        id: 1,
        label: 'property',
        translateKey: 'cc.consumers.view.property',
        isSortable: true
      },
      {
        id: 2,
        label: 'old value',
        translateKey: 'cc.consumers.view.old-value',
        isSortable: true
      }, {
        id: 3,
        label: 'new value',
        translateKey: 'cc.consumers.view.new-value',
        isSortable: true
      },
      {
        id: 4,
        label: 'changed by',
        translateKey: 'cc.consumers.view.changed-by',
        isSortable: true
      },
      {
        id: 5,
        label: 'date/time',
        translateKey: 'cc.consumers.view.date-time',
        isSortable: true
      }
    ],
    tableRowList: [
      {
      id: 1,
      label: 'attributeName',
      // routeName: (entityId, itemId) => `/admin/entity/${entityId}/management/consumers/edit/${itemId}`,
    },
      {
        id: 2,
        label: 'oldValue',
      },
      {
        id: 3,
        label: 'newValue',
        // formatDate: "ll"
      },
      {
        id: 4,
        label: 'systemUser',
      },
      {
        id: 5,
        label: 'modificationDate',
        formatDate: 'll'
      }
    ]
  },
  {
    id: 8,
    name: 'invoiceOverview',
    type: 'regular',
    tableContent: [
      {
        id: 1,
        label: 'Order number',
        translateKey: 'cc.table.order-number',
        isSortable: true
      },
      {
        id: 3,
        label: 'creation date',
        translateKey: 'cc.invoices.view.creation-date',
        isSortable: true
      },
      {
        id: 4,
        label: 'send date',
        translateKey: 'cc.invoices.view.send-date',
        isSortable: true
      },
      {
        id: 5,
        label: 'due date',
        translateKey: 'cc.common.view.due-date',
        isSortable: true
      },
      {
        id: 7,
        label: 'state',
        translateKey: 'cc.common.view.status',
        isSortable: true
      }
    ],
    tableRowList: [
      {
        id: 1,
        label: 'orderNumber',
        leftIcon: [
          {
            tableServiceMethod: 'editInvoice',
            icon: 'fa fa-pen',
            toolTip: 'cc.common.view.edit',
            permission: [allPermissions.PP_INVOICE_CAN_UPDATE]
          }
        ],
        routePermission: allPermissions.PP_INVOICE_CAN_UPDATE.name,
        routeName: (entityId: any, itemId: any) => `/admin/entity/${entityId}/management/invoices/consumers/edit/${itemId}`,
      },
      {
        id: 3,
        label: 'sysCreatedDatetime',
        formatDate: 'lll'
      },
      {
        id: 4,
        label: 'sendingDate',
        formatDate: 'lll'
      },
      {
        id: 5,
        label: 'dueDate',
        formatDate: 'll'
      },
      {
        id: 7,
        label: 'state',
        component: 'mc-status'
      }
    ]
  },
  {
    id: 9,
    name: 'invoices_products',
    type: 'regular',
    isConfigurable: true,
    hasCheckbox: true,
    tableContent: [
      {
        id: 1,
        label: 'Category',
        translateKey: 'cc.jobs.view.category',
        isSortable: true
      },
      {
        id: 2,
        label: 'External Id',
        translateKey: 'cc.common.edit.external-id',
        isSortable: true
      },
      {
        id: 3,
        label: 'name',
        translateKey: 'cc.common.view.name',
        isSortable: true
      },
      {
        id: 4,
        label: 'unit cost',
        translateKey: 'cc.product.view.unit-cost',
        isSortable: true
      },
      {
        id: 5,
        label: 'units in stock',
        translateKey: 'cc.product.view.units-in-stock',
        isSortable: true
      },
      {
        id: 6,
        label: 'vat %',
        translateKey: 'cc.table.vat-percent',
        isSortable: true
      },
      {
        id: 7,
        label: 'action',
        translateKey: 'cc.jobs.view.actions',
        isSortable: false
      },
      {
        id: 8,
        label: 'status',
        translateKey: 'cc.common.view.status',
        isSortable: true
      }
    ],
    tableRowList: [
      {
        id: 1,
        label: 'categoryName'
      },
      {
        id: 2,
        label: 'externalId'
      },
      {
        id: 3,
        label: 'name'
      },
      {
        id: 4,
        label: 'unitPrice'
      },
      {
        id: 5,
        label: 'unitsInStock'
      },
      {
        id: 6,
        label: 'vatRate'
      },
      {
        id: 7,
        /*todo: this will be changed when we implement dynamically render components*/
        label: 'button-add-product'
      },
      {
        id: 8,
        label: 'statusCode'
      }
    ]
  },
  {
    id: 10,
    type: 'invoice',
    name: 'invoice_1',
    isDragAndDrop: true,
    isConfigurable: true,
    tableContent: [
      {
        id: 1,
        label: 'category',
        translateKey: 'cc.jobs.view.category',
        isSortable: true
      },
      {
        id: 2,
        label: 'quantity',
        translateKey: 'cc.jobs.view.quantity',
        isSortable: true
      },
      {
        id: 3,
        label: 'product name',
        translateKey: 'cc.consumer.art-name',
        isSortable: true
      },
      {
        id: 4,
        label: 'description',
        translateKey: 'cc.common.view.description',
        isSortable: true
      },
      {
        id: 5,
        label: 'memo',
        translateKey: 'cc.jobs.view.memo',
        isSortable: true
      },
      {
        id: 6,
        label: 'vat %',
      translateKey: 'cc.table.vat-percent',
        isSortable: true
      },
      {
        id: 7,
        label: 'unit price €',
        translateKey: 'cc.jobs.view.unit-price',
        isSortable: true
      },
      {
        id: 8,
        label: 'total price €',
        translateKey: 'cc.jobs.view.total-price',
        isSortable: true
      },
      {
        id: 9,
        /*todo: this will be changed when we implement dynamically render components*/
        label: 'button-add-product',
        translateKey: ''
      }
    ],
    tableRowList: [
      {
        id: 1,
        label: 'categoryName',
        cssClass: 'mc-table-category-name',
      },
      {
        id: 2,
        label: 'quantity',
        conf: {
          element: 'input',
          steps: 1,
          type: 'number',
          value: '',
          placeholder: 'Quantity'
        }
      },
      {
        id: 3,
        label: 'name',
        conf: {
          element: 'textarea',
          rows: 1,
          value: '',
          placeholder: 'Product name'
        }
      },
      {
        id: 4,
        label: 'description',
        conf: {
          element: 'textarea',
          rows: 2,
          value: '',
          placeholder: 'Description'
        }
      },
      {
        id: 5,
        label: 'memo',
        conf: {
          element: 'textarea',
          rows: 2,
          value: '',
          placeholder: 'Memo'
        }
      },
      {
        id: 6,
        label: 'vatRate',
        conf: {
          element: 'input',
          steps: 0.01,
          type: 'number',
          value: '',
          placeholder: 'Vat'
        }
      },
      {
        id: 7,
        label: 'unitPrice',
        conf: {
          element: 'input',
          steps: 0.01,
          type: 'number',
          value: '',
          placeholder: 'Unit price'
        }
      },
      {
        id: 8,
        label: 'totalPrice',
        conf: {
          element: 'input',
          steps: 0.01,
          isReadonly: true,
          type: 'number',
          value: '',
          placeholder: 'total price',
          eval: (unit1: number, unit2: number) => unit1 * unit2,
          unit1: 'quantity',
          unit2: 'unitPrice'
        },
      },
      {
        id: 9,
        label: 'button-remove-reset',
      }
    ],
    // invoice: {
    //   subtotal: "totalPrice",
    // }
  },
  {
    id: 11,
    type: 'invoice',
    name: 'invoice_summary',
    invoiceSummaryToFix: 2,
    tableContent: [
      {
        id: 1,
        label: 'quantity',
        translateKey: 'cc.jobs.view.quantity',
      },
      {
        id: 2,
        label: 'name',
        translateKey: 'cc.common.name'
      },
      {
        id: 3,
        label: 'description',
        translateKey: 'cc.common.view.description-memo',
      },
      // {
      //   id: 4,
      //   label: "memo",
      //   translateKey: "cc.jobs.view.memo",
      // },
      {
        id: 5,
        label: 'vat %',
        translateKey: 'cc.table.vat-percent',
      },
      {
        id: 6,
        label: 'unit price €',
        translateKey: 'cc.jobs.view.unit-price-symbol',
      },
      {
        id: 7,
        label: 'totalDiscount',
        translateKey: 'cc.common.discount',
      },
      {
        id: 8,
        label: 'total price €',
        translateKey: 'cc.common.original-price',
      }
    ],
    tableRowList: [
      {
        id: 1,
        label: 'quantity'
      },
      {
        id: 2,
        label: 'name',
        css: {
          _style: {'max-width': '120px', 'min-width': '120px', 'word-break': 'break-all'}
        }
      },
      {
        id: 3,
        label: 'description-memo',
        css: {
          _style: {width: '485px'}
        }
      },
      // {
      //   id: 4,
      //   label: "memo"
      // },
      {
        id: 5,
        label: 'vatRate',
        toFix: 2
      },
      {
        id: 6,
        label: 'unitPrice',
        toFix: 2
      },
      {
        id: 7,
        label: 'totalDiscount',
        translateKey: 'cc.common.discount',
      },
      {
        id: 8,
        label: 'totalPrice',
        toFix: 2

      }
    ]
  },
  {
    id: 12,
    type: 'regular',
    name: 'addProduct',
    hasCheckbox: false,
    tableContent: [
      // {
      //   id: 2,
      //   label: "quantity",
      //   isSortable: true
      // },
      {
        id: 3,
        label: 'product name',
        translateKey: 'cc.consumer.art-name',
        isSortable: true
      },
      {
        id: 4,
        label: 'description',
        translateKey: 'cc.common.view.description',
        isSortable: true
      },
      // {
      //   id: 5,
      //   label: 'memo',
      //   translateKey: 'cc.jobs.view.memo',
      //   isSortable: true
      // },
      {
        id: 6,
        label: 'vat %',
        translateKey: 'cc.table.vat-percent',
        isSortable: true,
      },
      {
        id: 7,
        label: 'currency',
        translateKey: 'cc.common.currency',
        isSortable: true,
      },
      {
        id: 8,
        label: 'unit price €',
        translateKey: 'cc.jobs.view.unit-price-symbol',
        isSortable: true,
      },
      // {
      //   id: 8,
      //   label: "total price €",
      //   isSortable: true
      // },
      {
        id: 9,
        /*todo: this will be changed when we implement dynamically render components*/
        label: 'Product Rows',
        translateKey: 'cc.jobs.view.quantity',
      }
    ],
    tableRowList: [
      // {
      //   id: 2,
      //   label: "quantity",
      // },
      {
        id: 3,
        label: 'name',
        css: {
          _style: {'max-width': '120px', 'min-width': '120px', 'word-break': 'break-all'}
        }
      },
      {
        id: 4,
        label: 'description',
        css: {
          _style: {width: '130px', 'word-break': 'break-all'}
        }
      },
      // {
      //   id: 5,
      //   label: 'memo',
      //   css: {
      //     _style: {width: '130px'}
      //   }
      // },
      {
        id: 6,
        label: 'vatRate',
        toFix: 2
      },
      {
        id: 7,
        label: 'currency',
        css: {
          _style: {'max-width': '120px'}
        }
      },
      {
        id: 8,
        label: 'unitPrice',
        toFix: 2
      },
      // {
      //   id: 8,
      //   label: "totalPrice",
      // },
      {
        id: 9,
        label: 'button-add-product',
      }
    ],
  },
  {
    id: 13,
    type: 'invoice',
    name: 'invoiceForSelectedProducts',
    isDragAndDrop: true,
    isConfigurable: false,
    invoiceSummaryToFix: 2,
    tableContent: [
      {
        id: 1,
        label: 'category',
        translateKey: 'cc.jobs.view.category',
        isSortable: true,
        css: {
          _class: 'mc-g-font-size-14'
        }
      },
      {
        id: 2,
        label: 'quantity',
        translateKey: 'cc.jobs.view.quantity',
        isSortable: true,
        css: {
          _class: 'mc-g-font-size-14'
        }
      },
      {
        id: 3,
        label: 'product name',
        translateKey: 'cc.consumer.art-name',
        isSortable: true,
        css: {
          _class: 'mc-g-font-size-14'
        }
      },
      {
        id: 4,
        label: 'description',
        translateKey: 'cc.common.view.description-memo',
        isSortable: false,
        css: {
          _class: 'mc-g-font-size-14'
        }
      },
      // {
      //   id: 5,
      //   label: "memo",
      //   translateKey: "cc.jobs.view.memo",
      //   isSortable: true
      // },
      {
        id: 6,
        label: 'vat %',
        translateKey: 'cc.table.vat-percent',
        isSortable: true,
        css: {
          _class: 'mc-g-font-size-14'
        }
      },
      {
        id: 7,
        label: 'unit price €',
        translateKey: 'cc.jobs.view.unit-price-symbol',
        isSortable: true,
        invoiceSummaryToFix: 2,
        css: {
          _class: 'mc-g-font-size-14'
        }
      },
      {
        id: 8,
        label: 'discount',
        translateKey: 'cc.common.discount',
        isSortable: true,
        css: {
          _class: 'mc-g-font-size-14'
        }
      },
      {
        id: 9,
        label: 'total price €',
        translateKey: 'cc.common.original-price',
        invoiceSummaryToFix: 2,
        isSortable: true,
        css: {
          _class: 'mc-g-font-size-14'
        }
      },
      {
        id: 10,
        label: 'currency',
        translateKey: 'cc.common.currency',
        isSortable: true,
        css: {
          _class: 'mc-g-font-size-14'
        }
      },
      {
        id: 11,
        /*todo: this will be changed when we implement dynamically render components*/
        label: 'Actions',
        translateKey: 'cc.jobs.view.actions',
        css: {
          _class: 'mc-g-font-size-14'
        }
      }
    ],
    tableRowList: [
      {
        id: 1,
        label: 'productCategoryName',
        css: {
          _class: 'mc-table-category-name mc-g-padding-x-5',
          _style: {'max-width': '200px', 'min-width': '120px', 'word-break': 'normal'}
        },
      },
      {
        id: 2,
        label: 'quantity',
        css: {
          _style: {'text-align': 'right', width: '80px'},
          _class: 'mc-g-padding-x-5'
        },
        toFix: 2,
        conf: {
          element: 'input',
          steps: 1,
          type: 'number',
          value: '',
          placeholder: 'Quantity'
        }
      },
      {
        id: 3,
        label: 'name',
        css: {
          _class: 'mc-g-padding-x-5',
          _style: {'max-width': '120px', 'min-width': '120px', 'word-break': 'break-all'}
        }
      },
      {
        id: 4,
        label: 'description',
        conf: {
          element: 'textarea',
          rows: 2,
          value: '',
          placeholder: 'Description'
        },
        css: {
          _class: 'mc-g-padding-x-5'
        }
      },
      // {
      //   id: 5,
      //   label: "memo",
      //   conf: {
      //     element: "textarea",
      //     hasApplyButton: true,
      //     rows: 2,
      //     value: "",
      //     placeholder: "Memo"
      //   }
      // },
      {
        id: 6,
        label: 'vatRate',
        css: {
          _style: { width: '80px'},
          _class: 'mc-g-padding-x-5'
        },
        toFix: 2,
        // conf: {
        //   element: 'input',
        //   steps: 0.01,
        //   type: 'number',
        //   value: '',
        //   placeholder: 'Vat'
        // }
      },
      {
        id: 7,
        label: 'unitPrice',
        css: {
          _style: {width: '150px'},
          _class: 'mc-g-padding-x-5'
        },
        toFix: 2,
        // conf: {
        //   element: 'input',
        //   steps: 0.01,
        //   type: 'number',
        //   value: '',
        //   placeholder: 'Unit price'
        // }
      },
      {
        id: 8,
        componentName: 'McPriceAdjustmentComponent',
        componentSetup: {},
        css: {
          _style: {'text-align': 'right', width: '80px'},
          _class: 'mc-g-padding-x-5'
        }
      },
      {
        id: 9,
        label: 'totalPrice',
        toFix: 2,
        css: {
          _style: { width: '160px', 'padding-left': '40px' },
          _class: 'mc-g-padding-x-5'
        },
      },
      {
        id: 10,
        label: 'currency',
        css: {
          _style: {'max-width': '120px'}
        }
      },
      {
        id: 11,
        label: 'button-remove-reset',
        css: {
          _class: 'mc-g-padding-x-5'
        }
      }
    ],
    // invoice: {
    //   subtotal: "totalPrice",
    // }
  },
  {
    id: 14,
    name: 'invoices',
    isConfigurable: true,
    type: 'regular',
    hasCheckbox: true,
    tableContent: [
      {
        id: 1,
        label: 'Order number',
        translateKey: 'cc.table.order-number',
        isSortable: true
      },
      {
        id: 3,
        label: 'creation date',
        translateKey: 'cc.invoices.view.creation-date',
        isSortable: true
      },
      {
        id: 4,
        label: 'sending date',
        translateKey: 'cc.invoices.view.send-date',
        isSortable: true
      },
      {
        id: 5,
        label: 'due date',
        translateKey: 'cc.common.view.due-date',
        isSortable: true
      },
      {
        id: 7,
        label: 'recipient',
        translateKey: 'cc.table.recipient',
        isSortable: true
      },
      {
        id: 9,
        label: 'state',
        translateKey: 'cc.common.view.status',
        isSortable: true
      }
    ],
    tableRowList: [
      {
        id: 1,
        label: 'orderNumber'
      },
      {
        id: 3,
        label: 'sysCreatedDatetime',
        formatDate: 'll',
        css: {
          _style: {'text-align': 'center'},
        },
      },
      {
        id: 4,
        label: 'sendingDate',
        formatDate: 'll',
        css: {
          _style: {'text-align': 'center'},
        },
      },
      {
        id: 5,
        label: 'dueDate',
        formatDate: 'll',
        css: {
          _style: {'text-align': 'center'},
        },
      },
      {
        id: 7,
        label: 'recipient'
      },
      {
        id: 9,
        label: 'state',
        component: 'mc-status'
      }
    ]
  }
];
