import { RouterModule, Routes } from '@angular/router';
import { McEntityRegistrationComponent } from './pages/mc-entity-registration/mc-entity-registration.component';
import { NgModule } from '@angular/core';
import { McEntityRegistrationSuccessComponent } from './pages/mc-entity-registration-success/mc-entity-registration-success.component';
import { McEntityActivationFeedbackComponent } from './pages/mc-entity-activation-feedback/mc-entity-activation-feedback.component';

const routes: Routes = [
  {
    path: '',
    component: McEntityRegistrationComponent,
  },
  {
    path: 'success',
    component: McEntityRegistrationSuccessComponent
  },
  {
    path: 'feedback',
    component: McEntityActivationFeedbackComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PbEntityRegistrationRoutingModule {
}