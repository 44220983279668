import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { McBoolean, McEntity2, McEntity2Service, McEntityService, McForm, McUtilityService } from '@miticon-ui/mc-core';
import { ICON_PEN } from '../../../../assets/media/svg_icons/icon-pen';
import { ICON_PAUSE } from '../../../../assets/media/svg_icons/icon-pause';
import { ICON_FILE } from '../../../../assets/media/svg_icons/icon-file';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {McGod} from '@miticon-ui/mc-core';

@Component({
  selector: 'lib-mc-entity-settings-tab',
  templateUrl: './mc-entity-settings-tab.component.html',
  styleUrl: './mc-entity-settings-tab.component.scss'
})
export class McEntitySettingsTabComponent implements OnInit, OnDestroy {
  selectedEntityPath!: any;
  selectedEntity!: any;
  passwordPolicyObject!: any;
  passPolicyMessage!: string;
  passPolicyType!: string;
  parentEntityId!: any;
  showLoader!: boolean;
  currentEntityPath!: any;
  bankAccount!: any;
  impressum!: any;

  entityId: any;
  editEntityId: any;
  entityName: any;
  isLoggedEntity: any;
  userId: any;
  paramSubscription!: Subscription;
  pageTabSubscription!: Subscription;
  mcForm = new McForm();
  selectedTab!: string;
  active = 1;
  message!: string;
  type!: string;
  responseMessage!: boolean;
  showPauseDaysMcb = new McBoolean();
  showTransactionDescriptionMcb = new McBoolean();
  maximalPauseDays!: number;
  mcEntity2 = new McEntity2(); // Old api does not return all values, to avoid refactoring now
  mcGod = McGod.getInstance();
  McGod = McGod;
  countryName: string | undefined;
  isCreate: boolean;
  transactionDescription: string;
  entityApiKey: string;

  iconPen = ICON_PEN;
  iconPause = ICON_PAUSE;
  iconFile = ICON_FILE;

  /*Constructor*/
  constructor(private activatedRoute: ActivatedRoute,
    private utilityService: McUtilityService,
    private entityService: McEntityService,
    private mcEntityService: McEntity2Service,
    private toastr: ToastrService) {}

  /*On init class*/
  ngOnInit() {
    /*Set unique Id for current entity*/

    this.paramSubscription = this.activatedRoute.paramMap.subscribe(data => {
      if (data.get('id')) {
        this.entityId = data.get('id');
        this.editEntityId = data.get('id');
        // disable payment info for logged entity

        this.isLoggedEntity = data.get('id') !== this.utilityService.getPropertyFromToken('entity_id').toString();
      } else {
        this.entityId = this.utilityService.getPropertyFromToken('entity_id');
        this.isLoggedEntity = false;
      }
    });
    // set active tab
    this.pageTabSubscription = this.activatedRoute.queryParams.subscribe(params => {
      this.selectedTab = params['activeTab'];
    });

    this.parentEntityId = localStorage.getItem('parentEntityId');
    this.getPasswordPolicyFromDb();
    this.getParentEntityData();
    this.mcEntity2.loadById(this.entityId, () => {
      this.maximalPauseDays = this.mcEntity2.maxTransactionPauseDays;
      this.transactionDescription = this.mcEntity2.transactionDescriptionPrefix
      this.parentEntityId = this.mcEntity2.id;
      this.transactionDescription ? this.isCreate = false : this.isCreate = true;
    });

    this.getEntityKey();
  }


  getEntityKey() {
    this.mcEntityService.getApiKey()
      .subscribe((res) => {
        this.entityApiKey = res.api_key;
        this.mcForm.getControl('ctrlEntityKey')?.get('value')?.setValue(res.api_key);
      });
  }

  /*Get password policy*/
 private getPasswordPolicyFromDb() {
    this.showLoader = true;
    if (this.editEntityId) {
      this.entityService.getEntityPasswordPolicyData(this.editEntityId)
        .subscribe(response => {
          this.passwordPolicyObject = response;
          this.showLoader = false;
        }, error => {
          this.showLoader = false;
          if (error.status >= 500) {
            this.passPolicyMessage = 'Server is not available at the moment, please try again later.';
            this.passPolicyType = 'error';
            return;
          }
          this.passPolicyMessage = error.error ? error.error.message : 'There is no password policy defined';
          this.passPolicyType = 'error';
        });
    } else {
      this.entityService.getPasswordPolicyData()
        .subscribe(response => {
          this.passwordPolicyObject = response;
          this.showLoader = false;
        }, error => {
          this.showLoader = false;
          if (error.status >= 500) {
            this.passPolicyMessage = 'Server is not available at the moment, please try again later.';
            this.passPolicyType = 'error';
            return;
          }
          this.passPolicyMessage = error.error ? error.error.message : 'There is no password policy defined';
          this.passPolicyType = 'error';
        });
    }
  }

  // Get parent for current entity
public getParentEntityData() {
    this.showLoader = true;
    if (this.editEntityId) {
      this.entityService.getParentEntity(this.editEntityId)
        .subscribe(response => {
          this.currentEntityPath = response.reverse();
          this.showLoader = false;
        }, error => {
          this.showLoader = false;
          if (error.status >= 500) {
            this.passPolicyMessage = 'Server is not available at the moment, please try again later.';
            this.passPolicyType = 'error';
            return;
          }
          this.passPolicyMessage = error.error ? error.error.message : 'There is no password policy defined';
          this.passPolicyType = 'error';
        });
    }
  }

  /*Update entityPage after edit entityPasswordPolicy*/
  onSuccessEditPasswordPolicy(event: boolean) {
    if (event) {
      this.passwordPolicyObject = event;
    }
  }

  /*On destroy*/
  ngOnDestroy() {
    this.paramSubscription.unsubscribe();
    this.pageTabSubscription.unsubscribe();
  }

  actPauseDays() {
    this.showPauseDaysMcb.setTrue();
  }

  onPauseDaysSaved(event: number) {
    this.maximalPauseDays = event;
    this.showPauseDaysMcb.setFalse();
  }

  onPauseDaysCanceled($event: any) {
    this.showPauseDaysMcb.setFalse();
  }

  actTransactionDescription() {
    this.showTransactionDescriptionMcb.setTrue();
  }

  onTransactionDescriptionSaved(event: string) {
    this.transactionDescription = event;
    this.transactionDescription ? this.isCreate = false : this.isCreate = true;
    this.showTransactionDescriptionMcb.setFalse();
  }

  onTransactionDescriptionCanceled($event: any) {
    this.showTransactionDescriptionMcb.setFalse();
  }


  generateEntityKey() {
    this.mcEntityService.generateApiKey().subscribe((res) => {
      if (this.mcForm.getControl('ctrlEntityKey')) {
        this.mcForm.getControl('ctrlEntityKey')?.get('value')?.setValue(res.api_key);
        this.entityApiKey = res.api_key;
      }
      this.toastr.success(McGod.t('cc.entity.entity-key-has-been-successfully-generated'));
    }, () => {
      this.toastr.error(McGod.t('cc.entity.entity-key-has-not-been-successfully-generated'));
    });
  }

}

