import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from "@angular/material/icon";
import { MatRadioModule } from '@angular/material/radio';
import { McComponentsModule,McFilterModule,McTableModule } from '@miticon-ui/mc-components';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { SafeHtmlModule } from "../../assets/pipes/safe-html/safe-html.module";
import { AppSharedRoutingModule } from './app-shared-routing.module';
import { AppSharedBlacklistRemovePartComponent } from './components/app-shared-blacklist-remove-part/app-shared-blacklist-remove-part.component';
import { McCardComponent } from './components/app-shared-card/app-shared-card.component';
import { AppSharedChangeCollectionDatePartComponent } from './components/app-shared-change-collection-date-part/app-shared-change-collection-date-part.component';
import { AppSharedConsumerBalancePartComponent } from './components/app-shared-consumer-balance-part/app-shared-consumer-balance-part.component';
import { AppSharedConsumerBlacklistAddPartComponent } from './components/app-shared-consumer-blacklist-add-part/app-shared-consumer-blacklist-add-part.component';
import { AppSharedConsumerBlacklistRemovePartComponent } from './components/app-shared-consumer-blacklist-remove-part/app-shared-consumer-blacklist-remove-part.component';
import { AppSharedCustomAttributeAddEditPartComponent } from './components/app-shared-custom-attribute-add-edit-part/app-shared-custom-attribute-add-edit-part.component';
import { AppSharedFactoringAddEditModalPartComponent } from './components/app-shared-factoring-add-edit-modal-part/app-shared-factoring-add-edit-modal-part.component';
import { AppSharedFactoringAddEditPartComponent } from './components/app-shared-factoring-add-edit-part/app-shared-factoring-add-edit-part.component';
import { AppSharedFactoringEnableDisablePartComponent } from './components/app-shared-factoring-enable-disable-part/app-shared-factoring-enable-disable-part.component';
import { AppSharedFactoringExportPartComponent } from './components/app-shared-factoring-export-part/app-shared-factoring-export-part.component';
import { AppSharedFactoringProfileModalPartComponent } from './components/app-shared-factoring-profile-modal-part/app-shared-factoring-profile-modal-part.component';
import { AppSharedFactoringProfilePartComponent } from './components/app-shared-factoring-profile-part/app-shared-factoring-profile-part.component';
import { AppSharedFactoringRejectContractPartComponent } from './components/app-shared-factoring-reject-contract-part/app-shared-factoring-reject-contract-part.component';
import { AppSharedFactoringRequestTypeAddEditModalPartComponent } from './components/app-shared-factoring-request-type-add-edit-modal-part/app-shared-factoring-request-type-add-edit-modal-part.component';
import { AppSharedFactoringSelectContractTypeAndEntityComponent } from './components/app-shared-factoring-select-contract-type-and-entity/app-shared-factoring-select-contract-type-and-entity.component';
import { AppSharedFactoringSelectContractTypePartComponent } from './components/app-shared-factoring-select-contract-type-part/app-shared-factoring-select-contract-type-part.component';
import { AppSharedFactoringSelectEntityPartComponent } from './components/app-shared-factoring-select-entity-part/app-shared-factoring-select-entity-part.component';
import { AppSharedFilterWrapperComponent } from './components/app-shared-filter-wrapper/app-shared-filter-wrapper.component';
import { AppSharedGoBackComponent } from './components/app-shared-go-back/app-shared-go-back.component';
import { AppSharedMatBottomSheetComponent } from './components/app-shared-mat-bottom-sheet/app-shared-mat-bottom-sheet.component';
import { AppSharedPauseTransactionsComponent } from './components/app-shared-pause-transactions/app-shared-pause-transactions.component';
import { AppSharedProcessesActionDetailPartComponent } from './components/app-shared-processes-action-detail-part/app-shared-processes-action-detail-part.component';
import { AppSharedProcessesActionResendPartComponent } from './components/app-shared-processes-action-resend-part/app-shared-processes-action-resend-part.component';
import { AppSharedRolesCreateRoleFromTemplateComponent } from './components/app-shared-roles-create-role-from-template/app-shared-roles-create-role-from-template.component';
import { AppSharedTransactionAddEditPartComponent } from './components/app-shared-transaction-add-edit-part/app-shared-transaction-add-edit-part.component';
import { AppSharedTransactionAddManuallyPartComponent } from './components/app-shared-transaction-add-manually-part/app-shared-transaction-add-manually-part.component';
import { AppSharedTransactionAdjustmentAddEditPartComponent } from './components/app-shared-transaction-adjustment-add-edit-part/app-shared-transaction-adjustment-add-edit-part.component';
import { AppSharedTransactionPaymentAddEditPartComponent } from './components/app-shared-transaction-payment-add-edit-part/app-shared-transaction-payment-add-edit-part.component';
import { AppSharedTransactionProfilePartComponent } from './components/app-shared-transaction-profile-part/app-shared-transaction-profile-part.component';
import { AppSharedTransactionsPauseDaysPartComponent } from './components/app-shared-transactions-pause-days-part/app-shared-transactions-pause-days-part.component';
import { InfoBarMessageComponent } from "./components/info-bar-message/info-bar-message.component";
import { AppSharedTransactionBillingItemsComponent } from './pages/app-shared-transaction-billing-items/app-shared-transaction-billing-items.component';
import { AppSharedTransactionPayoutItemsComponent } from './pages/app-shared-transaction-payout-items/app-shared-transaction-payout-items.component';
import { AppSharedActionRowComponent } from './components/app-shared-action-row/app-shared-action-row.component';

@NgModule({
  declarations: [
    AppSharedConsumerBalancePartComponent,
    AppSharedTransactionPayoutItemsComponent,
    AppSharedTransactionBillingItemsComponent,
    AppSharedRolesCreateRoleFromTemplateComponent,
    AppSharedTransactionsPauseDaysPartComponent,
    AppSharedPauseTransactionsComponent,
    AppSharedFactoringExportPartComponent,
    AppSharedProcessesActionResendPartComponent,
    AppSharedProcessesActionDetailPartComponent,
    AppSharedTransactionAddManuallyPartComponent,
    AppSharedFactoringSelectContractTypePartComponent,
    AppSharedChangeCollectionDatePartComponent,
    AppSharedTransactionPaymentAddEditPartComponent,
    AppSharedFactoringSelectContractTypePartComponent,
    AppSharedCustomAttributeAddEditPartComponent,
    AppSharedTransactionAddEditPartComponent,
    AppSharedFactoringEnableDisablePartComponent,
    AppSharedFactoringSelectEntityPartComponent,
    AppSharedTransactionProfilePartComponent,
    AppSharedTransactionAdjustmentAddEditPartComponent,
    AppSharedBlacklistRemovePartComponent,
    AppSharedFactoringRejectContractPartComponent,
    AppSharedFactoringRequestTypeAddEditModalPartComponent,
    AppSharedFactoringAddEditModalPartComponent,
    AppSharedFactoringAddEditPartComponent,
    AppSharedFactoringProfileModalPartComponent,
    AppSharedFactoringProfilePartComponent,
    AppSharedConsumerBlacklistRemovePartComponent,
    AppSharedConsumerBlacklistAddPartComponent,
    AppSharedFactoringSelectContractTypeAndEntityComponent,
    AppSharedGoBackComponent,
    AppSharedFilterWrapperComponent,
    McCardComponent,
    AppSharedMatBottomSheetComponent,
    InfoBarMessageComponent,
    AppSharedActionRowComponent
  ],
  imports: [
    CommonModule,
    AppSharedRoutingModule,
    NgbModule,
    McComponentsModule,
    McFilterModule,
    McTableModule,
    FormsModule,
    ReactiveFormsModule,
    McComponentsModule,
    TranslateModule,
    MatBottomSheetModule,
    MatIconModule,
    SafeHtmlModule,
    MatRadioModule,
    MatButtonModule,
  ],
  exports: [
    AppSharedConsumerBalancePartComponent,
    AppSharedTransactionPayoutItemsComponent,
    AppSharedTransactionBillingItemsComponent,
    AppSharedRolesCreateRoleFromTemplateComponent,
    AppSharedFactoringExportPartComponent,
    AppSharedTransactionsPauseDaysPartComponent,
    AppSharedPauseTransactionsComponent,
    AppSharedProcessesActionResendPartComponent,
    AppSharedTransactionAddManuallyPartComponent,
    AppSharedProcessesActionDetailPartComponent,
    AppSharedChangeCollectionDatePartComponent,
    AppSharedFactoringSelectContractTypePartComponent,
    AppSharedTransactionPaymentAddEditPartComponent,
    AppSharedCustomAttributeAddEditPartComponent,
    AppSharedTransactionAddEditPartComponent,
    AppSharedFactoringEnableDisablePartComponent,
    AppSharedFactoringSelectEntityPartComponent,
    AppSharedTransactionProfilePartComponent,
    AppSharedTransactionAdjustmentAddEditPartComponent,
    AppSharedBlacklistRemovePartComponent,
    AppSharedFactoringRejectContractPartComponent,
    AppSharedFactoringRequestTypeAddEditModalPartComponent,
    AppSharedFactoringAddEditModalPartComponent,
    AppSharedFactoringAddEditPartComponent,
    AppSharedFactoringProfileModalPartComponent,
    AppSharedFactoringProfilePartComponent,
    AppSharedConsumerBlacklistRemovePartComponent,
    AppSharedConsumerBlacklistAddPartComponent,
    AppSharedRoutingModule,
    FormsModule,
    AppSharedFactoringSelectContractTypeAndEntityComponent,
    AppSharedGoBackComponent,
    AppSharedFilterWrapperComponent,
    McCardComponent,
    InfoBarMessageComponent,
    AppSharedActionRowComponent
  ],
})
export class AppSharedModule {}
