import {  Component, Inject, OnDestroy } from '@angular/core';
import {McPermissionService, McEntityService, McAuthService, McUtilityService} from '@miticon-ui/mc-core';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'mc-admin',
  templateUrl: './admin.component.html',
})
export class AdminComponent implements OnDestroy {

  collapseSidebar!: boolean;
  fullScreenMode = false;
  selectedEntityName = '';
  selectedEntityFullName!: string;
  selectedEntity!: any;
  listEntities: any[] = [];
  userInfo: any;
  showLoader = true;
  selectedLanguage!: string;

  selectedEntityPathSubscription: Subscription;
  getUserInfoSubscription!: Subscription;
  entitySwitchedSubscription!: Subscription;

  /*Constructor*/
  constructor(private permissionService: McPermissionService,
              private router: Router,
              private authService: McAuthService,
              private utilityService: McUtilityService,
              private translate: TranslateService,
              private entityService: McEntityService,
              @Inject('utilityConfig') utilityConfig: any) {

    utilityConfig.languages.forEach((language: any) => language.default && (this.selectedLanguage = language.abbreviation));

    this.getUserInfo();

    /*Get selected entity name from service*/
    this.selectedEntityPathSubscription = this.entityService._selectedEntityPath.subscribe(path => {
      const entityName: any = path[path.length - 1];
      /*Check if entity name is provided from service
      * Check if entity name length is larger than 15 chars*/
      if (entityName) {
        this.selectedEntityFullName = entityName;
        this.selectedEntityName = entityName.length > 15 ? entityName.slice(0, 15) + '...' : entityName;
      }
    });

  }

  /*Get user info*/
  private getUserInfo(): void {
    this.authService.getUserInfo(this.utilityService.getPropertyFromToken('user_id'))
      .subscribe(response => {
        this.userInfo = response;

        if (this.userInfo.hasOwnProperty('isoLanguage') && this.userInfo.isoLanguage) {
          this.selectedLanguage = this.userInfo.isoLanguage.toLowerCase();
          this.translate.setDefaultLang(this.selectedLanguage);
        } else {
          this.translate.setDefaultLang('en');
        }

      }, error => {
        this.router.navigate(['/auth/login'], {
          state: {
            data: 'Error in getting user info.'
          }
        });
      })
      .add(() => this.showLoader = false);
  }

  /*Collapse sidebar menu*/
  public onCollapseSidebar() {
    this.collapseSidebar = !this.collapseSidebar;
  }

  /*Sidebar CSS classes for collapsing*/
  public sidebarClass(): string {
    return !this.collapseSidebar ? 'col-md-2' : 'mc-sidebar-collapsed';
  }

  /*Content CSS classes for collapsing*/
  public contentClass(): string {
    return this.collapseSidebar ? 'mc-content-width' : (this.fullScreenMode ? 'col-md-12 mc-content-container' : 'col-md-10 mc-content-container');
  }

  /*Content CSS position*/
  public contentCssPosition() {
    return {
      'padding-top': this.fullScreenMode && !this.collapseSidebar ? '70px' : this.collapseSidebar ? '0' : '70px',
      'padding-left': this.fullScreenMode && this.collapseSidebar ? '65px' : !this.fullScreenMode && this.collapseSidebar ? '80px' : '40px',
      'margin-top': this.fullScreenMode && this.collapseSidebar ? '40px' : !this.collapseSidebar ? '0' : '20px'
    };
  }

  /*Full screen icon wrapper CSS position*/
  public fullsScreenIconWrapperClass() {
    return {
      right: this.collapseSidebar && !this.fullScreenMode ? '50px' : '10px',
      top: this.fullScreenMode ? '20px' : '55px'
    };
  }

  /*Router outlet CSS position*/
  public routerOutletCss() {
    return {
      top: this.fullScreenMode && !this.collapseSidebar ? '-35px' : !this.fullScreenMode && this.collapseSidebar ? '-5px' : '0'
    };
  }

  checkCollapseSidebar(event: boolean) {
    this.collapseSidebar = !event;
  }

  /*On destroy class*/
  ngOnDestroy(): void {
    this.selectedEntityPathSubscription && this.selectedEntityPathSubscription.unsubscribe();
    this.getUserInfoSubscription && this.getUserInfoSubscription.unsubscribe();
    this.entitySwitchedSubscription && this.entitySwitchedSubscription.unsubscribe();
  }
}
