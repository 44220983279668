<h3 class="page-title">{{ "cc.common.processes" | translate }}</h3>

<div class="actions-container">
  <span>{{ "cc.email-templates" | translate }}</span>
</div>

<info-bar-message *ngIf="successInfoMsg" message='{{successInfoMsg}}' [errorBar]="false"></info-bar-message>
<info-bar-message *ngIf="errorInfoMsg" message='{{errorInfoMsg}}' [errorBar]="true"></info-bar-message>

<div class="settings-card card">
  <div class="settings-card_header">
    <mat-form-field class='template-select' appearance="outline">
      <mat-select [value]="currentEmail.type">
        <mat-option *ngFor="let email of emailTemplates" (click)="onChangeEmail(email.name)" [value]="email.type">{{ email.name | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="settings-card_header_right">
      <ng-container *ngIf="isResetEnabled">
        <span class="settings-card_header_right_title"
              (click)="openResetTemplateDialog()">
        {{ "cc.email.reset-template" | translate  }}
      </span>
      </ng-container>

      <span class="settings-card_header_right_title"
            (click)="openEmailInfoDialog()">
        {{ "cc.email.instructions" | translate  }}
      </span>
      <span  *ngIf="errorMessages.length>0" class="settings-card_right_icon" matTooltipClass="errors_tool_tip" [innerHTML]="iconError | safeHtml" [matTooltip]="formattedTooltip"
      ></span>
    </div>
  </div>
  <div class="settings-card_content">
  </div>
  <div class="editor-container" (keyup)="isCodeModified = true">
    <ngx-monaco-editor style="height: 100%" [options]="editorOptions" [(ngModel)]="htmlCode" (keyup)="validateHtml()" (onInit)="onEditorInit($event)"
    ></ngx-monaco-editor>
  </div>
  <div class="settings-card_confirm">
    <button mat-button
            (click)="saveEmailTemplate()"
            class="primary-btn"
            [disabled]="errorMessages.length>0 || !isCodeModified"
    >
      {{ "cc.common.view.save" | translate  }}
    </button>
  </div>
</div>
