<ng-container *ngIf="isVisible()">
  <lib-mc-loader [showLoader]="ebConsumerBlacklist.apiLoadingFlg"></lib-mc-loader>
<div class="content-wrapper">
  <div *ngIf="ebConsumerBlacklist.apiErrorFlg">
    Error {{ebConsumerBlacklist.apiErrorMessage}}
  </div>

  <div *ngIf="(!ebConsumerBlacklist.apiLoadingFlg)">

    <div class="content-header">
    <h1 *ngIf="ebConsumerBlacklist.exists()">Edit EbConsumerBlacklist (#{{ebConsumerBlacklist.id}})</h1>
    <h1 *ngIf="!ebConsumerBlacklist.exists()">{{'cc.out-factoring.add-consumer-to-blacklist' | translate}}</h1>
    </div>
    <div class="content-body">

      <div class="row">
        <div class="col-md-6">

          <mcc-fi-textbox name="ctrlFirstName" label="{{'cc.common.edit.first-name' | translate}}"
                          [value]="ebConsumerBlacklist.firstName" [validRequiredFlg]="true" [validWhitespaces]="true"
                          [mcForm]="mcForm">
          </mcc-fi-textbox>

          <mcc-fi-textbox name="ctrlLastName" label="{{'cc.common.edit.last-name' | translate}}"
                          [value]="ebConsumerBlacklist.lastName" [validRequiredFlg]="true" [validWhitespaces]="true"
                          [mcForm]="mcForm">
          </mcc-fi-textbox>

          <mcc-fi-date-picker
            label="{{'cc.common.edit.birthday' | translate}}"
            [name]="'ctrlBirthday'"
            [validRequiredFlg]="true"
            [valueInput]="ebConsumerBlacklist.birthday"
            [mcForm]="mcForm"
          [maxDateInput]="today">
          </mcc-fi-date-picker>

          <mcc-fi-textbox name="ctrlEmail" label="{{'cc.common.edit.email' | translate}}"
                          [value]="ebConsumerBlacklist.email" [validRequiredFlg]="true" [validWhitespaces]="true"
                          [mcForm]="mcForm" [validEmail]="true">
          </mcc-fi-textbox>

          <mcc-fi-textbox name="ctrlIban" label="{{'cc.common.iban' | translate}}"
                          [value]="ebConsumerBlacklist.iban" [validRequiredFlg]="true" [validWhitespaces]="true"
                          [mcForm]="mcForm">
          </mcc-fi-textbox>

        </div>
        <div class="col-md-6">

          <mcc-fi-textbox name="ctrlStreet" label="{{'cc.consumers.edit.street' | translate}}"
                          [value]="ebConsumerBlacklist.street" [validRequiredFlg]="true" [validWhitespaces]="true"
                          [mcForm]="mcForm">
          </mcc-fi-textbox>

          <mcc-fi-textbox name="ctrlCity" label="{{'cc.common.edit.city' | translate}}"
                          [value]="ebConsumerBlacklist.city" [validRequiredFlg]="true" [validWhitespaces]="true"
                          [mcForm]="mcForm">
          </mcc-fi-textbox>

          <mcc-fi-textbox name="ctrlPostCode" label="{{'cc.consumers.edit.postal-code' | translate}}" [validWhitespaces]="true"
                          [value]="ebConsumerBlacklist.postCode" [validRequiredFlg]="true"
                          [mcForm]="mcForm">
          </mcc-fi-textbox>

          <mcc-fi-textarea name="ctrlAddedReason" label="{{'cc.out-factoring.added-reason' | translate}}" [validWhitespaces]="true" [value]="ebConsumerBlacklist.addedReason"
                           [validRequiredFlg]="true" [mcForm]="mcForm"></mcc-fi-textarea>


        </div>
      </div>

    </div>
    <div class="content-footer">
      <button type="button" class="btn btn-secondary move-right" (click)="actCancel()">{{'cc.common.edit.cancel' | translate}}</button>
      <button type="button" class="btn btn-primary" (click)="actSaveItem()">{{'cc.common.view.save' | translate}}</button>
    </div>

    <!--<br><br>
    <button (click)="actDeleteItem()">DELETE ITEM</button>-->
  </div>

  <router-outlet></router-outlet>
</div>
</ng-container>


