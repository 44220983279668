<!--todo: need refactor, need better css classes naming (move everything from dashboard.scss to admin.scss)-->
<div class="container-fluid mc-dashboard" >
  <div class="row">
    <div class="col-md-12">
      <!-- Header Component -->
      <div class="mc-header" *ngIf="!fullScreenMode">
        <mc-header (onSelectedEntity)="selectedEntity = $event"
                   (onGetAllEntities)="listEntities = $event"
                   (onSidebarCollapse)='collapseSidebar = $event'
                   [setLanguage]="selectedLanguage"
                   [isCollapsed]="collapseSidebar"
                   [user]="userInfo"></mc-header>
      </div>
    </div>
  </div>

  <!--Sidebar and content sections-->
  <div class="row">
    <div class="mc-sidebar-wrapper" [ngClass]="sidebarClass()" *ngIf="!fullScreenMode">
      <!-- Sidebar Component -->
      <mc-sidebar [getLanguage]="showLoader"
                  (onCollapse)='checkCollapseSidebar($event)'
                  [isCollapsed]="collapseSidebar"
                  class="mc-sidebar-first-child">
      </mc-sidebar>
    </div>
    <div [class.mc-content-width]="collapseSidebar"
         [class.mc-content-container]="!collapseSidebar"
         [class.col-md-12]="!collapseSidebar && fullScreenMode"
         [class.col-md-10]="!collapseSidebar && !fullScreenMode"
         [ngStyle]="contentCssPosition()">

      <!-- Content area -->
      <div class="row">
        <div class="col-md-12 mc-admin-content-wrapper {{sidebarClass() === 'mc-sidebar-collapsed' ? 'sidebar-collapsed-page-content' : ''}}" *ngIf="!showLoader" [ngStyle]="routerOutletCss()">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div><!--Sidebar and content section /end-->
</div>


<!--Loader-->
<lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>
