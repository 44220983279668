import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {EbPaymentReportServiceGENERATED} from '../_generated/eb-payment-report-generated.service';
import {BehaviorSubject} from 'rxjs';
import {EbPaymentReportFilter} from '../models/eb-payment-report-filter.model';



@Injectable({
  providedIn: 'root'
})
export class EbPaymentReportService extends EbPaymentReportServiceGENERATED {
  paginationChanged$ = new BehaviorSubject<{pageNumber: number, pageSize: number}>({pageNumber: 0, pageSize: 100});
  filterChanged$ = new BehaviorSubject<EbPaymentReportFilter>(new EbPaymentReportFilter());

  public markAsInvalid(id: number) {
    const body: any = {};
    body.id = id;

    return this.httpClient.put<any>(this.getApiServiceRootUrl() + `/markasinvalid/${id}`, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });

  }

  exportAll(filter: EbPaymentReportFilter, format: string) {
    return this.httpClient.post(this.getApiServiceRootUrl() + '/export/' + format, filter);
  }

  refundPaymentRecordSingle(body: any) {
    return this.httpClient.post(this.getApiServiceRootUrl() + '/refund-payment-report', body);
  }

  refundPaymentRecordBulk(body: any) {
    return this.httpClient.post(this.getApiServiceRootUrl() + '/bulk-refund-payment-reports', body);
  }

  scheduleRefundPayment(body: any){
    return this.httpClient.post(this.getApiServiceRootUrl() + '/schedule-refund-payment', body)
  }

  revertRefundPayment(body: any){
    return this.httpClient.post(this.getApiServiceRootUrl() + '/revert-refund', body);
  }

}
