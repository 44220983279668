import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ICON_CLOSE } from '../../../../assets/media/svg_icons/icon-close';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-mc-consumer-consumer-invoices-schedule-send-dialog',
  templateUrl: './mc-consumer-consumer-invoices-schedule-send-dialog.component.html',
  styleUrl: './mc-consumer-consumer-invoices-schedule-send-dialog.component.css',
})
export class McConsumerConsumerInvoicesScheduleSendDialogComponent implements OnInit {

  hours = Array.from({ length: 24 }, (_, i) => ({
    value: i,
    label: `${i.toString().padStart(2, '0')}:00`,
  }));
  form!: FormGroup;
  today: string = new Date().toISOString().split('T')[0];
  iconClose = ICON_CLOSE;

  constructor(public dialogRef: MatDialogRef<McConsumerConsumerInvoicesScheduleSendDialogComponent>,
              private fb: FormBuilder,
              private toastr: ToastrService,
              private tS: TranslateService) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      hour: ['', Validators.required],
      date: ['', Validators.required],
    });
  }

  combineDateAndTime(): any{
    const date = this.form.get('date')?.value;
    let hour = this.form.get('hour')?.value;

    if (date && hour !== null) {
      const combined = date;
      combined.setHours(++hour, 0, 0, 0);
      return combined;
    }
    return null;
  }

  scheduleSendingInvoice(){
    this.form.valid ? this.dialogRef.close(this.combineDateAndTime()) : this.toastr.error(this.tS.instant('cc.invoice.transaction-form-invalid'));
  }
}
