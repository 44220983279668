import { Component,Inject } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-mc-entity-registration-success',
  templateUrl: './mc-entity-registration-success.component.html',
  styleUrl: './mc-entity-registration-success.component.scss'
})
export class McEntityRegistrationSuccessComponent {

  landingBg: string;
  logo: string;
  selectedLanguage!: string;

  constructor(@Inject('designConfig') design: any,
              private translate: TranslateService,
              private router: Router,
              private route: ActivatedRoute) {

    this.landingBg = `url('${design.landingBg}')`
    this.logo = design.logoMark;

    /*if state doesn't exist redirect user to registration page*/
    this.route.queryParams.subscribe(() => {
      const navigation = this.router.getCurrentNavigation();
      if (navigation && navigation.extras.state && navigation.extras.state['selectedLanguage']) {
        this.selectedLanguage = navigation.extras.state['selectedLanguage'];
        this.translate.setDefaultLang(this.selectedLanguage);
      } else {
        this.router.navigate(['/registration']);
      }
    });
  }
}
