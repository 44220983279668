
import {Table3Config} from '../extra/table-3-config';

// import {EmailTemplateGENERATED} from '../_generated/email-template-generated.model';

export class EmailTemplateTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colIdEmailConfig', 'Email config ID', 'idEmailConfig', 'idEmailConfig');
    this.addColumn('colIdMcEntity', 'Entity ID', 'idMcEntity', 'idMcEntity');
    this.addColumn('colLanguageCode', 'Language code', 'languageCode', 'languageCode');
    this.addColumn('colSysCreatedDatetime', 'System Created Datetime', 'sysCreatedDatetime', 'sysCreatedDatetime');
    this.addColumn('colSysCreatedUserId', 'System Created User Id', 'sysCreatedUserId', 'sysCreatedUserId');
    this.addColumn('colSysUpdatedDatetime', 'System Updated Datetime', 'sysUpdatedDatetime', 'sysUpdatedDatetime');
    this.addColumn('colWebFile', 'Web file email', 'webFile', 'webFile');
    this.addColumn('colWebFileId', 'Email file ID', 'webFileId', 'webFileId');

*/
  }
}
