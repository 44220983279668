<div class="valign-container full-height mc-login-area" [ngStyle]="{'background-image': 'url(' + welcomeImg + ')'}">
  <div class="container">
    <div class="row">
        <div class="card mc-login-card">
          <mc-notify-message *ngIf="responseMessage" [message]="message" [type]="type"></mc-notify-message>
          <div class="text-center mc-login-header">
            <img [src]="logo" alt="">
          </div>
          <div class="card-body mc-login-card-body">
            <form [formGroup]="loginForm">
              <div class="mc-login-password-fields">
                <div class="form-label-group mc-login-input">
                  <label for="username"></label>
                  <input type="text"
                         name="username"
                         formControlName="username"
                         class="form-control"
                         [ngClass]="inputFieldsError ? 'mc-error-input-wrapper' : ''"
                         id="username"
                         placeholder="Username*"
                         required
                         (keyup)="validateLogin()"
                          (keyup.enter)="login()">
                </div>

                <div class="form-label-group mc-login-input mc-input-password">
                  <label for="password"></label>
                  <input [type]="seePassword ? 'text' : 'password'"
                         class="form-control"
                         [ngClass]="inputFieldsError ? 'mc-error-input-wrapper' : ''"
                         id="password"
                         name="password"
                         formControlName="password"
                         placeholder="Password*"
                         (keyup.enter)="login()"
                         required
                         />
                  <mc-see-password (seePasswordOnEyeBtn)="onChangeSeePassword($event)"></mc-see-password>
                </div>
              </div>
              <div class="col-md-12 text-center mc-login-btn-area">
                <button mat-button
                        class="primary-btn"
                        id="loginBtn"
                        type="submit"
                        [disabled]="!isFormValid || loginForm.invalid"
                        (click)="login()">
                  {{'cc.common.view.log-in' | translate}}
                </button>
              </div>
              <div class="row mc-login-loginAlternatives">
                <div class="col">
                  <a routerLink="/auth/forgot-password" class="float-right forgot-password-label" id="forgotPassword">
                   {{ 'cc.common.view.forgot-password' | translate }}?
                  </a>
                </div>
                <div class="col mc-keep-me-logged">
                  <div class="form-group">
                    <mat-checkbox [(ngModel)]="isKeepMeLoggedInChecked"
                                  [ngModelOptions]="{standalone: true}"
                                  (click)="rememberMeChb($event)"
                                  class="forgot-password-label">
                      {{   "cc.common.view.keep-me-logged-in" | translate}}
                    </mat-checkbox>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
    </div>
  </div>
</div>
<!--Loader-->
<lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>

