import { Component, OnInit } from '@angular/core';
import {
  McGod, McInvoiceService,
  PpInvoiceList, PpOrder, PpOrderList, PpOrderService,
  SortCriteriaList,
  WebFile,
  WebFileService
} from "@miticon-ui/mc-core";
import {MkMatTableMenuAction, MkTableConfig} from "@miticon-ui/mc-components";
import { ActivatedRoute, Router } from "@angular/router";
import {saveAs} from "file-saver";
import {HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import { McConsumerConsumerInvoicesScheduleSendDialogComponent } from '../mc-consumer-consumer-invoices-schedule-send-dialog/mc-consumer-consumer-invoices-schedule-send-dialog.component';
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'lib-mc-consumer-consumer-invoices-part',
  templateUrl: './mc-consumer-consumer-invoices-part.component.html',
  styleUrls: ['./mc-consumer-consumer-invoices-part.component.scss']
})
export class McConsumerConsumerInvoicesPartComponent implements OnInit {
  consumerId!: number;
  webFile!: WebFile;
  invoicesList = new PpInvoiceList();
  mcGod = McGod.getInstance();
  getAll$!: Observable<any>;

  errorMessage: string = '';

  //Table
  items = [];
  totalItemsCount = 0;
  pageNumber = 0;
  pageSize = 5;
  isLoading = false;
  tableConfig = new MkTableConfig();
  ppInvoiceList = new PpOrderList();
  mkMatMenuActionItems = [
    {
      title: McGod.t('cc.common.view.invoice-preview'),
      matIcon: 'pageview',
      actionCd: PpOrder.ACTION_SHOW_PREVIEW,
      permission: McGod.PERM_CSR_CONSUMER_INVOICES_VIEW
    },
    {
      title: McGod.t('cc.common.view.edit-invoice'),
      matIcon: 'edit',
      actionCd: PpOrder.ACTION_SHOW_EDIT,
      permission: McGod.PERM_CSR_CONSUMER_INVOICES_VIEW
    },
    {
      title: McGod.t('pp.invoice.cancel-order'),
      matIcon: 'cancel',
      actionCd: PpOrder.ACTION_CANCEL,
      permission: McGod.PERM_CSR_CONSUMER_INVOICES_VIEW
    },
    {
      title: McGod.t('cc.consumer.send-invoice'),
      matIcon: 'send',
      actionCd: PpOrder.ACTION_SEND_INVOICE,
      permission: McGod.PERM_CSR_CONSUMER_INVOICES_VIEW
    }
  ];

  constructor(private webFileService: WebFileService,
              private invoiceService: McInvoiceService,
              private orderService: PpOrderService,
              private route: ActivatedRoute,
              private router: Router,
              private toastrService: ToastrService,
              private tS: TranslateService,
              private dialog: MatDialog) {
  }

  ngOnInit() {
    this.consumerId = this.route.snapshot.params['id'];
    this.initTableConfig();
    this.addLicensePreviewAction();
    this.actLoad(0, 50);
  }

  addLicensePreviewAction() {
    this.orderService.hasLicensePreview(McGod.getLoggedEntityIdFromToken()).subscribe((hasLicense) => {
      if(hasLicense) {
        this.mkMatMenuActionItems.splice(1, 0, {
          title: McGod.t('cc.common.view.license-preview'),
          matIcon: 'pageview',
          actionCd: PpOrder.ACTION_SHOW_LICENSE_PREVIEW,
          permission: McGod.PERM_CSR_CONSUMER_INVOICES_VIEW
        })
      }
    })
  }

  initTableConfig() {
    this.tableConfig.addColumnStandard(McGod.t('pp.invoice.order-name'), 'orderNumber');
    this.tableConfig.addColumnStandard(McGod.t('pp.invoice.company-name'), 'entityName');
    this.tableConfig.addColumnStandard(McGod.t('cc.table.recipient'), 'recipient');
    this.tableConfig.addColumnStandard(McGod.t('pp.invoice.consumer-id'), 'idReceiverMcEntity');
    this.tableConfig.addColumnStandard(McGod.t('pp.invoice.total-amount'), 'fldTotalAmount()');
    this.tableConfig.addColumnStandard(McGod.t('pp.invoice.sending-date'), 'fldSendingDate()');
    this.tableConfig.addColumnStandard(McGod.t('pp.invoice.creation-date'), 'fldSysCreatedDate()');
    this.tableConfig.addColumnStandard(McGod.t('pp.invoice.type'), 'fldType()');
    this.tableConfig.addColumnStandard(McGod.t('pp.invoice.status'), 'fldState()');
  }

  actLoad(pageNumber: number, pageSize: number) {
    const sortCriteria = new SortCriteriaList();
    sortCriteria.addSort('id', 'DESC');

    let params = new HttpParams()
      .set("consumerId", this.consumerId)
      .set('page', pageNumber)
      .set('size', pageSize)

    this.ppInvoiceList.setPager(pageNumber, pageSize);

    this.loadInvoices(params);
  }

  onActionMkMatMenuItems($event: MkMatTableMenuAction) {
    switch ($event.action.actionCd) {
      case PpOrder.ACTION_SHOW_PREVIEW:
        if($event.item.state !== 'FAILED'){
          this.showInvoicePdf($event.item.id)
          this.errorMessage = ''
        } else {
          this.errorMessage = 'Cannot show invoice PDF for the invoice in status ' + $event.item.state + '.'
        }
        break;
      case PpOrder.ACTION_SHOW_LICENSE_PREVIEW:
        this.actShowLicensePreview($event.item.id);
        break;
      case PpOrder.ACTION_SHOW_EDIT:
        this.actShowEdit($event.item.id);
        break;
      case PpOrder.ACTION_CANCEL:
        this.actCancel($event.item.id);
        break;
      case PpOrder.ACTION_SEND_INVOICE:
        this.actScheduleSendInvoice($event.item.id);
        break;
    }
  }

  loadInvoices(params: HttpParams){
    this.getAll$ = this.invoiceService.getOrdersByFilter(params);

    this.getAll$.subscribe(
      response => {
        this.ppInvoiceList.items = response.content.map((object: any) => PpOrder.createFromJson(object));
        this.totalItemsCount = response.totalElements;
        this.pageSize = response.size;
      }
    )
  }

  showInvoicePdf(orderId: number){
    this.orderService.generatePreviewByOrderId(orderId).subscribe(
      (response) => {
        const blob = new Blob([response], {type: 'application/pdf'});
        const url = URL.createObjectURL(blob);
        const newWindow = window.open(url, '_blank');
        newWindow?.document.write(`<html><head><title>Invoice</title></head><body>
             <embed width="100%" height="100%" name="plugin" src="${url}"
            type="application/pdf" internalinstanceid="21"></body></html>`);
      }
    );
  }

  actShowLicensePreview(orderId: number){
    this.orderService.generateLicensePreviewByOrderId(orderId).subscribe(
      (response) => {
        const blob = new Blob([response], {type: 'application/pdf'});
        const url = URL.createObjectURL(blob);
        const newWindow = window.open(url, '_blank');
        newWindow?.document.write(`<html><head><title>License</title></head><body>
             <embed width="100%" height="100%" name="plugin" src="${url}"
            type="application/pdf" internalinstanceid="21"></body></html>`);
      }
    );
  }

  actScheduleSendInvoice(orderId: any) {
    this.dialog.open(McConsumerConsumerInvoicesScheduleSendDialogComponent, {
      height: '700px',
      width: '650px',
      panelClass: 'dialog',
      autoFocus: false,
      restoreFocus: false
    }).afterClosed().subscribe((sendingDate) => {
      if (sendingDate) {
        const body = {sendingDate: sendingDate};
        this.scheduleSendingInvoice(orderId, body);
      }
    })
  }

  scheduleSendingInvoice(orderId: number, body: {sendingDate: any}) {
    this.orderService.scheduleSendingInvoice(orderId, body).subscribe({
      next: () => {
        this.toastrService.success(this.tS.instant('cc.common.saved-successfully'));
        this.actLoad(this.pageNumber, this.pageSize);
      },
      error: (err: any) => {
        this.toastrService.error(err.error.message);
      }
    });
  }

  actShowEdit(orderId: number){
    this.router.navigateByUrl(`/admin/entity/${McGod.getLoggedEntityIdFromToken()}/management/invoices/consumers/edit/${orderId}`);
  }

  actCancel(orderId: number){
    this.invoiceService.cancelOrder(orderId).subscribe(
      () => {
        this.actLoad(this.pageNumber, this.pageSize);
      }
    );
  }

  onCreateInvoice() {
    this.router.navigateByUrl(`/admin/entity/${McGod.getLoggedEntityIdFromToken()}/management/invoices/consumers/create/${this.consumerId}`);
  }
}
