import {EntityInvitationFilterGENERATED} from '../_generated/entity-invitation-filter-generated.model';

export class EntityInvitationFilter extends EntityInvitationFilterGENERATED  {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new EntityInvitationFilter();
  }

  // ---------------------------------------------------------------------
}
