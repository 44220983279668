/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {EbPaymentReport} from '../models/eb-payment-report.model';
import {EbPaymentReportService} from '../services/eb-payment-report.service';
import {IEbPaymentReport} from '../_generated/eb-payment-report.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class EbPaymentReportGENERATED extends BaseObject {


    public static readonly IMPORT_TYPE_CD_SEPA: string = 'SEPA';
    public static readonly IMPORT_TYPE_CD_PSD2: string = 'PSD2';
  public static readonly IMPORT_TYPE_CD_LIST__ALL = [
    EbPaymentReportGENERATED.IMPORT_TYPE_CD_SEPA,
    EbPaymentReportGENERATED.IMPORT_TYPE_CD_PSD2
];

    public static readonly MATCHING_TYPE_AUTO: string = 'AUTO';
    public static readonly MATCHING_TYPE_MANUAL: string = 'MANUAL';
    public static readonly MATCHING_TYPE_SECONDARY: string = 'SECONDARY';
  public static readonly MATCHING_TYPE_LIST__ALL = [
    EbPaymentReportGENERATED.MATCHING_TYPE_AUTO,
    EbPaymentReportGENERATED.MATCHING_TYPE_MANUAL,
    EbPaymentReportGENERATED.MATCHING_TYPE_SECONDARY
];

    public static readonly STATUS_NEW: string = 'NEW';
    public static readonly STATUS_MATCHED: string = 'MATCHED';
    public static readonly STATUS_UNMATCHED: string = 'UNMATCHED';
    public static readonly STATUS_FAILED_IMPORT: string = 'FAILED_IMPORT';
    public static readonly STATUS_EXPORTED: string = 'EXPORTED';
    public static readonly STATUS_INVALID: string = 'INVALID';
    public static readonly STATUS_REFUNDED: string = 'REFUNDED';
    public static readonly STATUS_FAILED_REFUND: string = 'FAILED_REFUND';
    public static readonly STATUS_OLD_UNMATCHED: string = 'OLD_UNMATCHED';
  public static readonly STATUS_LIST__ALL = [
    EbPaymentReportGENERATED.STATUS_NEW,
    EbPaymentReportGENERATED.STATUS_MATCHED,
    EbPaymentReportGENERATED.STATUS_UNMATCHED,
    EbPaymentReportGENERATED.STATUS_FAILED_IMPORT,
    EbPaymentReportGENERATED.STATUS_EXPORTED,
    EbPaymentReportGENERATED.STATUS_INVALID,
    EbPaymentReportGENERATED.STATUS_REFUNDED,
    EbPaymentReportGENERATED.STATUS_FAILED_REFUND,
    EbPaymentReportGENERATED.STATUS_OLD_UNMATCHED
];

    public static readonly TYPE_RETURN: string = 'RETURN';
    public static readonly TYPE_PAYMENT: string = 'PAYMENT';
    public static readonly TYPE_GROUP_DD: string = 'GROUP_DD';
    public static readonly TYPE_PAYOUT: string = 'PAYOUT';
    public static readonly TYPE_BANK_FEES: string = 'BANK_FEES';
    public static readonly TYPE_STORNO: string = 'STORNO';
    public static readonly TYPE_UNDEFINED: string = 'UNDEFINED';
  public static readonly TYPE_LIST__ALL = [
    EbPaymentReportGENERATED.TYPE_RETURN,
    EbPaymentReportGENERATED.TYPE_PAYMENT,
    EbPaymentReportGENERATED.TYPE_GROUP_DD,
    EbPaymentReportGENERATED.TYPE_PAYOUT,
    EbPaymentReportGENERATED.TYPE_BANK_FEES,
    EbPaymentReportGENERATED.TYPE_STORNO,
    EbPaymentReportGENERATED.TYPE_UNDEFINED
];

  public apiService: EbPaymentReportService;
  public _rawJson: IEbPaymentReport;
    id: number = 0;
  errorReason = '';
  flgExported: boolean;
  idEbReport: number;
  importTypeCd: string;
  matchingType: string;
  status: string;
  sysCreatedDatetime: number;
  sysUpdatedDatetime: number;
  transactionDate: number;
  type: string;
  amount: any;  currencyStr: string;  debtorAccountId: string;  debtorName: string;  description: string;

  public properties: string[] = ['id', 'details', 'errorReason', 'flgExported', 'idEbReport', 'importTypeCd', 'matchingType', 'status', 'sysCreatedDatetime', 'sysUpdatedDatetime', 'transactionDate', 'type', 'amount', 'currencyStr', 'debtorAccountId', 'debtorName', 'description'];
  public propertiesRegular: string[] = ['id', 'details', 'errorReason', 'flgExported', 'idEbReport', 'importTypeCd', 'matchingType', 'status', 'sysCreatedDatetime', 'sysUpdatedDatetime', 'transactionDate', 'type', 'amount', 'currencyStr', 'debtorAccountId', 'debtorName', 'description'];
  public propertiesSpecial: string[] = [];



  public static getImportTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(EbPaymentReportGENERATED.IMPORT_TYPE_CD_SEPA, McGod.t('SEPA file type'));
    list.add(EbPaymentReportGENERATED.IMPORT_TYPE_CD_PSD2, McGod.t('PSD2 file type'));


    return list;
  }

  public static getImportTypeCdLabel(aCd: string): string {
    const list = EbPaymentReportGENERATED.getImportTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getMatchingTypeVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(EbPaymentReportGENERATED.MATCHING_TYPE_AUTO, McGod.t('Automatic matching'));
    list.add(EbPaymentReportGENERATED.MATCHING_TYPE_MANUAL, McGod.t('Manual matching'));
    list.add(EbPaymentReportGENERATED.MATCHING_TYPE_SECONDARY, McGod.t('Secondary matching'));


    return list;
  }

  public static getMatchingTypeLabel(aCd: string): string {
    const list = EbPaymentReportGENERATED.getMatchingTypeVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getStatusVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(EbPaymentReportGENERATED.STATUS_NEW, McGod.t('New'));
    list.add(EbPaymentReportGENERATED.STATUS_MATCHED, McGod.t('Matched'));
    list.add(EbPaymentReportGENERATED.STATUS_UNMATCHED, McGod.t('Unmatched'));
    list.add(EbPaymentReportGENERATED.STATUS_FAILED_IMPORT, McGod.t('Failed import'));
    list.add(EbPaymentReportGENERATED.STATUS_EXPORTED, McGod.t('Exported'));
    list.add(EbPaymentReportGENERATED.STATUS_INVALID, McGod.t('Invalid'));
    list.add(EbPaymentReportGENERATED.STATUS_REFUNDED, McGod.t('Refunded'));
    list.add(EbPaymentReportGENERATED.STATUS_FAILED_REFUND, McGod.t('Failed refund'));
    list.add(EbPaymentReportGENERATED.STATUS_OLD_UNMATCHED, McGod.t('Old unmatched'));


    return list;
  }

  public static getStatusLabel(aCd: string): string {
    const list = EbPaymentReportGENERATED.getStatusVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getTypeVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(EbPaymentReportGENERATED.TYPE_RETURN, McGod.t('Return'));
    list.add(EbPaymentReportGENERATED.TYPE_PAYMENT, McGod.t('Payment'));
    list.add(EbPaymentReportGENERATED.TYPE_GROUP_DD, McGod.t('Group DD'));
    list.add(EbPaymentReportGENERATED.TYPE_PAYOUT, McGod.t('Payout'));
    list.add(EbPaymentReportGENERATED.TYPE_BANK_FEES, McGod.t('Bank Fees'));
    list.add(EbPaymentReportGENERATED.TYPE_STORNO, McGod.t('Storno'));
    list.add(EbPaymentReportGENERATED.TYPE_UNDEFINED, McGod.t('Undefined'));


    return list;
  }

  public static getTypeLabel(aCd: string): string {
    const list = EbPaymentReportGENERATED.getTypeVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): EbPaymentReport {
    console.error('MTCN-ERROR: Not Implemented: EbPaymentReport::createNewInstance(). Add this method to final class and return new EbPaymentReport();');
    throw new Error('Cannot EbPaymentReport::createNewInstance(). Add this method to final class and return new EbPaymentReport();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IEbPaymentReport): EbPaymentReport {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EbPaymentReportService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE EbPaymentReport AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }

    public getSysUpdatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysUpdatedDatetime);
    }

    public getSysUpdatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysUpdatedDatetime);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getImportTypeCdLabel(): string {
    return EbPaymentReportGENERATED.getImportTypeCdLabel(this.importTypeCd);
  }


 public  isImportTypeCdSEPA(): boolean {
    const result = (this.importTypeCd === EbPaymentReportGENERATED.IMPORT_TYPE_CD_SEPA);

    return result;
  }

 public  isImportTypeCdPSD2(): boolean {
    const result = (this.importTypeCd === EbPaymentReportGENERATED.IMPORT_TYPE_CD_PSD2);

    return result;
  }


  public getMatchingTypeLabel(): string {
    return EbPaymentReportGENERATED.getMatchingTypeLabel(this.matchingType);
  }


 public  isMatchingTypeAUTO(): boolean {
    const result = (this.matchingType === EbPaymentReportGENERATED.MATCHING_TYPE_AUTO);

    return result;
  }

 public  isMatchingTypeMANUAL(): boolean {
    const result = (this.matchingType === EbPaymentReportGENERATED.MATCHING_TYPE_MANUAL);

    return result;
  }

 public  isMatchingTypeSECONDARY(): boolean {
    const result = (this.matchingType === EbPaymentReportGENERATED.MATCHING_TYPE_SECONDARY);

    return result;
  }


  public getStatusLabel(): string {
    return EbPaymentReportGENERATED.getStatusLabel(this.status);
  }


 public  isStatusNEW(): boolean {
    const result = (this.status === EbPaymentReportGENERATED.STATUS_NEW);

    return result;
  }

 public  isStatusMATCHED(): boolean {
    const result = (this.status === EbPaymentReportGENERATED.STATUS_MATCHED);

    return result;
  }

 public  isStatusUNMATCHED(): boolean {
    const result = (this.status === EbPaymentReportGENERATED.STATUS_UNMATCHED);

    return result;
  }

 public  isStatusFAILED_IMPORT(): boolean {
    const result = (this.status === EbPaymentReportGENERATED.STATUS_FAILED_IMPORT);

    return result;
  }

 public  isStatusEXPORTED(): boolean {
    const result = (this.status === EbPaymentReportGENERATED.STATUS_EXPORTED);

    return result;
  }

 public  isStatusINVALID(): boolean {
    const result = (this.status === EbPaymentReportGENERATED.STATUS_INVALID);

    return result;
  }

 public  isStatusREFUNDED(): boolean {
    const result = (this.status === EbPaymentReportGENERATED.STATUS_REFUNDED);

    return result;
  }

 public  isStatusFAILED_REFUND(): boolean {
    const result = (this.status === EbPaymentReportGENERATED.STATUS_FAILED_REFUND);

    return result;
  }

 public  isStatusOLD_UNMATCHED(): boolean {
    const result = (this.status === EbPaymentReportGENERATED.STATUS_OLD_UNMATCHED);

    return result;
  }


  public getTypeLabel(): string {
    return EbPaymentReportGENERATED.getTypeLabel(this.type);
  }


 public  isTypeRETURN(): boolean {
    const result = (this.type === EbPaymentReportGENERATED.TYPE_RETURN);

    return result;
  }

 public  isTypePAYMENT(): boolean {
    const result = (this.type === EbPaymentReportGENERATED.TYPE_PAYMENT);

    return result;
  }

 public  isTypeGROUP_DD(): boolean {
    const result = (this.type === EbPaymentReportGENERATED.TYPE_GROUP_DD);

    return result;
  }

 public  isTypePAYOUT(): boolean {
    const result = (this.type === EbPaymentReportGENERATED.TYPE_PAYOUT);

    return result;
  }

 public  isTypeBANK_FEES(): boolean {
    const result = (this.type === EbPaymentReportGENERATED.TYPE_BANK_FEES);

    return result;
  }

 public  isTypeSTORNO(): boolean {
    const result = (this.type === EbPaymentReportGENERATED.TYPE_STORNO);

    return result;
  }

 public  isTypeUNDEFINED(): boolean {
    const result = (this.type === EbPaymentReportGENERATED.TYPE_UNDEFINED);

    return result;
  }


  
// ---------------------------------------------------------------------
public callMatch( id: number, id_transactions: any, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.match(id,id_transactions).subscribe(responseJson => {
    this.setApiSuccess();
    this.processApiSuccess(responseJson, onSuccessCallback);
    },
  errorResponse => {
    this.setApiError();
    this.processApiError(errorResponse, onErrorCallback);
  });
}

// ---------------------------------------------------------------------
public callMarkAsInvalid( id: number, onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
  this.setApiLoading();
  this.apiService.markAsInvalid(id)
    .subscribe(responseJson => {
        this.populateFromJson(responseJson);
        this.processApiSuccess(responseJson, onSuccessCallback);
      }, errorResponse => {
        this.processApiError(errorResponse, onErrorCallback);
      });
}

}
