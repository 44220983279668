/*BbmTestObj*/
import {McEntity2GENERATED} from '../_generated/mc-entity2-generated.model';
import {McHtml} from '../_core/mc-html';
import {McGod} from '../extra/mc-god.service';
import {ActionOptions} from '../_core/mc-html-action-options';

export class McEntity2 extends McEntity2GENERATED {
  public static ACTION_ENABLE_FACTORING = 'ENABLE_FACTORING';

  private _fldEntity2Actions: McHtml | null = null;
  private countryName = '';
  public properties: string[] = ['id', 'abbreviation', 'address', 'bankAccount', 'bankTransactionEntityNameOverride', 'countryId', 'countryName', 'courtInCharge', 'createdAt', 'createdBy', 'customAttributeMap', 'description', 'euVat', 'extCreditorId', 'factoringEnableFlg', 'fax', 'impressum', 'ipWhitelist', 'isVisibleToChildren', 'legalRepresentative', 'legalRepresentativeContact', 'mailDomain', 'mailPrefix', 'maxTransactionPauseDays', 'name', 'parentEntityId', 'phone', 'rootEntityId', 'statusCd', 'studioManager', 'studioOwner', 'studioOwnerAddress', 'studioOwnerCity', 'studioOwnerPostalCode', 'transactionDescriptionPrefix', 'updatedAt', 'updatedBy', 'version', 'webDomain'];
  public propertiesRegular: string[] = ['id', 'abbreviation', 'address', 'bankAccount', 'bankTransactionEntityNameOverride', 'countryId', 'countryName', 'courtInCharge', 'createdAt', 'createdBy', 'customAttributeMap', 'description', 'euVat', 'extCreditorId', 'factoringEnableFlg', 'fax', 'impressum', 'ipWhitelist', 'isVisibleToChildren', 'legalRepresentative', 'legalRepresentativeContact', 'mailDomain', 'mailPrefix', 'maxTransactionPauseDays', 'name', 'parentEntityId', 'phone', 'rootEntityId', 'statusCd', 'studioManager', 'studioOwner', 'studioOwnerAddress', 'studioOwnerCity', 'studioOwnerPostalCode', 'transactionDescriptionPrefix', 'updatedAt', 'updatedBy', 'version', 'webDomain'];

  // ---------------------------------------------------------------------
  public static override createNewInstance() {
    return new McEntity2();
  }

  // ---------------------------------------------------------------------
  fldFactoringEnabled() {
    return this.factoringEnableFlg ? this.t('cc.common.edit.yes') : this.t('cc.common.edit.no');
  }

  fldEntity2Actions() {
    if (!this._fldEntity2Actions) {
      const html = new McHtml();
      const actSet = html.addActionSet();
      actSet.addActionB(this.t('cc.out-factoring.enable-disable-factoring'), McEntity2.ACTION_ENABLE_FACTORING, this, [McGod.PERM_MC_ENTITY_VIEW], '', ActionOptions.OPTION_EDIT);
      this._fldEntity2Actions = html;
    }
    return this._fldEntity2Actions.items;
  }
}
