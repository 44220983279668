import {Component, Inject, OnInit} from '@angular/core';
import {ICON_CLOSE} from "../../../../assets/media/svg_icons/icon-close";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup} from "@angular/forms";
import {McGod, McProcessNotification, McProcessNotificationService} from "@miticon-ui/mc-core";

@Component({
  selector: 'lib-mc-process-configuration-edit-dialog',
  templateUrl: './mc-process-configuration-edit-dialog.component.html',
  styleUrls: ['./mc-process-configuration-edit-dialog.component.scss']
})
export class McProcessConfigurationEditDialogComponent implements OnInit {
  iconClose = ICON_CLOSE;
  form: FormGroup;
  notificationTypes = [{
    value: 'Billing',
    label: 'cc.process.billing-notifications',
    controlName: 'typeBillingChecked'
  },{
    value: 'Payment',
    label: 'cc.process.payment-notifications',
    controlName: 'typePaymentChecked'
  }];

  paymentNotifications = [{
    value: McProcessNotification.EVENT_FILTER_CD_MANUAL_PAYMENT,
    label: 'cc.process.manual-payments'
  },{
    value: McProcessNotification.EVENT_FILTER_CD_DIRECT_DEBIT_PAYMENT,
    label: 'cc.process.direct-debits'
  },{
    value: McProcessNotification.EVENT_FILTER_CD_ALL_PAYMENT,
    label: 'cc.process.all-payments'
  }];

  billingNotifications = [{
    value: McProcessNotification.EVENT_FILTER_CD_ACCEPTED,
    label: 'cc.eb-factoring.accepted'
  },{
    value: McProcessNotification.EVENT_FILTER_CD_FOR_DUNNING,
    label: 'cc.factoring.transactions.for-dunning'
  },{
    value: McProcessNotification.EVENT_FILTER_CD_ALL_TRANSACTION_STATUSES,
    label: 'cc.my-factoring.all'
  }];

  processId: number;
  processNotification: McProcessNotification;
  billingNotification: McProcessNotification;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { processId: number, processNotifications: McProcessNotification[]},
              public dialogRef: MatDialogRef<McProcessConfigurationEditDialogComponent>,
              private fb: FormBuilder,
              private mcProcessNotificationService: McProcessNotificationService) { }

  ngOnInit(): void {
    this.createForm();

    this.processId = this.data.processId;

    if(this.data.processNotifications) {
      this.data.processNotifications.forEach((notification) => {
        if (notification.eventTypeCd === McProcessNotification.EVENT_TYPE_CD_PAYMENT) {
          this.processNotification = notification;
          this.form.controls['typePaymentChecked'].setValue(true);
          this.form.controls['paymentType'].setValue(notification.eventFilterCd);
        }
        if (notification.eventTypeCd === McProcessNotification.EVENT_TYPE_CD_BILLING) {
          this.billingNotification = notification;
          this.form.controls['typeBillingChecked'].setValue(true);
          this.form.controls['billingType'].setValue(notification.eventFilterCd);
        }
      });
    }
    this.setupValueChangeHandler('typePaymentChecked', 'paymentType');
    this.setupValueChangeHandler('typeBillingChecked', 'billingType');
  }

  private setupValueChangeHandler(triggerControlName: string, targetControlName: string): void {
    this.form.controls[triggerControlName].valueChanges.subscribe((data) => {
      if (!data) {
        this.form.controls[targetControlName].reset();
      }
    });
  }

  createForm() {
    this.form = this.fb.group({
      typePaymentChecked: false,
      typeBillingChecked: false,
      paymentType: [''],
      billingType:['']
    });
  }

  resetPaymentType() {
    if(!this.form.controls['typePaymentChecked'].value) {
      this.form.controls['paymentType'].reset();
    }
  }

  createOrEditNotificationType() {
    const notifications: McProcessNotification[] = [];

    const createNotification = (eventTypeCd: string, eventFilterCd: string): McProcessNotification => {
      const notification = new McProcessNotification();
      notification.idMcProcess = this.processId;
      notification.eventFilterCd = eventFilterCd;
      notification.eventTypeCd = eventTypeCd;
      notification.idMcEntity = McGod.getLoggedEntityIdFromToken();
      notification.statusCd = McProcessNotification.STATUS_CD_ACTIVE;
      notification.typeCd = McProcessNotification.TYPE_CD_EMAIL;
      return notification;
    };

    const notificationConfig = [
      {
        checkedControl: 'typePaymentChecked',
        eventType: McProcessNotification.EVENT_TYPE_CD_PAYMENT,
        filterControl: 'paymentType',
      },
      {
        checkedControl: 'typeBillingChecked',
        eventType: McProcessNotification.EVENT_TYPE_CD_BILLING,
        filterControl: 'billingType',
      },
    ];

    notificationConfig.forEach(config => {
      if (this.form.controls[config.checkedControl].value) {
        const notification = createNotification(
          config.eventType,
          this.form.controls[config.filterControl].value
        );
        notifications.push(notification);
      }
    });

    const serviceCall = this.processId ?
      this.mcProcessNotificationService.editMultiple(notifications, this.processId) :
      this.mcProcessNotificationService.createMultiple(notifications);

    serviceCall.subscribe((data) => {
      if (data) {
        this.dialogRef.close(true);
      }
    });
  }
}
