<div class='card'>
  <h2 class="title-medium-dark mb-large">{{ 'cc.contract-360'| translate }}</h2>
  <mcc-fi-select class='select'
                 name="selProduct"
                 placeholderOption="{{'cc.contract-360' | translate}}"
                 [value]="''"
                 [optionsVll]="productsVll"
                 (eventValueChanged)="onProductClick($event)"
                 [mcForm]="mcForm"></mcc-fi-select>
  <div class="error" *ngIf="allContracts.length === 0">{{ "cc.consumer-360.no-contracts" | translate }}</div>
 <ng-container *ngIf="selectedProduct">
   <div class="card dialog-header-card">
     <div *ngIf="!isOneTimeType()" class='dialog-row-wrapper dialog-header'>
       <div class='dialog-col'>
         <span class='dialog-col-label'>{{ 'cc.contract-360' | translate}}:</span>
         <span class='dialog-col-value'>{{ selectedProduct.name }}</span>
       </div>
       <div class='dialog-col'>
         <span class='dialog-col-label'>{{ 'cc.contract-360.type' | translate}}:</span>
         <span class='dialog-col-value'>{{ getTypeCdLabel(selectedProduct.typeCd) }}</span>
       </div>
       <div class='dialog-col'>
         <span class='dialog-col-label'>{{ 'cc.contract-360.duration' | translate}}:</span>
         <span class='dialog-col-value'>{{ selectedProduct.durationInMonths + " " + ((selectedProduct.durationInMonths === 1 ? "cc.common.month" : "cc.my-factoring.months") | translate | lowercase) }}</span>
       </div>
       <div class='dialog-col'>
         <span class='dialog-col-label'>{{ 'cc.common.original-amount' | translate}}:</span>
         <span class='dialog-col-value'>{{ selectedProduct.originalAmount + '€' }}</span>
       </div>
       <div class='dialog-col'>
         <span class='dialog-col-label'>{{ 'cc.common.discount' | translate}}:</span>
         <span class='dialog-col-value'>{{ selectedProduct.discount ? selectedProduct.discount.value + '%' : '-' }}</span>
       </div>
       <div class='dialog-col'>
         <span class='dialog-col-label'>{{ 'cc.billing.total-amount' | translate}}:</span>
         <span class='dialog-col-value'>{{ selectedProduct.totalAmount + '€' }}</span>
       </div>
       <div class='dialog-col'>
         <span class='dialog-col-label'>{{ getPriceLabel(selectedProduct.typeCd) | translate}}:</span>
         <span class='dialog-col-value'>{{ selectedProduct.price ? selectedProduct.price + '€' : '-' }}</span>
       </div>
       <div class='dialog-col'>
         <span class='dialog-col-label'>{{ 'mem.articles.one-time' | translate}}:</span>
         <span class='dialog-col-value'>{{ selectedProduct.priceOneTime ? selectedProduct.priceOneTime + '€' : '-' }}</span>
       </div>
     </div>
     <div *ngIf="isOneTimeType()" class='dialog-row-wrapper dialog-header'>
       <div class='dialog-col'>
         <span class='dialog-col-label'>{{ 'cc.contract-360' | translate}}:</span>
         <span class='dialog-col-value'>{{ selectedProduct.name }}</span>
       </div>
       <div class='dialog-col'>
         <span class='dialog-col-label'>{{ 'cc.contract-360.type' | translate}}:</span>
         <span class='dialog-col-value'>{{ getTypeCdLabel(selectedProduct.typeCd) }}</span>
       </div>
       <div class='dialog-col'>
         <span class='dialog-col-label'>{{ 'cc.contract-360.duration' | translate}}:</span>
         <span class='dialog-col-value'>
           {{ selectedProduct.durationInMonths ?
           selectedProduct.durationInMonths + " " +
           ((selectedProduct.durationInMonths === 1 ? "cc.common.month" : "cc.my-factoring.months") | translate | lowercase) :
           '-'
           }}
         </span>
       </div>
       <div class='dialog-col'>
         <span class='dialog-col-label'>{{ 'cc.consumer.total-netto' | translate}}:</span>
         <span class='dialog-col-value'>{{ selectedProduct.totalNetto + '€' }}</span>
       </div>
       <div class='dialog-col'>
         <span class='dialog-col-label'>{{ 'mem.common.total-brutto' | translate}}:</span>
         <span class='dialog-col-value'>{{ selectedProduct.totalAmount + '€'}}</span>
       </div>
       <div class='dialog-col'>
         <span class='dialog-col-label'>{{ 'cc.product.view.vat' | translate}}:</span>
         <span class='dialog-col-value'>{{ getVat() + ' %' }}</span>
       </div>
     </div>
   </div>
   <div class="divider"></div>
   <h2 class="title-medium-dark mb-large">{{ ('cc.contract-360'| translate) + " " + ('cc.factoring.start-date' | translate | lowercase) }}</h2>
   <div class="flex dates">
     <mcc-fi-date-picker
       extraCssClasses="mr-2"
       label="{{ ('cc.contract-360'| translate) + ' ' + ('cc.factoring.start-date' | translate | lowercase) }}"
       [name]="'ctrlContractStartDate'"
       [minDateInput]="todayDate"
       (eventValueChanged)="onStartDateChange($event)"
       [allowManualEntryFlg]="false"
       [newStyleDate]="true"
       [mcForm]="mcForm">
     </mcc-fi-date-picker>
     <mcc-fi-date-picker
       style="display: none"
       extraCssClasses="mr-2"
       label="{{'cc.factoring.start-date' | translate}}"
       [name]="'ctrlContractEndDate'"
       [minDateInput]="todayDate"
       (eventValueChanged)="onStartDateChange($event)"
       [allowManualEntryFlg]="false"
       [newStyleDate]="true"
       [mcForm]="mcForm">
     </mcc-fi-date-picker>
     <div class="mb-large dialog-row-wrapper">
       <div class='dialog-col'>
         <span class='dialog-col-label'>{{ 'cc.factoring.end-date' | translate}}:</span>
         <span class='dialog-col-value'>{{ getEndDate() ? (getEndDate() | date : "dd.MM.YYYY") : '-' }}</span>
       </div>
     </div>
   </div>

   <div *ngIf="this.selectedProduct.typeCd != 'ONE_TIME'">
     <div class="divider"></div>
     <h2 class="title-medium-dark mb-large">{{ ('cc.consumer.contract-renewal'| translate) }}</h2>

     <mcc-fi-radio-buttons2 [directionHorizontal]="true"
                            [label]="'cc.contract.renewal.enable-contract-renewal' | translate"
                            name="ctrlRenewal"
                            [mcForm]="mcForm"
                            [value]="showContractRenewal"
                            [optionsVll]="confirmationVll"
                            (eventValueChanged)="changedRenewal($event)"
                            [validRequiredFlg]="true">
     </mcc-fi-radio-buttons2>

     <div *ngIf="showContractRenewal">
       <mcc-fi-textbox label="{{'cc.contract.renewal.renewal-period-in-months' | translate}}" name="ctrlRenewalPeriodInMonths" [validRequiredFlg]="true" [validIntegerNumber]=true [validMaxNumber]="360" [validMinNumber]="0" (eventValueChanged)="validateRenewalPeriodInput($event)" [mcForm]="mcForm" [newStyleInput]="true"></mcc-fi-textbox>
       <mcc-fi-textbox label="{{'cc.contract.renewal.renewal-period' | translate}}" name="ctrlRenewalNoticePeriod" [validRequiredFlg]="true" [validIntegerNumber]=true [validMaxNumber]="selectedProduct.durationInMonths" [validMinNumber]="0" [mcForm]="mcForm" [newStyleInput]="true" (eventValueChanged)="validateNoticeRenewalPeriod($event)"></mcc-fi-textbox>
       <info-bar-message *ngIf="errorMsgRenewal || errorMsgNoticePeriod"  [errorBar]="true" [message]="errorMsgRenewal ? errorMsgRenewal : errorMsgNoticePeriod"></info-bar-message>
     </div>
   </div>
 </ng-container>
</div>
