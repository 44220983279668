import {EmailConfig} from './email-config.model';
import {EmailConfigListGENERATED} from '../_generated/email-config-list-generated.model';

export class EmailConfigList extends EmailConfigListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new EmailConfigList();
  }

  // ---------------------------------------------------------------------
}
