import { Component,Inject,OnInit } from '@angular/core';
import { FormBuilder,FormGroup,Validators } from '@angular/forms';
import { MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { EntityActivationService, McAuthService } from '@miticon-ui/mc-core';
import { ToastrService } from 'ngx-toastr';
import { ICON_CLOSE } from '../../../../assets/media/svg_icons/icon-close';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-mc-profile-activation-dialog',
  templateUrl: './mc-profile-activation-dialog.component.html',
  styleUrl: './mc-profile-activation-dialog.component.scss',
})
export class McProfileActivationDialogComponent implements OnInit {
  iconClose = ICON_CLOSE;
  form!: FormGroup;
  activationTypes = {  ACTIVATE: 'activate',  DENY: 'deny'};

  constructor(@Inject(MAT_DIALOG_DATA) public data: { entityId: number },
              public dialogRef: MatDialogRef<McProfileActivationDialogComponent>,
              private fb: FormBuilder,
              private entityActivationService: EntityActivationService,
              private toastr: ToastrService,
              private tS: TranslateService,
              private authService: McAuthService) {}

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      activationFlg: [this.activationTypes.ACTIVATE, Validators.required],
    });
  }

  onConfirm() {
    if (!this.form.valid) return;

    const activationFlg = this.form.get('activationFlg')?.value;
    const entityId = this.data.entityId;
    const activationMethod = activationFlg === this.activationTypes.ACTIVATE ? this.entityActivationService.activateProfile(entityId)
                                                          : activationFlg === this.activationTypes.DENY ? this.entityActivationService.denyProfile(entityId)
                                                          : null;
                                                
    if (activationMethod) {
      activationMethod.subscribe({
        next: (res) => {
          if(res){
            this.dialogRef.close(activationFlg ===this.activationTypes.ACTIVATE);
            activationFlg ===this.activationTypes.DENY ? this.authService.logout() : this.toastr.success(this.tS.instant('cc.entity.profile-send-successfully'));
          }
        },
        error: (error) => this.toastr.error(error.error.message),
      });
    }
  }
}
