import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { CsrConsumerService, McForm, McGod, McProductService, McValueLabelList, PpOrder, PpOrderService } from '@miticon-ui/mc-core';
import {Subject} from "rxjs";
import moment from "moment";

@Component({
  selector: 'pp-invoice-generate-invoice-bulk-part',
  templateUrl: './pp-invoice-generate-invoice-bulk-part.component.html',
  styleUrls: ['./pp-invoice-generate-invoice-bulk-part.component.css']
})
export class PpInvoiceGenerateInvoiceBulkPartComponent implements OnInit {

  //Vlls
  invoiceTypeVll = new McValueLabelList();
  entitiesVll = new McValueLabelList()

  //Form
  mcForm = new McForm();
  servicePeriodFrom: any;
  servicePeriodTo: any;
  entityIds: any[];
  clearEntitiesFilter = new Subject<boolean>();
  invoiceType: string;

  //Indicators
  apiErrorFlg: boolean
  errorMsg: string
  badInputsFlg: boolean
  badInputsErrorMsg: string
  generatedFlg: boolean

  @Output() eventCanceled = new EventEmitter();
  @Output() eventSaved = new EventEmitter();

  constructor(
    private ppOrderService: PpOrderService,
    private csrConsumerService: CsrConsumerService
  ) {
  }

  ngOnInit(): void {
    this.actLoad()
  }

  actLoad(){
    this.invoiceTypeVll = PpOrder.getTypeCdVll();
    this.loadEntities(false);
  }

  loadEntities(externalStudios: boolean){
    let entityList = new McValueLabelList();

    if(this.invoiceType === PpOrder.TYPE_CD_CUSTOM_CONTRACT360){
      this.loadGymSoundConsumers(entityList)
    }
    else{
      this.loadFranchiseConsumers(entityList, externalStudios)
    }
  }

  actGenerate(){
    const entitiesIdToStringArr = this.entityIds.map(e => e.toString());
    const filteredEntityIds = entitiesIdToStringArr.filter(arr1 => arr1.length);

    const invoiceTypeCd = this.mcForm.getValue('selInvoiceType');
    const scheduledSendingDate = this.mcForm.getValue('ctrlSendingDate');

    const body: any = {};
    body.entityIds = filteredEntityIds;
    body.typeCd = invoiceTypeCd;
    body.servicePeriodFrom = moment(this.servicePeriodFrom).format('YYYY-MM-DDTHH:mm:ss.sssZ');
    body.servicePeriodTo = moment(this.servicePeriodTo).format('YYYY-MM-DDTHH:mm:ss.sssZ');
    body.scheduledSendingDate = moment(scheduledSendingDate).format('YYYY-MM-DDTHH:mm:ss.sssZ');

    console.log(body)
    this.ppOrderService.generateInvoicesBulk(body).subscribe(
      () => {
        this.generatedFlg = true;
        this.eventSaved.emit();
      },
      (error) => {
        this.errorMsg = error.error.message;
      }
    )
  }

  actCancel(){
    this.eventCanceled.emit();
  }

  onServicePeriodDateFrom(value: any) {
    if (value !== null) {
      this.servicePeriodFrom = value;
    } else {
      this.servicePeriodFrom = null;
    }
  }

  onServicePeriodDateTo(value: any) {
    if (value !== null) {
      this.servicePeriodTo = value;
    } else {
      this.servicePeriodTo = null;
    }
  }

  onSelectedEntity(entitiesVll: []){
    this.entityIds = entitiesVll
  }

  onSelectedInvoiceType(value: any){
    this.invoiceType = value;
    this.loadEntities(value === PpOrder.TYPE_CD_EXTERNAL_FRANCHISE_FEE);
  }

  loadGymSoundConsumers(entityList: McValueLabelList){
    this.csrConsumerService.getConsumersByInvoiceType(this.invoiceType).subscribe(
      (res) => {
        res.forEach((item: any) => {
          entityList.add(item.idRef, item.entityName);
        });
      }).add( ()=> this.entitiesVll = entityList );
  }

  loadFranchiseConsumers(entityList: McValueLabelList, externalStudios: boolean){
    this.csrConsumerService.getReferenceEntities(McGod.getLoggedEntityIdFromToken(), externalStudios).subscribe(
      (res) => {
        res.forEach((item: any) => {
          entityList.add(item.idRef, item.entityName);
        });
      }
    ).add( ()=> this.entitiesVll = entityList )
  }
}
