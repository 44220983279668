/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McEntity2} from '../models/mc-entity2.model';
import {McEntity2Service} from '../services/mc-entity2.service';
import {IMcEntity2} from '../_generated/mc-entity2.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class McEntity2GENERATED extends BaseObject {


  public static readonly STATUS_CD_ACTIVE: string = 'ACTIVE';
  public static readonly STATUS_CD_INACTIVE: string = 'INACTIVE';
  public static readonly STATUS_CD_PENDING_ACTIVATION: string = 'PENDING_ACTIVATION';
  public static readonly STATUS_CD_LIST__ALL = [
    McEntity2GENERATED.STATUS_CD_ACTIVE,
    McEntity2GENERATED.STATUS_CD_INACTIVE,
    McEntity2GENERATED.STATUS_CD_PENDING_ACTIVATION
  ];

  public apiService: McEntity2Service;
  public _rawJson: IMcEntity2;
    id: number = 0;
  abbreviation = '';
  address = '';
  bankAccount = '';
  bankTransactionEntityNameOverride = '';
  countryId: number;
  courtInCharge = '';
  createdAt: number;
  createdBy: number;
  customAttributeMap: string;
  description = '';
  euVat = '';
  extCreditorId = '';
  factoringEnableFlg: boolean;
  fax = '';
  impressum = '';
  ipWhitelist = '';
  isVisibleToChildren: boolean;
  legalRepresentative = '';
  legalRepresentativeContact = '';
  mailDomain = '';
  mailPrefix = '';
  maxTransactionPauseDays: number;
  name = '';
  parentEntityId: number;
  phone = '';
  rootEntityId: number;
  statusCd: string;
  studioManager = '';
  studioOwner = '';
  studioOwnerAddress = '';
  studioOwnerCity = '';
  studioOwnerPostalCode = '';
  transactionDescriptionPrefix = '';
  updatedAt: number;
  updatedBy: number;
  version: number;
  webDomain = '';


  public properties: string[] = ['id', 'abbreviation', 'address', 'bankAccount', 'bankTransactionEntityNameOverride', 'countryId', 'courtInCharge', 'createdAt', 'createdBy', 'customAttributeMap', 'description', 'euVat', 'extCreditorId', 'factoringEnableFlg', 'fax', 'impressum', 'ipWhitelist', 'isVisibleToChildren', 'legalRepresentative', 'legalRepresentativeContact', 'mailDomain', 'mailPrefix', 'maxTransactionPauseDays', 'name', 'parentEntityId', 'phone', 'rootEntityId', 'statusCd', 'studioManager', 'studioOwner', 'studioOwnerAddress', 'studioOwnerCity', 'studioOwnerPostalCode', 'transactionDescriptionPrefix', 'updatedAt', 'updatedBy', 'version', 'webDomain'];
  public propertiesRegular: string[] = ['id', 'abbreviation', 'address', 'bankAccount', 'bankTransactionEntityNameOverride', 'countryId', 'courtInCharge', 'createdAt', 'createdBy', 'customAttributeMap', 'description', 'euVat', 'extCreditorId', 'factoringEnableFlg', 'fax', 'impressum', 'ipWhitelist', 'isVisibleToChildren', 'legalRepresentative', 'legalRepresentativeContact', 'mailDomain', 'mailPrefix', 'maxTransactionPauseDays', 'name', 'parentEntityId', 'phone', 'rootEntityId', 'statusCd', 'studioManager', 'studioOwner', 'studioOwnerAddress', 'studioOwnerCity', 'studioOwnerPostalCode', 'transactionDescriptionPrefix', 'updatedAt', 'updatedBy', 'version', 'webDomain'];
  public propertiesSpecial: string[] = [];



  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
    list.add(McEntity2GENERATED.STATUS_CD_ACTIVE, McGod.t('Active'));
    list.add(McEntity2GENERATED.STATUS_CD_INACTIVE, McGod.t('Inactive'));
    list.add(McEntity2GENERATED.STATUS_CD_PENDING_ACTIVATION, McGod.t('Pending activation'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = McEntity2GENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): McEntity2 {
    console.error('MTCN-ERROR: Not Implemented: McEntity2::createNewInstance(). Add this method to final class and return new McEntity2();');
    throw new Error('Cannot McEntity2::createNewInstance(). Add this method to final class and return new McEntity2();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcEntity2): McEntity2 {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McEntity2Service));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McEntity2 AABB()');

  }

  // ---------------------------------------------------------------------


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --


  public getStatusCdLabel(): string {
    return McEntity2GENERATED.getStatusCdLabel(this.statusCd);
  }


  public  isStatusCdACTIVE(): boolean {
    const result = (this.statusCd === McEntity2GENERATED.STATUS_CD_ACTIVE);

    return result;
  }

  public  isStatusCdINACTIVE(): boolean {
    const result = (this.statusCd === McEntity2GENERATED.STATUS_CD_INACTIVE);

    return result;
  }

  public  isStatusCdPENDING_ACTIVATION(): boolean {
    const result = (this.statusCd === McEntity2GENERATED.STATUS_CD_PENDING_ACTIVATION);

    return result;
  }



}
