import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CsrBankAccount,
  CsrBankAccountService,
  CsrConsumerService, CsrPaymentDetails,
   McBankAccount, McBankAccountService, McEntity2Service, McGod, McValueLabelList
} from '@miticon-ui/mc-core';
import { ICON_ATTRIBUTE } from '../../../../../assets/media/svg_icons/icon-attribute';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ICON_FAILED} from '../../../../../assets/media/svg_icons/icon-failed'
import { ICON_IMPORTED } from '../../../../../assets/media/svg_icons/icon-imported';

@Component({
  selector: 'lib-invoice-step-payment',
  templateUrl: './invoice-step-payment.component.html',
  styleUrls: ['./invoice-step-payment.component.scss']
})
export class InvoiceStepPaymentComponent implements OnInit {

  stepForm!: FormGroup;
  @Output() isDataFormInvalid = new EventEmitter<boolean>();
  @Output() paymentDetails = new EventEmitter<CsrPaymentDetails>();
  @Input() parentForm!: FormGroup;
  @Input() csrConsumerId!: number;
  iconGenerate= ICON_ATTRIBUTE;
  iconSuccess = ICON_IMPORTED;
  iconFailed = ICON_FAILED;

  paymentMethods = [
    {value: CsrPaymentDetails.TYPE_CD_DIRECT_DEBIT, label: this.tS.instant('cc.invoice.direct-debit')},
    {value: CsrPaymentDetails.TYPE_CD_TRANSFER, label: this.tS.instant('cc.consumer.money-transfer')},
    {value: CsrPaymentDetails.TYPE_CD_CASH, label: this.tS.instant('cc.transaction.adjustment.cash')},
  ];

  primaryBankAccount: CsrBankAccount;
  bankAccounts: CsrBankAccount[];
  transferMoneyOptionsVll = new McValueLabelList();
  mcBankAccountsVll = new McValueLabelList();
  mcBankAccount: McBankAccount | null;
  showBankInfoForTransferMoney : boolean = false;
  csrPaymentDetails = new CsrPaymentDetails();

  constructor(private formBuilder: FormBuilder,
              private mcBankAccountService: McBankAccountService,
              private csrconsumerService: CsrConsumerService,
              private mcEntity2Service: McEntity2Service,
              private csrBankAccountService: CsrBankAccountService,
              private toastr: ToastrService,
              private tS: TranslateService) { }

  ngOnInit() {
    this.createForm();
    this.parentForm.setControl("step2", this.stepForm);
    this.getCsrBankAccountPrimary();
    this.getTransferMoneyOptions();
    this.goNextValidation();
  }

  createForm() {
    this.stepForm = this.formBuilder.group({
      billingOption: [false],
      paymentMethod: [''],
      moneyTransferBankAccs: [''],
      transferMoneyTo: [''],
      differentBankAccount: [false],
      bankName: ['', Validators.required],
      accountOwner: ['', Validators.required],
      iban: ['', Validators.required],
      bic: ['', Validators.required]
    });
  }

  getTransferMoneyOptions() {
    this.mcEntity2Service.getAncestors(McGod.getLoggedEntityIdFromToken())
      .subscribe((entities) => {
        entities.forEach(item => {
          this.transferMoneyOptionsVll.add(item.id, item.name);
        });
      });
  }

  onBankAccountValueChanged(value: any) {
    this.mcBankAccount = value;
    this.showBankInfoForTransferMoney = true;
    this.userDataFormInvalid(false);
    this.setCsrPaymentDetails(this.mcBankAccount!.id, CsrPaymentDetails.TYPE_CD_TRANSFER);
  }

  onTransferMoneyValueChanged(value: any) {
    this.mcBankAccountsVll = new McValueLabelList();
    this.mcBankAccount = null;
    this.mcBankAccountService.getDefaultByEntityId(value).subscribe((bankAccounts) => {
      if(bankAccounts && bankAccounts.length > 1) {
        this.showBankInfoForTransferMoney = false;
        bankAccounts.forEach((bankAccount: McBankAccount) => {
          this.mcBankAccountsVll.addItem({
            label: bankAccount.bankName,
            value: bankAccount
          });
        })
      } else {
        this.mcBankAccount = bankAccounts[0];
        this.showBankInfoForTransferMoney = true;
        this.setCsrPaymentDetails(this.mcBankAccount!.id, CsrPaymentDetails.TYPE_CD_TRANSFER);
      }
      this.userDataFormInvalid(!this.mcBankAccount);
    });
  }

  onDiffBankAccountChanged(event:any) {
    this.stepForm.get('differentBankAccount')?.setValue(event.checked);
    this.userDataFormInvalid(event.checked);
  }

   getCsrBankAccountPrimary(){
    this.csrconsumerService.getById(this.csrConsumerId).subscribe(
      result => {
        this.bankAccounts = result.bankAccounts;
        if(this.bankAccounts){
          this.bankAccounts.forEach(bankAccount => {
            if(bankAccount.flgPrimary){
              this.primaryBankAccount = bankAccount;
            }
          });
        }
      });
  }

  generateSepaMandate() {
    if(this.stepForm.valid){
      let newBankAccount = new CsrBankAccount();
      newBankAccount.flgPrimary = true;
      newBankAccount.flgConsumer360 = true;
      newBankAccount.idCsrConsumer = this.csrConsumerId;
      newBankAccount.iban = this.stepForm.get('iban')?.value;
      newBankAccount.bic = this.stepForm.get('bic')?.value;
      newBankAccount.owner = this.stepForm.get('accountOwner')?.value;
      newBankAccount.bankName = this.stepForm.get('bankName')?.value;

      this.csrBankAccountService.insert(newBankAccount).subscribe({
        next: (data: any) =>{
          if(data){
            this.primaryBankAccount = data;
            this.toastr.success(this.tS.instant("cc.consumer360.generated-sepa-mandate"));
            this.userDataFormInvalid(false);
            this.setCsrPaymentDetails(this.primaryBankAccount.id, CsrPaymentDetails.TYPE_CD_DIRECT_DEBIT)
            this.stepForm.patchValue({
              bankName: '',
              accountOwner: '',
              iban: '',
              bic: ''
            });
          }
        },
        error: (err) =>{
          this.toastr.error(err.error.message);
        }
      });
    }
    else{
      this.toastr.error(this.tS.instant('cc.invoice.transaction-form-invalid'));
    }
  }

  userDataFormInvalid(data: boolean) {
    this.isDataFormInvalid.emit(data);
  }

  goNextValidation() {
    this.stepForm.get('billingOption')?.valueChanges.subscribe((value) => {
      this.stepForm.patchValue({
        paymentMethod: '',
      });
      this.userDataFormInvalid(value);
    });
    this.stepForm.get('paymentMethod')?.valueChanges.subscribe((value) => {
      if(value === 'CASH') this.setCsrPaymentDetails(0, CsrPaymentDetails.TYPE_CD_CASH);

      this.userDataFormInvalid(value != CsrPaymentDetails.TYPE_CD_CASH);
      this.stepForm.patchValue({
        moneyTransferBankAccs: '',
        transferMoneyTo: '',
        bankName: '',
        accountOwner: '',
        iban: '',
        bic: ''
      });
      if(value===CsrPaymentDetails.TYPE_CD_DIRECT_DEBIT && this.primaryBankAccount) {
        this.userDataFormInvalid(false);
        this.setCsrPaymentDetails(this.primaryBankAccount.id, CsrPaymentDetails.TYPE_CD_DIRECT_DEBIT);
      }
    });
  }

  setCsrPaymentDetails(idRef: number, typeCd: string) {
    this.csrPaymentDetails.idRef = idRef;
    this.csrPaymentDetails.typeCd = typeCd
    this.csrPaymentDetails.refTypeCd = (typeCd === CsrPaymentDetails.TYPE_CD_DIRECT_DEBIT ?  CsrPaymentDetails.REF_TYPE_CD_CSR_BANK_ACCOUNT :  '');
    this.paymentDetails.emit(this.csrPaymentDetails);
  }

}
