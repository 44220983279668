/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {EmailTemplate} from '../models/email-template.model';
import {EmailTemplateService} from '../services/email-template.service';
import {IEmailTemplate} from '../_generated/email-template.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class EmailTemplateGENERATED extends BaseObject {


  public apiService: EmailTemplateService;
  public _rawJson: IEmailTemplate;
    id: number = 0;
  idEmailConfig: number;
  idMcEntity: number;
  languageCode = '';
  sysCreatedDatetime: number;
  sysCreatedUserId: number;
  sysUpdatedDatetime: number;
  webFileId: number;


  public properties: string[] = ['id', 'idEmailConfig', 'idMcEntity', 'languageCode', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'webFileId'];
  public propertiesRegular: string[] = ['id', 'idEmailConfig', 'idMcEntity', 'languageCode', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'webFile', 'webFileId'];
  public propertiesSpecial: string[] = [];


  // ---------------------------------------------------------------------
public static createNewInstance(): EmailTemplate {
    console.error('MTCN-ERROR: Not Implemented: EmailTemplate::createNewInstance(). Add this method to final class and return new EmailTemplate();');
    throw new Error('Cannot EmailTemplate::createNewInstance(). Add this method to final class and return new EmailTemplate();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IEmailTemplate): EmailTemplate {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EmailTemplateService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE EmailTemplate AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }

    public getSysUpdatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysUpdatedDatetime);
    }

    public getSysUpdatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysUpdatedDatetime);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  
}
