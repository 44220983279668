import { CdkStepperModule } from "@angular/cdk/stepper";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule,ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepicker,MatDatepickerInput,MatDatepickerToggle } from '@angular/material/datepicker';
import { MatDialogContent } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from "@angular/router";
import { McComponentsModule,McFilterModule,McTableModule } from "@miticon-ui/mc-components";
import { InvoiceScheduleSendDialogComponent } from './components/invoice-schedule-send-dialog/invoice-schedule-send-dialog.component';
import { NgbDatepickerModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { SafeHtmlModule } from '../../../assets/pipes/safe-html/safe-html.module';
import { InvoiceStepPaymentComponent } from './components/invoice-step-payment/invoice-step-payment.component';
import { InvoiceStep1Component } from "./components/invoice-step1/invoice-step1.component";
import { InvoiceStep2Component } from "./components/invoice-step2/invoice-step2.component";
import { PpInvoiceGenerateInvoiceBulkPartComponent } from './components/pp-invoice-generate-invoice-bulk-part/pp-invoice-generate-invoice-bulk-part.component';
import { McInvoiceCreationComponent } from "./mc-invoice-creation/mc-invoice-creation.component";
import { McInvoiceEditComponent } from "./mc-invoice-edit/mc-invoice-edit.component";
import { McInvoiceOverviewComponent } from "./mc-invoice-overview/mc-invoice-overview.component";

@NgModule({
  declarations: [
    McInvoiceOverviewComponent,
    McInvoiceCreationComponent,
    InvoiceStep1Component,
    InvoiceStep2Component,
    McInvoiceEditComponent,
    PpInvoiceGenerateInvoiceBulkPartComponent,
    InvoiceStepPaymentComponent,
    InvoiceScheduleSendDialogComponent,
  ],

  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    McComponentsModule,
    TranslateModule,
    McFilterModule,
    McTableModule,
    CdkStepperModule,
    NgbDatepickerModule,
    RouterModule,
    MatRadioModule,
    SafeHtmlModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogContent,
    MatDatepicker,
    MatDatepickerToggle,
    MatDatepickerInput,
  ],

  exports: [McInvoiceOverviewComponent, McInvoiceCreationComponent, InvoiceStep1Component, InvoiceStep2Component, InvoiceStepPaymentComponent],
})
export class McInvoiceModule {}
