import {Component, OnInit} from '@angular/core';
import { EbFactoring, EbFactoringFilter, McGod, McValueLabelList, McBoolean, McEntity2, SortCriteriaList, EbFactoringService, WebFile, WebFileService } from '@miticon-ui/mc-core';
import { MkFilterConfig, MkMatMenuItem, MkMatTableMenuAction, MkTableConfig, MkFilterItemType, MkFilterValue, MkFilterOutput, ConfirmationDialogComponent } from '@miticon-ui/mc-components';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import * as fileSaver from 'file-saver';
import { Router } from '@angular/router';

@Component({
  selector: "mc-out-factoring-contract-list-page",
  templateUrl: "./mc-out-factoring-contract-list-page.component.html",
})

export class McOutFactoringContractListPageComponent implements OnInit {

  ebFactoringFilter = new EbFactoringFilter();
  profileEbFactoringId!: number;
  ebFactoring!: EbFactoring;
  showAddEditPart = new McBoolean();
  showSelectEntityPart = new McBoolean();
  selectedEntityId!: number;
  contractToRevoke!: EbFactoring;
  statusPendingMcb = new McBoolean();
  statusNotPendingMcb = new McBoolean();
  mcGod = McGod.getInstance();
  contractType!: string;
  isContractTypeService!: boolean;
  isContractBeingCloned = false;
  ebFactoringId!: number;
  entityId!: number;
  entityForCloning!: McEntity2;
  searchTooltip = this.tS.instant('cc.my-factoring.search-by-contract-name');
  items: EbFactoring[] = [];
  pageNumber = 0;
  pageSize = 5;
  isLoading = false;
  totalItemsCount!: number;
  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();

  tableActionItems: MkMatMenuItem[] = [
    {
      title: this.tS.instant('cc.consumer.filter.view-contract'),
      actionCd: EbFactoring.ACTION_SHOW_PROFILE,
      matIcon: 'remove_red_eye',
      permission: McGod.PERM_EB_FACTORING_OUT_CONTRACT_VIEW
    },
    {
      title: this.tS.instant('cc.factoring.revoke'),
      actionCd: EbFactoring.ACTION_SHOW_REVOKE,
      matIcon: 'settings_backup_restore ',
      permission: McGod.PERM_EB_FACTORING_OUT_CONTRACT_VIEW
    },
    {
      title: this.tS.instant('cc.contracts.clone-contract'),
      actionCd: EbFactoring.ACTION_CLONE,
      matIcon: 'content_copy',
      permission: McGod.PERM_EB_FACTORING_OUT_CONTRACT_VIEW
    },
    {
      title: this.tS.instant('cc.out-factoring.download'),
      actionCd: EbFactoring.ACTION_DOWNLOAD,
      matIcon: 'download',
      permission: McGod.PERM_EB_FACTORING_OUT_CONTRACT_VIEW
    }
  ]
  // ---------------------------------------------------------------------

  constructor(private ebFactoringService: EbFactoringService,
              private tS: TranslateService,
              private toastr: ToastrService,
              private dialog: MatDialog,
              private webFileService: WebFileService,
              private router: Router) {
  }

  ngOnInit() {
    this.actLoad(this.pageNumber, this.pageSize, this.ebFactoringFilter);
    this.initTableConfig();
    this.initFilterConfig();
  }

  // ---------------------------------------------------------------------

  public actLoad(pageNumber: number, pageSize: number, ebFactoringFilter: EbFactoringFilter): void {
    this.pageNumber=pageNumber;
    this.pageSize= pageSize;
    this.isLoading = true;
    this.statusPendingMcb.setFalse();
    this.ebFactoringFilter.outFactoringFlg = true;
    const sortCriteriaList = new SortCriteriaList();
    this.ebFactoringService.getByFilter(ebFactoringFilter, this.pageNumber,this.pageSize, sortCriteriaList).subscribe((res)=> {
      if(res){
        this.isLoading=false;
        this.totalItemsCount=res.totalElements;
        this.items = res.content.map((object: any) => EbFactoring.createFromJson(object));
      }
    })
  }

  initTableConfig(){
    this.tableConfig.addColumnSvgIconAction(this.tS.instant('cc.my-factoring.contract-name'), 'getContractNameAndProfileLink()', 'Profile');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.factoring.transactions.entity-name'), 'getChildEntityName()', 250);
    this.tableConfig.addColumnSvgIcon(this.tS.instant('cc.common.contract-type'), 'getContractTypeFld()');
    this.tableConfig.addColumnStandard(this.tS.instant('cc.factoring.start-date'), 'getStartDate()', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.factoring.end-date'), 'getEndDate()', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.common.view.status'), 'getStatusCdLabel()', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.factoring.rejection-reason'), 'contractRejectionReason', 250);
  }

  onTableAction($event: MkMatTableMenuAction) {
    switch ($event.action.actionCd){
      case EbFactoring.ACTION_SHOW_PROFILE:
        this.actShowProfileModal($event.item);
        if ($event.item.statusCd === EbFactoring.STATUS_CD_PENDING_ACCEPTANCE) {
          this.statusPendingMcb.setTrue();
          this.statusNotPendingMcb.setFalse();
        } else {
          this.statusNotPendingMcb.setTrue();
          this.statusPendingMcb.setFalse();
        }
        break;

      case EbFactoring.ACTION_CLONE:
        this.showSelectEntityPart.setTrue();
        if ($event.item.typeCd === 'FACTORING') {
          this.isContractTypeService = false;
        }
        if ($event.item.typeCd === 'SERVICE') {
          this.isContractTypeService = true;
        }
        this.isContractBeingCloned = true;
        this.ebFactoringId = $event.item.id;
        this.entityId = $event.item.idChildMcEntity;
        this.contractType = $event.item.typeCd;
        this.ebFactoring = $event.item;
        break;

      case EbFactoring.ACTION_SHOW_REVOKE:
        if ($event.item.statusCd === EbFactoring.STATUS_CD_PENDING_ACCEPTANCE) {
          this.contractToRevoke = $event.item;
          this.openRevokeContractDialog(this.contractToRevoke);
        } else {
          this.toastr.error(this.tS.instant('cc.out-factoring.you-can-only-revoke-contract-in-status-pending-acceptance'));
        }
        break;

      case EbFactoring.ACTION_DOWNLOAD:
        this.actDownloadContract($event.item.idWebFile);
        break;
    }
  }

  openRevokeContractDialog(item: EbFactoring){
    this.dialog.open(ConfirmationDialogComponent,{
      height: '230px',
      autoFocus: false,
      restoreFocus: false,
      data: {
        header: 'cc.out-factoring.revoke-contract',
        description: this.tS.instant('cc.out-factoring.are-you-sure-you-want-to-revoke-this-contract') + ' ?',
        cancelBtnLabel: 'cc.common.edit.cancel',
        confirmBtnLabel: 'cc.factoring.revoke'
      }
    }).afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.handleRevokeAction(item);
      }
    });
  }

  initFilterConfig(){
    const statusValueLableList = EbFactoring.getStatusCdVll();
    const typeValueLableList = EbFactoring.getTypeCdVll();
    const entityValueLabelList = new McValueLabelList();
    const ebFactoringFilter = new EbFactoringFilter();
    ebFactoringFilter.outFactoringFlg = true;
    this.ebFactoringService.getByFilter(ebFactoringFilter, 0, 100, new SortCriteriaList()).subscribe((res)=> {
      if(res){
        res.content.forEach((item: EbFactoring) => {
          if(!entityValueLabelList.hasValue(item.childEntity.id)){
            entityValueLabelList.add(item.childEntity.id, item.childEntity.name);
          }
        });
        this.filterConfig.addItem(MkFilterItemType.MULTISELECT, this.tS.instant('cc.factoring.transactions.entity-name'), entityValueLabelList.items.map((item) => new MkFilterValue(item.label, item.value)));
      }
    });
    this.filterConfig.addItem(MkFilterItemType.SELECT, this.tS.instant('cc.common.contract-type'), typeValueLableList.items.map((item) =>new MkFilterValue(item.label, item.value)));
    this.filterConfig.addItem(MkFilterItemType.MULTISELECT, this.tS.instant('cc.common.view.status'), statusValueLableList.items.map((item) =>new MkFilterValue(item.label, item.value)));
  }

  onFilterChanged(filterOutput: MkFilterOutput){
    this.ebFactoringFilter.searchTerm = filterOutput.search ? filterOutput.search : '';
    this.ebFactoringFilter.contractType = filterOutput.selections[this.tS.instant('cc.common.contract-type')] ? filterOutput.selections[this.tS.instant('cc.common.contract-type')] : '';
    this.ebFactoringFilter.statusCds = MkFilterOutput.convertFilterSelectionToArray(filterOutput.selections[this.tS.instant('cc.common.view.status')] ? filterOutput.selections[this.tS.instant('cc.common.view.status')] : []);
    this.ebFactoringFilter.childEntityIds = MkFilterOutput.convertFilterSelectionToArray(filterOutput.selections[this.tS.instant('cc.factoring.transactions.entity-name')] ? filterOutput.selections[this.tS.instant('cc.factoring.transactions.entity-name')] : []);

    this.actLoad(filterOutput.pageEvent.pageIndex, filterOutput.pageEvent.pageSize, this.ebFactoringFilter);
  }

  actShowProfileModal(ebFactoring: EbFactoring) {
    this.profileEbFactoringId = ebFactoring.id;
    this.ebFactoring = ebFactoring;
    const serializableEbFactoring = this.makeSerializable(ebFactoring);
    this.router.navigateByUrl(`/entity/${McGod.getLoggedEntityIdFromToken()}/my-factoring/view-contact`, {
      state: { contractData: serializableEbFactoring}
    });
  }

  makeSerializable(obj: any): any {
    return JSON.parse(JSON.stringify(obj, (key, value) => {
        if (typeof value === 'function' || key === 'apiService') {
          return undefined;
        }
        return value;
      }
    ));
  }
  handleRevokeAction(ebFactoring: EbFactoring) {
    this.ebFactoring = ebFactoring;
    this.ebFactoring.statusCd = EbFactoring.STATUS_CD_REVOKED;
    this.ebFactoring.save(() => {
      this.actLoad(this.pageNumber, this.pageSize, this.ebFactoringFilter);
      this.toastr.success(`${this.mcGod.t('cc.my-factoring.factoring-contract')}  '${this.ebFactoring.factoringContractName}' ${this.mcGod.t('cc.out-factoring.revoked-successfully')}.`);
    }, () => {
      this.actLoad(this.pageNumber, this.pageSize, this.ebFactoringFilter);
      this.toastr.error(this.ebFactoring.apiErrorMessage);
    });
  }


  onPrevious() {
    this.showSelectEntityPart.setTrue();
    this.showAddEditPart.setFalse();
  }

  onFactoringContractSaved(value: EbFactoring) {
    this.toastr.success(`Contract '${value.factoringContractName}' created successfully`);
    this.showSelectEntityPart.setFalse();
    this.showAddEditPart.setFalse();
    this.showAddEditPart.setFalse();
    this.actLoad(this.pageNumber, this.pageSize, this.ebFactoringFilter);
  }

  onFactoringContractCanceled() {
    this.showSelectEntityPart.setFalse();
    this.showAddEditPart.setFalse();
  }

  onSelectedEntityId(value: any) {
    this.selectedEntityId = value;
  }

  onSelectEntityAndContractTypeNext(value: any) {
    this.contractType = value;
    this.showSelectEntityPart.setFalse();
    this.showAddEditPart.setTrue();
  }

  onEntityClone(event: McEntity2) {
    this.entityForCloning = event;
    this.selectedEntityId = event.id;
  }

  onCreateNewContract() {
    this.showSelectEntityPart.setTrue();
    this.isContractBeingCloned = false;
  }

  private actDownloadContract(idWebfile: number) {
    this.webFileService.getByIdAndType(idWebfile, WebFile.TYPE_CD_MC_FACTORING_CONTRACT)
      .subscribe({
        next: (webFile) => {
          if (webFile) {
            this.webFileService.downloadWebFile(webFile.id, WebFile.TYPE_CD_MC_FACTORING_CONTRACT).subscribe(response => {
              fileSaver.saveAs(response, `${webFile.filename}`);
            })
          }
        },
        error: () => {
          this.toastr.error(this.tS.instant("cc.out-factoring.contract-pdf-cannot-be-found"));
        }
      });
  }

  onColumnButtonAction(event: any) {
    switch(event.actionCd) {
      case 'Profile':
        this.actShowProfileModal(event.element);
        if (event.element.statusCd === EbFactoring.STATUS_CD_PENDING_ACCEPTANCE) {
          this.statusPendingMcb.setTrue();
          this.statusNotPendingMcb.setFalse();
        } else {
          this.statusNotPendingMcb.setTrue();
          this.statusPendingMcb.setFalse();
        }
        break;
    }
  }
}
