import { Component, OnInit } from '@angular/core';
import { CsrConsumer, CsrConsumerService } from '@miticon-ui/mc-core';
import {ActivatedRoute} from "@angular/router";
import moment from 'moment';

@Component({
  selector: 'lib-mc-consumer-consumer-360-additional-info-part',
  templateUrl: './mc-consumer-consumer-360-additional-info-part.component.html',
  styleUrls: ['./mc-consumer-consumer-360-additional-info-part.component.scss']
})
export class McConsumerConsumer360AdditionalInfoPartComponent implements OnInit {
  csrConsumer: CsrConsumer =  new CsrConsumer();
  csrContract: any;

  constructor(private route: ActivatedRoute,
              private csrConsumerService: CsrConsumerService) { }

  ngOnInit(): void {
    this.actLoad();
  }

  actLoad() {
    this.route.paramMap.subscribe((params) => {
      // @ts-ignore
      const consumerId = +params.get('id');
      this.csrConsumer.apiLoadingFlg = true;
      this.loadConsumer(consumerId);
    });
  }

  loadConsumer(consumerId: number) {
    this.csrConsumerService.getById(consumerId).subscribe((csrConsumer: any) => {
      this.csrConsumer = csrConsumer;
      this.csrContract = csrConsumer.activeContracts[0];
    });
  }

  getFormattedDate(date: any) {
    return date ? moment(date).format('DD.MM.YYYY') : '-';
  }

  hasCustomInfo() {
    for (const key in this.csrConsumer.additionalAttributes) {
      if (this.csrConsumer.additionalAttributes.hasOwnProperty(key)) {
        if (!key.includes("contract")) {
          return true;
        }
      }
    }
    return false;
  }
}
