
import {Table3Config} from '../extra/table-3-config';

// import {EbConsumerBlacklistGENERATED} from '../_generated/eb-consumer-blacklist-generated.model';

export class EbConsumerBlacklistTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colAddedDate', 'Added date', 'addedDate', 'addedDate');
    this.addColumn('colAddedReason', 'Added reason', 'addedReason', 'addedReason');
    this.addColumn('colAddedUserId', 'Added user id', 'addedUserId', 'addedUserId');
    this.addColumn('colBirthday', 'Birthday', 'birthday', 'birthday');
    this.addColumn('colCity', 'City', 'city', 'city');
    this.addColumn('colEmail', 'Email', 'email', 'email');
    this.addColumn('colExtTransactionId', 'External Transaction ID', 'extTransactionId', 'extTransactionId');
    this.addColumn('colFirstName', 'First name', 'firstName', 'firstName');
    this.addColumn('colIban', 'IBAN', 'iban', 'iban');
    this.addColumn('colIdCsrConsumer', 'Consumer ID', 'idCsrConsumer', 'idCsrConsumer');
    this.addColumn('colIdMcEntity', 'Entity ID', 'idMcEntity', 'idMcEntity');
    this.addColumn('colIdTransaction', 'Transaction ID', 'idTransaction', 'idTransaction');
    this.addColumn('colLastName', 'Last name', 'lastName', 'lastName');
    this.addColumn('colPostCode', 'Post code', 'postCode', 'postCode');
    this.addColumn('colRemovedDate', 'Removed date', 'removedDate', 'removedDate');
    this.addColumn('colRemovedReason', 'Removed reason', 'removedReason', 'removedReason');
    this.addColumn('colRemovedUserId', 'Removed user id', 'removedUserId', 'removedUserId');
    this.addColumn('colStreet', 'Street', 'street', 'street');

*/
  }
}
