<ng-container class="dialog">
  <div class="dialog_header">
    <span class="title-medium-dark">{{ "cc.email-custom-template-instructions" | translate }}</span>
    <span class="dialog_header_icon" [innerHTML]="iconClose | safeHtml" (click)="dialogRef.close(false)"></span>
  </div>
  <mat-dialog-content>
    <div class='email-description'>
      <span >{{'cc.email-custom-text' | translate}}.</span>
    </div>
    <div class='dialog-col' *ngIf="data.requiredElements.length > 0">
      <span class='dialog-col-value'>{{"cc.required-elements" | translate}}:</span>
      <div class='card email-req' *ngFor="let element of data.requiredElements">
        <span> {{ element }} </span>
      </div>
    </div>
    <div class='dialog-col' *ngIf="data.optionalElements.length > 0">
      <span class='dialog-col-value'>{{"cc.optional-elements" | translate }}:</span>
      <div class='card email-req' *ngFor="let element of data.optionalElements">
        <span> {{ element }} </span>
      </div>
    </div>

    <div class="email-description" *ngIf="data.requiredElements.length > 0">
      <span>{{"cc.email-custom-footer" | translate}}.</span>
    </div>
  </mat-dialog-content>
  <div class='dialog-footer'>
    <button mat-button class="secondary-btn" (click)="dialogRef.close(false)">
      {{ "cc.common.edit.cancel" | translate }}
    </button>
  </div>
</ng-container>
