/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {McProductTable3ConfigGENERATED} from '../_generated/mc-product-table-3-config-generated.model';

// import {McProductGENERATED} from '../_generated/mc-product-generated.model';

export class McProductTable3Config extends McProductTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();
    this.clearColumns();
    this.addColumn('colActions', '', 'fldActions()', '');
    this.addColumn('name', this.t('cc.common.name'), 'fldName()', 'name');
    this.addColumn('unitCost', this.t('cc.product.view.unit-cost'), 'fldUnitPriceLoc()', 'unitPrice');
    // this.addColumn('unitsInStock', 'Units In Stock', 'unitsInStock', 'unitsInStock');
    this.addColumn('vat', this.t('cc.product.view.vat') + '(%)', 'fldVatRate()', 'vat');
    // this.addColumn('availableFrom', 'Available From', 'fldAvailableFrom()', 'availableFrom');
    // this.addColumn('availableTo', 'Available To', 'fldAvailableTo()', 'availableTo');
    this.addColumn('chargingTypeCd', this.t('pp.product.charging-type'), 'getChargingType()', '');
    this.addColumn('serviceTypeCd', this.t('pp.product.article-type'), 'getProductType()', '');
    this.addColumn('category', this.t('cc.jobs.view.category'), 'fldCategory()', '');
    this.addColumn('status', this.t('cc.common.view.status'), 'state', 'state');
  }
}
