import { Component, Inject, OnInit } from '@angular/core';
import { ICON_CLOSE } from "@miticon-ui/mc-components";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CsrBankAccount, CsrContract, CsrContractPreDefined, CsrPaymentDetails, CsrRecurringDetails, McBankAccount, McGod } from '@miticon-ui/mc-core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-view-contract-dialog',
  templateUrl: './view-contract-dialog.component.html',
  styleUrls: ['./view-contract-dialog.component.scss']
})

export class ViewContractDialogComponent implements OnInit {
  iconClose = ICON_CLOSE;
  reccuringDetails: CsrRecurringDetails;
  paymentDetails: CsrPaymentDetails;
  csrBankAccount: CsrBankAccount;
  mcBankAccount: McBankAccount;
  csrContractPreDefined: CsrContractPreDefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public csrContract: CsrContract,
    public dialogRef: MatDialogRef<ViewContractDialogComponent>,
    public tS: TranslateService,
  ) { }

  ngOnInit(): void {
    this.reccuringDetails = JSON.parse(this.csrContract.jsonbRecurringDetails);
    this.paymentDetails = JSON.parse(this.csrContract.jsonbPaymentDetails);
    this.csrBankAccount = this.csrContract.csrBankAccount;
    if(this.csrContract.mcBankAccount) {
      this.mcBankAccount = this.csrContract.mcBankAccount;
    }
    this.csrContractPreDefined = this.csrContract.csrContractPreDefined;
  }

  getMethod(): string {
    const paymentTypeKey = this.findTrueKey(this.reccuringDetails);

    switch (paymentTypeKey) {
      case 'typeCdWEEKLY':
        return CsrRecurringDetails.getTypeCdLabel(CsrRecurringDetails.TYPE_CD_WEEKLY);
      case 'typeCdMONTHLY':
        return CsrRecurringDetails.getTypeCdLabel(CsrRecurringDetails.TYPE_CD_MONTHLY);
      case 'typeCdQUARTERLY':
        return CsrRecurringDetails.getTypeCdLabel(CsrRecurringDetails.TYPE_CD_QUARTERLY);
      case 'typeCdHALF_YEARLY':
        return CsrRecurringDetails.getTypeCdLabel(CsrRecurringDetails.TYPE_CD_HALF_YEARLY);
      case 'typeCdYEARLY':
        return CsrRecurringDetails.getTypeCdLabel(CsrRecurringDetails.TYPE_CD_YEARLY);
      default:
        return '-';
    }
  }

  private findTrueKey(data: any): string | null {
    for (const key in data) {
      if (data[key] === true) {
        return key;
      }
    }
    return null;
  }

  getDay(): string {
    const paymentMethod = this.getMethod();

    switch (paymentMethod) {
      case McGod.t('Weekly'):
        return new CsrRecurringDetails().getWeekDaysVll().getLabel(this.reccuringDetails.day, '');
      case McGod.t('Monthly'):
      case McGod.t('Quarterly'):
      case McGod.t('Half-yearly'):
      case McGod.t('Yearly'):
        return new CsrRecurringDetails().getMonthDaysVll().getLabel(this.reccuringDetails.day, '');
      default:
        return '-';
    }
  }

  getPriceLabel(typeCd: string) {
    return CsrContractPreDefined.getLabel(typeCd).label;
  }

  getMonth(): string {
    const paymentMethod = this.getMethod();

    switch (paymentMethod) {
      case McGod.t('Quarterly'):
        return new CsrRecurringDetails().getQuarterlyVll().getLabel(this.reccuringDetails.month, '');
      case McGod.t('Half-yearly'):
        return new CsrRecurringDetails().getHalfYearlyVll().getLabel(this.reccuringDetails.month, '');
      case McGod.t('Yearly'):
        return new CsrRecurringDetails().getYearlyVll().getLabel(this.reccuringDetails.month, '');
      default:
        return '-';
    }
  }

  getPaymentMethod(): string {
    const paymentTypeKey = this.findTrueKey(this.paymentDetails);

    switch (paymentTypeKey) {
      case 'typeCdDIRECT_DEBIT':
        return CsrContract.PAYMENT_METHOD_DIRECT_DEBIT;
      case 'typeCdTRANSFER':
        return CsrContract.PAYMENT_METHOD_MONEY_TRANSFER;
      default:
        return '-';
    }
  }

  getTypeCdLabel(typeCd: string): string {
    return typeCd ? this.tS.instant(CsrContractPreDefined.getTypeCdLabel(typeCd)) : '-';
  }

  formatDuration(): string {
    if (!this.csrContractPreDefined) return '-';

    const { durationInMonths, durationInDays } = this.csrContract;

    if (durationInMonths) {
      const key = durationInMonths === 1 ? 'cc.my-factoring.month' : 'cc.my-factoring.months';
      return `${durationInMonths} ${this.tS.instant(key).toLowerCase()}`;
    }

    if (durationInDays) {
      const key = durationInDays === 1 ? 'cc.common.day' : 'cc.factoring.day-s';
      return `${durationInDays} ${this.tS.instant(key).toLowerCase()}`;
    }

    return '-';
  }

  getOriginalAmount(): string {
    return this.getFormattedAmount(this.csrContractPreDefined?.originalAmount);
  }

  getTotalAmount(): string {
    return this.getFormattedAmount(this.csrContractPreDefined?.totalAmount);
  }

  getPrice(): string {
    return this.getFormattedAmount(this.csrContractPreDefined?.price);
  }

  getDiscount(): string {
    const discount = this.csrContractPreDefined?.discount;
    if (!discount) return '-';

    const value = discount.value || '-';
    const unit = discount.typeCd === 'PERCENTAGE' ? '%' : '€';
    return `${value} ${unit}`;
  }

  private getFormattedAmount(amount?: number): string {
    return amount ? `${amount.toFixed(2)}€` : '-';
  }
}
