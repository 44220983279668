/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {EbPsd2Report} from '../models/eb-psd2-report.model';
import {EbPsd2ReportService} from '../services/eb-psd2-report.service';
import {IEbPsd2Report} from '../_generated/eb-psd2-report.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';

import {McCurrency} from '../models/mc-currency.model';


export abstract class EbPsd2ReportGENERATED extends BaseObject {


    public static readonly STATUS_CD_ACTIVE: string = 'ACTIVE';
    public static readonly STATUS_CD_COMPLETED: string = 'COMPLETED';
    public static readonly STATUS_CD_FAILED: string = 'FAILED';
  public static readonly STATUS_CD_LIST__ALL = [
    EbPsd2ReportGENERATED.STATUS_CD_ACTIVE,
    EbPsd2ReportGENERATED.STATUS_CD_COMPLETED,
    EbPsd2ReportGENERATED.STATUS_CD_FAILED
];

  public apiService: EbPsd2ReportService;
  public _rawJson: IEbPsd2Report;
    id: number = 0;
  dateFrom: number;
  dateTo: number;
  entryCount: number;
  idMcBankAccount: number;
  idMcCurrency: number;
  idMcEntity: number;
  statusCd: string;
  sysCreatedDatetime: number;
  sysCreatedUserId: number;
  sysUpdatedDatetime: number;
  bankName: string;  matchedReturnedAmount: any;  matchedReturnedCount: number;  matchedWithdrawnAmount: any;  matchedWithdrawnCount: number;  mcCurrency: McCurrency;  totalReturnedAmount: any;  totalReturnedCount: number;  totalWithdrawnAmount: any;  totalWithdrawnCount: number;

  public properties: string[] = ['id', 'dateFrom', 'dateTo', 'entryCount', 'idMcBankAccount', 'idMcCurrency', 'idMcEntity', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'bankName', 'matchedReturnedAmount', 'matchedReturnedCount', 'matchedWithdrawnAmount', 'matchedWithdrawnCount', 'mcCurrency', 'totalReturnedAmount', 'totalReturnedCount', 'totalWithdrawnAmount', 'totalWithdrawnCount'];
  public propertiesRegular: string[] = ['id', 'dateFrom', 'dateTo', 'entryCount', 'idMcBankAccount', 'idMcCurrency', 'idMcEntity', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'bankName', 'matchedReturnedAmount', 'matchedReturnedCount', 'matchedWithdrawnAmount', 'matchedWithdrawnCount', 'totalReturnedAmount', 'totalReturnedCount', 'totalWithdrawnAmount', 'totalWithdrawnCount'];
  public propertiesSpecial: string[] = ['mcCurrency'];



  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(EbPsd2ReportGENERATED.STATUS_CD_ACTIVE, McGod.t('Active'));
    list.add(EbPsd2ReportGENERATED.STATUS_CD_COMPLETED, McGod.t('Completed'));
    list.add(EbPsd2ReportGENERATED.STATUS_CD_FAILED, McGod.t('Failed'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = EbPsd2ReportGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): EbPsd2Report {
    console.error('MTCN-ERROR: Not Implemented: EbPsd2Report::createNewInstance(). Add this method to final class and return new EbPsd2Report();');
    throw new Error('Cannot EbPsd2Report::createNewInstance(). Add this method to final class and return new EbPsd2Report();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IEbPsd2Report): EbPsd2Report {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EbPsd2ReportService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE EbPsd2Report AABB()');
          if (this._rawJson['mcCurrency']) {
        this.mcCurrency = McCurrency.createFromJson(this._rawJson['mcCurrency']);
      }

  }

  // ---------------------------------------------------------------------
  
    public getDateFromStrD(): string {
       return McDateUtils.formatDateToString(this.dateFrom);
    }

    public getDateFromStr(): string {
       return McDateUtils.formatDateTimeToString(this.dateFrom);
    }

    public getDateToStrD(): string {
       return McDateUtils.formatDateToString(this.dateTo);
    }

    public getDateToStr(): string {
       return McDateUtils.formatDateTimeToString(this.dateTo);
    }

    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }

    public getSysUpdatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysUpdatedDatetime);
    }

    public getSysUpdatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysUpdatedDatetime);
    }

    public getMatchedReturnedAmountStr(): string {
       return McGod.formatNumberToMoney(this.matchedReturnedAmount);
    }

    public getMatchedWithdrawnAmountStr(): string {
       return McGod.formatNumberToMoney(this.matchedWithdrawnAmount);
    }

    public getTotalReturnedAmountStr(): string {
       return McGod.formatNumberToMoney(this.totalReturnedAmount);
    }

    public getTotalWithdrawnAmountStr(): string {
       return McGod.formatNumberToMoney(this.totalWithdrawnAmount);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();
    if (this.hasOwnProperty('mcCurrency')) {
        if (this.mcCurrency != null) {
            dto['mcCurrency'] = this.mcCurrency.toDto();
        } else {
            dto['mcCurrency'] = null;
        }
    }

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getStatusCdLabel(): string {
    return EbPsd2ReportGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdACTIVE(): boolean {
    const result = (this.statusCd === EbPsd2ReportGENERATED.STATUS_CD_ACTIVE);

    return result;
  }

 public  isStatusCdCOMPLETED(): boolean {
    const result = (this.statusCd === EbPsd2ReportGENERATED.STATUS_CD_COMPLETED);

    return result;
  }

 public  isStatusCdFAILED(): boolean {
    const result = (this.statusCd === EbPsd2ReportGENERATED.STATUS_CD_FAILED);

    return result;
  }


  
}
