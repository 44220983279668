/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {EmailConfigKeyTable3ConfigGENERATED} from '../_generated/email-config-key-table-3-config-generated.model';

// import {EmailConfigKeyGENERATED} from '../_generated/email-config-key-generated.model';

export class EmailConfigKeyTable3Config extends EmailConfigKeyTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colKey', 'Key', 'key', 'key');
    this.addColumn('colName', 'Name', 'name', 'name');
    this.addColumn('colRequiredFlg', 'Required flag', 'requiredFlg', 'requiredFlg');


  }
}
