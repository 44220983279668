<ng-container class="dialog">
  <div class="dialog_header">
    <span class="dialog_header_title text-uppercase">
      {{ ("cc.contract-360" | translate) + ': ' + csrContractPreDefined.name }}
    </span>
    <span class="dialog_header_icon"
          [innerHTML]="iconClose | safeHtml"
          (click)="dialogRef.close()"></span>
  </div>

  <mat-dialog-content>
<!--    Basic informations-->
    <div class='card'>
      <div class="items info">
        <div class="items_info">
          <div class='card_title'>{{ "cc.common.basic-information" | translate }}</div>
        </div>
      </div>
      <div *ngIf="!isOneTimeType()" class="dialog-row-wrapper">
        <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ "cc.common.status" | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">{{ csrContractPreDefined.statusCd | titlecase }}</span>
        </div>
        <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ "cc.common.name" | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">{{ csrContractPreDefined.name }}</span>
        </div>
        <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ "cc.consumer.total-netto" | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">{{ csrContractPreDefined.totalNetto + '€' }}</span>
        </div>
        <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ "cc.common.original-amount" | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">{{ csrContractPreDefined.originalAmount + '€' }}</span>
        </div>
        <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ "cc.common.discount" | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">{{ discountValue ? discountValue + '%'  : '-' }}</span>
        </div>
        <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ "cc.billing.total-amount" | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">{{ csrContractPreDefined.totalAmount + '€' }}</span>
        </div>
        <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ getPriceLabel() | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">{{ csrContractPreDefined.price.toFixed(2) + '€' }}</span>
        </div>
        <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ "mem.articles.one-time" | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">{{ csrContractPreDefined.priceOneTime.toFixed(2) + '€' }}</span>
        </div>
      </div>
      <div *ngIf="isOneTimeType()" class="dialog-row-wrapper">
        <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ "cc.common.status" | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">{{ csrContractPreDefined.statusCd | titlecase }}</span>
        </div>
        <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ "cc.common.name" | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">{{ csrContractPreDefined.name }}</span>
        </div>
        <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ "cc.consumer.total-netto" | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">{{ csrContractPreDefined.totalNetto + '€' }}</span>
        </div>
        <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ "mem.common.total-brutto" | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">{{ csrContractPreDefined.totalAmount + '€' }}</span>
        </div>
        <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ "cc.product.view.vat" | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">{{ getVat() + ' %' }}</span>
        </div>
      </div>
      <div class="dialog-row-wrapper description">
        <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ "cc.common.view.description" | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">{{ csrContractPreDefined.description ? csrContractPreDefined.description : '-' }}</span>
        </div>
      </div>
    </div>

<!-- Contract 360 type -->
    <div class='card'>
      <div class="items info">
        <div class="items_info">
          <div class='card_title'>{{ "cc.contract-360.type" | translate }}</div>
        </div>
      </div>
      <div class="dialog-row-wrapper">
        <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ "cc.contract-360.type" | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">
            {{ getTypeCdLabel(csrContractPreDefined.typeCd) }}
          </span>
        </div>
      </div>
    </div>

<!--    Products-->
    <div class='card'>
      <div class="items info">
        <div class="items_info">
          <div class='card_title'>{{ "cc.common.articles" | translate }}</div>
        </div>
      </div>
      <div class="mc-table-wrapper mt-small products-table">
        <div class="mc-table">
          <table class="w-100">
            <thead class="mc-table-head">
            <tr>
              <th class="text-left">{{'cc.consumer.art-no' | translate}}</th>
              <th class="text-left">{{'cc.consumer.art-name' | translate}}</th>
              <th class="text-left">{{'cc.common.view.description' | translate}}</th>
              <th class="text-right">{{'cc.consumer.price-netto' | translate}}</th>
              <th class="text-right">{{'cc.product.view.vat' | translate}}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor='let selectedProduct of csrContractPreDefined.products.items'>
              <td class="text-left">{{selectedProduct.id}}</td>
              <td class="text-left">{{selectedProduct.name}}</td>
              <td class="text-left">{{selectedProduct.description}}</td>
              <td class="text-right">{{selectedProduct?.unitPrice?.toFixed(2)}}€</td>
              <td class="text-right">{{selectedProduct.vatRate ? selectedProduct.vatRate.toFixed(2).replace('.', ',') : '0'}}%</td>
              <td></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

<!--    Contract 360 duration-->
    <div class='card'>
      <div class="items info">
        <div class="items_info">
          <div class='card_title'>{{ "cc.contract-360.duration" | translate }}</div>
        </div>
      </div>
      <div class="dialog-row-wrapper">
        <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ "cc.contract-360.duration" | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">
            {{ csrContractPreDefined.durationInMonths ?
               csrContractPreDefined.durationInMonths + " " +
               ((csrContractPreDefined.durationInMonths === 1 ? "cc.common.month" : "cc.my-factoring.months") | translate | lowercase) :
               '-'
            }}
          </span>
        </div>
      </div>
    </div>

<!--    Offer duration-->
    <div class='card'>
      <div class="items info">
        <div class="items_info">
          <div class='card_title'>{{ "cc.contract-360.offer-duration" | translate }}</div>
        </div>
      </div>
      <div class="dialog-row-wrapper">
        <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ "cc.factoring.start-date" | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">{{ csrContractPreDefined.startDate | date : "dd.MM.YYYY" }}</span>
        </div>
        <div class="dialog-col">
          <span class="dialog_content_row dialog-col-label">{{ "cc.factoring.end-date" | translate }}:</span>
          <span class="dialog_content_row dialog-col-value">{{ csrContractPreDefined.endDate ? (csrContractPreDefined.endDate | date : "dd.MM.YYYY") : '-' }}</span>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <div class='dialog_footer'>
    <button mat-button
            class="secondary-btn"
            (click)="dialogRef.close()">
      {{ "cc.common.close" | translate }}
    </button>
  </div>
</ng-container>
