/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectList} from '../_core/base-object-list';
import {EntityInvitation} from '../models/entity-invitation.model';
import {EntityInvitationList} from '../models/entity-invitation-list.model';
import {EntityInvitationFilter} from '../models/entity-invitation-filter.model';
import {EntityInvitationService} from '../services/entity-invitation.service';
import {IEntityInvitation} from '../_generated/entity-invitation.interface';
import {McGod} from "../extra/mc-god.service";

export class EntityInvitationListGENERATED extends BaseObjectList {
  public propertyNames: string[] = ['id', 'createdAt', 'email', 'idMcEntity', 'invitationCode', 'invitedEntityId', 'status', 'usedAt'];
  public items: EntityInvitation[] = [];
  public apiService: EntityInvitationService;
 // protected tempObjList: EntityInvitationList;

  // ---------------------------------------------------------------------
  public static createNewInstance(): EntityInvitationList {
    console.error('MTCN-ERROR: Not Implemented: EntityInvitationList::createNewInstance(). Add this method to final class and return new EntityInvitationList();');
    throw new Error('Cannot EntityInvitationList::createNewInstance(). Add this method to final class and return new EntityInvitationList();');
  }

  // ---------------------------------------------------------------------
  public static createFromJson(aJsonObjArray: IEntityInvitation[]): EntityInvitationList {
    const  listObj: EntityInvitationList = this.createNewInstance();
    listObj.populateFromJson(aJsonObjArray);
 //   aJsonObjArray.forEach((singleItemJson, index) => {
 //     const myObj: EntityInvitation = EntityInvitation.createFromJson(singleItemJson);
 //     listObj.items[index] = myObj;
 //   });

    return listObj;
  }

  // ---------------------------------------------------------------------
  public static createFromJsonPageable(aJsonObjPageable: any): EntityInvitationList {
    const  listObj: EntityInvitationList = this.createNewInstance();
    listObj.populateFromJsonPageable(aJsonObjPageable);
    return listObj;
  }

  // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EntityInvitationService));
  }

  // ---------------------------------------------------------------------
  public clear() {
    this.items = [];
    // TODO this.selectedList.clear();
  }
  // ---------------------------------------------------------------------
  public  populateFromJsonPageable(aJsonObjPageable: any): void {
    const listObj = this.populateFromJson(aJsonObjPageable.content);

    this.pagination.first = aJsonObjPageable.first;
    this.pagination.last = aJsonObjPageable.last;
    this.pagination.totalPages = aJsonObjPageable.totalPages;
    this.pagination.pageNumber = aJsonObjPageable.pageable.pageNumber;
    this.pagination.pageSize = aJsonObjPageable.pageable.pageSize;
    this.pagination.totalElements = aJsonObjPageable.totalElements;
    this.pagination.numberOfElements = aJsonObjPageable.numberOfElements;
    this.pagination.empty = aJsonObjPageable.empty;

    // return listObj;
  }

  // ---------------------------------------------------------------------
  public populateFromJson(aJsonObjArray: IEntityInvitation[]): void {
    this.items = [];
    aJsonObjArray.forEach((singleItemJson, index) => {
      const myObj: EntityInvitation = EntityInvitation.createFromJson(singleItemJson);
      this.items[index] = myObj;
    });
  }

  // ---------------------------------------------------------------------
  public loadByFilter( aEntityInvitationFilter: EntityInvitationFilter , onSuccessCallback?: () => void, onErrorCallback?: () => void): void {
    return super.loadByFilter(aEntityInvitationFilter, onSuccessCallback, onErrorCallback);
  }

  getLastItem(): EntityInvitation {
    if (this.getCount() > 0 ) {
      return this.items[this.getCount() - 1];
    } else {
      return new EntityInvitation();
    }
  }

  public replaceItem(newItem: EntityInvitation) {
    this.items = this.items.map(oldItem => oldItem.id === newItem.id ? newItem : oldItem);
  }
  // -- CUSTOM LIST METHODS --
  
}

