<h3>{{ "cc.sidemenu.settings" | translate }}</h3>

  <div class="flex-direction-column mt-large">
    <lib-app-shared-action-row [trailingPermission]="McGod.PERM_MC_ENTITY_EDIT_MAX_TRANSACTION_PAUSE_DAYS " leadingTitle="cc.entity.profile.pause-days" leadingText="cc.entity.profile.max-pause-days" [leadingValue]="maximalPauseDays" trailingTitle="cc.common.edit" [leadingIcon]="iconPause" [trailingIcon]="iconPen" (onTrailingAction)="actPauseDays()"></lib-app-shared-action-row>
    <mcc-modal1-wrapper [showMcb]="showPauseDaysMcb" *ngIf="showPauseDaysMcb.value" modalSizeCd="small">
      <app-shared-transactions-pause-days-part (eventSaved)="onPauseDaysSaved($event)"
                                               (eventCanceled)="onPauseDaysCanceled($event)"></app-shared-transactions-pause-days-part>
    </mcc-modal1-wrapper>


    <lib-app-shared-action-row [trailingPermission]="McGod.PERM_MC_ENTITY_ADD_EDIT_TRANSACTION_DESCRIPTION" leadingTitle="cc.factoring.transaction-description" [leadingIcon]="iconFile" leadingText="cc.factoring.transaction-description" [leadingValue]="transactionDescription" trailingTitle="cc.common.edit" [trailingIcon]="iconPen" (onTrailingAction)="actTransactionDescription()"></lib-app-shared-action-row>
    <mcc-modal1-wrapper [showMcb]="showTransactionDescriptionMcb" *ngIf="showTransactionDescriptionMcb.value" modalSizeCd="small">
      <mc-entity-transaction-description-modal (eventSaved)="onTransactionDescriptionSaved($event)"
                                               (eventCanceled)="onTransactionDescriptionCanceled($event)"
                                               [isCreate]="isCreate">
      </mc-entity-transaction-description-modal>
    </mcc-modal1-wrapper>

  </div>
<!--API settings section-->
<ng-container *ngIf="mcGod.userHasMcEntityApiKeyViewPermission()">
    <h3 class="mt-large">{{'cc.entity.api-settings' | translate}}</h3>
      <div class="row">
        <div class="col-md-12">
          <div class="d-flex">

            <ng-container *ngIf="!entityApiKey">
              <mcc-fi-textbox
                [mcForm]="mcForm" label="{{'cc.entity.entity-key' | translate}}:"
                name="ctrlEntityKey"></mcc-fi-textbox>
              <div style="align-self: center; margin-top: 18px;margin-left: 20px;">
                <button *ngIf="mcGod.userHasMcEntityApiKeyEditPermission()" class="btn btn-secondary" (click)="generateEntityKey()">{{'cc.entity.generate' | translate}}</button>
              </div>
            </ng-container>
            <ng-container *ngIf="entityApiKey">
              <mcc-fi-textbox
                [mcForm]="mcForm" label="{{'cc.entity.entity-key' | translate}}:" [value]="entityApiKey"
                name="ctrlEntityKey"></mcc-fi-textbox>
              <div style="align-self: center; margin-top: 18px;margin-left: 20px;">
                <button *ngIf="mcGod.userHasMcEntityApiKeyEditPermission()" class="btn btn-secondary" (click)="generateEntityKey()">{{'cc.entity.regenerate' | translate}}</button>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
</ng-container>

<!--Password policy section-->
  <h3 class="">{{'cc.common.view.password-policy' | translate}}</h3>
    <div class="row">
      <div class="col-md-12">
        <!--Entity password policy Modal-->
        <mc-edit-entity-password-policy-modal [editEntityId]="editEntityId" [parentData]="passwordPolicyObject"
                                              (entityPasswordPolicyEvent)="onSuccessEditPasswordPolicy($event)"></mc-edit-entity-password-policy-modal>

        <!--Content row-->
        <div class="row pt-1 px-2" *ngIf="passwordPolicyObject">
          <!--Left column-->
          <div class="col-md-6">

            <!--Maximum retry count-->
            <div class="row">
              <div class="col-md-5 text-uppercase set-font">{{ 'cc.common.edit.maximum-retry-count' | translate }}
                :
              </div>
              <div
                class="col-md-7 h6 text-muted position-top">{{passwordPolicyObject.maxRetryCount === 1 ? passwordPolicyObject.maxRetryCount + ' time' : passwordPolicyObject.maxRetryCount + ' times'}}</div>
            </div>

            <!--Lockout time-->
            <div class="row">
              <div class="col-md-5 text-uppercase set-font">{{ 'cc.common.edit.lockout-time' | translate }}:</div>
              <div
                class="col-md-7 h6 text-muted position-top">{{passwordPolicyObject.lockoutTime === 1 ? passwordPolicyObject.lockoutTime + ' minute' : passwordPolicyObject.lockoutTime + ' minutes'}}</div>
            </div>

            <!--Minimum password length-->
            <div class="row">
              <div class="col-md-5 text-uppercase set-font">min. password length:</div>
              <div
                class="col-md-7 h6 text-muted position-top">{{passwordPolicyObject.minPassLength === 1 ? passwordPolicyObject.minPassLength + ' character' : passwordPolicyObject.minPassLength + ' characters'}}</div>
            </div>

            <!--Password expiration period-->
            <div class="row">
              <div
                class="col-md-5 text-uppercase set-font">{{ 'cc.common.edit.password-expiration-period' | translate }}
                :
              </div>
              <div
                class="col-md-7 h6 text-muted position-top">{{passwordPolicyObject.passMaxDays === 1 ? passwordPolicyObject.passMaxDays + ' day' : passwordPolicyObject.passMaxDays + ' days'}}</div>
            </div>

            <!--Minimum different characters-->
            <div class="row">
              <div class="col-md-5 text-uppercase set-font">min. different characters:</div>
              <div
                class="col-md-7 h6 text-muted position-top">{{passwordPolicyObject.minDiffChars === 1 ? passwordPolicyObject.minDiffChars + ' character' : passwordPolicyObject.minDiffChars + ' characters'}}</div>
            </div>

          </div><!--Left column /end-->

          <!--Right column-->
          <div class="col-md-6">

            <!--Minimum different passwords-->
            <div class="row">
              <div class="col-md-5 text-uppercase set-font">min different passwords:</div>
              <div
                class="col-md-7 h6 text-muted right-col-position">{{passwordPolicyObject.minPassHistory === 1 ? passwordPolicyObject.minPassHistory + ' password' : passwordPolicyObject.minPassHistory + ' passwords'}}</div>
            </div>

            <!--Minimum digits-->
            <div class="row">
              <div class="col-md-5 text-uppercase set-font">min. digits:</div>
              <div
                class="col-md-7 h6 text-muted right-col-position">{{passwordPolicyObject.minDigits === 1 ? passwordPolicyObject.minDigits + ' digit' : passwordPolicyObject.minDigits + ' digits'}}</div>
            </div>

            <!--Minimum special characters-->
            <div class="row">
              <div class="col-md-5 text-uppercase set-font">min. special characters:</div>
              <div
                class="col-md-7 h6 text-muted right-col-position">{{passwordPolicyObject.minSpecialChars === 1 ? passwordPolicyObject.minSpecialChars + ' character' : passwordPolicyObject.minSpecialChars + ' characters'}}</div>
            </div>

            <!--Minimum lowercase letters-->
            <div class="row">
              <div class="col-md-5 text-uppercase set-font">min. lowercase letters:</div>
              <div
                class="col-md-7 h6 text-muted right-col-position">{{passwordPolicyObject.minLowercaseLetters === 1 ? passwordPolicyObject.minLowercaseLetters + ' letter' : passwordPolicyObject.minLowercaseLetters + ' letters'}}</div>
            </div>

            <!--Minimum uppercase letters-->
            <div class="row">
              <div class="col-md-5 text-uppercase set-font">min. uppercase letters:</div>
              <div
                class="col-md-7 h6 text-muted right-col-position">{{passwordPolicyObject.minUppercaseLetter === 1 ? passwordPolicyObject.minUppercaseLetter + ' letter' : passwordPolicyObject.minUppercaseLetter + ' letters'}}</div>
            </div>

          </div><!--Right column /end-->

        </div><!--Content row /end-->

        <!--Else basic info-->
        <div class="mc-notify-wrapper">
          <mc-notify-message [message]="passPolicyMessage" [type]="passPolicyType"></mc-notify-message>
        </div>
      </div>
    </div><!--Password policy /end-->
