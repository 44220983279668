/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {McProcessNotification} from '../models/mc-process-notification.model';
import {McProcessNotificationService} from '../services/mc-process-notification.service';
import {IMcProcessNotification} from '../_generated/mc-process-notification.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class McProcessNotificationGENERATED extends BaseObject {


    public static readonly EVENT_FILTER_CD_ALL_PAYMENT: string = 'ALL_PAYMENT';
    public static readonly EVENT_FILTER_CD_MANUAL_PAYMENT: string = 'MANUAL_PAYMENT';
    public static readonly EVENT_FILTER_CD_DIRECT_DEBIT_PAYMENT: string = 'DIRECT_DEBIT_PAYMENT';
    public static readonly EVENT_FILTER_CD_ACCEPTED: string = 'ACCEPTED';
    public static readonly EVENT_FILTER_CD_FOR_DUNNING: string = 'FOR_DUNNING';
    public static readonly EVENT_FILTER_CD_ALL_TRANSACTION_STATUSES: string = 'ALL_TRANSACTION_STATUSES';
  public static readonly EVENT_FILTER_CD_LIST__ALL = [
    McProcessNotificationGENERATED.EVENT_FILTER_CD_ALL_PAYMENT,
    McProcessNotificationGENERATED.EVENT_FILTER_CD_MANUAL_PAYMENT,
    McProcessNotificationGENERATED.EVENT_FILTER_CD_DIRECT_DEBIT_PAYMENT,
    McProcessNotificationGENERATED.EVENT_FILTER_CD_ACCEPTED,
    McProcessNotificationGENERATED.EVENT_FILTER_CD_FOR_DUNNING,
    McProcessNotificationGENERATED.EVENT_FILTER_CD_ALL_TRANSACTION_STATUSES
];

    public static readonly EVENT_TYPE_CD_PAYMENT: string = 'PAYMENT';
    public static readonly EVENT_TYPE_CD_BILLING: string = 'BILLING';
  public static readonly EVENT_TYPE_CD_LIST__ALL = [
    McProcessNotificationGENERATED.EVENT_TYPE_CD_PAYMENT,
    McProcessNotificationGENERATED.EVENT_TYPE_CD_BILLING
];

    public static readonly STATUS_CD_ACTIVE: string = 'ACTIVE';
    public static readonly STATUS_CD_INACTIVE: string = 'INACTIVE';
  public static readonly STATUS_CD_LIST__ALL = [
    McProcessNotificationGENERATED.STATUS_CD_ACTIVE,
    McProcessNotificationGENERATED.STATUS_CD_INACTIVE
];

    public static readonly TYPE_CD_EMAIL: string = 'EMAIL';
  public static readonly TYPE_CD_LIST__ALL = [
    McProcessNotificationGENERATED.TYPE_CD_EMAIL
];

  public apiService: McProcessNotificationService;
  public _rawJson: IMcProcessNotification;
    id: number = 0;
  description = '';
  eventFilterCd: string;
  eventTypeCd: string;
  idMcEntity: number;
  idMcProcess: number;
  statusCd: string;
  sysCreatedDatetime: number;
  sysCreatedUserId: number;
  sysUpdatedDatetime: number;
  title = '';
  typeCd: string;


  public properties: string[] = ['id', 'description', 'eventFilterCd', 'eventTypeCd', 'idMcEntity', 'idMcProcess', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'title', 'typeCd'];
  public propertiesRegular: string[] = ['id', 'description', 'eventFilterCd', 'eventTypeCd', 'idMcEntity', 'idMcProcess', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'title', 'typeCd'];
  public propertiesSpecial: string[] = [];



  public static getEventFilterCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McProcessNotificationGENERATED.EVENT_FILTER_CD_ALL_PAYMENT, McGod.t('All payment'));
    list.add(McProcessNotificationGENERATED.EVENT_FILTER_CD_MANUAL_PAYMENT, McGod.t('Manual payment'));
    list.add(McProcessNotificationGENERATED.EVENT_FILTER_CD_DIRECT_DEBIT_PAYMENT, McGod.t('Direct debit payment'));
    list.add(McProcessNotificationGENERATED.EVENT_FILTER_CD_ACCEPTED, McGod.t('Accepted'));
    list.add(McProcessNotificationGENERATED.EVENT_FILTER_CD_FOR_DUNNING, McGod.t('For Dunning'));
    list.add(McProcessNotificationGENERATED.EVENT_FILTER_CD_ALL_TRANSACTION_STATUSES, McGod.t('All transaction statuses'));


    return list;
  }

  public static getEventFilterCdLabel(aCd: string): string {
    const list = McProcessNotificationGENERATED.getEventFilterCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getEventTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McProcessNotificationGENERATED.EVENT_TYPE_CD_PAYMENT, McGod.t('Payment'));
    list.add(McProcessNotificationGENERATED.EVENT_TYPE_CD_BILLING, McGod.t('Billing'));


    return list;
  }

  public static getEventTypeCdLabel(aCd: string): string {
    const list = McProcessNotificationGENERATED.getEventTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McProcessNotificationGENERATED.STATUS_CD_ACTIVE, McGod.t('Active'));
    list.add(McProcessNotificationGENERATED.STATUS_CD_INACTIVE, McGod.t('Inactive'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = McProcessNotificationGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }


  public static getTypeCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(McProcessNotificationGENERATED.TYPE_CD_EMAIL, McGod.t('email'));


    return list;
  }

  public static getTypeCdLabel(aCd: string): string {
    const list = McProcessNotificationGENERATED.getTypeCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): McProcessNotification {
    console.error('MTCN-ERROR: Not Implemented: McProcessNotification::createNewInstance(). Add this method to final class and return new McProcessNotification();');
    throw new Error('Cannot McProcessNotification::createNewInstance(). Add this method to final class and return new McProcessNotification();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IMcProcessNotification): McProcessNotification {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(McProcessNotificationService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE McProcessNotification AABB()');
    
  }

  // ---------------------------------------------------------------------
  
    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }

    public getSysUpdatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysUpdatedDatetime);
    }

    public getSysUpdatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysUpdatedDatetime);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getEventFilterCdLabel(): string {
    return McProcessNotificationGENERATED.getEventFilterCdLabel(this.eventFilterCd);
  }


 public  isEventFilterCdALL_PAYMENT(): boolean {
    const result = (this.eventFilterCd === McProcessNotificationGENERATED.EVENT_FILTER_CD_ALL_PAYMENT);

    return result;
  }

 public  isEventFilterCdMANUAL_PAYMENT(): boolean {
    const result = (this.eventFilterCd === McProcessNotificationGENERATED.EVENT_FILTER_CD_MANUAL_PAYMENT);

    return result;
  }

 public  isEventFilterCdDIRECT_DEBIT_PAYMENT(): boolean {
    const result = (this.eventFilterCd === McProcessNotificationGENERATED.EVENT_FILTER_CD_DIRECT_DEBIT_PAYMENT);

    return result;
  }

 public  isEventFilterCdACCEPTED(): boolean {
    const result = (this.eventFilterCd === McProcessNotificationGENERATED.EVENT_FILTER_CD_ACCEPTED);

    return result;
  }

 public  isEventFilterCdFOR_DUNNING(): boolean {
    const result = (this.eventFilterCd === McProcessNotificationGENERATED.EVENT_FILTER_CD_FOR_DUNNING);

    return result;
  }

 public  isEventFilterCdALL_TRANSACTION_STATUSES(): boolean {
    const result = (this.eventFilterCd === McProcessNotificationGENERATED.EVENT_FILTER_CD_ALL_TRANSACTION_STATUSES);

    return result;
  }


  public getEventTypeCdLabel(): string {
    return McProcessNotificationGENERATED.getEventTypeCdLabel(this.eventTypeCd);
  }


 public  isEventTypeCdPAYMENT(): boolean {
    const result = (this.eventTypeCd === McProcessNotificationGENERATED.EVENT_TYPE_CD_PAYMENT);

    return result;
  }

 public  isEventTypeCdBILLING(): boolean {
    const result = (this.eventTypeCd === McProcessNotificationGENERATED.EVENT_TYPE_CD_BILLING);

    return result;
  }


  public getStatusCdLabel(): string {
    return McProcessNotificationGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdACTIVE(): boolean {
    const result = (this.statusCd === McProcessNotificationGENERATED.STATUS_CD_ACTIVE);

    return result;
  }

 public  isStatusCdINACTIVE(): boolean {
    const result = (this.statusCd === McProcessNotificationGENERATED.STATUS_CD_INACTIVE);

    return result;
  }


  public getTypeCdLabel(): string {
    return McProcessNotificationGENERATED.getTypeCdLabel(this.typeCd);
  }


 public  isTypeCdEMAIL(): boolean {
    const result = (this.typeCd === McProcessNotificationGENERATED.TYPE_CD_EMAIL);

    return result;
  }


  
}
