/*BbmTestObj*/
import {EmailConfigKeyGENERATED} from '../_generated/email-config-key-generated.model';

export class EmailConfigKey extends EmailConfigKeyGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new EmailConfigKey();
  }

  // ---------------------------------------------------------------------
}
