
import {Table3Config} from '../extra/table-3-config';

// import {EmailConfigGENERATED} from '../_generated/email-config-generated.model';

export class EmailConfigTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colDefaultEmailPath', 'Default email path', 'defaultEmailPath', 'defaultEmailPath');
    this.addColumn('colEmailName', 'Email name', 'emailName', 'emailName');
    this.addColumn('colEmailType', 'Email type', 'emailType', 'emailType');
    this.addColumn('colLanguageCode', 'Language code', 'languageCode', 'languageCode');

*/
  }
}
