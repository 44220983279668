<div class="mc-consumer-360-tabs-wrapper">
  <span class="back-option" (click)="goToPrevious()">
      <span [innerHTML]="iconBack | safeHtml"></span>
    {{ "cc.entity.profile-back-to-contracts" | translate }}
    </span>
  <div class="cardbox mt-medium" *ngIf="ebFactoringContract">
    <div class="cardbox_header">
      <span class="cardbox_title">{{( ebFactoringContract.factoringContractName ? ebFactoringContract.factoringContractName : '-')| uppercase}}</span>
      <button *ngIf="isStatusPending()"
              class="primary-btn"
              type="submit">
        {{ 'cc.consumer.edit-contract' | translate }}
      </button>
    </div>
    <div class="divider"></div>
    <div class='dialog-row-wrapper dialog-header'>
      <div class='header-row'>
        <span class='header-row_label'>{{ 'cc.common.view.status' | translate}}:</span>
        <span class='header-row_value'><span [innerHTML]="iconStatus | safeHtml"></span>{{ (ebFactoringContract.statusCd ? getFormattedStatus(ebFactoringContract.statusCd) : '-')}}</span>
      </div>
      <div class='header-row'>
        <span class='header-row_label'>{{ 'cc.common.view.entity' | translate}}:</span>
        <span class='header-row_value'>{{ ebFactoringContract.childEntity ? ebFactoringContract.childEntity.name : '-'  }}</span>
      </div>
      <div class='header-row'>
        <span class='header-row_label'>{{ 'cc.contract.contract-type' | translate}}:</span>
        <span class='header-row_value'>{{ (ebFactoringContract.factoringContractCd ? ebFactoringContract.factoringContractCd : '-') | titlecase}}</span>
      </div>
    </div>
  </div>
</div>

<div class="cardbox">
  <h2>{{'cc.contract.general-conditions' | translate}}</h2>
  <div class="cardbox_subtext">{{ 'cc.contract.contract-validity' | translate }}</div>
  <div  class='dialog-row-wrapper'>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.contract.contract-start' | translate}}:</span>
      <span class='dialog-col-value'>{{  ebFactoringContract.contractStartDate ? ebFactoringContract.contractStartDate : '-' }}</span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.contract.contract-end' | translate}}:</span>
      <span class='dialog-col-value'>{{ ebFactoringContract.contractEndDate ? ebFactoringContract.contractEndDate : '-' }}</span>
    </div>
  </div>
  <div class="cardbox_subtext">{{ 'cc.contract.contract-cancellation' | translate }}</div>
  <div class='dialog-row-wrapper'>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.my-factoring.contract-cancellation-period' | translate}}:</span>
      <span class='dialog-col-value'>{{ ebFactoringContract.contractCancellationPeriod ? (ebFactoringContract.contractCancellationPeriod + ' ' +
        (ebFactoringContract.contractCancellationPeriod === 1 ? ('cc.common.month' | translate) : ('cc.my-factoring.months' | translate))) : '-'}}</span>
    </div>
  </div>

  <div class="divider"></div>

  <h2>{{'cc.contract.fees' | translate}}</h2>
  <ng-container *ngIf="!isFactoringType()">
    <div  class='dialog-row-wrapper'>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.contract.per-transaction' | translate}}:</span>
        <span class='dialog-col-value'>{{  ebFactoringContract.feePerTransaction ? (ebFactoringContract.feePerTransaction + ' ' +
                                        (ebFactoringContract.feePerTransactionTypeCd === 'AMOUNT' ? (ebFactoringContract.mcCurrency.code | titlecase) : '%') ) : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.contract.per-batch-%-of-the-total-amount' | translate}}:</span>
        <span class='dialog-col-value'>{{ ebFactoringContract.feePerBatch ? ebFactoringContract.feePerBatch : '-' }} %</span>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isFactoringType()">
    <div  class='dialog-row-wrapper'>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.contract.factored-transaction-fee' | translate}}:</span>
        <span class='dialog-col-value'>{{  ebFactoringContract.factoredClaimFeePerTransaction ? ebFactoringContract.factoredClaimFeePerTransaction : '-' }} %</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.contract.non-factored-transaction-fee' | translate}}:</span>
        <span class='dialog-col-value'>{{ ebFactoringContract.nonFactoredClaimFeePerTransaction ? ebFactoringContract.nonFactoredClaimFeePerTransaction : '-' }} %</span>
      </div>
    </div>
  </ng-container>

  <div class="divider"></div>

  <h2>{{'cc.contract.limits' | translate}}</h2>
  <div class="cardbox_subtext">{{ 'cc.contract.failed-withdrawals' | translate }}</div>
  <div  class='dialog-row-wrapper'>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.contract.maximal-number-of-failed-withdrawals' | translate}}:</span>
      <span class='dialog-col-value'>{{  ebFactoringContract.maximalNumberOfFailedWithdrawals ? ebFactoringContract.maximalNumberOfFailedWithdrawals : '-' }}</span>
    </div>
  </div>

  <div class="cardbox_subtext">{{ 'cc.contract.soft-limits' | translate }}</div>
  <div  class='dialog-row-wrapper'>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.contract.per-transaction-limit' | translate}}:</span>
      <span class='dialog-col-value'>{{ ebFactoringContract.softLimitPerTransaction ? format(ebFactoringContract.softLimitPerTransaction) : '-' }}</span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.contract.per-sepa-run-limit' | translate}}:</span>
      <span class='dialog-col-value'>{{  ebFactoringContract.softLimitPerSepaRun ? format(ebFactoringContract.softLimitPerSepaRun) : '-' }}</span>
    </div>
  </div>

  <div class="cardbox_subtext">{{ 'cc.contract.import-time-limits' | translate }}</div>
  <div  class='dialog-row-wrapper'>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.contract.allowed-to-import-transactions-in-the-past' | translate}}:</span>
      <span class='dialog-col-value'>{{ ebFactoringContract.monthsAllowedPastCount ? getMonthsAllowedPastCount() : '-' }}</span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.contract.allowed-to-import-transactions-in-the-future' | translate}}:</span>
      <span class='dialog-col-value'>{{ ebFactoringContract.monthsAllowedFutureCount ? getMonthsAllowedFutureCount() : '-' }}</span>
    </div>
  </div>

  <div class="cardbox_subtext">{{ 'cc.contract.hard-limits' | translate }}</div>
  <div  class='dialog-row-wrapper'>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.contract.limit-for-current-entity' | translate}}:</span>
      <span class='dialog-col-value'>{{ getHardLimitForCurrentEntityInLastDays() }}</span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.contract.cumulative-limit-for-current-entity-and-all-sub-entities' | translate}}:</span>
      <span class='dialog-col-value'>{{ getHardCumulativeLimitInLastDays() }}</span>
    </div>
  </div>

  <div class="divider"></div>

  <h2>{{'cc.contracts.payout-management' | translate}}</h2>
  <div class="cardbox_subtext">{{ 'cc.contracts.initial-payout' | translate }}</div>
  <ng-container *ngIf="!isFactoringType()">
    <div  class='dialog-row-wrapper'>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.contracts.split' | translate}}:</span>
        <span class='dialog-col-value'>{{  getSplit() }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.contracts.the-rest-to-be-paid' | translate}}:</span>
        <span class='dialog-col-value'>{{ getRestToBePaid() }}</span>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isFactoringType()">
    <div  class='dialog-row-wrapper'>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.contracts.factored-transactions' | translate}}:</span>
        <span class='dialog-col-value'>{{  ebFactoringContract.daysForInitialPayoutForFactoredTransactions>=0 ? ebFactoringContract.daysForInitialPayoutForFactoredTransactions + ' ' + ('cc.common.day(s)' | translate) : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.contracts.non-factored-transactions' | translate}}:</span>
        <span class='dialog-col-value'>{{ ebFactoringContract.daysForInitialPayoutForNonFactoredTransactions>=0 ? ebFactoringContract.daysForInitialPayoutForNonFactoredTransactions + ' ' + ('cc.common.day(s)' | translate) : '-' }}</span>
      </div>
    </div>
  </ng-container>
  <div class="divider"></div>

  <h2>{{'cc.contract.blacklist-management' | translate}}</h2>
  <div  class='dialog-row-wrapper'>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.contracts.send-transactions-to-blacklist' | translate}}:</span>
      <span class='dialog-col-value'>{{  getSendToBl() }}</span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.contract.remove-member-without-open-debt-from-bl' | translate }}:</span>
      <span class='dialog-col-value'>{{ getRemoveFromBl() }}</span>
    </div>
  </div>
  <ng-container *ngIf="ebFactoringContract.sendClaimsToBlacklistFlg">
    <div class="cardbox_subtext">{{ 'cc.contract.soft-bounce' | translate }}</div>
    <div  class='dialog-row-wrapper'>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.contract.send-to-blacklist-after' | translate}}:</span>
        <span class='dialog-col-value'>{{  getBlacklistTriggerSoftBounceCount() }}</span>
      </div>
    </div>

    <div class="cardbox_subtext">{{ 'cc.contract.hard-bounce' | translate }}</div>
    <div  class='dialog-row-wrapper'>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.contract.send-to-blacklist-after' | translate}}:</span>
        <span class='dialog-col-value'>{{  getBlacklistTriggerHardBounceCount() }}</span>
      </div>
    </div>
  </ng-container>
  <div class="divider"></div>

  <h2>{{'cc.common.currency' | translate}}</h2>
  <div  class='dialog-row-wrapper'>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.common.currency' | translate }}:</span>
      <span class='dialog-col-value'>{{ ebFactoringContract.mcCurrency.name }}</span>
    </div>
  </div>

</div>

