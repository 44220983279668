import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import { McBoolean, McConsumerAction, McProcessRun, McProcessRunItem, McProcessRunItemList, McProcessRunItemService, SortCriteriaList } from '@miticon-ui/mc-core';
import { MkTableConfig } from '@miticon-ui/mc-components';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mc-process-process-run-items-profile',
  templateUrl: './mc-process-process-run-items-profile.component.html',
})
export class McProcessProcessRunItemsProfileComponent implements OnInit {

  @Input() actionCdForEvent!: string;
  @Input() mcProcessRun!: McProcessRun;
  @Output() eventCancel = new EventEmitter();

  mcProcessRunItemList = new McProcessRunItemList();
  mcRunItemsStatus = '';
  mcRunItem = new McProcessRunItem();
  showActionDetailsMcb = new McBoolean();
  mcActionCd!: string;

  items: McProcessRunItem[] = [];
  pageNumber = 0;
  pageSize = 50;
  isLoading = false;
  totalItemsCount!: number;
  tableConfig = new MkTableConfig();

  constructor(private apiService: McProcessRunItemService, private tS: TranslateService) {}

   ngOnInit() {
    this.actLoad();
    this.initTableConfig();
   }

  loadMcProcess(pageNumber: number, pageSize: number, mcProcessRunId: number, mcProcessRunStatus: string, newItemFlg?: boolean): void {
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    this.isLoading = true;
    const sortCriteriaList = new SortCriteriaList();

    const apiCall$ = newItemFlg
      ? this.apiService.getByIdMcProcessRunAndNewItemFlg(mcProcessRunId, newItemFlg, this.pageNumber, this.pageSize, sortCriteriaList)
      : this.apiService.getByIdMcProcessRunAndStatusCd(mcProcessRunId, mcProcessRunStatus, this.pageNumber, this.pageSize, sortCriteriaList);

    apiCall$.subscribe({
      next: (res) => {
        if (res) {
          this.isLoading = false;
          this.totalItemsCount = res.totalElements;
          this.items = res.content.map((object: any) => McProcessRunItem.createFromJson(object));
        }
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }


  initTableConfig() {
    this.tableConfig.addColumnStandard(this.tS.instant('cc.my-factoring.consumer'), 'getConsumerFullName()', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.factoring.transaction-description'), 'getTransactionDescription()', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.table.amount-due'), 'getAmountDue()', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.processes.flow'), 'mcProcessFlowName', 250);
    this.tableConfig.addColumnStandard(this.tS.instant('cc.processes.level'), 'mcProcessFlowLevelName', 250);
    this.tableConfig.addColumnSvgIconAction(this.tS.instant('cc.billing.actions'), 'getEmailIcon()', 'EmailOrPost');
    this.tableConfig.addColumnInnerHtml(this.tS.instant('cc.factoring.export-sepa.status-code'), 'fldStatus()', '');
  }

  onColumnButtonAction(event: any) {
    if(event.actionCd === 'EmailOrPost') {
        this.mcRunItem = event.element;
        event.element.csrConsumerAction.emailActionFlg ? this.mcActionCd = McConsumerAction.ACTION_SHOW_EMAIL : this.mcActionCd = McConsumerAction.ACTION_SHOW_POST;
        this.showActionDetailsMcb.setTrue();
    }
  }

  actLoad(): void {
    switch (this.actionCdForEvent) {
      case McProcessRun.ACTION_VIEW_NEW_ITEMS:
        this.mcRunItemsStatus = 'New';
        this.loadMcProcess(this.pageNumber, this.pageSize, this.mcProcessRun.id, McProcessRunItem.STATUS_CD_READY, true);
        break;

      case McProcessRun.ACTION_VIEW_READY:
        this.mcRunItemsStatus = McProcessRunItem.getStatusCdLabel(McProcessRunItem.STATUS_CD_READY);
        this.loadMcProcess(this.pageNumber, this.pageSize, this.mcProcessRun.id, McProcessRunItem.STATUS_CD_READY);
        break;

      case McProcessRun.ACTION_VIEW_BUILT:
        this.mcRunItemsStatus = McProcessRunItem.getStatusCdLabel(McProcessRunItem.STATUS_CD_BUILT);
        this.loadMcProcess(this.pageNumber, this.pageSize, this.mcProcessRun.id, McProcessRunItem.STATUS_CD_BUILT);
        break;

      case McProcessRun.ACTION_VIEW_DELIVERED:
        this.mcRunItemsStatus = McProcessRunItem.getStatusCdLabel(McProcessRunItem.STATUS_CD_DELIVERED);
        this.loadMcProcess(this.pageNumber, this.pageSize, this.mcProcessRun.id, McProcessRunItem.STATUS_CD_DELIVERED);
        break;

      case McProcessRun.ACTION_VIEW_SKIPPED:
        this.mcRunItemsStatus = McProcessRunItem.getStatusCdLabel(McProcessRunItem.STATUS_CD_SKIPPED);
        this.loadMcProcess(this.pageNumber, this.pageSize, this.mcProcessRun.id, McProcessRunItem.STATUS_CD_SKIPPED);
        break;

      case McProcessRun.ACTION_VIEW_ERROR:
        this.mcRunItemsStatus = McProcessRunItem.getStatusCdLabel(McProcessRunItem.STATUS_CD_ERROR);
        this.loadMcProcess(this.pageNumber, this.pageSize, this.mcProcessRun.id, McProcessRunItem.STATUS_CD_ERROR);
        break;

      default:
        console.error('Unknown action:', this.actionCdForEvent);
    }
  }

  actCancelActionDetailsMcb() {
    this.showActionDetailsMcb.setFalse();
    this.mcRunItem = new McProcessRunItem();
  }

  actCancel() {
    this.eventCancel.emit();
  }
}
