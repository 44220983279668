/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {ImDataSet} from '../models/im-data-set.model';
import {ImDataSetService} from '../services/im-data-set.service';
import {IImDataSet} from '../_generated/im-data-set.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';

import {ImDataSetAttributeList} from '../models/im-data-set-attribute-list.model';


export abstract class ImDataSetGENERATED extends BaseObject {


  public apiService: ImDataSetService;
  public _rawJson: IImDataSet;
    id: number = 0;
  allowCustomAtt: boolean;
  allowedForEntityId: number;
  description = '';
  name = '';
  sysCreatedDatetime: number;
  data_set_attributes: ImDataSetAttributeList;

  public properties: string[] = ['id', 'allowCustomAtt', 'allowedForEntityId', 'description', 'name', 'sysCreatedDatetime', 'data_set_attributes'];
  public propertiesRegular: string[] = ['id', 'allowCustomAtt', 'allowedForEntityId', 'description', 'name', 'sysCreatedDatetime'];
  public propertiesSpecial: string[] = ['data_set_attributes'];


  // ---------------------------------------------------------------------
public static createNewInstance(): ImDataSet {
    console.error('MTCN-ERROR: Not Implemented: ImDataSet::createNewInstance(). Add this method to final class and return new ImDataSet();');
    throw new Error('Cannot ImDataSet::createNewInstance(). Add this method to final class and return new ImDataSet();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IImDataSet): ImDataSet {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(ImDataSetService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE ImDataSet AABB()');
          if (this._rawJson['data_set_attributes']) {
        this.data_set_attributes = ImDataSetAttributeList.createFromJson(this._rawJson['data_set_attributes']);
      }

  }

  // ---------------------------------------------------------------------
  
    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();
    if (this.hasOwnProperty('data_set_attributes')) {
        if (this.data_set_attributes != null) {
            dto['data_set_attributes'] = this.data_set_attributes.toDto();
        } else {
            dto['data_set_attributes'] = [];
        }
    }

    return dto;
  }


  // -- CUSTOM METHODS --
  

  
}
