<h3 class="page-title">{{ 'cc.factoring.contracts' | translate}}</h3>

<div>
  <div class="actions-container">
    <span>{{'cc.factoring.contracts-list' | translate}}</span>
    <div class="actions-wrapper">
      <button  class="system-action-btn mr-2" (click)="onCreateNewContract()">
        <i class="fas fa-plus-circle mc-add-new-user-icon"></i> {{'cc.out-factoring.create-new-contract' | translate}}
      </button>
    </div>
  </div>
  <mcc-modal1-wrapper [showMcb]="showSelectEntityPart" modalSizeCd="small" *ngIf="showSelectEntityPart.value">
    <!--<app-shared-factoring-select-entity-part (eventShowAddEditPart)="onNext()"
                                             (selectedEntityId)="onSelectedEntityId($event)">
    </app-shared-factoring-select-entity-part>-->
    <lib-app-shared-factoring-select-contract-type-and-entity (nextEmit)="onSelectEntityAndContractTypeNext($event)"
                                                              (selectedEntityId)="onSelectedEntityId($event)"
                                                              [isContractTypeOfService]="isContractTypeService"
                                                              [isContractCloned]="isContractBeingCloned"
                                                              (eventEntityForCloning)="onEntityClone($event)"
                                                              [ebFactoring]="ebFactoring"
    ></lib-app-shared-factoring-select-contract-type-and-entity>
  </mcc-modal1-wrapper>


  <mcc-modal1-wrapper [showMcb]="showAddEditPart" *ngIf="showAddEditPart.value">
    <app-shared-factoring-add-edit-part (eventSaved)="onFactoringContractSaved($event)"
                                        (eventCanceled)="onFactoringContractCanceled()"
                                        (eventPrevious)="onPrevious()"
                                        [contractType]="contractType"
                                        [isClonedContract]="isContractBeingCloned"
                                        [ebFactoringId]="ebFactoringId"
                                        [entityClone]="entityForCloning"
                                        [idEntityForCloning]="entityId"
                                        [idChildEntity]="selectedEntityId"></app-shared-factoring-add-edit-part>
  </mcc-modal1-wrapper>


</div>
<mk-mat-table
  [isLoading]="isLoading"
  [items]="items"
  [config]="tableConfig"
  [itemsPerPage]="pageSize"
  [filterConfig]="filterConfig"
  [totalItemsCount]="totalItemsCount"
  [mkMatMenuActionItems]="tableActionItems"
  (actionMkMatMenuItems)="onTableAction($event)"
  (filterChanged)="onFilterChanged($event)"
  [searchTooltip]="searchTooltip"
  (eventColumnButtonAction)="onColumnButtonAction($event)"
></mk-mat-table>
