import {allPermissions} from './all-permissions.config';

/*Sidebar configuration
* if permission property is empty string - link will be visible*/
export const sideBarMenu = [
  {
    id: 1,
    name: 'dashboard',
    pluralName: 'cc.common.dashboard',
    sidebarLinkName: 'cc.common.dashboard',
    icon: 'fas fa-chart-area',
    routeName: 'dashboard',
    permission: '',
    ulName: '',
    isExpanded: false
  },
  {
    id: 2,
    name: 'product',
    pluralName: 'cc.common.articles',
    sidebarLinkName: 'cc.common.articles',
    icon: 'fab fa-product-hunt',
    routeName: 'management/product',
    permission: allPermissions.PP_PRODUCT_CAN_READ.name,
    ulName: '',
    isExpanded: false
  },
  {
    id: 3,
    name: 'consumers',
    pluralName: 'cc.common.consumers',
    sidebarLinkName: 'cc.common.consumers',
    icon: 'fas fa-users-cog',
    routeName: '',
    // permission: allPermissions.CSR_CONSUMER_CAN_READ.name
    permission: '',
    ulName: 'consumerDropdown',
    isExpanded: false,
    children: [
      {
        id: 30,
        name: 'consumers',
        pluralName: 'cc.common.consumers-overview',
        sidebarLinkName: 'cc.common.consumers-overview',
        icon: 'fas fa-users',
        routeName: 'management/consumers',
        permission: '',
      },
      {
        id: 31,
        type: 1,
        name: 'consumers-archive',
        pluralName: 'cc.common.consumers-archive',
        sidebarLinkName: 'cc.common.consumers-archive',
        isEnabled: true,
        icon: 'fas fa-archive',
        routeName: 'management/consumer/archive',
        permission: ''
      },
      {
        id: 32,
        name: 'import',
        pluralName: 'cc.common.imports',
        sidebarLinkName: 'cc.common.imports',
        icon: 'fas fa-file-import',
        routeName: 'management/consumer/import',
        permission: '',
      },
      {
        id: 33,
        name: 'import-mappings',
        pluralName: 'cc.common.import-mappings',
        sidebarLinkName: 'cc.common.import-mappings',
        icon: 'fas fa-file-upload',
        routeName: 'management/consumer/import-mappings',
        permission: ''
      }
    ]
  },
  {
    id: 4,
    name: 'invoices',
    pluralName: 'cc.common.invoices',
    sidebarLinkName: 'cc.common.invoices',
    icon: 'fas fa-sticky-note',
    routeName: 'management/invoices',
    permission: '',
    ulName: '',
    isExpanded: false
  },
  {
    id: 5,
    name: 'factoring',
    pluralName: 'cc.factoring.factoring',
    sidebarLinkName: 'cc.factoring.factoring',
    icon: 'fas fa-users-cog',
    routeName: '',
    // permission: allPermissions.CSR_CONSUMER_CAN_READ.name
    permission: '',
    ulName: 'factoring',
    isExpanded: false,
    children: [
      {
        id: 51,
        name: 'factoring-contracts',
        pluralName: 'cc.factoring.factoring-contracts',
        sidebarLinkName: 'cc.factoring.factoring-contracts',
        icon: 'fas fa-users',
        isEnabled: true,
        routeName: 'factoring/factoring-contracts',
        permission: '',
      },
      {
        id: 52,
        type: 1,
        name: 'imported-sepa',
        pluralName: 'cc.factoring.imported-sepa',
        sidebarLinkName: 'cc.factoring.imported-sepa',
        icon: 'fas fa-archive',
        routeName: 'factoring/imported-sepa',
        permission: ''
      },
      {
        id: 53,
        name: 'export-sepa',
        pluralName: 'cc.factoring.export-sepa',
        sidebarLinkName: 'cc.factoring.export-sepa',
        icon: 'fas fa-file-import',
        routeName: 'factoring/export-sepa',
        permission: '',
      },
      {
        id: 54,
        name: 'whitelist',
        pluralName: 'cc.common.whitelist',
        sidebarLinkName: 'cc.common.whitelist',
        icon: 'fas fa-file-upload',
        isEnabled: true,
        routeName: 'factoring/whitelist',
        permission: ''
      },
      {
        id: 55,
        name: 'blacklist',
        pluralName: 'cc.common.blacklist',
        sidebarLinkName: 'cc.common.blacklist',
        icon: 'fas fa-file-upload',
        isEnabled: true,
        routeName: 'factoring/blacklist',
        permission: ''
      },
      {
        id: 56,
        name: 'factoring transactions',
        pluralName: 'cc.factoring.transactions',
        sidebarLinkName: 'cc.factoring.transactions',
        icon: 'fas fa-file-upload',
        routeName: 'factoring/factoring-transactions',
        permission: ''
      },
      {
        id: 57,
        name: 'my transactions',
        pluralName: 'cc.factoring.my.transactions',
        sidebarLinkName: 'cc.factoring.my.transactions',
        icon: 'fas fa-file-upload',
        routeName: 'factoring/my-transactions',
        permission: ''
      },
      {
        id: 58,
        name: 'reporting',
        pluralName: 'cc.factoring.reporting',
        sidebarLinkName: 'cc.factoring.reporting',
        icon: 'fas fa-file-upload',
        isEnabled: true,
        routeName: 'factoring/reporting',
        permission: ''
      }
    ]
  },
  {
    id: 7,
    name: 'banking module',
    pluralName: 'cc.banking-module.banking-module',
    sidebarLinkName: 'cc.banking-module.banking-module',
    icon: 'fas fa-money-check',
    routeName: '',
    // permission: allPermissions.CSR_CONSUMER_CAN_READ.name
    permission: '',
    ulName: 'banking module',
    isExpanded: false,
    children: [
      {
        id: 60,
        name: 'starmoney',
        pluralName: 'cc.banking-module.star-money',
        sidebarLinkName: 'cc.banking-module.star-money',
        icon: 'fas fa-money-bill-wave',
        routeName: 'banking-module/starmoney',
        permission: '',
      }
    ]
  },
  {
    id: 6,
    name: 'reminders',
    pluralName: 'cc.common.reminders',
    sidebarLinkName: 'cc.common.reminders',
    icon: 'fas fa-user-clock',
    routeName: 'management/reminders',
    permission: '',
    isReminders: true,
    ulName: '',
    isExpanded: false
  },
  {
    id: 8,
    name: 'jobs',
    pluralName: 'cc.common.jobs',
    sidebarLinkName: 'cc.common.jobs',
    icon: 'fas fa-briefcase',
    routeName: 'scheduler/jobs',
    ulName: '',
    isExpanded: false,
    permission: allPermissions.QRTZ_JOB_CAN_READ.name
  },
  {
    id: 9,
    name: 'admin',
    pluralName: 'cc.common.admin',
    sidebarLinkName: 'cc.common.admin',
    icon: 'fas fa-university',
    routeName: '',
    ulName: 'adminDropdown',
    isExpanded: false,
    children: [
      {
        id: 10,
        name: 'entity-profile',
        pluralName: '',
        sidebarLinkName: 'cc.common.admin.entity-profile',
        icon: 'fa fa-cog',
        routeName: 'entity-profile',
        permission: '',
        hovered: false
      },
      {
        id: 11,
        type: 1,
        name: 'entity',
        pluralName: 'cc.common.admin.entities',
        sidebarLinkName: 'cc.common.admin.entities',
        icon: 'fas fa-building',
        routeName: 'management/entity',
        permission: allPermissions.MC_ENTITY_CAN_READ.name
      },
      {
        id: 12,
        name: 'user',
        pluralName: 'cc.common.admin.users',
        sidebarLinkName: 'cc.common.admin.users',
        icon: 'fas fa-users',
        routeName: 'management/user',
        permission: allPermissions.MC_SYSTEM_USER_CAN_READ.name
      },
      {
        id: 13,
        name: 'role',
        pluralName: 'cc.common.admin.roles',
        sidebarLinkName: 'cc.common.admin.roles',
        icon: 'fas fa-user-tag',
        routeName: 'management/role',
        permission: allPermissions.MC_LOGIN_CAN_READ.name
      }
    ]
  }
];
