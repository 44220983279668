/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {EmailConfigFilter} from '../models/email-config-filter.model';

export class EmailConfigFilterGENERATED extends BaseObjectFilter{
  public properties: string[] = []
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): EmailConfigFilter {
    console.error('MTCN-ERROR: Not Implemented: EmailConfigFilter::createNewInstance(). Add this method to final class and return new EmailConfigFilter();');
      throw new Error('Cannot EmailConfigFilter::createNewInstance(). Add this method to final class and return new EmailConfigFilter();');
  }

}
