/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObjectFilter} from '../_core/base-object-filter';
import {McEntityFilter} from '../models/mc-entity-filter.model';

export class McEntityFilterGENERATED extends BaseObjectFilter{    public searchTerm: string | undefined;
    public statusCd: string | undefined;

  public properties: string[] = ['searchTerm', 'statusCd', ]
;

  // ---------------------------------------------------------------------
  public static createNewInstance(): McEntityFilter {
    console.error('MTCN-ERROR: Not Implemented: McEntityFilter::createNewInstance(). Add this method to final class and return new McEntityFilter();');
      throw new Error('Cannot McEntityFilter::createNewInstance(). Add this method to final class and return new McEntityFilter();');
  }

}
