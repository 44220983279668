import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { McGod } from '../extra/mc-god.service';


@Injectable({
  providedIn: 'root'
})
export class EntityActivationService {

  apiServiceUrlSubPath= '/activation'
  httpClient = McGod.getHttpClient();
  apiUrl = McGod.getEnv().apiUrl;
  httpOptions = McGod.getHttpOptions();

  public getApiServiceRootUrl(): string {
    return this.apiUrl + this.apiServiceUrlSubPath;
  }

  public activateProfile(id: any): Observable<any> {
    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/consent`, {entityId: id})
  }

  public denyProfile(entityId: any): Observable<any> {
    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/deny/${entityId}`, {})
  }

}
