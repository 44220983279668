import {Component, OnInit} from '@angular/core';
import {
  McBillingStatementList,
  McBillingStatement, McForm, McGod, McBillingStatementFilter, WebFile
} from '@miticon-ui/mc-core';
import {Router} from '@angular/router';
import * as fileSaver from 'file-saver';
import { MkFilterConfig, MkFilterItemType, MkFilterOutput, MkMatMenuItem, MkMatTableMenuAction, MkTableConfig } from '@miticon-ui/mc-components';

@Component({
  selector: 'mc-my-billing-statement-list-page',
  templateUrl: './mc-my-billing-statement-list-page.component.html',
})
export class McMyBillingStatementListPageComponent implements OnInit {
  isLoading = false;
  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();
  searchTooltip = McGod.t('cc.statement.search-by-title');
  mkMatMenuActionItems: MkMatMenuItem[] = [
    {
      title: McGod.t('cc.billing.preview-pdf'),
      actionCd: McBillingStatement.ACTION_PREVIEW_PDF,
      permission: McGod.PERM_MC_BILLING_MY_STATEMENT_PREVIEW_PDF,
      matIcon: 'pageview',
    },
    {
      title: McGod.t('cc.billing.download-pdf'),
      actionCd: McBillingStatement.ACTION_DOWNLOAD_PDF,
      permission: McGod.PERM_MC_BILLING_MY_STATEMENT_DOWNLOAD_PDF,
      matIcon: 'cloud_download',
    },
    {
      title: McGod.t('cc.billing.download-xls'),
      actionCd: McBillingStatement.ACTION_DOWNLOAD_XLS,
      permission: McGod.PERM_MC_BILLING_MY_STATEMENT_DOWNLOAD_XLS,
      matIcon: 'cloud_download',
    },
  ];

  mcBillingStatementList = new McBillingStatementList();
  mcBillingStatementFilter = new McBillingStatementFilter();
  mcForm = new McForm();
  webFile = new WebFile();

  constructor() {
  }

  ngOnInit() {
    this.initTableConfig();
    this.initFilterConfig();
    this.mcBillingStatementFilter.statusCds = [McBillingStatement.STATUS_CD_COMPLETED];
    this.actLoad(0, 100, this.mcBillingStatementFilter);
  }

  initTableConfig() {
    this.tableConfig.addColumnId([`/entity/${McGod.getLoggedEntityIdFromToken()}/my-billing/statements`], 'id');
    this.tableConfig.addColumnStandard(McGod.t('cc.common.edit.title'), 'title', 250, 'title');
    this.tableConfig.addColumnStandard(McGod.t('cc.billing.number-of-items'), 'fldNumberOfItems()');
    this.tableConfig.addColumnStandard(McGod.t('cc.billing.total-amount'), 'getTotalPayoutLoc()');
    this.tableConfig.addColumnStandard(McGod.t('cc.billing.statement-date'), 'getStatementDate()');
    this.tableConfig.addColumnStandard(McGod.t('cc.common.payout-date'), 'getPayoutDate()');
    this.tableConfig.addColumnInnerHtml(McGod.t('cc.common.view.status'), 'fldStatusWithIconsOutPage()', 'statusCd');
  }

  initFilterConfig() {
    this.filterConfig.addItem(MkFilterItemType.RANGE_DATEPICKER, McGod.t(McBillingStatement.FILTER_PAYOUT_DATE));
  }

  actLoad(pageNumber: number, pageSize: number, filters: McBillingStatementFilter) {
    this.isLoading = true;
    this.mcBillingStatementList.pageNum = pageNumber;
    this.mcBillingStatementList.pageItemsPerPageCount = pageSize;
    filters.flgOutBilling = false;
    this.mcBillingStatementFilter.statusCds = [McBillingStatement.STATUS_CD_COMPLETED, McBillingStatement.STATUS_CD_FINALIZED];
    this.mcBillingStatementList.loadByFilter(filters, () => {
      this.mcBillingStatementList.items.map((item) => (item.navigation = `/entity/${McGod.getLoggedEntityIdFromToken()}/my-billing/statement/${item.id}`));
      this.isLoading = false;
      console.log(this.mcBillingStatementList)
    });
  }

  onFilterChanged(filters: MkFilterOutput) {
    this.mcBillingStatementList.setSortB(filters.sort.sortProperty, filters.sort.sortType);
    this.mcBillingStatementFilter.searchTerm = filters.search;
    this.mcBillingStatementFilter.flgOutBilling = false;
    this.mcBillingStatementFilter.statusCds = [McBillingStatement.STATUS_CD_COMPLETED];
    this.mcBillingStatementFilter.payoutDateFrom = filters.selections[McGod.t(McBillingStatement.FILTER_PAYOUT_DATE)]?.startDate;
    this.mcBillingStatementFilter.payoutDateTo = filters.selections[McGod.t(McBillingStatement.FILTER_PAYOUT_DATE)]?.endDate;
    this.actLoad(filters.pageEvent.pageIndex, filters.pageEvent.pageSize, this.mcBillingStatementFilter);
  }

  onMkMatMenuItemsAction(event: MkMatTableMenuAction) {
    switch (event.action.actionCd) {
      case McBillingStatement.ACTION_DOWNLOAD_PDF:
        this.webFile.getFileByIdAndType(event.item.pdfWebfileId, WebFile.TYPE_CD_MC_BILLING_STATEMENT_PAYOUT_REPORT_PDF, () => {
          this.webFile.downloadFile().subscribe(response => {
            fileSaver.saveAs(response, `${this.webFile.originalFilename}`);
          });
        });
        break;
      case McBillingStatement.ACTION_DOWNLOAD_XLS:
        if (event.item.isStatusCdCOMPLETED()) {
          this.webFile.getFileByIdAndType(event.item.xlsWebfileId, WebFile.TYPE_CD_MC_BILLING_STATEMENT_CONSUMERS_REPORT_CSV, () => {
            this.webFile.downloadFile().subscribe(response => {
              fileSaver.saveAs(response, this.webFile.filename);
            });
          });
        }
        break;
      case McBillingStatement.ACTION_PREVIEW_PDF:
        this.webFile.getFileByIdAndType(event.item.pdfWebfileId, WebFile.TYPE_CD_MC_BILLING_STATEMENT_PAYOUT_REPORT_PDF,() => {
          this.webFile.downloadFile().subscribe(response => {
            const blob = new Blob([response], {type: 'application/pdf'});
            const url = URL.createObjectURL(blob);
            const newWindow = window.open(url, '_blank');
            newWindow?.document.write(`<html><head><title>${this.webFile.filename}</title></head><body>
             <embed width="100%" height="100%" name="plugin" src="${url}"
            type="application/pdf" internalinstanceid="21"></body></html>`);
          });
        });
        break;
    }
  }
}
