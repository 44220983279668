import {Component, Inject, Input, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { McUtilityService, McConsumersService, McEntityService, McInvoiceService } from '@miticon-ui/mc-core';
import {FormGroup, FormBuilder} from '@angular/forms';
import {Observable, Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Location} from '@angular/common';
import _moment from 'moment';

@Component({
  selector: 'lib-mc-invoice-edit',
  templateUrl: './mc-invoice-edit.component.html'
})
export class McInvoiceEditComponent implements OnInit {
  area: string;
  editForm!: FormGroup;
  invoiceFromBacked: any;
  selectedTableProductsProceed: any = {};
  activeRoute;
  userId!: number;
  entityId!: any;
  orderId: number;
  invoiceReceiver!: string;
  showLoader!: boolean;
  receiverName!: string;
  responseMessage!: boolean;
  message!: string;
  type!: string;
  invoiceId!: number;
  utilityConfig: any;
  idConsumer: number;

  @Input() isDataFormInvalid!: boolean;

  getAllOrderItemsObservable: Observable<any>;
  destroySubject = new Subject<void>();

  receavedOrderListForEdit: any;
  receivedInvoiceData: any;
  private invoiceProductList = [];
  private invoiceProductListSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private utilityService: McUtilityService,
    private consumerService: McConsumersService,
    private entityService: McEntityService,
    private invoiceService: McInvoiceService,
    private _location: Location,
    @Inject('utilityConfig') utilityConfig: any
  ) {
    this.utilityConfig = utilityConfig;
    this.orderId = this.activatedRoute.snapshot.params['orderId'] - 0;
    this.area = this.activatedRoute.snapshot.params['area'];

    this.getAllOrderItemsObservable = this.invoiceService.getAllOrderItems(this.orderId).pipe(
      takeUntil(this.destroySubject)
    );
    this.getAllOrderItems();
    this.createForm();

    this.utilityService.setStepNumber(2);

    // check if invoice is for consumers or entities
    this.activeRoute = this.router.url.includes('consumers');

    this.entityId = this.utilityService.getPropertyFromToken('entity_id');

    this.getInvoiceReceiverData();
    this.invoiceProductListSubscription = this.invoiceService.invoiceList.subscribe(list => this.invoiceProductList = list);
  }

  ngOnInit() {
  }

  createForm() {
    this.editForm = this.fb.group({
      step1: '',
      step2: '',
      receivedData: '',
      dataFromStep1: ''
    });
  }

  getAllOrderItems() {
    this.showLoader = true;
    this.getAllOrderItemsObservable.subscribe(
      response => {
        this.invoiceId = response.id;
        this.idConsumer = response.idRefReceiver
        this.showLoader = false;
        this.receiverName = response.recipient;
        this.reorderReceivedData(response);
      },
      error => {
        this.showLoader = false;
        this.apiErrorHandler(error);
      }
    );
  }

  reorderReceivedData(response: any) {
    this.receavedOrderListForEdit = {
      id: response.id,
      idRefReceiver: response.idRefReceiver,
      idSenderMcEntity: response.idSenderMcEntity,
      discount: response.discount || { valueTypeCd: 'PERCENTAGE', value: 0, typeCd: 'DISCOUNT' },
      dueDate: response.dueDate,
      excludedDiscountedItems: (response.discount || {}).excludedDiscountedItems || false,
      sendingDate: _moment(response.sendingDate).zone(this.utilityConfig.timeZone.europeBerlin).format('LLL'),
      servicePeriodFrom: response.servicePeriodFrom,
      servicePeriodTo: response.servicePeriodTo,
      state: response.state,
      receiverEntityId: response.idReceiverMcEntity,
      orderItems: response.orderItems
    };
    this.editForm.get('receivedData')?.setValue(this.receavedOrderListForEdit);
  }

  goToPrevious() {
    this._location.back();
    this.utilityService.setStepNumber(1);
  }

  userDataFormInvalid(data: boolean) {
    this.isDataFormInvalid = data;
  }


  getInvoiceReceiverData() {
    if (this.activeRoute) {
      if (this.userId) {
        this.consumerService.getConsumerData(this.userId).subscribe(
          response => {
            // @ts-ignore
            this.invoiceReceiver = response.displayName ? response.displayName : response.companyName;
          },
          error => {
            console.log(error);
          }
        );
      }
    } else {
      if (this.entityId) {
        this.entityService.getMcEntityById(this.entityId).subscribe(response => {
          this.invoiceReceiver = response.name;
        }, error => {
          console.log(error);
        });
      }
    }
  }

  selectedTableProducts(data: any) {
    if (data.servicePeriodFrom) {
      this.selectedTableProductsProceed = {...this.selectedTableProductsProceed, ...data};
    } else {
      data.isEditArea = true;
      data.invoiceId =  this.invoiceId;
      this.selectedTableProductsProceed = {...this.selectedTableProductsProceed, ...data};
    }
    this.activeRoute ? this.selectedTableProductsProceed.idRefReceiver = this.idConsumer : this.selectedTableProductsProceed.receiverEntityId = this.entityId;
    this.selectedTableProductsProceed.idSenderMcEntity = this.entityId

    this.editForm.get('dataFromStep1')?.setValue(this.selectedTableProductsProceed);
  }


// Api ErrorHandler
  private apiErrorHandler(error: any) {
    let message = '';
    let msgType = '';
    if (error.status >= 500) {
      message = 'Server error';
      msgType = 'error';
    } else if (error.error) {
      if (error.error.errors.length > 1) {
        message = error.error.errors.join();
        msgType = 'error';
      } else {
        message = error.error.errors;
        msgType = 'error';
      }
    } else {
      message = 'Invalid request';
      msgType = 'error';
    }
    this.responseMessage = true;
    this.setNotificationMessage(message, msgType);
  }


  public handleCssForWrapper() {
    return {
      width: this.invoiceProductList.length  && window.innerWidth < 1600 ? 'fit-content' : '100%'
    };
  }
  // Display notification message
  private setNotificationMessage(message: string, type: string): void {
    this.message = message;
    this.type = type;
  }

  onNgDestroy() {
    this.invoiceProductListSubscription.unsubscribe();
  }


}
