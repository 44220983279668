/*BbmTestObj*/
import {EmailConfigGENERATED} from '../_generated/email-config-generated.model';

export class EmailConfig extends EmailConfigGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new EmailConfig();
  }

  // ---------------------------------------------------------------------
}
