import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {EntityInvitationServiceGENERATED} from '../_generated/entity-invitation-generated.service';
import {Observable} from 'rxjs';
import {SortCriteriaList} from '../_core/sort-criteria-list.model';


@Injectable({
  providedIn: 'root'
})
export class EntityInvitationService extends EntityInvitationServiceGENERATED {

  constructor() {
    super();
    this.apiServiceUrlSubPath = '/registration/invitations';
  }

  public getByEntityId(aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.getApiServiceRootUrl() + `/get-all?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });
  }

  public cancelEntityInvitation(id: number){
    return this.httpClient.put<any>(this.getApiServiceRootUrl() + `/${id}/cancel`, {})
  }

  public sendInvitationWithEmail(email: string) {
    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/send-email`, { email: email })
  }

  public generateInvitationCode() {
    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/create`, {})
  }
}
