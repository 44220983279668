import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { McGod } from '../extra/mc-god.service';


@Injectable({
  providedIn: 'root'
})
export class EntityRegistrationService {

  apiServiceUrlSubPath= '/registration'
  httpClient = McGod.getHttpClient();
  apiUrl = McGod.getEnv().apiUrl;
  httpOptions = McGod.getHttpOptions();

  public getApiServiceRootUrl(): string {
    return this.apiUrl + this.apiServiceUrlSubPath;
  }

  public registerEntity(body: any): Observable<any> {
    return this.httpClient.post<any>(this.getApiServiceRootUrl() + `/register-entity`, body)
  }

}
