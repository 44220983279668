<div class="content-wrapper">

  <div class="content-header">
    <h2>{{mcRunItemsStatus}} Run Items for {{mcProcessRun.fldRunNameId()}}</h2>
  </div>

  <div class="content-body">
    <div class="col-md-12">
      <mcc-message [errorMsg]="mcProcessRunItemList.apiErrorMessage"></mcc-message>
    </div>

    <mk-mat-table
      [isLoading]="isLoading"
      [items]="items"
      [config]="tableConfig"
      [itemsPerPage]="pageSize"
      [totalItemsCount]="totalItemsCount"
      (eventColumnButtonAction)="onColumnButtonAction($event)"
    ></mk-mat-table>

    <mcc-modal1-wrapper modalSizeCd="small" *ngIf="showActionDetailsMcb.value" [showMcb]="showActionDetailsMcb" (eventCanceled)="actCancelActionDetailsMcb()">
      <app-shared-processes-action-detail-part [mcConsumerAction]="mcRunItem.csrConsumerAction" [mcActionCd]="mcActionCd" (eventCanceled)="actCancelActionDetailsMcb()"></app-shared-processes-action-detail-part>
    </mcc-modal1-wrapper>

  </div>

  <div class="content-footer">
    <mcc-button class="move-right" extraCssClasses="btn btn-secondary" (eventClicked)="actCancel();"
                label="Cancel"></mcc-button>
  </div>

</div>
