import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  CsrContract, CsrPaymentDetails, CsrRecurringDetails, CurrencyUtil, EbFactoringService, McBankAccount,
  McConsumer,
  McCurrency,
  McDateUtils, McEntity2, McGod,
} from '@miticon-ui/mc-core';

@Component({
  selector: 'mc-consumer-consumer-contract-profile-part',
  templateUrl: './mc-consumer-consumer-contract-profile-part.component.html',
})
export class McConsumerConsumerContractProfilePartComponent implements OnInit {
  @Input() csrContract!: CsrContract;
  @Input() mcConsumer!: McConsumer;
  @Input() currency!: McCurrency;
  @Input() totalVat!: number;
  @Input() totalNetto!: number;
  @Input() entityCurrency!: string;

  daysBeforeExpiration!: number;

  mcDateUtils = new McDateUtils();
  mcEntity = new McEntity2();
  bankAccount = new McBankAccount();
  csrPaymentDetails = new CsrPaymentDetails();
  csrRecurringDetails = new CsrRecurringDetails();


  @Output() eventClosed = new EventEmitter<boolean>();
  constructor(private factoringService: EbFactoringService) {
  }

  ngOnInit() {
    if (this.csrContract.idBeneficiary) {
      this.mcEntity.loadById(this.csrContract.idBeneficiary, () => {
      });
      this.bankAccount.loadDefault();
    }
  }

  format(value: number): string {
    return CurrencyUtil.format(value, this.factoringService.activeChildFactoring.mcCurrency.code);
  }


  onClose() {
    this.eventClosed.emit(true);
  }
}
