/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {EmailConfig} from '../models/email-config.model';
import {EmailConfigService} from '../services/email-config.service';
import {IEmailConfig} from '../_generated/email-config.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';



export abstract class EmailConfigGENERATED extends BaseObject {


    public static readonly EMAIL_TYPE_MEM_VERIFY_INVITATION: string = 'MEM VERIFY INVITATION';
    public static readonly EMAIL_TYPE_MEM_CONFIRM_PURCHASE_MEMBERSHIP: string = 'MEM CONFIRM PURCHASE MEMBERSHIP';
    public static readonly EMAIL_TYPE_MEM_VERIFY_PURCHASE: string = 'MEM VERIFY PURCHASE';
  public static readonly EMAIL_TYPE_LIST__ALL = [
    EmailConfigGENERATED.EMAIL_TYPE_MEM_VERIFY_INVITATION,
    EmailConfigGENERATED.EMAIL_TYPE_MEM_CONFIRM_PURCHASE_MEMBERSHIP,
    EmailConfigGENERATED.EMAIL_TYPE_MEM_VERIFY_PURCHASE
];

  public apiService: EmailConfigService;
  public _rawJson: IEmailConfig;
    id: number = 0;
  defaultEmailPath = '';
  emailName = '';
  emailType: string;
  languageCode = '';


  public properties: string[] = ['id', 'defaultEmailPath', 'emailName', 'emailType', 'languageCode'];
  public propertiesRegular: string[] = ['id', 'defaultEmailPath', 'emailName', 'emailType', 'languageCode'];
  public propertiesSpecial: string[] = [];



  public static getEmailTypeVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(EmailConfigGENERATED.EMAIL_TYPE_MEM_VERIFY_INVITATION, McGod.t('MEM VERIFY INVITATION'));
    list.add(EmailConfigGENERATED.EMAIL_TYPE_MEM_CONFIRM_PURCHASE_MEMBERSHIP, McGod.t('MEM CONFIRM PURCHASE MEMBERSHIP'));
    list.add(EmailConfigGENERATED.EMAIL_TYPE_MEM_VERIFY_PURCHASE, McGod.t('MEM VERIFY PURCHASE'));


    return list;
  }

  public static getEmailTypeLabel(aCd: string): string {
    const list = EmailConfigGENERATED.getEmailTypeVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): EmailConfig {
    console.error('MTCN-ERROR: Not Implemented: EmailConfig::createNewInstance(). Add this method to final class and return new EmailConfig();');
    throw new Error('Cannot EmailConfig::createNewInstance(). Add this method to final class and return new EmailConfig();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: IEmailConfig): EmailConfig {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(EmailConfigService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE EmailConfig AABB()');
    
  }

  // ---------------------------------------------------------------------
  

  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();

    return dto;
  }


  // -- CUSTOM METHODS --
  

  public getEmailTypeLabel(): string {
    return EmailConfigGENERATED.getEmailTypeLabel(this.emailType);
  }


 public  isEmailTypeMEM_VERIFY_INVITATION(): boolean {
    const result = (this.emailType === EmailConfigGENERATED.EMAIL_TYPE_MEM_VERIFY_INVITATION);

    return result;
  }

 public  isEmailTypeMEM_CONFIRM_PURCHASE_MEMBERSHIP(): boolean {
    const result = (this.emailType === EmailConfigGENERATED.EMAIL_TYPE_MEM_CONFIRM_PURCHASE_MEMBERSHIP);

    return result;
  }

 public  isEmailTypeMEM_VERIFY_PURCHASE(): boolean {
    const result = (this.emailType === EmailConfigGENERATED.EMAIL_TYPE_MEM_VERIFY_PURCHASE);

    return result;
  }


  
}
