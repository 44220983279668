import {EmailTemplate} from './email-template.model';
import {EmailTemplateListGENERATED} from '../_generated/email-template-list-generated.model';

export class EmailTemplateList extends EmailTemplateListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new EmailTemplateList();
  }

  // ---------------------------------------------------------------------
}
