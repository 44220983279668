
import {Table3Config} from '../extra/table-3-config';

// import {EmailConfigKeyGENERATED} from '../_generated/email-config-key-generated.model';

export class EmailConfigKeyTable3ConfigGENERATED extends Table3Config {

  // ---------------------------------------------------------------------
    constructor() {
    super();
/*
    this.addColumn('colKey', 'Key', 'key', 'key');
    this.addColumn('colName', 'Name', 'name', 'name');
    this.addColumn('colRequiredFlg', 'Required flag', 'requiredFlg', 'requiredFlg');

*/
  }
}
