import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { EbSepaExport, EbSepaExportService, McBankAccountService, McBillingStatementService, McUtilityService, PmTransaction, PmTransactionFilter, SortCriteriaList } from '@miticon-ui/mc-core';
import { ICON_CLOSE } from '../../../../assets/media/svg_icons/icon-close';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import moment from 'moment/moment';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lib-mc-out-factoring-sepa-export-dialog',
  templateUrl: './mc-out-factoring-sepa-export-dialog.component.html',
  styleUrl: './mc-out-factoring-sepa-export-dialog.component.scss',
})
export class McOutFactoringSepaExportDialogComponent implements OnInit {

  @ViewChild('multiselect') multiselect: MatSelect;
  allSelected = false;
  iconClose = ICON_CLOSE;
  form!: FormGroup;
  bankAccounts = [];
  entities!: any;
  minDate = new Date();

  exportFileTypes = [{
    label: this.tS.instant("cc.ebics.ebics"),
    value: "EBICS"
  },{
    label: this.tS.instant("cc.banking-module.star-money"),
    value: "STAR_MONEY"
  }];

  transactionStatuses = [{
    label: this.tS.instant("cc.eb-factoring.accepted"),
    value: "ACCEPTED"
  },{
    label: this.tS.instant("cc.factoring.transactions.returned"),
    value: "RETURNED"
  }];

  constructor(public dialogRef: MatDialogRef<McOutFactoringSepaExportDialogComponent>,
              private mcBankAccountService: McBankAccountService,
              private utilityService: McUtilityService,
              private mcBillingStatementService: McBillingStatementService,
              private fb: FormBuilder,
              private ebSepaExportService: EbSepaExportService,
              private tS: TranslateService,
              private toastr: ToastrService) {}

  ngOnInit() {
    this.setBankAccounts();
    this.setEntities();
    this.createForm();
  }

  createForm(){
    this.form = this.fb.group({
      collectionDate: ['', [Validators.required]],
      dueDateFrom: [''],
      dueDateTo: [''],
      bankAccountId: ['', [Validators.required]],
      exportType: ['', [Validators.required]],
      status: ['', [Validators.required]],
      childEntityIds: ['', [Validators.required]],
    })
  }

  setBankAccounts() {
    const entityId = this.utilityService.getPropertyFromToken('entity_id');
    this.mcBankAccountService.getByEntityId(entityId, 0, 100, new SortCriteriaList()).subscribe((data) => {
      this.bankAccounts = data.content;
    });
  }

  setEntities() {
    let entities = null;
    entities = this.mcBillingStatementService.getFilteredListOfEntitiesForCreateStatement(0, 10000, new SortCriteriaList());
    if(entities) {
      entities.subscribe((data) => {
        this.entities = data.content;
        const entityId = this.utilityService.getPropertyFromToken("entity_id");
        this.entities = this.entities.filter((entity: any) => entity.id !== entityId)
          .sort((a: any, b: any) => a.name.localeCompare(b.name));
      });
    }
  }

  exportSepa() {
    const pmTransactionFilter = new PmTransactionFilter();
    pmTransactionFilter.outTransactionFlg = true;
    pmTransactionFilter.statusCds = [this.form.controls['status'].value];
    pmTransactionFilter.childEntityIds = this.form.controls['childEntityIds'].value;
    pmTransactionFilter.pausedFilterOptionCd = PmTransaction.FILTER_UNPAUSED;
    if(this.form.controls['dueDateFrom'].value) pmTransactionFilter.transactionPaymentDueDateTimeFromDate = this.formatDateWithTime(this.form.controls['dueDateFrom'].value);
    if(this.form.controls['dueDateTo'].value) pmTransactionFilter.transactionPaymentDueDateTimeToDate = this.formatDateWithTime(this.form.controls['dueDateTo'].value, true);

    const newSepaExport = new EbSepaExport();
    newSepaExport.typeCd = this.form.controls['exportType'].value;
    newSepaExport.requestedCollectionDate = this.formatDate(this.form.controls['collectionDate'].value);
    newSepaExport.idMcBankAccount = this.form.controls['bankAccountId'].value;
    newSepaExport.creationTypeCd = EbSepaExport.CREATION_TYPE_CD_MANUAL;

    this.ebSepaExportService.exportAllFilteredTransactions(newSepaExport, pmTransactionFilter).subscribe({
        next: () =>{
          this.dialogRef.close(true);
          this.toastr.success(this.tS.instant('cc.sidemenu.sepa-direct-debit') + ' ' + this.tS.instant('cc.data-export.successfully'));
        },
        error: error => {
          this.toastr.error(error.error.message);
        }
    });
  }

  toggleSelectAllEntities() {
    this.allSelected ? this.form.controls['childEntityIds'].setValue(this.entities.map((entity: any) => entity.id))
                     : this.form.controls['childEntityIds'].setValue([]);
  }

  optionClick() {
    let newStatus = true;
    this.multiselect.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }


  private formatDate(value: any): string {
    return moment(value).format('YYYY-MM-DD');
  }

  private formatDateWithTime(value: any, isEndOfDay: boolean = false): string {
    return isEndOfDay
      ? moment(value).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS')
      : moment(value).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
  }
}
