import {Component, EventEmitter, OnInit, Input, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {McCountryList, McEntity2, McEntityService, McUtilityService} from '@miticon-ui/mc-core';
import {LoginEnums} from '@miticon-ui/mc-core';

@Component({
  selector: 'mc-entity-basic-info',
  templateUrl: './mc-entity-basic-info.component.html'
})
export class McEntityBasicInfoComponent implements OnInit {

  entityBasicInfoForm: FormGroup;
  message!: string;
  type!: string;
  isDisabled!: boolean;
  validForm = true;
  invalidMail = false;
  invalidWebDomain = false;
  invalidIP = false;
  invalidAddress = false;
  invalidPhone = false;
  invalidFax = false;
  invalidVatNumber = false;
  basicInfoTab!: boolean;
  showLoader!: boolean;
  invalidName = false;
  invalidCountryId = false;
  invalidLegalContact = false;
  invalidBankTransactionEntityName = false;
  existEntityName!: boolean;
  existWebDomain!: boolean;
  existAddress!: boolean;
  imageUrl: any = 'https://www.royalcontainers.com/wp-content/uploads/2017/03/logo-placeholder.jpeg';
  entityList: any[] = [];
  webDomainList: any[] = [];
  mcCountryList = new McCountryList();
  mcEntity2 = new McEntity2();
  studioOwner!: string;

  @Input() editEntityId!: any;
  @Input() parentData!: any;
  @Input() parentForm!: FormGroup;
  @Input() isEdit!: any;

  // Outputs
  @Output() successfullyEditBasicInfo = new EventEmitter<any>();
  @Output() isEntityBasicInfoDataInvalid = new EventEmitter<boolean>();
  @Output() selectedImagePath = new EventEmitter<string>();

  constructor(
    private modalService: NgbModal,
    private entityService: McEntityService,
    private utilityService: McUtilityService,
    private fb: FormBuilder
  ) {
    this.entityBasicInfoForm = new FormGroup({});
  }

  ngOnInit() {
    if (this.parentData) {
      this.mcEntity2.loadById(this.parentData.id, () => {
        this.createForm();
      });
    }
    this.createForm();
    this.mcCountryList.loadAll();
    this.getAllWebDomains();
    this.getAllEntityNames();
    this.imageUrl = this.parentData ? this.parentData.imageUrl : 'https://www.royalcontainers.com/wp-content/uploads/2017/03/logo-placeholder.jpeg';
    console.log('IMG URL', this.parentData);
    // set formValues

    // Forward child entityBasicInfo form to the parent one
    if (this.parentForm) {
      this.parentForm.setControl('step1', this.entityBasicInfoForm);
    }
    // Set color for active tab
    this.basicInfoTab = true;
    // Empty notification message
    this.setNotificationMessage('', '');
    this.isDisabled = false;
    this.validForm = true;
    this.invalidMail = false;
    this.invalidWebDomain = false;
    this.invalidIP = false;
    this.invalidPhone = false;
    this.invalidFax = false;
    this.invalidVatNumber = false;
    this.invalidName = false;
    this.invalidCountryId = false;
    this.invalidBankTransactionEntityName = false;
    this.invalidLegalContact = false;
    this.existEntityName = false;
    this.existWebDomain = false;
  }

  // Create Form
  private createForm() {
    // @ts-ignore
    const matchLetterRegExp = /(?=.*[a-zA-Z])^.{2,}$/;
    const emailRegExp = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,6})$/;
    const filename = '';
    const whitespaceRegex = /^(?!\s*$).+/;

    this.entityBasicInfoForm = this.fb.group({
      countryId: [this.parentData && this.parentData.countryId],
      status: [this.parentData && this.parentData.active],
      image: [this.parentData ? this.parentData.logo : filename],
      name: [this.parentData && this.parentData.name, [Validators.required, Validators.pattern(matchLetterRegExp)]],
      mail: [this.parentData && this.parentData.mailDomain,
        [
          Validators.required,
          Validators.pattern('(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]')
        ]
      ],
      abbreviation: [this.parentData && this.parentData.abbreviation, Validators.required],
      webDomain: [this.parentData && this.parentData.webDomain,
        [
          Validators.required,
          Validators.pattern('(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]')
        ]
      ],
      description: [this.parentData && this.parentData.description],
      IP: [
        this.parentData && this.parentData.ipWhitelist,
      ],
      phoneNumber: [this.parentData && this.parentData.phone, Validators.pattern('^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\\s\\./0-9()]*$')],
      faxNumber: [this.parentData && this.parentData.fax, Validators.pattern('^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\\s\\./0-9()]*$')],
      vatNumber: [this.mcEntity2 && this.mcEntity2.euVat],
      address: [this.parentData && this.parentData.address],
      impressum: [this.parentData && this.parentData.impressum && this.parentData.impressum.toString().replace(/<br\/>/g, '\n')],
      legalRepresentative: [this.parentData && this.parentData.legalRepresentative],
      // eslint-disable-next-line max-len
      legalRepresentativeContact: [this.parentData && this.parentData.legalRepresentativeContact, Validators.pattern(emailRegExp)],
      extCreditorId: [this.mcEntity2 && this.mcEntity2.extCreditorId],
      bankTransactionEntityName: [this.mcEntity2 && this.mcEntity2.bankTransactionEntityNameOverride, Validators.pattern(whitespaceRegex)],
      studioOwner: [this.mcEntity2 && this.mcEntity2.studioOwner],
      studioOwnerAddress: [this.mcEntity2 && this.mcEntity2.studioOwnerAddress],
      studioOwnerPostalCode: [this.mcEntity2 && this.mcEntity2.studioOwnerPostalCode],
      studioOwnerCity: [this.mcEntity2 && this.mcEntity2.studioOwnerCity],
      studioManager: [this.mcEntity2 && this.mcEntity2.studioManager],
      courtInCharge: [this.mcEntity2 && this.mcEntity2.courtInCharge]
    });

    console.log(this.entityBasicInfoForm);
  }

  // Display notification message
  private setNotificationMessage(message: string, type: string): void {
    this.message = message;
    this.type = type;
  }

  // Unlock Next btn
  private shareUnlockProperty(valid: boolean) {
    this.isEntityBasicInfoDataInvalid.emit(
      valid
    );
    this.isDisabled = valid;
  }

  // Take value from inputFileImage
  onFileChanged(event: any) {
    const file: File = event.target.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', (e: any) => {
      this.imageUrl = reader.result;

      // send imageUrl to summary page
      this.selectedImagePath.emit(
        this.imageUrl
      );

      // send imageUrl as a image control value for validate purpose
      this.entityBasicInfoForm.get('image')?.setValue(this.imageUrl ? this.imageUrl : '');

      this.isFormValid();
    });
    reader.readAsDataURL(file);
  }

  // Check if form is valid
  public isFormValid() {
    const form = this.entityBasicInfoForm;
    const isInvalid = Object.keys(form.controls).find(item => form.controls[item].invalid);
    if (isInvalid) {
      this.validForm = false;
      this.shareUnlockProperty(true);
      this.invalidMail = false;
      this.invalidWebDomain = false;
      this.invalidPhone = false;
      this.invalidFax = false;
      this.invalidName = false;
      this.invalidCountryId = false;
      this.invalidLegalContact = false;
      this.invalidBankTransactionEntityName = false;
      switch (isInvalid) {
        case 'countryId':
          if (form.get(isInvalid)?.errors && form.get(isInvalid)?.errors?["required"] : '') {
            this.invalidCountryId = false;
            this.setNotificationMessage(`Required fields can\'t be empty`, 'error');
          }
          break;
        case 'address':
          if (form.get(isInvalid)?.errors && form.get(isInvalid)?.errors?["required"] : '') {
            this.invalidAddress = true;
            this.setNotificationMessage(`Required fields can\'t be empty`, 'error');
          }
          break;
        case 'name':
          if (form.get(isInvalid)?.errors && form.get(isInvalid)?.errors?["required"] : '') {
            this.invalidName = false;
            this.setNotificationMessage(`Required fields can\'t be empty`, 'error');
          } else if (form.get(isInvalid)?.errors && form.get(isInvalid)?.errors?["pattern"] : '') {
            this.invalidName = true;
            this.setNotificationMessage('At least one letter and any other character required', 'error');
          }
          break;
        case 'mail':
          if (form.get(isInvalid)?.errors && form.get(isInvalid)?.errors?["required"] : '') {
            this.invalidMail = false;
            this.setNotificationMessage(`Required fields can\'t be empty`, 'error');
          } else if (form.get(isInvalid)?.errors && form.get(isInvalid)?.errors?["pattern"] : '') {
            this.invalidMail = true;
            this.setNotificationMessage(`A valid mail domain is required.`, 'error');
          }
          break;
        case 'webDomain':
          if (form.get(isInvalid)?.errors && form.get(isInvalid)?.errors?["required"] : '') {
            this.invalidWebDomain = false;
            this.setNotificationMessage(`Required fields can\'t be empty`, 'error');
          } else if (form.get(isInvalid)?.errors && form.get(isInvalid)?.errors?["pattern"] : '') {
            this.invalidWebDomain = true;
            this.setNotificationMessage(`A valid web domain is required.`, 'error');
          }
          break;
        case 'phoneNumber':
          if (form.get(isInvalid)?.errors && form.get(isInvalid)?.errors?["pattern"] : '') {
            this.invalidPhone = true;
            this.setNotificationMessage(`A valid phone number is required.`, 'error');
          }
          break;
        case 'faxNumber':
          if (form.get(isInvalid)?.errors && form.get(isInvalid)?.errors?["pattern"] : '') {
            this.invalidFax = true;
            this.setNotificationMessage(`A valid fax number is required.`, 'error');
          }
          break;
        case 'legalRepresentativeContact':
          if (form.get(isInvalid)?.errors && form.get(isInvalid)?.errors?["pattern"] : '') {
            this.invalidLegalContact = true;
            this.setNotificationMessage(`A valid email format is required.`, 'error');
          }
          break;
        case 'bankTransactionEntityName':
          this.invalidBankTransactionEntityName = true;
          this.setNotificationMessage(`No whitespaces allowed in bank transaction entity name override field.`, 'error');
          break;
        default:
          this.invalidMail = false;
          this.invalidWebDomain = false;
          this.invalidPhone = false;
          this.invalidFax = false;
          this.invalidName = false;
          this.invalidCountryId = false;
          this.invalidLegalContact = false;
          this.invalidBankTransactionEntityName = false;
          this.invalidAddress = false;
          this.setNotificationMessage(`Required fields can\'t be empty`, 'error');
      }
    } else if (this.existEntityName === true) {
      this.shareUnlockProperty(true);
      this.setNotificationMessage(`Name you entered already exists. Entity name must be unique.`, 'error');
      this.validForm = true;
      this.invalidMail = false;
      this.invalidWebDomain = false;
      this.invalidPhone = false;
      this.invalidAddress = false;
      this.invalidFax = false;
      this.invalidLegalContact = false;
      this.invalidBankTransactionEntityName = false;
    } else if (this.existWebDomain === true) {
      this.shareUnlockProperty(true);
      this.setNotificationMessage(`Web domain you entered already exists. Web domain must be unique.`, 'error');
      this.validForm = true;
      this.invalidMail = false;
      this.invalidWebDomain = false;
      this.invalidPhone = false;
      this.invalidFax = false;
      this.invalidAddress = false;
      this.invalidLegalContact = false;
      this.invalidBankTransactionEntityName = false;
    } else if (this.invalidIP === true) {
      this.shareUnlockProperty(true);
      this.setNotificationMessage(`A valid IP address is required.`, 'error');
      this.validForm = true;
      this.invalidMail = false;
      this.invalidWebDomain = false;
      this.invalidAddress = false;
      this.invalidPhone = false;
      this.invalidFax = false;
      this.invalidLegalContact = false;
      this.invalidBankTransactionEntityName = false;
    } else if (this.invalidVatNumber === true) {
      this.shareUnlockProperty(true);
      this.setNotificationMessage(`Entered VAT number is invalid.`, 'error');
      this.validForm = true;
      this.invalidMail = false;
      this.invalidWebDomain = false;
      this.invalidPhone = false;
      this.invalidFax = false;
      this.invalidName = false;
      this.invalidAddress = false;
      this.invalidCountryId = false;
      this.invalidLegalContact = false;
      this.invalidBankTransactionEntityName = false;
    } else {
      this.setNotificationMessage('', '');
      this.shareUnlockProperty(false);
      this.validForm = true;
      this.invalidMail = false;
      this.invalidWebDomain = false;
      this.invalidAddress = false;
      this.invalidPhone = false;
      this.invalidFax = false;
      this.invalidName = false;
      this.invalidCountryId = false;
      this.invalidLegalContact = false;
      this.invalidBankTransactionEntityName = false;
    }
  }

  // Get all webDomains
  getAllWebDomains() {
    this.showLoader = true;
    let msgText;
    let msgType;
    this.entityService.getAllWebDomains().subscribe((response) => {
      this.webDomainList = response;
      this.showLoader = false;
    }, (error) => {
      this.showLoader = false;
      if (error.status >= 500) {
        msgText = 'Server is not available at the moment, please try again later.';
        msgType = 'error';
        this.setNotificationMessage(msgText, msgType);
        return;
      }

      msgText = error.error ? error.error.message : error.message;
      msgType = error.ok === false ? LoginEnums.errorMsg : LoginEnums.successMsg;
      this.setNotificationMessage(msgText, msgType);
    });
  }

  // Get all entities
  getAllEntityNames() {
    this.showLoader = true;
    let msgText;
    let msgType;
    this.entityService.getAllEntitiesNames().subscribe((response) => {
      this.entityList = response;
      this.showLoader = false;
    }, (error) => {
      this.showLoader = false;
      if (error.status >= 500) {
        msgText = 'Please fill required fields.';
        msgType = 'error';
        this.setNotificationMessage(msgText, msgType);
        return;
      }

      msgText = error.error ? error.error.message : error.message;
      msgType = error.ok === false ? LoginEnums.errorMsg : LoginEnums.successMsg;
      this.setNotificationMessage(msgText, msgType);
    });
  }

  // Check all entities names
  checkExistNames(entityName: string) {
    let name;

    // Remove whitespaces
    if (entityName) {
      name = entityName.trim().toLowerCase();
    }

    if (name) {
      if (this.parentData) {
        const filteredArray: any[] = this.entityList.filter(obj => obj !== this.parentData.name);
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < filteredArray.length; i += 1) {
          if (name === filteredArray[i].toLowerCase()) {
            this.existEntityName = true;
            this.shareUnlockProperty(true);
            return this.setNotificationMessage(`Name you entered already exists. Entity name must be unique.`, 'error');
          } else if (this.validForm === false) {
            this.existEntityName = false;
            this.isFormValid();
          } else if (this.existWebDomain === true) {
            this.existEntityName = false;
            this.shareUnlockProperty(true);
            this.setNotificationMessage(`Web domain you entered already exists. Web domain must be unique.`, 'error');
          } else if (this.invalidVatNumber === true) {
            this.existEntityName = false;
            this.shareUnlockProperty(true);
            this.setNotificationMessage('Entered VAT number is invalid.', 'error');
          } else if (this.invalidIP === true) {
            this.existEntityName = false;
            this.shareUnlockProperty(true);
            this.setNotificationMessage(`A valid IP address is required.`, 'error');
          } else {
            this.existEntityName = false;
            this.setNotificationMessage('', '');
            this.shareUnlockProperty(false);
          }
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < this.entityList.length; i += 1) {
          if (name === this.entityList[i].toLowerCase()) {
            this.existEntityName = true;
            this.shareUnlockProperty(true);
            return this.setNotificationMessage(`Name you entered already exists. Entity name must be unique.`, 'error');
          } else if (this.validForm === false) {
            this.existEntityName = false;
            this.isFormValid();
          } else if (this.existWebDomain === true) {
            this.existEntityName = false;
            this.shareUnlockProperty(true);
            this.setNotificationMessage(`Web domain you entered already exists. Web domain must be unique.`, 'error');
          } else if (this.invalidVatNumber === true) {
            this.existEntityName = false;
            this.shareUnlockProperty(true);
            this.setNotificationMessage('Entered VAT number is invalid.', 'error');
          } else if (this.invalidIP === true) {
            this.existEntityName = false;
            this.shareUnlockProperty(true);
            this.setNotificationMessage(`A valid IP address is required.`, 'error');
          } else {
            this.existEntityName = false;
            this.setNotificationMessage('', '');
            this.shareUnlockProperty(false);
          }
        }
      }
    }
  }

  // Check all web domains
  checkExistWebDomains(webDomain: string) {
    let domain;

    // Remove whitespaces
    if (webDomain) {
      domain = webDomain.trim().toLowerCase();
    }

    if (domain) {
      if (this.parentData) {
        const filteredArray: any[] = this.webDomainList.filter(item => item !== this.parentData.webDomain);
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < filteredArray.length; i += 1) {
          if (domain === filteredArray[i].toLowerCase()) {
            this.existWebDomain = true;
            this.shareUnlockProperty(true);
            return this.setNotificationMessage(`Web domain you entered already exists. Web domain must be unique.`, 'error');
          } else if (this.validForm === false) {
            this.existWebDomain = false;
            this.isFormValid();
          } else if (this.existEntityName === true) {
            this.existWebDomain = false;
            this.shareUnlockProperty(true);
            this.setNotificationMessage(`Name you entered already exists. Name must be unique.`, 'error');
          } else if (this.invalidVatNumber === true) {
            this.existWebDomain = false;
            this.shareUnlockProperty(true);
            this.setNotificationMessage('Entered VAT number is invalid.', 'error');
          } else if (this.invalidIP === true) {
            this.existWebDomain = false;
            this.shareUnlockProperty(true);
            this.setNotificationMessage(`A valid IP address is required.`, 'error');
          } else {
            this.existWebDomain = false;
            this.setNotificationMessage('', '');
            this.shareUnlockProperty(false);
          }
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < this.webDomainList.length; i += 1) {
          if (domain === this.webDomainList[i].toLowerCase()) {
            this.existWebDomain = true;
            this.shareUnlockProperty(true);
            return this.setNotificationMessage(`Web domain you entered already exists. Web domain must be unique.`, 'error');
          } else if (this.validForm === false) {
            this.existWebDomain = false;
            this.isFormValid();
          } else if (this.existEntityName === true) {
            this.existWebDomain = false;
            this.shareUnlockProperty(true);
            this.setNotificationMessage(`Name you entered already exists. Name must be unique.`, 'error');
          } else if (this.invalidVatNumber === true) {
            this.existWebDomain = false;
            this.shareUnlockProperty(true);
            this.setNotificationMessage('Entered VAT number is invalid.', 'error');
          } else if (this.invalidIP === true) {
            this.existWebDomain = false;
            this.shareUnlockProperty(true);
            this.setNotificationMessage(`A valid IP address is required.`, 'error');
          } else {
            this.existWebDomain = false;
            this.setNotificationMessage('', '');
            this.shareUnlockProperty(false);
          }
        }
      }
    }
  }

  // Check if Ip address is valid
  isIpAddressValid(ipAddress: any) {
    const splitIpAddressList = ipAddress.split(',').filter((ip: any) => ip !== '');
    // eslint-disable-next-line max-len
    const ipV4Reg = '^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$';
    // eslint-disable-next-line max-len
    const ipV4SubnetReg = '^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)/(3[0-2]|[12][0-9]|[1-9])$';
    // eslint-disable-next-line max-len
    const ipV6Reg = '^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$';
    // eslint-disable-next-line max-len
    const ipV6SubnetReg = '^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/(1[0-2][0-8]|[1-9][0-9]|[1-9])$';
    if (ipAddress && splitIpAddressList.length > 0) {
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < splitIpAddressList.length; i += 1) {
        if (splitIpAddressList.length > 0 &&
          !splitIpAddressList[i].match(ipV6SubnetReg) &&
          !splitIpAddressList[i].match(ipV4SubnetReg) &&
          !splitIpAddressList[i].match(ipV6Reg) &&
          !splitIpAddressList[i].match(ipV4Reg)) {
          this.invalidIP = true;
          this.setNotificationMessage(`A valid IP address is required.`, 'error');
          this.shareUnlockProperty(true);
        } else {
          this.invalidIP = false;
          this.setNotificationMessage(``, '');
          this.shareUnlockProperty(false);
        }
      }
    } else if (ipAddress === '') {
      this.invalidIP = false;
      this.setNotificationMessage(``, '');
      this.shareUnlockProperty(false);
    } else {
      this.setNotificationMessage(``, '');
      this.shareUnlockProperty(false);
    }
    if ((ipAddress === '' || this.invalidIP === false) && this.validForm === false) {
      this.isFormValid();
    }
    if ((ipAddress === '' || this.invalidIP === false) && this.existEntityName === true) {
      this.shareUnlockProperty(true);
      this.setNotificationMessage(`Name you entered already exists. Entity name must be unique.`, 'error');
    }
    if ((ipAddress === '' || this.invalidIP === false) && this.existWebDomain === true) {
      this.shareUnlockProperty(true);
      this.setNotificationMessage(`Web domain you entered already exists. Web domain must be unique.`, 'error');
    }
    if ((ipAddress === '' || this.invalidIP === false) && this.invalidVatNumber === true) {
      this.shareUnlockProperty(true);
      this.setNotificationMessage('Entered VAT number is invalid.', 'error');
    }
  }
  isAddressValid(address: any) {
    if (address !== undefined && address !== null && address !== '') {
      this.setNotificationMessage(``, '');
      this.existAddress = true;
    } else {
      this.existAddress=false;
      this.setNotificationMessage(`A valid address is required.`, 'error');
    }

  }
  // On vatNumberChanged
  // onVatNumberChanged(vat) {
  //   const body = {
  //     vatNumber: vat
  //   };
  //   if (vat !== "") {
  //     this.entityService.vatNumberValidation(body).subscribe(() => {
  //       this.invalidVatNumber = false;
  //       if (this.validForm === false) {
  //         this.isFormValid();
  //       } else if (this.existEntityName === true) {
  //         this.shareUnlockProperty(true);
  //         this.setNotificationMessage(`Name you entered already exists. Entity name must be unique.`, "error");
  //       } else if (this.existWebDomain === true) {
  //         this.shareUnlockProperty(true);
  //         this.setNotificationMessage(`Web domain you entered already exists. Web domain must be unique.`, "error");
  //       } else if (this.invalidIP === true) {
  //         this.shareUnlockProperty(true);
  //         this.setNotificationMessage(`A valid IP address is required.`, "error");
  //       } else {
  //         this.invalidVatNumber = false;
  //         this.shareUnlockProperty(false);
  //         this.setNotificationMessage("", "");
  //       }
  //     }, () => {
  //       if (vat !== "") {
  //         this.invalidVatNumber = true;
  //         this.shareUnlockProperty(true);
  //         this.setNotificationMessage("Entered VAT number is invalid.", "error");
  //       } else {
  //         this.invalidVatNumber = false;
  //         this.shareUnlockProperty(false);
  //         this.setNotificationMessage("", "");
  //       }
  //     });
  //   } else {
  //     this.invalidVatNumber = false;
  //     this.shareUnlockProperty(false);
  //     this.setNotificationMessage("", "");
  //   }
  //   if (vat === "" && this.validForm === false) {
  //     this.isFormValid();
  //   }
  //   if (vat === "" && this.existEntityName === true) {
  //     this.shareUnlockProperty(true);
  //     this.setNotificationMessage(`Name you entered already exists. Entity name must be unique.`, "error");
  //   }
  //   if (vat === "" && this.existWebDomain === true) {
  //     this.shareUnlockProperty(true);
  //     this.setNotificationMessage(`Web domain you entered already exists. Web domain must be unique.`, "error");
  //   }
  //   if (vat === "" && this.invalidIP === true) {
  //     this.shareUnlockProperty(true);
  //     this.setNotificationMessage(`A valid IP address is required.`, "error");
  //   }
  // }

  // Edit entityBasicInfo
  editEntityBasicInfo() {
    this.showLoader = true;
    if (this.entityBasicInfoForm.valid) {
      if (this.isEdit === true) {
        let msgText: string;
        let msgType: string;
        const formData = this.entityBasicInfoForm.getRawValue();
        const body = {
          mailPrefix: '',
          statusCd: formData.status ? McEntity2.STATUS_CD_ACTIVE : McEntity2.STATUS_CD_INACTIVE,
          imageData: this.imageUrl,
          name: formData.name,
          countryId: formData.countryId,
          mailDomain: formData.mail,
          abbreviation: formData.abbreviation,
          webDomain: formData.webDomain,
          description: formData.description || null,
          ipWhitelist: formData.IP || null,
          phone: formData.phoneNumber || null,
          bankAccount: '',
          fax: formData.faxNumber || null,
          euVat: formData.vatNumber || null,
          address: formData.address || null,
          impressum: formData.impressum || null,
          legalRepresentative: formData.legalRepresentative || null,
          legalRepresentativeContact: formData.legalRepresentativeContact || null,
          extCreditorId: formData.extCreditorId || null,
          bankTransactionEntityName: formData.bankTransactionEntityName || null,
        };


        if (this.editEntityId) {
          console.log(this.editEntityId);
          this.mcEntity2.id = this.editEntityId;
          this.mcEntity2.studioOwner = formData.studioOwner;
          this.mcEntity2.studioOwnerAddress = formData.studioOwnerAddress;
          this.mcEntity2.studioOwnerPostalCode = formData.studioOwnerPostalCode;
          this.mcEntity2.studioOwnerCity = formData.studioOwnerCity;
          this.mcEntity2.studioManager = formData.studioManager;
          this.mcEntity2.courtInCharge = formData.courtInCharge;
          this.mcEntity2.mailPrefix = '';
          this.mcEntity2.statusCd = body.statusCd;
          this.mcEntity2.name = body.name;
          this.mcEntity2.countryId = body.countryId;
          this.mcEntity2.mailDomain = body.mailDomain;
          this.mcEntity2.abbreviation = body.abbreviation;
          this.mcEntity2.webDomain = body.webDomain;
          this.mcEntity2.description = body.description;
          this.mcEntity2.ipWhitelist = body.ipWhitelist;
          this.mcEntity2.phone = body.phone;
          this.mcEntity2.bankAccount = body.bankAccount;
          this.mcEntity2.fax = body.fax;
          this.mcEntity2.euVat = body.euVat;
          this.mcEntity2.address = body.address;
          this.mcEntity2.impressum = body.impressum;
          this.mcEntity2.legalRepresentative = body.legalRepresentative;
          this.mcEntity2.legalRepresentativeContact = body.legalRepresentativeContact;
          this.mcEntity2.extCreditorId = body.extCreditorId;
          this.mcEntity2.bankTransactionEntityNameOverride = body.bankTransactionEntityName;
          this.mcEntity2.save(() => {
            this.successfullyEditBasicInfo.emit(true);
            msgText = LoginEnums.successMsg;
            msgType = LoginEnums.successMsg;
            this.modalService.dismissAll('Cross click');
            this.showLoader = false;
          }, () => {
            this.showLoader = false;
            msgText = 'Server is not available at the moment, please try again later.';
            msgType = 'error';
            this.setNotificationMessage(msgText, msgType);
            this.setNotificationMessage(msgText, msgType);
          });
        } else {
          this.mcEntity2.studioOwner = formData.studioOwner;
          this.mcEntity2.studioOwnerAddress = formData.studioOwnerAddress;
          this.mcEntity2.studioOwnerPostalCode = formData.studioOwnerPostalCode;
          this.mcEntity2.studioOwnerCity = formData.studioOwnerCity;
          this.mcEntity2.studioManager = formData.studioManager;
          this.mcEntity2.courtInCharge = formData.courtInCharge;
          this.mcEntity2.mailPrefix = '';
          this.mcEntity2.statusCd = body.statusCd;
          this.mcEntity2.name = body.name;
          this.mcEntity2.countryId = body.countryId;
          this.mcEntity2.mailDomain = body.mailDomain;
          this.mcEntity2.abbreviation = body.abbreviation;
          this.mcEntity2.webDomain = body.webDomain;
          this.mcEntity2.description = body.description;
          this.mcEntity2.ipWhitelist = body.ipWhitelist;
          this.mcEntity2.phone = body.phone;
          this.mcEntity2.bankAccount = body.bankAccount;
          this.mcEntity2.fax = body.fax;
          this.mcEntity2.euVat = body.euVat;
          this.mcEntity2.address = body.address;
          this.mcEntity2.impressum = body.impressum;
          this.mcEntity2.legalRepresentative = body.legalRepresentative;
          this.mcEntity2.legalRepresentativeContact = body.legalRepresentativeContact;
          this.mcEntity2.extCreditorId = body.extCreditorId;
          this.mcEntity2.bankTransactionEntityNameOverride = body.bankTransactionEntityName;
          this.mcEntity2.save(() => {
            this.successfullyEditBasicInfo.emit(true);
            msgText = LoginEnums.successMsg;
            msgType = LoginEnums.successMsg;
            this.modalService.dismissAll('Cross click');
            this.showLoader = false;
          }, () => {
            this.showLoader = false;
            msgText = 'Server is not available at the moment, please try again later.';
            msgType = 'error';
            this.setNotificationMessage(msgText, msgType);
          });
        }
      }
    } else {
      this.showLoader = false;
      this.setNotificationMessage('Please fill required fields', 'error');
    }

  }

}
