<div class="action-row">

  <div class="action-row_left" >
    <span [innerHTML]="leadingIcon | safeHtml" class="mr-smallest"></span>
    <span class="leading-title"> {{ leadingTitle | translate }} </span>

    <ng-container *ngIf="leadingText">
      <div class="line ml-medium mr-medium"></div>
      <span class="light-span">{{ leadingText | translate }}:</span>
      <span class="light-span">{{ leadingValue ? leadingValue : '-' }}</span>
    </ng-container>

  </div>

  <div class="action-row_right" (click)="onTrailingAction.emit()" *ngIf="!trailingPermission || mcGod.userHasPermission(trailingPermission)">
    <span   [innerHTML]="trailingIcon | safeHtml" class="bold-span mr-smallest"></span>
    <span> {{ trailingTitle | translate }} </span>
  </div>

</div>
