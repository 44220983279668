<ng-container class="dialog">
  <div class="dialog_header">
    <span class="dialog_header_title text-uppercase">
      {{ "cc.factoring.export-sepa" | translate }} {{"cc.invoice.direct-debit" | translate}}
    </span>
    <span class="dialog_header_icon"
          [innerHTML]="iconClose | safeHtml"
          (click)="dialogRef.close(false)"></span>
  </div>

  <mat-dialog-content>
    <form [formGroup]="form">
      <div class="form_fields">
        <mat-form-field appearance="outline">
          <mat-label>{{'mem.transaction.export.collection-date' | translate}}</mat-label>
          <input matInput [matDatepicker]="collectionDate" formControlName='collectionDate'
                 (click)='collectionDate.open()' [min]="minDate" readonly>
          <mat-datepicker-toggle matSuffix [for]="collectionDate"></mat-datepicker-toggle>
          <mat-datepicker #collectionDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="date-range" appearance="outline">
          <mat-label>{{'cc.common.view.due-date' | translate}}</mat-label>
          <mat-date-range-input [rangePicker]="dueDate">
            <input matStartDate readonly formControlName="dueDateFrom">
            <input matEndDate readonly formControlName="dueDateTo">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="dueDate"></mat-datepicker-toggle>
          <mat-date-range-picker #dueDate></mat-date-range-picker>
        </mat-form-field>
      </div>
      <div class="form_fields">
        <mat-form-field appearance="outline">
          <mat-label>{{ "cc.common.edit.bank-account" | translate }}</mat-label>
          <mat-select panelClass="dialog-select"
                      formControlName="bankAccountId"
                      >
            <mat-option *ngFor="let bank of bankAccounts" [value]="bank.id">
              {{ bank.bankName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ "cc.jobs.export-file-type" | translate }}</mat-label>
          <mat-select panelClass="dialog-select"
                      formControlName="exportType"
          >
            <mat-option *ngFor="let exportType of exportFileTypes" [value]="exportType.value">
              {{ exportType.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form_fields">
        <mat-form-field appearance="outline">
          <mat-label>{{ "cc.factoring.transactions.transaction-status" | translate }}</mat-label>
          <mat-select panelClass="dialog-select"
                      formControlName="status"
                      >
            <mat-option *ngFor="let status of transactionStatuses" [value]="status.value">
              {{ status.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ "cc.common.admin.entities" | translate }}</mat-label>
          <mat-select formControlName="childEntityIds"
                      #multiselect
                      multiple
                      >
            <div class="select-all-checkbox" [class.all-checked]="allSelected">
              <mat-checkbox [(ngModel)]="allSelected"
                            [ngModelOptions]="{standalone: true}"
                            (change)="toggleSelectAllEntities()">
                {{   "cc.common.view.select-all" | translate}}
              </mat-checkbox>
            </div>
            <mat-option  *ngFor="let entity of entities" [value]="entity.id" (click)="optionClick()">
              {{ entity.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </mat-dialog-content>

  <div class='dialog_footer'>
    <button mat-button
            class="secondary-btn"
            (click)="dialogRef.close(false)">
      {{ 'cc.common.edit.cancel' | translate }}
    </button>
    <button mat-button
            class="primary-btn"
            type="submit"
            (click)="exportSepa()"
            [disabled]="form.invalid">
      {{ 'cc.common.create' | translate }}
    </button>
  </div>
</ng-container>
