import { Component, OnInit } from '@angular/core';
import { ICON_CLOSE } from '../../../../assets/media/svg_icons/icon-close';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ICON_COPY } from '../../../../assets/media/svg_icons/icon-copy';
import {Clipboard} from '@angular/cdk/clipboard';
import { EntityInvitationService } from '@miticon-ui/mc-core';
import { ICON_SUCCESS } from '../../../../assets/media/svg_icons/icon-success';
import { ICON_FAILED_WHITE } from '../../../../assets/media/svg_icons/icon-failed-white';

@Component({
  selector: 'lib-mc-send-invitation-dialog',
  templateUrl: './mc-send-invitation-dialog.component.html',
  styleUrl: './mc-send-invitation-dialog.component.scss'
})
export class McSendInvitationDialogComponent implements OnInit {

  iconClose = ICON_CLOSE;
  iconCopy = ICON_COPY;

  form!: FormGroup;
  copyToClipboardMsg = 'cc.entity.copy-to-clipboard'
  infoMessage: string | null = null;
  infoIcon: any;

  constructor(public dialogRef: MatDialogRef<McSendInvitationDialogComponent>,
              private fb: FormBuilder,
              private clipboard: Clipboard,
              private entityInvitation: EntityInvitationService) {
   }

   ngOnInit() {
    this.createForm();
    this.trackForm();
   }

   createForm(){
     this.form = this.fb.group({
       email: ['', [Validators.required, Validators.email]],
       code: [{ value:'', disabled: true }]
     });
   }

   copyToClipboard() {
    this.clipboard.copy(this.form.get('code')?.value);
    this.copyToClipboardMsg = 'cc.entity.copied';
    setTimeout(() => { this.copyToClipboardMsg = 'cc.entity.copy-to-clipboard' }, 3000);
   }

   sendInvitation(){
    if(!this.form.valid){
      this.infoMessage = 'cc.entity.invitation-email-error';
      this.infoIcon = ICON_FAILED_WHITE;
      return;
    }
    this.entityInvitation.sendInvitationWithEmail(this.form.get('email')?.value).subscribe({
      next: () => {
        this.infoMessage = 'cc.entity.invitation-email-success';
        this.infoIcon = ICON_SUCCESS;
      },
      error: () => {
        this.infoMessage = 'cc.entity.invitation-email-error';
        this.infoIcon = ICON_FAILED_WHITE;
      }
    })
  }

  generateInvitationCode(){
    this.entityInvitation.generateInvitationCode().subscribe({
      next: (data) => {
        this.form.patchValue({ code : data.invitationCode});
      }
    })
  }

  trackForm() {
    this.form.get('email')?.valueChanges.subscribe(() => {
      this.infoMessage = null;
      this.infoIcon = null;
    })
  }
}
