
  <h3 class="page-title">{{ 'cc.common.invoices' | translate }}</h3>

  <div class="actions-container">
    <span>{{ 'pp.invoice.list' | translate }}</span>
    <div *ngIf="receiverType === 'consumers'" class="actions-wrapper">
      <button class="system-action-btn" id="createInvoiceModalButton" (click)="onInvoiceCreation()">
        <i class="fas fa-plus-circle mc-add-new-user-icon"></i>{{'cc.invoice.view.create-new-invoice' | translate}}
      </button>
    </div>
    <div *ngIf="!receiverType" class="actions-wrapper">
      <button class="system-action-btn mr-2" (click)="showGenerateInvoiceBulkMcb.setTrue()">
        <i class="fas fa-plus-circle mc-add-new-user-icon"></i>{{'cc.common.view.create-invoice' | translate}}
      </button>
    </div>
  </div>


  <!--Table-->
  <div class="col-md-12 px-0">
    <mk-mat-table *ngIf="isContentLoaded"
              [config]="tableConfig"
              [filterConfig]="filterConfig"
              [searchTooltip]="searchTooltip"
              (filterChanged)="onFilterChanged($event)"
              [items]="ppInvoiceList.items"
              [totalItemsCount]="totalItemsCount"
              [itemsPerPage]="pageSize"
              [mkMatMenuActionItems]="mkMatMenuActionItems"
              (actionMkMatMenuItems)="onActionMkMatMenuItems($event)">
    </mk-mat-table>
  </div>

  <mcc-modal1-wrapper modalSizeCd="small" [showMcb]="showGenerateInvoiceBulkMcb" *ngIf="showGenerateInvoiceBulkMcb.value">
    <pp-invoice-generate-invoice-bulk-part (eventSaved)="onInvoiceCreationBulk()" (eventCanceled)="showGenerateInvoiceBulkMcb.setFalse()"></pp-invoice-generate-invoice-bulk-part>
  </mcc-modal1-wrapper>

  <!--Loader-->
<lib-mc-loader [showLoader]="showLoader"></lib-mc-loader>
