import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-mc-entity-activation-feedback',
  templateUrl: './mc-entity-activation-feedback.component.html',
  styleUrl: './mc-entity-activation-feedback.component.scss'
})
export class McEntityActivationFeedbackComponent implements OnInit{

  landingBg: string;
    logo: string;
    message= "";

    constructor(@Inject('designConfig') design: any,
                private translate: TranslateService,
                private router: Router,
                private route: ActivatedRoute) {
  
      this.landingBg = `url('${design.landingBg}')`
      this.logo = design.logoMark;
      

  }

  ngOnInit(){
    this.route.queryParams.subscribe(params => {
      this.message = params['message'];
    });
  }
}