<div class="mc-sidebar">
  <nav>
    <div class="mc-sidebar-body mc-scrollbar-vertical" *ngIf="menu.menuItemList.length">
      <ul class="flex-column sidebar-list">
        <li
          class="sidebar-list-item"
          [class.sidebar-list-item_collapsed]="isCollapsed"
          *ngFor="let mainItem of menu.menuItemList; let mainIndex = index"
          McdPermissions
          [permissions]="mainItem.permission"
        >
          <!-- Main items with submenu -->
          <div *ngIf="mainItem.submenu.length > 0">
            <div
              McdPermissions
              [permissions]="mainItem.permission"
              matTooltip="{{ mainItem.translationKey | translate }}"
              [matTooltipClass]="mainItem.hasActiveChild ? 'tabs_tool_tip_active' : 'tabs_tool_tip'"
              [matTooltipDisabled]="!isCollapsed"
            >
              <div
                (click)="toggleDropdownMenu(mainItem, $event)"
                [class.active-tab]="mainItem.hasActiveChild"
                class="dropdown border-bottom"
                [class.dropdown-collapsed]="isCollapsed"
              >
                <!-- Icon for main item -->
                <span
                  class="menu-item-icon"
                  [class.menu-item-icon_collapsed]="isCollapsed"
                  [class.menu-item-icon_active]="mainItem.hasActiveChild"
                  [innerHTML]="mainItem.icon | safeHtml"
                ></span>

                <!-- Main item name -->
                <a
                  *ngIf="!isCollapsed"
                  [class.active-parent]="mainItem.hasActiveChild"
                  class="mc-sidebar-link mc-submenu"
                >
                  {{ mainItem.translationKey | translate }}
                </a>

                <!-- Dropdown arrow for main item -->
                <span
                  class="mc-menu-arrow arrow-position"
                  [class.rotate-icon]="isNewMenu && mainItem.isExpanded"
                  [innerHTML]=" (!isNewMenu ? (mainItem.isExpanded ? dropDownIcon : dropUpIcon) : (arrowDownGreyIcon)) | safeHtml"
                  [class.mc-menu-arrow_active]="mainItem.hasActiveChild"></span>

              </div>

              <!-- First-level submenu -->
              <ul
                *ngIf="!isCollapsed"
                [style.height]="mainItem.isExpanded ? calculateSubmenuHeight(mainItem) : '0'"
                class="mc-sidebar-submenu"
              >
                <li
                  *ngFor="let subItem of mainItem.submenu; let subIndex = index"
                  class="submenu-item ml-2 mt-0"
                  McdPermissions
                  [permissions]="subItem.permission"
                >
                  <!-- Submenu with additional levels -->
                  <div *ngIf="subItem.submenu.length > 0" McdPermissions [permissions]="subItem.permission">
                    <div
                      (click)="toggleDropdownMenu(subItem, $event)"
                      class="submenu-item-hover d-flex"
                      [class.dropdown-collapsed]="isCollapsed"
                    >
                      <a
                        class="dropdown-link mc-sidebar-link mc-sidebar-link-subitem link-with-icon"
                        [class.active]="subItem.hasActiveChild"
                      >
                        {{ subItem.translationKey | translate }}
                        <span
                          [class.mc-menu-arrow_active]="subItem.hasActiveChild"
                          [class.rotate-icon_submenu]="subItem.isExpanded"
                          [class.rotate-icon]="isNewMenu && subItem.isExpanded"
                          [innerHTML]="arrowDownGreyIcon | safeHtml">
                        </span>
                      </a>
                    </div>
                    <ul
                      [style.height]="subItem.isExpanded ? calculateSubmenuHeight(subItem) : '0'"
                      class="mc-sidebar-submenu"
                    >
                      <div
                        class="submenu-item-indicator"
                        [ngStyle]="{ 'border-image': (activeLevel2Index === subIndex && activeLevel0Index === mainIndex) ? getBorderImage(subItem) : 'linear-gradient(to bottom, #CCCCCC 0%, #CCCCCC 100%) 1' }"
                      >
                        <li
                          *ngFor="let subSubItem of subItem.submenu; let subSubIndex = index"
                          class="submenu-item ml-2 mt-0"
                          McdPermissions
                          [permissions]="subSubItem.permission"
                        >
                          <div
                            class="submenu-item-hover d-flex"
                            *ngIf="subSubItem"
                            (click)="setActiveIndexes(subSubIndex, subIndex, mainIndex)"
                          >
                            <a
                              #link
                              class="dropdown-link mc-sidebar-link mc-sidebar-link-subitem"
                              routerLink="{{ subSubItem.route }}"
                            >
                              {{ subSubItem.translationKey | translate }}
                            </a>
                          </div>
                        </li>
                      </div>
                    </ul>
                  </div>

                  <!-- Submenu with single level -->
                  <div *ngIf="!subItem.submenu.length" class="submenu-item-hover d-flex">
                    <a
                      #link
                      class="dropdown-link mc-sidebar-link mc-sidebar-link-subitem"
                      routerLinkActive="active"
                      routerLink="{{ subItem.route }}"
                    >
                      {{ subItem.translationKey | translate }}
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <!-- Main items without submenu -->
          <div *ngIf="!mainItem.submenu.length">
            <div
              McdPermissions
              [permissions]="mainItem.permission"
              [class.sidebar-list-item_collapsed]="isCollapsed"
              matTooltip="{{ mainItem.translationKey | translate }}"
              [matTooltipClass]="'tabs_tool_tip'"
              [matTooltipDisabled]="!isCollapsed"
              [class.active-tab]="isActive(mainItem.route)"
              class="dropdown"
              routerLink="{{ mainItem.route }}"
              (click)="onSelectMenuItem(mainItem)"
            >
              <span
                class="menu-item-icon"
                [class.menu-item-icon_collapsed]="isCollapsed"
                [innerHTML]="mainItem.icon | safeHtml"
              ></span>
              <a
                *ngIf="!isCollapsed"
                routerLinkActive="active"
                routerLink="{{ mainItem.route }}"
                (click)="onSelectMenuItem(mainItem)"
                class="mc-sidebar-link-no-subitems"
                [class.active-parent]="isActive(mainItem.route)"
              >
                {{ mainItem.translationKey | translate }}
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</div>
