<div class="background" [style.background-image]="landingBg">
  <div class="success_container">
    <img class="success_container_logo" [src]="logo" alt="">
    <div class="success_container_body">
      <span class="success_container_body_title">{{ "cc.entity.registration.success-title" | translate }}!</span>
      <p class="success_container_body_text">{{ "cc.entity.registration.success-text" | translate }}
        <span class="success_container_body_text_last">{{ "cc.entity.registration.success-look-forward" | translate }}</span>
      </p>
    </div>
  </div>
</div>

