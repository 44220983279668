/*BbmTestObj*/
import {Table3Config} from '../extra/table-3-config';
import {EmailConfigTable3ConfigGENERATED} from '../_generated/email-config-table-3-config-generated.model';

// import {EmailConfigGENERATED} from '../_generated/email-config-generated.model';

export class EmailConfigTable3Config extends EmailConfigTable3ConfigGENERATED {

  // ---------------------------------------------------------------------
    constructor() {
    super();

    this.clearColumns();
    this.addColumn('colDefaultEmailPath', 'Default email path', 'defaultEmailPath', 'defaultEmailPath');
    this.addColumn('colEmailName', 'Email name', 'emailName', 'emailName');
    this.addColumn('colEmailType', 'Email type', 'emailType', 'emailType');
    this.addColumn('colLanguageCode', 'Language code', 'languageCode', 'languageCode');


  }
}
