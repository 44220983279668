import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogContent } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { McComponentsModule } from '@miticon-ui/mc-components';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { SafeHtmlModule } from '../../assets/pipes/safe-html/safe-html.module';
import { AdminComponent } from './admin.component';
import { McAdminRoutingModule } from './mc-admin-routing.module';
import { McSelectEntityComponent } from './mc-header/components/mc-select-entity/mc-select-entity.component';
import { SelectEntityListItemComponent } from './mc-header/components/mc-select-entity/select-entity-list-item/select-entity-list-item.component';
import { SelectEntityListWrapperComponent } from './mc-header/components/mc-select-entity/select-entity-list-wrapper/select-entity-list-wrapper.component';
import { McHeaderComponent } from './mc-header/mc-header.component';
import { McSidebarComponent } from './mc-sidebar/mc-sidebar.component';
import { NewMenuDialogComponent } from './mc-sidebar/new-menu-dialog/new-menu-dialog.component';

@NgModule({
  declarations: [AdminComponent, McHeaderComponent, McSidebarComponent, McSelectEntityComponent, SelectEntityListItemComponent, SelectEntityListWrapperComponent, NewMenuDialogComponent],
  imports: [
    CommonModule,
    NgbModule,
    McAdminRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    McComponentsModule,
    TranslateModule,
    SafeHtmlModule,
    MatTooltipModule,
    MatButton,
    MatDialogContent,
  ],
  exports: [AdminComponent, NewMenuDialogComponent],
})
export class McAdminModule {}
