import { Component, EventEmitter, Input, Output } from '@angular/core';
import { McGod } from '@miticon-ui/mc-core';

@Component({
  selector: 'lib-app-shared-action-row',
  standalone: false,
  templateUrl: './app-shared-action-row.component.html',
  styleUrl: './app-shared-action-row.component.scss'
})
export class AppSharedActionRowComponent {

  @Input() leadingIcon: string;
  @Input() leadingTitle: string;
  @Input() leadingText: string;
  @Input() leadingValue: any;

  @Input() trailingIcon: string;
  @Input() trailingTitle: string;
  @Input() trailingPermission: string;

  @Output() onTrailingAction = new EventEmitter<void>();

  mcGod = McGod;

  constructor() {}
}
