/*BbmTestObj*/
import {BkFinanceReportGENERATED} from '../_generated/bk-finance-report-generated.model';
import moment from "moment";
import {ICON_CREATED} from "../svg/icon-created";
import {ICON_FAILED} from "../svg/icon-failed";
import {ICON_INVALID} from "../svg/icon-invalid"

export class BkFinanceReport extends BkFinanceReportGENERATED {

  exportedBy: string;
  financeReportFilename: string;

  public properties: string[] = ['id', 'bookingDateFrom', 'bookingDateTo', 'idWebFile', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'exportedBy', 'financeReportFilename'];
  public propertiesRegular: string[] = ['id', 'bookingDateFrom', 'bookingDateTo', 'idWebFile', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'sysUpdatedUserId', 'exportedBy', 'financeReportFilename'];

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new BkFinanceReport();
  }

  // ---------------------------------------------------------------------

  getDateFrom() {
    return moment(this.bookingDateFrom).format('DD.MM.YYYY');
  }

  getDateTo() {
    return moment(this.bookingDateTo).format('DD.MM.YYYY');
  }

  getCreateDate() {
    return moment(this.sysCreatedDatetime).format('DD.MM.YYYY');
  }

  getStatusCd() {
    if (this.statusCd === BkFinanceReport.STATUS_CD_CREATED) {
      return `<div class='icon-text-table-cell'>${ICON_CREATED} <span>Created</div>`
    }
    if (this.statusCd === BkFinanceReport.STATUS_CD_INVALID) {
      return `<div class='icon-text-table-cell'>${ICON_INVALID} <span>Invalid</span></div>`
    }
    if (this.statusCd === BkFinanceReport.STATUS_CD_FAILED) {
      return `<div class='icon-text-table-cell'>${ICON_FAILED} <span>Failed</div>`
    }
    return this.statusCd;
  }
}
