import {McEntityAdditionalData} from './mc-entity-additional-data.model';
import {McEntityAdditionalDataListGENERATED} from '../_generated/mc-entity-additional-data-list-generated.model';

export class McEntityAdditionalDataList extends McEntityAdditionalDataListGENERATED {

  // ---------------------------------------------------------------------
  public static createNewInstance() {
    return new McEntityAdditionalDataList();
  }

  // ---------------------------------------------------------------------
}
