<div class="mc-consumer-360-tabs-wrapper">
  <span class="back-option" (click)="goToPrevious()">
    <span [innerHTML]="iconBack | safeHtml"></span>
    {{ "cc.consumer360-profile.back-option" | translate }}
  </span>
  <div class="cardbox mt-medium" *ngIf="csrConsumer.id">
    <div class="cardbox_header">
      <span class="cardbox_title">{{( csrConsumer.isTypeCdCOMPANY() ? csrConsumer.companyName : csrConsumer.firstName + " " + csrConsumer.lastName)| uppercase}}</span>
      <button mat-button
              class="primary-btn"
              (click)="goToEditConsumer()"
              type="submit">
        {{ 'cc.consumer.edit-consumer' | translate }}
      </button>
    </div>
    <div class="divider"></div>
    <div class='dialog-row-wrapper dialog-header'>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.consumer.view.consumer-id' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrConsumer.id ? csrConsumer.id : '-' }}</span>
      </div>
      <div class='dialog-col' *ngIf="csrConsumer.isTypeCdPERSON()">
        <span class='dialog-col-label'>{{ 'cc.common.edit.birthday' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrConsumer.birthday ? (csrConsumer.birthday | date : "dd.MM.YYYY") : '-' }}</span>
      </div>
      <div class='dialog-col' *ngIf="csrConsumer.isTypeCdPERSON()">
        <span class='dialog-col-label'>{{ 'cc.common.edit.gender' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrConsumer.gender ? csrConsumer.gender : '-' }}</span>
      </div>
      <div class='dialog-col' *ngIf="csrConsumer.isTypeCdCOMPANY()">
        <span class='dialog-col-label'>{{ 'cc.common.edit.first-name' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrConsumer.firstName ? csrConsumer.firstName : '-' }}</span>
      </div>
      <div class='dialog-col' *ngIf="csrConsumer.isTypeCdCOMPANY()">
        <span class='dialog-col-label'>{{ 'cc.common.edit.last-name' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrConsumer.lastName ? csrConsumer.lastName : '-' }}</span>
      </div>
      <div class='dialog-col' *ngIf="csrConsumer.isTypeCdCOMPANY()">
        <span class='dialog-col-label'>{{ 'cc.common.edit.title' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrConsumer.title ? csrConsumer.title : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.product.view.vat' | translate}}:</span>
        <span class='dialog-col-value'>{{ csrConsumer.euVat ? csrConsumer.euVat : '-' }}</span>
      </div>
    </div>
  </div>
  <div class="card mc-consumer-360-tabs mt-small">
    <ul class="d-flex">
      <li (click)="selectedTab = 0">
        <a [ngClass]="selectedTab === 0 ? 'active' : ''" [routerLink]="'profile/' + csrConsumer.id">{{'cc.common.profile' | translate}}</a>
      </li>
      <li (click)="selectedTab = 1">
        <a [ngClass]="selectedTab === 1 ? 'active' : ''" [routerLink]="'bank-info/' + csrConsumer.id">{{'cc.common.bank-information' | translate}}</a>
      </li>
      <li (click)="selectedTab = 2">
        <a [ngClass]="selectedTab === 2 ? 'active' : ''" [routerLink]="'additional-info/' + csrConsumer.id">{{'cc.consumer.overview.additional-information' | translate}}</a>
      </li>
      <li *ngIf="mcGod.userHasCsrConsumer360ContractPermission()" (click)="selectedTab = 3">
        <a [ngClass]="selectedTab === 3 ? 'active' : ''" [routerLink]="'contracts/' + csrConsumer.id">{{'cc.consumer.overview.contracts' | translate}}</a>
      </li>
      <li *ngIf="mcGod.userHasCsrConsumer360InvoicePermission()" (click)="selectedTab = 4">
        <a [ngClass]="selectedTab === 4 ? 'active' : ''" [routerLink]="'invoices/' + csrConsumer.id">{{'cc.common.invoices' | translate}}</a>
      </li>
    </ul>
  </div>
  <div>
    <router-outlet></router-outlet>
  </div>
</div>
