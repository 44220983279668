import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {EbConsumerBlacklist, McConsumerList, McForm} from "@miticon-ui/mc-core";
import {McBoolean} from '@miticon-ui/mc-core';

@Component({
  selector: 'app-shared-consumer-blacklist-add-part',
  templateUrl: './app-shared-consumer-blacklist-add-part.component.html',
  encapsulation: ViewEncapsulation.None
})

export class AppSharedConsumerBlacklistAddPartComponent implements OnInit {

  public mcForm = new McForm();
  public ebConsumerBlacklist = new EbConsumerBlacklist();
  public notifyMessage = { type: '', message: ''};
  @Input() visibleMcb = new McBoolean();
  @Input() selectedConsumerList = new McConsumerList();
  @Output() eventCancel = new EventEmitter();
  @Output() eventAddedToBlacklist = new EventEmitter();

  ngOnInit() {
  }

  // ---------------------------------------------------------------------
  actCancel() {
    this.notifyMessage.type = '';
    this.notifyMessage.message = '';
    this.clearForm();
    this.visibleMcb.setFalse();
    this.eventCancel.emit();
  }
  clearForm() {
    this.mcForm.getControl('reason')?.get('value')?.setValue('');
    this.mcForm.getControl('factoring')?.get('value')?.setValue('');
    this.mcForm.getControl('directDebit')?.get('value')?.setValue('');
  }

  // ---------------------------------------------------------------------
  isVisible() {
    if (!this.visibleMcb) {
      this.visibleMcb = new McBoolean();
    }
    return this.visibleMcb.value;
  }
  actAddToBlacklist() {
    if (this.mcForm.isValid()) {
      const consumerIds = this.selectedConsumerList.getIds();
      const reason = this.mcForm.getValue('reason');
      console.log('##########', consumerIds, reason);
      this.ebConsumerBlacklist.callAddConsumersToBlacklist(consumerIds, reason, () => {
        this.notifyMessage.type = '';
        this.notifyMessage.message = '';
        this.clearForm();
        this.visibleMcb.setFalse();
        this.eventAddedToBlacklist.emit();
      }, () => {
        this.notifyMessage.type = 'error';
        this.notifyMessage.message = this.ebConsumerBlacklist.apiErrorMessage;
      });
    }
  }
}
