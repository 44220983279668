<ng-container class="dialog">
  <div class="dialog_header">
    <span class="dialog_header_title text-uppercase">
      {{ "cc.entity.profile.activation" | translate }}
    </span>
    <span class="dialog_header_icon"
          [innerHTML]="iconClose | safeHtml"
          (click)="dialogRef.close(false)"></span>
  </div>

  <mat-dialog-content>
    <form [formGroup]="form">
      <div>
        <div class="line mt-medium"></div>
        <div class="config mt-medium">{{ "cc.entity.profile.select-entity" | translate }}:</div>
        <div class="config_item mt-large">{{ "cc.entity.profile.select-entity-activate-text" | translate }}</div>
        <div class="config_item mt-large">{{ "cc.entity.profile.select-entity-deny-text" | translate }}</div>
      </div>
      <div class="radio_buttons">
        <div class="radio_buttons_item">
          <div>
            <mat-radio-group formControlName="activationFlg">
              <mat-radio-button [value]="'activate'">{{'cc.common.activate' | translate}}</mat-radio-button>
              <mat-radio-button [value]="'deny'">{{'cc.entity.profile.deny' | translate}}</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

    </form>
  </mat-dialog-content>

  <div class='dialog_footer'>
    <button mat-button
            class="secondary-btn"
            (click)="dialogRef.close(false)">
      {{ 'cc.common.edit.cancel' | translate }}
    </button>
    <button mat-button
            class="primary-btn"
            type="submit"
            (click)="onConfirm()"
            [disabled]="form.invalid">
      {{ 'cc.common.edit.confirm' | translate }}
    </button>
  </div>
</ng-container>
